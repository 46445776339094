import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators as AuthActions } from '../../store/ducks/auth';
import Loader from '../../components/Loader';
import logoRepasse from '../../assets/logo-repasse-blue.png';
import bgImage from '../../assets/images/bg-default.jpg';

import './style.sass';
import FormGroupSelect from '../../components/FormGroupSelect';

class PageSelectLoja extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storeId: this.props.user.stores[0].id,
        };

        this.pageContainerStyle = { backgroundColor: 'transparent' };
        this.PageLoginStyle = {
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
        };
    }

    render() {
        return (
            <div className="PageLogin" style={this.PageLoginStyle}>
                <div className="page-container" style={this.pageContainerStyle}>
                    <div className="content-container">
                        <div className="PageLogin-card">
                            <div className="PageLogin-logo-container">
                                <img
                                    src={logoRepasse}
                                    alt="Logo Repasse"
                                    className="PageLogin-logo"
                                />
                            </div>

                            <div className="PageLogin-step-title">Selecione a Loja</div>

                            <FormGroupSelect
                                label="Selecione a Loja *"
                                labelSize="small"
                                inputSize="small"
                                options={this.props.user.stores.map(item => ({
                                    value: item.id,
                                    content: item.trade_name,
                                }))}
                                onChange={e => this.setState({ storeId: e.target.value })}
                                errorMessage=""
                            />

                            <button className="button block primary" onClick={this.onStoreSelect}>
                                {this.props.loading ? <Loader /> : 'Entrar'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onStoreSelect = e => {
        e.preventDefault();

        const { storeId } = this.state;
        const { user } = this.props;

        this.props.loadUserData(storeId, user);
    };
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
    loading: auth.loading,
});

const mapDispatchToProps = dispatch => bindActionCreators(AuthActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageSelectLoja);
