import React from 'react';

import './style.sass';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * @param {*} props.icon
 * @param {*} props.iconColor
 * @param {*} props.image
 * @param {*} props.text
 */
const IconText = props => {
    const iconStyle = {
        color: props.iconColor,
        fontSize: props.iconFontSize
    };

    const imageStyle = {
        width: props.imageWidth,
    };

    return (
        <div className="IconText">
            <div className="IconText-icon-container">
                {props.icon && <FontAwesomeIcon icon={props.icon} style={iconStyle} />}
                {props.image && <img src={props.image} style={imageStyle} alt="" />}
            </div>
            <div className="IconText-text">{props.text}</div>
        </div>
    );
};

export default IconText;
