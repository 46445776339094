import React, { Component } from 'react';
import './style.sass';

// Images and Assets
import RepasseInfo from '../RepasseInfo';
import RepasseValores from '../RepasseValores';
import InteressadosContainer from '../InteressadosContainer';
import ModalPreviaRepasse from '../ModalPreviaRepasse';

export default class RepasseItemHome extends Component {
    constructor(props) {
        super(props);

        this.previaModal = React.createRef();
    }

    openPreviaModal = () => this.previaModal.current.openModal();

    render() {
        return (
            <div className="RepasseItemHome">
                <div className="RepasseItemHome-image-container">
                    <img
                        src="https://img.newatlas.com/mazda-vision-coupe-wins-2018-concept-car-of-the-year-4.jpg?auto=format%2Ccompress&ch=Width%2CDPR&fit=crop&h=347&q=60&rect=0%2C106%2C1620%2C912&w=616&s=a40e38fdb6995f043b03589dd2e2bf6e"
                        alt=""
                    />
                </div>
                <RepasseInfo
                    tituloRepasse="Nissan 350z"
                    ano="2018"
                    combustivel="Etanol"
                    cor="Vermelho"
                    diasNoPatio="30"
                    vendedor="Yan Gondim"
                    acompanharNegociacao={true}
                />
                <RepasseValores
                    valorRepasse="180.000"
                    valorFipe="240.000"
                    corElemento="#ffaa00"
                    valorPorcentagem="20"
                />

                <div className="RepasseValores-button-container">
                    <div
                        className="RepasseValores-button button outline primary block"
                        onClick={this.openPreviaModal}
                    >
                        Maiores informações
                    </div>
                </div>

                <InteressadosContainer numInteressados="14" isTurbo={false} />

                <ModalPreviaRepasse ref={this.previaModal} />
            </div>
        );
    }
}
