import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import validator from 'validator';
import { Link } from 'react-router-dom';

import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import MessageBox from '../../components/MessageBox';

import logoRepasse from '../../assets/logo-repasse-blue.png';
import bgImage from '../../assets/images/bg-default.jpg';
import api from '../../services/api';

class PageForgotPassword extends Component {
    state = {
        email: '',
        loading: '',
        error: '',
    };

    constructor(props) {
        super(props);

        this.modal = React.createRef();

        this.pageContainerStyle = { backgroundColor: 'transparent' };
        this.PageLoginStyle = {
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
        };
    }

    openModal = modalRef => {
        return modalRef.current.openModal();
    };

    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    /* goToStep = async step => {
        if (step === 2) await this.onForgotPassword();

        /// if (!this.props.error.length) this.setState({ currentStep: step });
    }; */

    openModal = modalRef => {
        return modalRef.current.openModal();
    };

    onForgotPassword = async () => {
        const { email } = this.state;

        if (!email.length || !validator.isEmail(email)) {
            const error = 'Preencha o email corretamente';

            return this.setState({ error });
        }

        try {
            this.setState({ loading: true });

            await api.post('/api/recupera-senha', {
                email: email,
            });

            this.openModal(this.modal);
        } catch ({ response }) {
            console.log({ response });

            if (response) {
                const { msg } = response.data;

                this.setState({ error: msg });
            }
        } finally {
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.scrollToTop();
    }

    render() {
        return (
            <div className="PageLogin" style={this.PageLoginStyle}>
                <Helmet>
                    <title>Redefinir Senha • Repasse</title>
                </Helmet>
                <div className="page-container" style={this.pageContainerStyle}>
                    <div className="content-container">
                        <div className="PageLogin-card">
                            <div className="PageLogin-logo-container">
                                <img
                                    src={logoRepasse}
                                    alt="Logo Repasse"
                                    className="PageLogin-logo"
                                />
                            </div>

                            <React.Fragment>
                                <Modal
                                    title="Redefinir senha"
                                    customClass="Modal-forgot-password"
                                    ref={this.modal}
                                >
                                    <div className="Modal-forgot-password__text">
                                        Um link para recuperação da senha foi enviado para o e-mail
                                        '{this.state.email}
                                        '.`
                                    </div>

                                    <Link to="/login" className="button primary block">
                                        Ir para login
                                    </Link>
                                </Modal>

                                <div className="PageLogin-step-title">Redefinir Senha</div>

                                <div className="PageLogin-step-subtitle">
                                    Enviaremos um código de recuperação para o e-mail cadastrado na
                                    sua conta.
                                </div>

                                <form className="PageLogin-form">
                                    <div className="form-group">
                                        <label
                                            className="label"
                                            htmlFor="form-forgot-password-email"
                                        >
                                            E-mail
                                        </label>
                                        <input
                                            className="form-control"
                                            id="form-forgot-password-email"
                                            type="email"
                                            onChange={e => this.setState({ email: e.target.value })}
                                            value={this.state.email}
                                        />
                                    </div>

                                    {this.state.error && (
                                        <div
                                            className="form-group"
                                            style={{ width: '100%', textAlign: 'center' }}
                                        >
                                            <MessageBox type="danger" size="normal">
                                                {this.state.error}
                                            </MessageBox>
                                        </div>
                                    )}

                                    <button
                                        className="button block primary"
                                        onClick={this.onForgotPassword}
                                        type="button"
                                    >
                                        {this.state.loading ? <Loader /> : 'Enviar'}
                                    </button>
                                    <Link to="/login" className="forgot-password">
                                        Voltar
                                    </Link>
                                </form>
                            </React.Fragment>

                            {/* {this.state.currentStep === 2 && (
                                <React.Fragment>
                                    <div className="PageLogin-step-title">Redefinir Senha</div>

                                    <div className="PageLogin-step-subtitle">
                                        Um código de recuperação foi enviado para o e-mail '
                                        {this.props.email}
                                        '.
                                    </div>

                                    <form className="PageLogin-form">
                                        <div className="form-group">
                                            <label
                                                className="label"
                                                htmlFor="form-forgot-password-code"
                                            >
                                                Código
                                            </label>
                                            <input
                                                className="form-control"
                                                id="form-forgot-password-code"
                                                type="text"
                                            />
                                        </div>

                                        <button
                                            className="button block primary"
                                            type="button"
                                            onClick={() => this.goToStep(3)}
                                        >
                                            {this.props.loading ? <Loader /> : 'Enviar'}
                                        </button>

                                        <div className="PageLogin-resend-code">
                                            Não recebeu o código?
                                            <button
                                                className="button clean"
                                                type="button"
                                                onClick={this.onForgotPassword}
                                            >
                                                Clique aqui para reenviar
                                            </button>
                                            .
                                        </div>
                                    </form>
                                </React.Fragment>
                            )} */}

                            {/* {this.state.currentStep === 3 && (
                                <React.Fragment>
                                    <Modal
                                        title="Redefinir senha"
                                        customClass="Modal-confirmation"
                                        size="small"
                                        ref={this.modal}
                                    >
                                        <div className="Modal-confirmation__text-container">
                                            Sua senha foi redefinida com sucesso
                                        </div>

                                        <Link to="/login" className="button primary block">
                                            Ir para login
                                        </Link>
                                    </Modal>

                                    <div className="PageLogin-step-title">Redefinir Senha</div>

                                    <div className="PageLogin-step-subtitle">
                                        Redefina sua senha nos campos abaixo.
                                    </div>

                                    <form className="PageLogin-form">
                                        <div className="form-group">
                                            <label
                                                className="label"
                                                htmlFor="form-login-new-password"
                                            >
                                                Nova senha
                                            </label>
                                            <div className="input-icon right">
                                                <div
                                                    className="input-icon__icon-container"
                                                    onClick={() =>
                                                        this.setState({
                                                            passwordMaskNew: !this.state
                                                                .passwordMaskNew,
                                                        })
                                                    }
                                                    style={{
                                                        color: this.state.passwordMaskNew
                                                            ? '#aaa'
                                                            : '#1e88e5',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon="eye" />
                                                </div>
                                                <input
                                                    className="form-control"
                                                    id="form-login-new-password"
                                                    type={
                                                        this.state.passwordMaskNew
                                                            ? 'password'
                                                            : 'text'
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label
                                                className="label"
                                                htmlFor="form-login-confirm-password"
                                            >
                                                Confirmar senha
                                            </label>
                                            <div className="input-icon right">
                                                <div
                                                    className="input-icon__icon-container"
                                                    onClick={() =>
                                                        this.setState({
                                                            passwordMaskConfirmNew: !this.state
                                                                .passwordMaskConfirmNew,
                                                        })
                                                    }
                                                    style={{
                                                        color: this.state.passwordMaskConfirmNew
                                                            ? '#aaa'
                                                            : '#1e88e5',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon="eye" />
                                                </div>
                                                <input
                                                    className="form-control"
                                                    id="form-login-confirm-password"
                                                    type={
                                                        this.state.passwordMaskConfirmNew
                                                            ? 'password'
                                                            : 'text'
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <button
                                            className="button block primary"
                                            type="button"
                                            onClick={this.openModal.bind(this, this.modal)}
                                        >
                                            Redefinir
                                        </button>
                                    </form>
                                </React.Fragment>
                            )} */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageForgotPassword;
