import { createActions } from 'reduxsauce';
import { push } from 'connected-react-router';
import cepPromise from 'cep-promise';

import { Creators as AuthActions } from '../ducks/auth';
import api, { baseURL } from '../../services/api';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
    toggleLoading: ['bool'],
    setError: ['msg'],
    setPlans: ['data'],
    setUserTypes: ['data'],
    setStates: ['data'],
    setUserCities: ['data'],
    setDealershipCities: ['data'],
    setUserAttribute: ['name', 'value'],
    setUserToUpdate: ['data'],
    cleanUser: [],
    resetApp: [],
});

export const getUser = (user_id, store_id) => async dispatch => {
    try {
        const {
            data: { data },
        } = await api.get(`/api/v2/user/${user_id}`);

        dispatch(Creators.setUserToUpdate(data));
        dispatch(Creators.setUserAttribute('photo', `${baseURL}/uploads/avatar/${data.photo}`));
        dispatch(Creators.setUserAttribute('photoFile', ''));

        const {
            data: {
                data: { role },
            },
        } = await api.get(`api/v2/store/${store_id}/staff/${data.id}`);

        if (role === 'Diretor') dispatch(Creators.setUserAttribute('hasOptionPlano', true));
    } catch (error) {
        console.log({ error });
    }
};

export const createUser = user => async dispatch => {
    try {
        dispatch(Creators.setError(''));
        dispatch(Creators.toggleLoading(true));

        let filename = '';
        if (user.photoFile) {
            const formData = new FormData();
            formData.append('filename', user.photoFile, user.photoFile.name);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            const response = await api.post(`/api/user-image`, formData, config);
            filename = response.data.filename;

            console.log('response photo register', response);
        }
        console.log('user register', user);

        if (user.user_type !== '3' && user.user_type !== '4') {
            await api.post('/api/register', {
                photo: filename,
                user_type: user.user_type,
                name: user.name,
                email: user.email,
                password: user.password,
                dealership: user.dealership,
                document: user.document.replace(/\D/g, ''),
                zipcode: user.zipcode.replace(/\D/g, ''),
                address: user.address,
                number: user.number,
                neighborhood: user.neighborhood,
                city: user.city,
                state: user.state,
                phone: user.phone.replace(/\D/g, ''),
                cell_phone: user.cell_phone.replace(/\D/g, ''),
                dealership_company_name: user.dealership_company_name,
                dealership_trade_name: user.dealership_trade_name,
                dealership_cnpj: user.dealership_cnpj.replace(/\D/g, ''),
                dealership_city_id: user.dealership_city_id,
                dealership_neighborhood: user.dealership_neighborhood,
                dealership_street: user.dealership_street,
                dealership_number: user.dealership_number,
                dealership_complement: user.dealership_complement,
                dealership_landmark: user.dealership_landmark,
                dealership_zipcode: user.dealership_zipcode.replace(/\D/g, ''),
                dealership_state: user.dealership_state,
                // plan_id: user.plan_id,
            });
        } else {
            await api.post('/api/register', {
                photo: filename,
                user_type: user.user_type,
                name: user.name,
                email: user.email,
                password: user.password,
                dealership: user.dealership,
                document: user.document.replace(/\D/g, ''),
                zipcode: user.zipcode.replace(/\D/g, ''),
                address: user.address,
                number: user.number,
                neighborhood: user.neighborhood,
                city: user.city,
                state: user.state,
                phone: user.phone.replace(/\D/g, ''),
                cell_phone: user.cell_phone.replace(/\D/g, ''),
            });
        }

        URL.revokeObjectURL(user.photo);

        dispatch(push('/cadastro-usuario-quatro'));
        dispatch(Creators.cleanUser());
    } catch (error) {
        console.log({ error });

        dispatch(
            Creators.setError('Um erro ocorreu ao criar o usuário, tente novamente mais tarde')
        );
    } finally {
        dispatch(Creators.toggleLoading(false));
    }
};

export const updateUser = user => async dispatch => {
    try {
        dispatch(Creators.toggleLoading(true));

        if ((user.user_type !== '3' && user.user_type !== '4') || user.hasOptionPlano) {
            await api.post('/api/v2/user/update', {
                user_type: user.user_type,
                name: user.name,
                email: user.email,
                password: user.password,
                dealership: user.dealership_trade_name,
                document: user.document.replace(/\D/g, ''),
                zipcode: user.zipcode.replace(/\D/g, ''),
                address: user.address,
                number: user.number,
                neighborhood: user.neighborhood,
                city: user.city,
                state: user.state,
                phone: user.phone.replace(/\D/g, ''),
                cell_phone: user.cell_phone.replace(/\D/g, ''),
                store_id: user.store_id,
                dealership_company_name: user.dealership_company_name,
                dealership_trade_name: user.dealership_trade_name,
                dealership_cnpj: user.dealership_cnpj.replace(/\D/g, ''),
                dealership_city_id: user.dealership_city_id,
                dealership_neighborhood: user.dealership_neighborhood,
                dealership_street: user.dealership_street,
                dealership_number: user.dealership_number,
                dealership_complement: user.dealership_complement,
                dealership_landmark: user.dealership_landmark,
                dealership_zipcode: user.dealership_zipcode.replace(/\D/g, ''),
                dealership_state: user.dealership_state,
                plan_id: user.plan_id,
            });
        } else {
            await api.post('/api/v2/user/update', {
                user_type: user.user_type,
                name: user.name,
                email: user.email,
                password: user.password,
                dealership: user.dealership_trade_name,
                document: user.document.replace(/\D/g, ''),
                zipcode: user.zipcode.replace(/\D/g, ''),
                address: user.address,
                number: user.number,
                neighborhood: user.neighborhood,
                city: user.city,
                state: user.state,
                phone: user.phone.replace(/\D/g, ''),
                cell_phone: user.cell_phone.replace(/\D/g, ''),
            });
        }

        if (user.photoFile) {
            const formData = new FormData();
            formData.append('filename', user.photoFile, user.photoFile.name);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            await api.post(`/api/v2/user/image/update`, formData, config);

            URL.revokeObjectURL(user.photo);
        }

        dispatch(AuthActions.acceptTermsOfUse());

        dispatch(push('/cadastro-usuario-quatro'));
        dispatch(Creators.cleanUser());
    } catch (error) {
        console.log({ error });
    } finally {
        dispatch(Creators.toggleLoading(false));
    }
};

export const getPlans = () => async dispatch => {
    try {
        dispatch(Creators.toggleLoading(true));

        const response = await api.get('/api/plans');
        const { data } = response.data;

        dispatch(Creators.setPlans(data));
    } catch (error) {
        console.log({ error });
    } finally {
        dispatch(Creators.toggleLoading(false));
    }
};

export const getUserTypes = () => async dispatch => {
    try {
        const response = await api.get('/api/user/types');
        const { data } = response.data;

        dispatch(Creators.setUserTypes(data));
    } catch (error) {
        console.log({ error });
    }
};

export const getAddressByCEP = (zipcode, states, setCepInvalido) => async dispatch => {
    try {
        const cepFormatted = zipcode.replace(/\D/g, '');

        if (cepFormatted.length === 8) {
            const response = await cepPromise(cepFormatted);
            const { city, neighborhood, state, street } = response;

            states.forEach(item => {
                if (state === item.initials) getCities(item.id, 'user')(dispatch);
            });

            dispatch(Creators.setUserAttribute('city', city));
            dispatch(Creators.setUserAttribute('neighborhood', neighborhood));
            dispatch(Creators.setUserAttribute('state', state));
            dispatch(Creators.setUserAttribute('address', street));
        }
    } catch (error) {
        setCepInvalido();
        console.log(error);
    }
};

export const getAddressByDealershipCEP = (
    zipcode,
    states,
    setDealershipCepInvalido
) => async dispatch => {
    try {
        const cepFormatted = zipcode.replace(/\D/g, '');

        if (cepFormatted.length === 8) {
            const response = await cepPromise(cepFormatted);
            const { city, neighborhood, state, street } = response;

            let state_id = '';

            states.forEach(item => {
                if (state === item.initials) state_id = item.id;
            });

            const cities = await getCities(state_id, 'dealership')(dispatch);

            cities.forEach(item => {
                if (city === item.name) {
                    dispatch(Creators.setUserAttribute('dealership_city_id', item.id));
                }
            });

            dispatch(Creators.setUserAttribute('dealership_neighborhood', neighborhood));
            dispatch(Creators.setUserAttribute('dealership_state', state));
            dispatch(Creators.setUserAttribute('dealership_street', street));
        }
    } catch (error) {
        setDealershipCepInvalido();
        console.log(error);
    }
};

export const getStates = () => async dispatch => {
    try {
        const response = await api.get(`/api/states`);
        const { data } = response.data;

        dispatch(Creators.setStates(data));
    } catch (error) {
        console.log({ error });
    }
};

export const getCities = (state_id, userData) => async dispatch => {
    try {
        const response = await api.get(`/api/cities/${state_id}`);
        const { data } = response.data;

        if (userData === 'user') dispatch(Creators.setUserCities(data));
        if (userData === 'dealership') dispatch(Creators.setDealershipCities(data));

        return data;
    } catch (error) {
        console.log({ error });
    }
};
