import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import iconAddPhoto from '../../assets/images/icon-add-photo.png';
import MessageBox from '../MessageBox';
import Modal from '../Modal';
import './style.sass';

class CRGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            galleryImages: [],
            isEmpty: false,
            notice: 'teste',
        };

        this.modalNotice = React.createRef();
    }

    componentDidMount() {
        this.setState({ galleryImages: this.props.gallery });
    }

    openModal = notice => {
        this.setState({ notice }, this.modalNotice.current.openModal());
    };

    closeModal = () => {
        this.modalNotice.current.closeModal();
    };

    addImageToGallery = event => {
        let currentLength = this.state.galleryImages.length;

        const files = [...event.target.files];

        if (currentLength + files.length > 9) {
            event.target.value = '';
            const limit = 9 - currentLength;
            const message =
                limit > 1
                    ? `Você só pode adicionar mais ${limit} fotos!`
                    : `Você só pode adicionar mais uma foto!`;
            this.openModal(message);
            return;
        }

        files.forEach((item, index) => {
            const reader = new FileReader();
            const file = event.target.files[index];

            reader.readAsDataURL(file);
            reader.onerror = error => console.log(error);
            reader.onload = upload => {
                if (
                    !this.state.galleryImages.filter(item => item === upload.target.result).length
                ) {
                    file.path = upload.target.result;

                    this.setState(
                        prevState => ({
                            galleryImages: [...prevState.galleryImages, file],
                            isEmpty: false,
                        }),
                        () => this.props.handleStateChange('gallery', this.state.galleryImages)
                    );
                } else {
                    this.openModal('Você já realizou o upload dessa imagem!');
                }
            };

            console.dir(event.target);
        });

        event.target.value = '';
    };

    removeImageFromGallery = id => {
        const initialGallery = [...this.state.galleryImages];
        const image = initialGallery[id];

        if (image.id)
            this.props.handleStateChange('galleryDelete', [...this.props.galleryDelete, image.id]);

        this.setState(
            {
                galleryImages: initialGallery.filter((item, index) => item !== initialGallery[id]),
            },
            () => {
                this.props.handleStateChange('gallery', this.state.galleryImages);
            }
        );
    };

    goForward = () => {
        let { galleryImages } = this.state;
        if (galleryImages.length < 3 || galleryImages.length > 9) {
            this.setState({ isEmpty: true });
        } else {
            this.props.nextStep();
        }
    };

    swapIndex = (index1, index2) => {
        let { galleryImages } = this.state;
        let transformed = galleryImages;

        [transformed[index1], transformed[index2]] = [transformed[index2], transformed[index1]];

        this.setState({ galleryImages: transformed });
    };

    render() {
        return (
            <React.Fragment>
                <h2 className="CadastroRepasse-title">
                    Adicione fotos para a galeria deste repasse
                    <small className="observacao">
                        Você pode adicionar ter até 10 imagens na galeria, contando com a imagem de
                        capa do Repasse.
                    </small>
                </h2>

                <input
                    type="file"
                    accept="image/*"
                    onChange={event => this.addImageToGallery(event)}
                    className="CadastroRepasse-gallery-input"
                    id="CadastroRepasse-gallery-input"
                    multiple
                />

                {this.state.isEmpty && (
                    <div className="margin-bottom">
                        <MessageBox type="danger" inlineBlock>
                            A galeria deve ter entre 3 e 9 fotos!
                        </MessageBox>
                    </div>
                )}

                <div className="CadastroRepasse-gallery-listing">
                    {this.state.galleryImages.map((item, index) => (
                        <React.Fragment key={index}>
                            <label
                                htmlFor="CadastroRepasse-gallery-input"
                                className="CadastroRepasse-gallery-item"
                            >
                                <div className="CadastroRepasse-gallery-item-content-container responsive-embed">
                                    <img
                                        src={item && item.path}
                                        className="CadastroRepasse-gallery-item-photo responsive-embed-item"
                                        alt=""
                                    />
                                </div>
                                <button
                                    className="button clean CadastroRepasse-gallery-item-close"
                                    onClick={() => this.removeImageFromGallery(index)}
                                >
                                    <FontAwesomeIcon icon="times" />
                                </button>
                            </label>
                            {index < this.state.galleryImages.length - 1 && (
                                <button
                                    onClick={() => this.swapIndex(index, index + 1)}
                                    className="button clean"
                                >
                                    <FontAwesomeIcon
                                        icon="exchange-alt"
                                        style={{ color: '#335180' }}
                                    />
                                </button>
                            )}
                        </React.Fragment>
                    ))}

                    {this.state.galleryImages.length < 9 && (
                        <label
                            htmlFor="CadastroRepasse-gallery-input"
                            className="CadastroRepasse-gallery-item"
                        >
                            <div className="CadastroRepasse-gallery-item-content-container responsive-embed">
                                <img
                                    src={iconAddPhoto}
                                    className="CadastroRepasse-gallery-add-photo"
                                    alt=""
                                />
                            </div>
                        </label>
                    )}
                </div>

                <div className="CadastroRepasse-navigation margin-top">
                    <button className="button primary outline" onClick={this.props.prevStep}>
                        <FontAwesomeIcon className="navigation-icon" icon="chevron-left" />
                        Detalhes do veículo
                    </button>
                    <button className="button primary" onClick={() => this.goForward()}>
                        Confirmar dados
                        <FontAwesomeIcon className="navigation-icon" icon="chevron-right" />
                    </button>
                </div>

                <Modal ref={this.modalNotice} title={'Aviso'} size="small">
                    <div className="margin-top">
                        <div>{this.state.notice}</div>
                        <div
                            className="margin-top"
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <button className="button grey big" onClick={() => this.closeModal()}>
                                Fechar
                            </button>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default CRGallery;
