import React, { Component } from 'react';
import { baseURL } from '../../services/api';

// Style
import './style.sass';

export default class NonAvatar extends Component {
    render() {
        return (
            <div>
                {this.props.userPhoto ? (
                    <img
                        className="non-avatar"
                        src={`${baseURL}/uploads/avatar/${this.props.userPhoto}`}
                        alt=""
                    />
                ) : (
                    <div className="non-avatar">{this.props.iniciais}</div>
                )}
            </div>
        );
    }
}
