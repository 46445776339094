import React, { Component } from 'react'
// Style
import './style.sass';

import fotoBanner from '../../assets/carros-banner-turbine.png';

export default class BannerTurbine extends Component {
  render() {
    return (
      <div className="banner-turbine">
            <div className="texto-banner">
                <div className="chamada">{this.props.textoBanner}</div>
                <div className="botao-assinar">
                    {this.props.textoBotao}
                </div>
            </div>

            <div className="foto-banner">
                <img src={fotoBanner} alt=""/>
            </div>
      </div>
    )
  }
}
