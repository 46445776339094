import React from 'react';

import InputMask from 'react-input-mask';
import FormGroupSelect from '../FormGroupSelect';
// import FormGroupSelect from '../../components/FormGroupSelect';
// import iconArrowPositive from '../../assets/images/icon-arrow-positive.png';
// import iconArrowNegative from '../../assets/images/icon-arrow-negative.png';

import './style.sass';

const GestaoEquipeSearchRow = props => {
    function returnNameInitials(name) {
        const names = name.split(' ');
        const initials = names.map(name => name[0]).join('');

        if (initials.length > 2) {
            return '' + initials[0] + initials[initials.length - 1];
        }

        return initials;
    }

    const nameInitials = returnNameInitials(props.name);

    let isLite = false;
    let avatar = props.imageUrl ? (
        <img className="gestao-equipe-avatar" src={props.imageUrl} alt="Avatar" />
    ) : (
        <div className="gestao-equipe-initials">{nameInitials}</div>
    );

    if (props.isLite === true) {
        isLite = true;
    }

    return (
        <div
            className={isLite ? 'GestaoEquipeRow is-lite' : 'GestaoEquipeRow'}
            style={{ alignItems: 'center' }}
        >
            <div className="GestaoEquipeRow-item --avatar">
                <div className="gestao-equipe-avatar-container">{avatar}</div>
            </div>

            <div className="GestaoEquipeRow-item --name">
                <div className="gestao-equipe-name-container">
                    <div className="gestao-equipe-name">{props.name}</div>
                    <div className="gestao-equipe-phones">
                        <InputMask
                            mask="(99) 99999-9999"
                            value={props.phone}
                            style={{ border: 0 }}
                            readOnly
                        />
                        <div>{props.email}</div>
                    </div>
                </div>
            </div>

            {/* <div style={{ margin: '0 auto' }} /> */}

            <div className="GestaoEquipeRow-item --invite">
                <FormGroupSelect
                    label=""
                    onChange={e => props.onChangeUserSearchRole(e.target.value)}
                    placeholder="Cargo"
                    options={props.roles}
                    value={props.roleSearch}
                    errorMessage=""
                    size="small"
                    noMargin
                />
                <button className="button primary" onClick={props.onInvite}>
                    Convidar
                </button>
            </div>
        </div>
    );
};

export default GestaoEquipeSearchRow;
