import React from 'react';

const PageContainer = props => {
    return (
        <div className="page-container">
            <div className="content-container">{props.children}</div>
        </div>
    );
};

export default PageContainer;
