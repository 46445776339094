import React, { Component } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnimateHeight from 'react-animate-height';

//Style
import './style.sass';

export default class Collapse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    componentDidMount() {
        const { isOpen = false } = this.props;

        this.setState({
            isOpen,
        });
    }

    collapseToggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };

    render() {
        const collapseBarClass = classNames('collapse-bar', {
            'is-open': this.state.isOpen,
        });
        return (
            <div>
                <div className={collapseBarClass} onClick={this.collapseToggle}>
                    <div className="collapse-bar-title">
                        {this.props.title}
                        <FontAwesomeIcon icon="chevron-down" />
                    </div>
                </div>
                <AnimateHeight duration={400} height={this.state.isOpen ? 'auto' : 0}>
                    <div className="collapse-container">{this.props.children}</div>
                </AnimateHeight>
            </div>
        );
    }
}
