import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import CardConteudo from '../../components/CardConteudo';
import TopBar from '../../components/TopBar';

import iconWarning from '../../assets/images/icon-warning-lg.png';

import './style.sass';

class Page404 extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <TopBar />
                <div className="Page404 page-container">
                    <div className="content-container">
                        <CardConteudo>
                            <div className="Page404-card-content">
                                <img className="Page404-image" src={iconWarning} alt="" />
                                <h2 className="Page404-title">Erro 404</h2>
                                <div className="Page404-subtitle">
                                    A página que você procura não pôde ser encontrada
                                </div>
                                <Link className="button blue" to="/">
                                    Voltar para a página inicial
                                </Link>
                            </div>
                        </CardConteudo>
                    </div>
                </div>
            </>
        );
    }
}

export default Page404;
