import React, { Component } from 'react';
import './style.sass';

class BurgerMenu extends Component {
    constructor(props) {
        super(props);
        this.state = { activeClass: false };
    }

    toggleMenu() {
        this.setState({ activeClass: !this.setState.activeClass });
    }

    render() {
        return (
            <div
                className="burger-icon-container"
                onClick={() => {
                    this.props.onClick();
                }}
                tabIndex="0"
                aria-label="Abrir menu"
            >
                <div className="burger-icon white" />
            </div>
        );
    }
}

export default BurgerMenu;
