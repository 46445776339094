import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators as RepassesListActions } from '../../store/ducks/repassesList';

import TopBar from '../../components/TopBar';
import CardConteudo from '../../components/CardConteudo';
import RepasseInfo from '../../components/RepasseInfo';
import StatusLabel from '../../components/StatusLabel';
import RepasseValores from '../../components/RepasseValores';
import BotaoCarregarMais from '../../components/BotaoCarregarMais';
import TitleAside from '../../components/TitleAside';

import './style.sass';
import MessageBox from '../../components/MessageBox';

import api, { baseURL } from '../../services/api';

import SpinnerFull from '../../components/SpinnerFull';

import Modal from '../../components/Modal';

const intlMonetary = new Intl.NumberFormat('pt-BR', {
    // style: 'currency',
    // currency: 'BRL',
    minimumFractionDigits: 2,
});

class PageGestaoFavoritos extends Component {
    state = {
        id_repasse: '',
        loading: false,
        current_page: 1,
        listEntry: [],
        cantLoad: false,
    };

    constructor(props) {
        super(props);

        this.modalConfirmation = React.createRef();
    }

    componentDidMount = () => {
        this.loadFavorites();
    };

    loadFavorites = async () => {
        try {
            this.setState({ loading: true });

            const response = await api.get(
                `${baseURL}/api/v2/my-favorites?current_page=${this.state.current_page}`
            );

            let listEntry = [];

            if (this.state.current_page === 1) listEntry = response.data.data.items;
            else listEntry = this.state.listEntry.concat(response.data.data.items);

            this.setState({
                listEntry,
                loading: false,
                cantLoad: response.data.data.totals < this.state.current_page * 10 ? true : false,
                current_page: this.state.current_page + 1,
            });
        } catch (error) {
            console.log({ error });
            this.setState({ loading: false, emptyListText: 'Ocorreu um erro. Tente novamente.' });
        }
    };

    removeFavorite = async () => {
        try {
            // await api.get(`/api/v2/favorites/${this.state.id_repasse}/change`);

            /**
             * Esta ação precisa ser feita com
             * redux, pois tem efeitos colaterais na PageHome
             */
            this.props.toggleRepasseFavorite(this.state.id_repasse);

            const { listEntry } = this.state;

            let arr = listEntry.filter(item => item.id !== this.state.id_repasse);

            this.setState({ listEntry: arr }, () => this.closeModal());
        } catch (error) {
            console.log(error);
        }
    };

    getPorcentagemColor = valorPorcentagem => {
        let color = '';

        if (valorPorcentagem < 15) color = '#D0021B';
        if (valorPorcentagem >= 15 && valorPorcentagem <= 16) color = '#FFAA00';
        if (valorPorcentagem > 16 && valorPorcentagem <= 19) color = '#F78C46';
        if (valorPorcentagem > 20) color = '#34A853';

        return color;
    };

    openModal = () => {
        this.modalConfirmation.current.openModal();
    };

    closeModal = () => {
        this.modalConfirmation.current.closeModal();
    };

    render() {
        const { listEntry, loading, cantLoad } = this.state;

        return (
            <React.Fragment>
                <TopBar />

                <Helmet>
                    <title>Gestão de Favoritos • Repasse</title>
                </Helmet>

                <div className="page-container PageGestaoFavoritos">
                    <div className="content-container">
                        <Modal ref={this.modalConfirmation} title={'Desfavoritar'} size="small">
                            <div className="margin-top">
                                <div>Deseja realmente desfavoritar este repasse?</div>
                                <div
                                    className="margin-top"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <button
                                        className="button primary big margin-right--small"
                                        onClick={() => this.removeFavorite()}
                                    >
                                        Sim
                                    </button>
                                    <button
                                        className="button grey big"
                                        onClick={() => this.closeModal()}
                                    >
                                        Não
                                    </button>
                                </div>
                            </div>
                        </Modal>

                        <TitleAside title="Gestão de Favoritos">
                            <MessageBox type="warning">
                                Repasses são removidos automaticamente dos seus favoritos caso
                                tenham sido vendidos.
                            </MessageBox>
                        </TitleAside>

                        <CardConteudo>
                            <div className="Favoritos-listing">
                                <SpinnerFull
                                    size={40}
                                    spinnerColor={'#335180'}
                                    spinnerWidth={3}
                                    visible={loading}
                                />
                                {!loading && !listEntry.length ? (
                                    <div className="result__title">
                                        Você não favoritou nenhum anúncio.
                                    </div>
                                ) : (
                                    listEntry.map((item, index) => {
                                        const valorPorcentagem = Math.abs(
                                            item.fipe_percent_off.split('%')[0]
                                        );

                                        return (
                                            <div className="Favoritos-item" key={index}>
                                                <Link
                                                    to={`/repasses/${item.id}/${item.user.id}`}
                                                    className="Favoritos-image-container"
                                                >
                                                    <img
                                                        src={`${baseURL}/uploads/gallery/${
                                                            item.photos[0].filename
                                                        }`}
                                                        className="Favoritos-image"
                                                        alt=""
                                                    />
                                                </Link>
                                                <div className="Favoritos-info">
                                                    <RepasseInfo
                                                        idRepasse={item.id}
                                                        idUsuario={item.user.id}
                                                        tituloRepasse={item.model}
                                                        ano={item.year}
                                                        combustivel={item.fuel}
                                                        cor={item.color}
                                                        diasNoPatio={item.created_at}
                                                        marginAuto={true}
                                                        canSee
                                                        vendedor={item.user.name}
                                                        showStockLink
                                                    />
                                                </div>
                                                <div className="Favoritos-status">
                                                    <StatusLabel
                                                        statusValue={item.status.name}
                                                        isLite={true}
                                                    />
                                                </div>
                                                <div className="Favoritos-valores">
                                                    <RepasseValores
                                                        valorRepasse={intlMonetary.format(
                                                            item.price
                                                        )}
                                                        valorFipe={intlMonetary.format(
                                                            item.fipe_price
                                                        )}
                                                        valorPorcentagem={valorPorcentagem}
                                                        corElemento={this.getPorcentagemColor(
                                                            valorPorcentagem
                                                        )}
                                                    />
                                                </div>
                                                <div
                                                    className="Favoritos-desfavoritar"
                                                    onClick={() => {
                                                        this.openModal();
                                                        this.setState({ id_repasse: item.id });
                                                    }}
                                                >
                                                    <button className="button clean">
                                                        <div className="desfavoritar-icon-container">
                                                            <FontAwesomeIcon icon="star" />
                                                        </div>
                                                        <div className="desfavoritar-text">
                                                            Desfavoritar
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        </CardConteudo>
                        {!cantLoad && (
                            <BotaoCarregarMais margin="4rem auto 0" onClick={this.loadFavorites} />
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(RepassesListActions, dispatch);

export default connect(
    null,
    mapDispatchToProps
)(PageGestaoFavoritos);
