import React, { Component } from 'react';

import './CRVehicleSelection.sass';

import iconCar from '../../assets/images/icon-car.png';
import iconTruck from '../../assets/images/icon-truck.png';
import iconMotorcycle from '../../assets/images/icon-motorcycle.png';

class CRVehicleSelection extends Component {
    vehicleItems = [
        {
            id: 0,
            icon: iconCar,
            description: 'Carros e utilitários pequenos',
            buttonText: 'Cadastrar carro',
        },
        {
            id: 5,
            icon: iconTruck,
            description: 'Caminhões e micro ônibus',
            buttonText: 'Cadastrar caminhão',
        },
        { id: 8, icon: iconMotorcycle, description: 'Motos', buttonText: 'Cadastrar moto' },
    ];

    render() {
        return (
            <React.Fragment>
                <h2 className="CadastroRepasse-title">Escolha o tipo de veículo</h2>
                <div className="CadastroRepasse-VehicleSelection">
                    <div className="VehicleSelection__listing">
                        {this.vehicleItems.map((item, index) => {
                            return (
                                <div className="VehicleSelection__item" key={index}>
                                    <div className="VehicleSelection__image-container">
                                        <img
                                            src={item.icon}
                                            alt=""
                                            className="VehicleSelection__image"
                                        />
                                    </div>
                                    <div className="VehicleSelection__description">
                                        {item.description}
                                    </div>
                                    <div className="VehicleSelection__action-container">
                                        <button
                                            className="button primary"
                                            onClick={() => {
                                                this.props.handleStateChange(
                                                    'vehicleType',
                                                    item.id
                                                );
                                                if(item.id === 8) {
                                                    this.props.handleStateChange(
                                                        'vehicleDoors',
                                                        0
                                                    );
                                                }
                                                this.props.nextStep();
                                            }}
                                        >
                                            {item.buttonText}
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CRVehicleSelection;
