import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators as AuthActions } from '../../store/ducks/auth';
import { Creators as UserCreateActions } from '../../store/userCreate/actions';
import Loader from '../../components/Loader';
import MessageBox from '../../components/MessageBox';
import logoRepasse from '../../assets/logo-repasse-blue.png';
import bgImage from '../../assets/images/bg-default.jpg';

import './style.sass';

class PageLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordMask: true,
            email: '',
            password: '',
        };

        this.pageContainerStyle = { backgroundColor: 'transparent' };
        this.PageLoginStyle = {
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
        };
    }

    componentDidMount() {
        const { state } = this.props.location;

        if (state && state.goto) this.props.setGoto(state.goto);
    }

    onLoginUser = e => {
        e.preventDefault();
        const { email, password } = this.state;

        this.props.loginUser(email, password);
    };

    togglePasswordVisibility = () => {
        this.setState({ passwordMask: !this.state.passwordMask });
    };

    render() {
        return (
            <div className="PageLogin" style={this.PageLoginStyle}>
                <Helmet>
                    <title>Login • Repasse</title>
                </Helmet>

                <div className="page-container" style={this.pageContainerStyle}>
                    <div className="content-container">
                        <div className="PageLogin-card">
                            <div className="PageLogin-logo-container">
                                <img
                                    src={logoRepasse}
                                    alt="Logo Repasse"
                                    className="PageLogin-logo"
                                />
                            </div>

                            <div className="PageLogin-step-title">Login</div>

                            <form className="PageLogin-form">
                                <div className="form-group">
                                    <label className="label" htmlFor="form-login-email">
                                        E-mail
                                    </label>
                                    <input
                                        className="form-control"
                                        id="form-login-email"
                                        type="email"
                                        onChange={e => this.setState({ email: e.target.value })}
                                        value={this.state.email}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="label" htmlFor="form-login-password">
                                        Senha
                                    </label>
                                    <div className="input-icon right">
                                        <div
                                            className="input-icon__icon-container"
                                            onClick={() => this.togglePasswordVisibility()}
                                            style={{
                                                color: this.state.passwordMask ? '#aaa' : '#1e88e5',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <FontAwesomeIcon icon="eye" />
                                        </div>
                                        <input
                                            className="form-control"
                                            id="form-login-password"
                                            type={this.state.passwordMask ? 'password' : 'text'}
                                            onChange={e =>
                                                this.setState({ password: e.target.value })
                                            }
                                            value={this.state.password}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="checkbox-group">
                                        <input type="checkbox" id="form-login-remember-me" />
                                        <label htmlFor="form-login-remember-me" className="label">
                                            Lembrar-me
                                        </label>
                                    </div>
                                </div>

                                {this.props.error && (
                                    <div
                                        className="form-group"
                                        style={{ width: '100%', textAlign: 'center' }}
                                    >
                                        <MessageBox type="danger" size="normal">
                                            {this.props.error}
                                        </MessageBox>
                                    </div>
                                )}

                                <button className="button block primary" onClick={this.onLoginUser}>
                                    {this.props.loading ? <Loader /> : 'Entrar'}
                                </button>
                                <Link to="/forgot-password" className="forgot-password">
                                    Esqueci minha senha
                                </Link>
                            </form>

                            <div className="PageLogin-register-container">
                                <div className="PageLogin-register-title">
                                    Faça parte da maior rede profissional de Repasses automotivos do
                                    Brasil
                                </div>
                                <button
                                    style={{ textAlign: 'center' }}
                                    className="PageLogin-register-link button outline primary block"
                                    onClick={() => {
                                        this.props.cleanUser();
                                        this.props.history.push('/cadastro-usuario-um');
                                    }}
                                >
                                    Cadastre-se
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    error: auth.error,
    loading: auth.loading,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ ...AuthActions, ...UserCreateActions }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageLogin);
