import React from 'react';
import { Link } from 'react-router-dom';

import iconNoTurbo from '../../assets/images/icon-no-turbo.png';
import iconTurboLite from '../../assets/images/icon-turbo-fast.png';
import iconTurboMax from '../../assets/images/icon-turbo-max.png';

import './style.sass';

const RepasseTurboStatus = props => {
    let turboIcon = iconNoTurbo;
    let turboText = 'Sem Turbo';
    let turboCtaText = 'Adquira já';

    if (props.turboStatus === 'no-turbo') {
        turboIcon = iconNoTurbo;
        turboText = 'Sem Turbo';
        turboCtaText = 'Adquira já';
    } else if (props.turboStatus === 'turbo-lite') {
        turboIcon = iconTurboLite;
        turboText = 'Turbo Lite';
        turboCtaText = 'Adquira mais';
    } else if (props.turboStatus === 'turbo-full') {
        turboIcon = iconTurboMax;
        // turboText = 'Turbo Máximo';
        turboText = 'Turbo';
        turboCtaText = '';
    } else {
        console.warn('props.turboStatus inválido.');
    }

    if (props.status && props.status !== 1 && props.status !== 9) {
        turboIcon = iconNoTurbo;
        turboText = 'Indisponível';
        turboCtaText = '';
    }

    return (
        <div className="RepasseTurboStatus">
            <div className="RepasseTurboStatus-image-container">
                <img src={turboIcon} alt="" className="RepasseTurboStatus-image" />
            </div>
            <div className="RepasseTurboStatus-status">{turboText}.</div>
            {turboCtaText && (
                <Link
                    to={{
                        pathname: `/repasses/${props.idRepasse}/${props.idUsuario}`,
                        state: { showModalTurbo: true },
                    }}
                    className="RepasseTurboStatus-cta"
                >
                    {turboCtaText}
                </Link>
            )}
            <span />
        </div>
    );
};

export default RepasseTurboStatus;
