import React, { Component } from 'react'
import './style.sass';


export default class DescricaoPlano extends Component {
  render() {
    return (
        <div className="descricao-plano-container">
            <div className="titulo-plano">{this.props.tituloPlano}</div>
            <div className="descricao-plano">
            Por <span className="destaque-bold">R$ {this.props.valorPlano} mensais</span>, você tem:
            <br/>
            <br/>
            <span className="destaque-bold">{this.props.transacoesMensais}</span> transações mensais e <span className="destaque-bold">R$ {this.props.valorAdicionalTransacao}</span> por transação adicional<br/>
            Sem limite de fotos por veículo<br/>
            <span className="destaque-bold"> 10</span> Turbo por mês e <span className="destaque-bold">R$ 50</span> por Turbo avulso<br/>
            <span className="destaque-bold"> R$ {this.props.valorConsulta}</span> por consulta veícular e {this.props.quantidadeVoucher} voucher de consulta no primeiro mês<br/>
            Criação de diretores, gerentes e vendedores ilimitada
            </div>
        </div>
    )
  }
}
