import React from 'react';

import iconEdit from '../../assets/images/icon-edit.png';
import iconRefresh from '../../assets/images/icon-refresh.png';
import iconRemove from '../../assets/images/icon-remove.png';
import InputMask from 'react-input-mask';
// import iconArrowPositive from '../../assets/images/icon-arrow-positive.png';
// import iconArrowNegative from '../../assets/images/icon-arrow-negative.png';

import './style.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GestaoEquipeRow = props => {
    /* function returnVariationIcon(variationInput) {
        if (variationInput === 'positive' || variationInput === 'pos') {
            return iconArrowPositive;
        } else if (variationInput === 'negative' || variationInput === 'neg') {
            return iconArrowNegative;
        } else {
            return null;
        }
    } */

    function returnNameInitials(name) {
        const names = name.split(' ');
        const initials = names.map(name => name[0]).join('');

        if (initials.length > 2) {
            return '' + initials[0] + initials[initials.length - 1];
        }

        return initials;
    }

    const nameInitials = returnNameInitials(props.name);
    // const salesVariation = returnVariationIcon(props.salesVariation);
    // const avgTimeVariation = returnVariationIcon(props.avgTimeVariation);

    let isLite = false;
    let avatar = props.imageUrl ? (
        <img className="gestao-equipe-avatar" src={props.imageUrl} alt="Avatar" />
    ) : (
        <div className="gestao-equipe-initials">{nameInitials}</div>
    );

    if (props.isLite === true) {
        isLite = true;
    }

    return (
        <div className={isLite ? 'GestaoEquipeRow is-lite' : 'GestaoEquipeRow'}>
            <div className="GestaoEquipeRow-item --avatar">
                <div className="gestao-equipe-avatar-container">{avatar}</div>
            </div>

            <div className="GestaoEquipeRow-item --name">
                <div className="gestao-equipe-name-container">
                    <div className="gestao-equipe-name">{props.name}</div>
                    <div className="gestao-equipe-job-title">{props.jobTitle}</div>
                    <div className="gestao-equipe-phones">
                        <InputMask
                            mask="(99) 9999-9999"
                            value={props.phone}
                            style={{ border: 0 }}
                            readOnly
                        />
                        <InputMask
                            mask="(99) 99999-9999"
                            value={props.cellPhone}
                            style={{ border: 0 }}
                            readOnly
                        />
                    </div>
                </div>
            </div>

            {/* <div className="GestaoEquipeRow-item --sales">
                <div className="gestao-equipe-number">
                    <div className="number">
                        {props.sales}
                        {salesVariation && (
                            <img className="number-variation" src={salesVariation} alt="" />
                        )}
                    </div>
                    <div className="text">Vendas</div>
                </div>
            </div> */}

            {/* <div className="GestaoEquipeRow-item --avg-time">
                <div className="gestao-equipe-number">
                    <div className="number">
                        {props.avgTime} MIN
                        {avgTimeVariation && (
                            <img className="number-variation" src={avgTimeVariation} alt="" />
                        )}
                    </div>
                    <div className="text">Tempo de Resposta</div>
                </div>
            </div> */}

            <div style={{ margin: '0 auto' }} />

            {!isLite && (
                <div className="GestaoEquipeRow-item --view">
                    <div className="gestao-equipe-icon-text" onClick={props.openUserRepasses}>
                        <div className="icon-container">
                            <div className="text">{props.repassesAmount}</div>
                            <img className="icon" src={iconRefresh} alt="" />
                        </div>
                        <div className="text-container">Ver repasses</div>
                    </div>
                </div>
            )}

            {!isLite && (
                <div className="GestaoEquipeRow-item --edit">
                    <div className="gestao-equipe-icon-text" onClick={props.openEditModal}>
                        <div className="icon-container">
                            <img className="icon" src={iconEdit} alt="" />
                        </div>
                        <div className="text-container">Editar</div>
                    </div>
                </div>
            )}

            {!isLite && (
                <div className="GestaoEquipeRow-item --remove">
                    <div className="gestao-equipe-icon-text" onClick={props.openRemoveModal}>
                        <div className="icon-container">
                            <img className="icon" src={iconRemove} alt="" />
                        </div>
                        <div className="text-container">Remover</div>
                    </div>
                </div>
            )}

            {isLite && (
                <div className="GestaoEquipeRow-item --actions">
                    <div className="gestao-equipe-more-actions-container">
                        <div className="gestao-equipe-more-actions">
                            <FontAwesomeIcon icon="plus-circle" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GestaoEquipeRow;
