import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './style.sass';

class PlanoCadastroDesktopItem extends Component {
    render() {
        return (
            <div className="plano-cadastro-desktop-item">
                <div className="area-info">
                    <div className="nome-plano">{this.props.nomePlano}</div>
                    <div className="valor-plano">
                        R$ {this.props.valorPlano}
                        <span className="tag-mes">/mês</span>
                    </div>

                    <button
                        className="button primary"
                        style={{ fontSize: 10 }}
                        onClick={() =>
                            this.props.choosePlan(this.props.idPlano, this.props.planObject)
                        }
                    >
                        Selecionar Plano
                    </button>
                </div>
                <div className="area-info">
                    <div className="info-item">{this.props.transacoesMensais}</div>
                    <div className="info-item">R$ {this.props.adicionalTransacao}</div>
                </div>
                <div className="area-info">
                    <div className="info-item">{this.props.fotosVeiculo}</div>
                </div>
                <div className="area-info">
                    <div className="info-item">{this.props.turbosMes}</div>
                    <div className="info-item">R$ {this.props.valorTurboAvulso}</div>
                </div>
                <div className="area-info">
                    <div className="info-item">{this.props.consultaPrimeiroMes}</div>
                    <div className="info-item">R$ {this.props.consultaVeicular}</div>
                </div>
                <div className="area-info">
                    <div className="info-item">{this.props.diretores}</div>
                    <div className="info-item">{this.props.gerentes}</div>
                    <div className="info-item">{this.props.vendedores}</div>

                    <button
                        className="button primary"
                        style={{ fontSize: 10 }}
                        onClick={() =>
                            this.props.choosePlan(this.props.idPlano, this.props.planObject)
                        }
                    >
                        Selecionar Plano
                    </button>
                </div>
            </div>
        );
    }
}

export default withRouter(PlanoCadastroDesktopItem);
