import { createActions, createReducer } from 'reduxsauce';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
    /** Async actions */
    requestRepasses: [],
    requestMoreRepasses: ['page'],
    toggleRepasseFavorite: ['id_repasse'],
    populateSearch: [],
    populateSearchByType: ['type_id'],
    populateSearchByBrand: ['brand_id'],
    populateSearchByState: ['state_id'],
    buscarRepasses: ['toSearch'],
    loadMoreFromSearch: ['toSearch'],

    /** State actions */
    setLoading: ['value'],
    setNothingToLoad: ['value'],
    setRepasseFavorite: ['id_repasse'],
    setRepasses: ['total', 'page', 'repasses'],
    setSearch: ['data'],
    setSearchByType: ['brands', 'models', 'optionals'],
    setSearchByBrand: ['models'],
    setSearchByState: ['ddds'],
    resetApp: [''],
    setSearchedFilters: ['searchedFilters'],
    setFieldValue: ['field', 'value'],
});

/* Initial State */

const INITIAL_STATE = {
    total: 0,
    lastUpdatedAt: '',
    page: 1,
    nothingToLoad: false,
    loading: true,
    search: {},
    repasses: [],
    searchedFilters: {
        selectedIndex: 0,
    },
};

/* Reducers */

const setLoading = (state = INITIAL_STATE, action) => ({
    ...state,
    loading: action.value,
});

const setNothingToLoad = (state = INITIAL_STATE, action) => ({
    ...state,
    nothingToLoad: action.value,
});

const requestRepasses = (state = INITIAL_STATE, action) => ({
    ...state,
    ...INITIAL_STATE,
});

const setRepasses = (state = INITIAL_STATE, action) => ({
    ...state,
    total: action.total,
    page: action.page,
    repasses: action.repasses,
    lastUpdatedAt: new Date(),
});

const setRepasseFavorite = (state = INITIAL_STATE, action) => ({
    ...state,
    repasses: state.repasses.map(item =>
        item.id === action.id_repasse ? { ...item, is_favorite: !item.is_favorite } : item
    ),
});

const setSearch = (state = INITIAL_STATE, action) => ({
    ...state,
    search: action.data,
});

const setSearchByType = (state = INITIAL_STATE, action) => ({
    ...state,
    search: {
        ...state.search,
        brands: action.brands,
        models: action.models,
        optionals: action.optionals,
    },
});

const setSearchByBrand = (state = INITIAL_STATE, action) => ({
    ...state,
    search: {
        ...state.search,
        models: action.models,
    },
});

const setSearchByState = (state = INITIAL_STATE, action) => ({
    ...state,
    search: {
        ...state.search,
        ddds: action.ddds,
    },
});

const resetApp = (state = INITIAL_STATE, action) => ({
    ...state,
    ...INITIAL_STATE,
});

const setSearchedFilters = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchedFilters: action.searchedFilters,
    };
};

const setFieldValue = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchedFilters: {
            ...state.searchedFilters,
            [action.field]: action.value,
        },
    };
};

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
    [Types.SET_LOADING]: setLoading,
    [Types.SET_NOTHING_TO_LOAD]: setNothingToLoad,
    [Types.REQUEST_REPASSES]: requestRepasses,
    [Types.SET_REPASSES]: setRepasses,
    [Types.SET_REPASSE_FAVORITE]: setRepasseFavorite,
    [Types.SET_SEARCH]: setSearch,
    [Types.SET_SEARCH_BY_TYPE]: setSearchByType,
    [Types.SET_SEARCH_BY_BRAND]: setSearchByBrand,
    [Types.SET_SEARCH_BY_STATE]: setSearchByState,
    [Types.RESET_APP]: resetApp,
    [Types.SET_SEARCHED_FILTERS]: setSearchedFilters,
    [Types.SET_FIELD_VALUE]: setFieldValue,
});
