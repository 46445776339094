import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.sass';

export default class RoundedSearchInput extends Component {
    render() {
        return (
            <div className="rounded-search-input">
                <input
                    type="text"
                    placeholder="Buscar..."
                    onChange={event => this.props.onChange(event.target.value)}
                />
                <div className="icone">
                    <FontAwesomeIcon icon="search" />
                </div>
            </div>
        );
    }
}
