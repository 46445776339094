import React, { Component } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class FormGroupInputPassword extends Component {
    constructor(props) {
        super(props);
        this.inputProps = {
            labelSize: 'small',
            labelColor: '#335180',
        };
        this.state = {
            passwordMask: true,
        };
    }

    togglePasswordVisibility = () => {
        this.setState({ passwordMask: !this.state.passwordMask });
    };

    render() {
        const {
            labelSize = '',
            inputSize = '',
            value = '',
            appendPosition = 'right',
            errorMessage = '',
            placeholder = '',
            size = '',
        } = this.props;

        const labelClass = classNames('label', [labelSize], [size]);
        const inputClass = classNames('form-control', [inputSize], [size]);
        const inputIconClass = classNames('input-icon', [appendPosition]);
        const groupClass = classNames('FormGroupInput', 'form-group', {
            'is-invalid': errorMessage,
        });

        return (
            <div className={groupClass}>
                <label
                    className={labelClass}
                    htmlFor="form-login-password"
                    style={{ color: this.props.labelColor }}
                >
                    {this.props.label}
                </label>
                <div className={inputIconClass}>
                    <div
                        className="input-icon__icon-container"
                        onClick={() => this.togglePasswordVisibility()}
                        style={{
                            color: this.state.passwordMask ? '#aaa' : '#1e88e5',
                            cursor: 'pointer',
                        }}
                    >
                        <FontAwesomeIcon icon="eye" />
                    </div>
                    <input
                        className={inputClass}
                        type={this.state.passwordMask ? 'password' : 'text'}
                        onChange={this.props.onChange}
                        id={this.props.id}
                        readOnly={this.props.readOnly}
                        disabled={this.props.disabled}
                        value={value}
                        placeholder={placeholder}
                        {...this.props}
                    />
                </div>

                {this.props.errorMessage && (
                    <div className="form-validation-danger is-visible">
                        <FontAwesomeIcon icon="exclamation-circle" /> {this.props.errorMessage}
                    </div>
                )}
            </div>
        );
    }
}
