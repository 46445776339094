import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import './style.sass';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
    Creators,
    getUser,
    getAddressByCEP,
    getAddressByDealershipCEP,
    getStates,
    getCities,
} from '../../store/userCreate/actions';
import CadastroUsuarioContainer from '../../components/CadastroUsuarioContainer';
import FormGroupInput from '../../components/FormGroupInput';
import FormGroupSelect from '../../components/FormGroupSelect';
import FormGroupInputPassword from '../../components/FormGroupInputPassword';
import Button from '../../components/Button';
import AlterarFotoCadastro from '../../components/AlterarFotoCadastro';
import MessageBox from '../../components/MessageBox';
import Modal from '../../components/Modal';
import { validateCPF, validateCNPJ, checkExistField } from '../../helpers';
import SpinnerFull from '../../components/SpinnerFull';
import api from '../../services/api';

class CadastroUsuarioDois extends Component {
    state = {
        loading: false,
        error7dias: false,
        termsUpdated: '',
        useTermsChecked: false,
        passwordMask: true,
        confPassword: '',
        photoError: '',
        nameError: '',
        documentError: '',
        phoneError: '',
        cellPhoneError: '',
        zipcodeError: '',
        stateError: '',
        cityError: '',
        neighborhoodError: '',
        addressError: '',
        numberError: '',
        dealership_company_nameError: '',
        dealership_trade_nameError: '',
        dealership_cnpjError: '',
        dealership_zipcodeError: '',
        dealership_stateError: '',
        dealership_city_idError: '',
        dealership_neighborhoodError: '',
        dealership_streetError: '',
        dealership_numberError: '',
        emailError: '',
        passwordError: '',
        confPasswordError: '',
        useTermsError: '',
    };

    constructor(props) {
        super(props);
        this.inputProps = {
            size: 'small',
            labelColor: '#335180',
        };
        this.modalTermos = React.createRef();

        this.getAddressByCEP = _.debounce(this.props.getAddressByCEP, 1000);
        this.getAddressByDealershipCEP = _.debounce(this.props.getAddressByDealershipCEP, 1000);
    }

    async componentDidMount() {
        await this.props.getStates();

        let termsUpdated = await fetch('https://www.repasses.com.br/wp-json/wp/v2/pages/50');
        termsUpdated = await termsUpdated.json();

        this.setState({ termsUpdated });

        if (
            this.props.match.params &&
            this.props.match.params.store_id &&
            this.props.match.params.user_id &&
            !this.props.user.id
        ) {
            this.setState({ loading: true });

            if (this.props.match.params.token) {
                const { token } = this.props.match.params;

                await localStorage.setItem('@Repasse:token', token);
            }

            this.props.setUserAttribute('update', true);
            await this.props.setUserAttribute('currentStep', 2);

            const { user_id, store_id } = this.props.match.params;
            await this.props.getUser(user_id, store_id);

            const { states, user } = this.props;

            const dealership = user.stores.find(item => item.id === Number(store_id));

            this.props.setUserAttribute('store_id', dealership.id);
            this.props.setUserAttribute('dealership_company_name', dealership.company_name);
            this.props.setUserAttribute('dealership_trade_name', dealership.trade_name);
            this.props.setUserAttribute('dealership_cnpj', dealership.cnpj);
            this.props.setUserAttribute('dealership_zipcode', dealership.zipcode);
            this.props.setUserAttribute('dealership_neighborhood', dealership.neighborhood);
            this.props.setUserAttribute('dealership_street', dealership.street);
            this.props.setUserAttribute('dealership_number', dealership.number);
            this.props.setUserAttribute('dealership_complement', dealership.complement);
            this.props.setUserAttribute('dealership_landmark', dealership.landmark);

            // Popula endereço da loja através do CEP
            if (dealership.zipcode)
                this.getAddressByDealershipCEP(
                    dealership.zipcode,
                    states,
                    this.setCepDealershipInvalido
                );

            // Popula select de cidades, de acordo com o estado do usuário
            if (user.state) this.handleStateChange(user.state, 'user');

            this.props.setUserAttribute('latestEmail', user.email);
            this.props.setUserAttribute('latestDocument', user.document);
            this.props.setUserAttribute('latestDealership_cnpj', user.dealership_cnpj);

            this.setState({ loading: false });
        } else if (this.props.user.currentStep === 0 || this.props.user.currentStep < 2)
            this.props.history.push('/cadastro-usuario-um');
    }

    openModal = ref => {
        ref.current.openModal();
    };

    closeModal = ref => {
        ref.current.closeModal();
    };

    selectFreePeriod = async () => {
        try {
            if (await this.validateFields()) {
                const response = await api.put(`/api/v2/user/trial`);

                console.log(response);
                this.props.setUserAttribute('currentStep', 4);
                this.props.history.push('/cadastro-usuario-quatro');
            }
        } catch (error) {
            console.log({ error });

            this.setState({ error7dias: true });
        } finally {
            this.setState({ loading: false });
        }
    };

    changeProfileAvatar = e => {
        const file = e.target.files[0];

        if (this.props.user.photo) URL.revokeObjectURL(this.props.user.photo);

        if (file) {
            this.props.setUserAttribute('photo', URL.createObjectURL(file));
            this.props.setUserAttribute('photoFile', file);

            this.setState({ photoError: '' });
        }
    };

    removeProfileAvatar = () => {
        URL.revokeObjectURL(this.props.user.photo);

        this.props.setUserAttribute('photo', '');
        this.props.setUserAttribute('photoFile', '');
    };

    handleStateChange = (state_initials, userData) =>
        this.props.states.forEach(item => {
            if (state_initials === item.initials) this.props.getCities(item.id, userData);
        });

    handleErrorChange = (name, value) =>
        this.setState({
            [`${name}Error`]: value,
        });

    cleanErrors = () =>
        this.setState({
            photoError: '',
            nameError: '',
            documentError: '',
            phoneError: '',
            cellPhoneError: '',
            zipcodeError: '',
            stateError: '',
            cityError: '',
            neighborhoodError: '',
            addressError: '',
            numberError: '',
            dealership_company_nameError: '',
            dealership_trade_nameError: '',
            dealership_cnpjError: '',
            dealership_zipcodeError: '',
            dealership_stateError: '',
            dealership_city_idError: '',
            dealership_neighborhoodError: '',
            dealership_streetError: '',
            dealership_numberError: '',
            emailError: '',
            passwordError: '',
            confPasswordError: '',
            useTermsError: '',
        });

    setCepInvalido = () => this.handleErrorChange('zipcode', 'Cep inválido');

    setCepDealershipInvalido = () => this.handleErrorChange('dealership_zipcode', 'Cep inválido');

    validateFields = async () => {
        let validated = true;
        const { user } = this.props;

        this.cleanErrors();

        if (!this.state.useTermsChecked) {
            validated = false;
            this.handleErrorChange('useTerms', 'Você não aceitou os termos de uso');
        }

        /* if (
            user.photo === null ||
            validator.isEmpty(user.photo) ||
            !(user.photoFile && user.photoFile.type)
        ) {
            validated = false;
            console.log(user.photo, user.photoFile);
            this.handleErrorChange('photo', 'Selecione uma imagem para o perfil');
        } */

        if (user.name === null || validator.isEmpty(user.name)) {
            validated = false;
            this.handleErrorChange('name', 'Nome requerido');
        }

        if (user.document === null || !validateCPF(user.document)) {
            validated = false;
            this.handleErrorChange('document', 'CPF inválido');

            if (user.document === null || validator.isEmpty(user.document))
                this.handleErrorChange('document', 'CPF requerido');
        }

        if (user.phone && user.phone.replace(/\D/g, '').length < 10) {
            validated = false;

            this.handleErrorChange('phone', 'Preencha o Telefone corretamente');
        }

        if (user.cell_phone === null || user.cell_phone.replace(/\D/g, '').length < 11) {
            validated = false;
            this.handleErrorChange('cellPhone', 'Preencha o Celular corretamente');

            if (user.cell_phone === null || validator.isEmpty(user.cell_phone))
                this.handleErrorChange('cellPhone', 'Celular requerido');
        }

        if (user.zipcode === null || user.zipcode.replace(/\D/g, '').length < 8) {
            validated = false;
            this.handleErrorChange('zipcode', 'Preencha o CEP corretamente');

            if (user.zipcode === null || validator.isEmpty(user.zipcode))
                this.handleErrorChange('zipcode', 'CEP requerido');
        }

        if (user.state === null || validator.isEmpty(user.state)) {
            validated = false;
            this.handleErrorChange('state', 'Selecione um estado');
        }

        if (user.city === null || validator.isEmpty(user.city)) {
            validated = false;
            this.handleErrorChange('city', 'Selecione uma cidade');
        }

        if (user.neighborhood === null || validator.isEmpty(user.neighborhood)) {
            validated = false;
            this.handleErrorChange('neighborhood', 'Bairro requerido');
        }

        if (user.address === null || validator.isEmpty(user.address)) {
            validated = false;
            this.handleErrorChange('address', 'Logradouro requerido');
        }

        if (user.number === null || validator.isEmpty(user.number)) {
            validated = false;
            this.handleErrorChange('number', 'Número requerido');
        }

        if ((user.user_type !== '3' && user.user_type !== '4') || user.hasOptionPlano) {
            if (
                user.dealership_company_name === null ||
                validator.isEmpty(user.dealership_company_name)
            ) {
                validated = false;
                this.handleErrorChange('dealership_company_name', 'Razão social requerida');
            }

            if (
                user.dealership_trade_name === null ||
                validator.isEmpty(user.dealership_trade_name)
            ) {
                validated = false;
                this.handleErrorChange('dealership_trade_name', 'Nome fantasia requerido');
            }

            if (user.dealership_cnpj === null || !validateCNPJ(user.dealership_cnpj)) {
                validated = false;
                this.handleErrorChange('dealership_cnpj', 'CNPJ inválido');

                if (user.dealership_cnpj === null || validator.isEmpty(user.dealership_cnpj))
                    this.handleErrorChange('dealership_cnpj', 'CNPJ requerido');
            }

            if (
                user.dealership_zipcode === null ||
                user.dealership_zipcode.replace(/\D/g, '').length < 8
            ) {
                validated = false;
                this.handleErrorChange('dealership_zipcode', 'Preencha o CEP corretamente');

                if (validator.isEmpty(user.dealership_cnpj))
                    this.handleErrorChange('dealership_zipcode', 'CEP requerido');
            }

            if (user.dealership_state === null || validator.isEmpty(user.dealership_state)) {
                validated = false;
                this.handleErrorChange('dealership_state', 'Selecione um estado');
            }

            if (
                user.dealership_city_id === null ||
                validator.isEmpty(user.dealership_city_id.toString())
            ) {
                validated = false;
                this.handleErrorChange('dealership_city_id', 'Selecione uma cidade');
            }

            if (
                user.dealership_neighborhood === null ||
                validator.isEmpty(user.dealership_neighborhood)
            ) {
                validated = false;
                this.handleErrorChange('dealership_neighborhood', 'Bairro requerido');
            }

            if (user.dealership_street === null || validator.isEmpty(user.dealership_street)) {
                validated = false;
                this.handleErrorChange('dealership_street', 'Logradouro requerido');
            }

            if (user.dealership_number === null || validator.isEmpty(user.dealership_number)) {
                validated = false;
                this.handleErrorChange('dealership_number', 'Número requerido');
            }
        }

        this.setState({ loading: true });

        if (user.email === null || !validator.isEmail(user.email)) {
            validated = false;
            this.handleErrorChange('email', 'E-mail inválido');

            if (user.email === null || validator.isEmpty(user.email))
                this.handleErrorChange('email', 'E-mail requerido');
        }

        if (user.password === null || validator.isEmpty(user.password)) {
            validated = false;
            this.handleErrorChange('password', 'Senha requerida');
        }

        if (user.confPassword === null || validator.isEmpty(this.state.confPassword)) {
            validated = false;
            this.handleErrorChange('confPassword', 'Confirmação de senha requerida');
        }

        if (user.password !== this.state.confPassword) {
            validated = false;
            this.handleErrorChange('password', 'Senhas diferentes');
            this.handleErrorChange('confPassword', 'Senhas diferentes');
        }

        if (!validator.isEmpty(user.email) && user.email !== user.latestEmail) {
            if (await checkExistField('email', user.email)) {
                validated = false;
                this.handleErrorChange('email', 'Email já em uso');
            }
        }

        if (!validator.isEmpty(user.document) && user.document !== user.latestDocument) {
            if (
                (await checkExistField('cpf', user.document)) ||
                (await checkExistField('cpf', user.document.replace(/\D/g, '')))
            ) {
                validated = false;
                this.handleErrorChange('document', 'CPF já em uso');
            }
        }

        /* if (
            !validator.isEmpty(user.dealership_cnpj) &&
            user.dealership_cnpj !== user.latestDealership_cnpj
        ) {
            if (
                (await checkExistField('cpf', user.dealership_cnpj)) ||
                (await checkExistField('cpf', user.dealership_cnpj.replace(/\D/g, '')))
            ) {
                validated = false;
                this.handleErrorChange('dealership_cnpj', 'CNPJ já em uso');
            }
        } */

        this.setState({ loading: false });

        return validated;
    };

    togglePasswordVisibility = () => {
        this.setState({ passwordMask: !this.state.passwordMask });
    };

    render() {
        const { states, userCities, dealershipCities, user, setUserAttribute } = this.props;

        return (
            <div>
                <CadastroUsuarioContainer
                    currentStep={2}
                    tituloPagina={user.update ? 'Atualização de cadastro' : 'Cadastro'}
                >
                    {user.update ? (
                        <>
                            <Helmet>
                                <title>Atualização de cadastro de usuário • Repasse</title>
                            </Helmet>
                            <div style={{ marginTop: 10 }}>
                                Atualize seu cadastro e escolha o plano em que você melhor se adequa
                                para poder continuar utilizando a Plataforma Repasse e vender cada
                                vez mais.
                            </div>
                        </>
                    ) : (
                        <Helmet>
                            <title>Cadastro de usuário • Repasse</title>
                        </Helmet>
                    )}
                    <div className="cadastro-usuario-dois">
                        <div className="area-cadastro">
                            <div className="subtitulo">DADOS PESSOAIS</div>
                            <SpinnerFull
                                size={40}
                                spinnerColor={'#335180'}
                                spinnerWidth={3}
                                visible={this.state.loading}
                            />

                            <AlterarFotoCadastro
                                photo={user.photo}
                                onChange={this.changeProfileAvatar}
                                onRemove={this.removeProfileAvatar}
                            />

                            <div className="input-linha">
                                <div className="input-item">
                                    <FormGroupInput
                                        label="Nome Completo *"
                                        onChange={e => {
                                            setUserAttribute('name', e.target.value);
                                            this.setState({ nameError: '' });
                                        }}
                                        value={user.name}
                                        errorMessage={this.state.nameError}
                                        {...this.inputProps}
                                    />
                                </div>

                                <div className="input-item">
                                    <InputMask
                                        mask="999.999.999-99"
                                        onChange={e => {
                                            setUserAttribute('document', e.target.value);
                                            this.setState({ documentError: '' });
                                        }}
                                        value={user.document}
                                    >
                                        {inputMaskProps => (
                                            <FormGroupInput
                                                {...inputMaskProps}
                                                label="CPF *"
                                                errorMessage={this.state.documentError}
                                                {...this.inputProps}
                                            />
                                        )}
                                    </InputMask>
                                </div>
                            </div>
                            <div className="input-linha">
                                <div className="input-item">
                                    <InputMask
                                        mask="(99) 9999-9999"
                                        onChange={e => {
                                            setUserAttribute('phone', e.target.value);
                                            this.setState({ phoneError: '' });
                                        }}
                                        value={user.phone}
                                    >
                                        {inputMaskProps => (
                                            <FormGroupInput
                                                {...inputMaskProps}
                                                label="Telefone Fixo"
                                                errorMessage={this.state.phoneError}
                                                {...this.inputProps}
                                            />
                                        )}
                                    </InputMask>
                                </div>
                                <div className="input-item">
                                    <InputMask
                                        mask="(99) 99999-9999"
                                        onChange={e => {
                                            setUserAttribute('cell_phone', e.target.value);
                                            this.setState({ cellPhoneError: '' });
                                        }}
                                        value={user.cell_phone}
                                    >
                                        {inputMaskProps => (
                                            <FormGroupInput
                                                {...inputMaskProps}
                                                label="Celular *"
                                                errorMessage={this.state.cellPhoneError}
                                                {...this.inputProps}
                                            />
                                        )}
                                    </InputMask>
                                </div>
                            </div>
                        </div>

                        <div className="area-cadastro">
                            <div className="subtitulo">LOCALIZAÇÃO</div>
                            <div className="input-linha">
                                <div className="input-item">
                                    <InputMask
                                        mask="99999-999"
                                        onChange={e => {
                                            setUserAttribute('zipcode', e.target.value);
                                            this.setState({ zipcodeError: '' });
                                            this.getAddressByCEP(
                                                e.target.value,
                                                states,
                                                this.setCepInvalido
                                            );
                                        }}
                                        value={user.zipcode}
                                    >
                                        {inputMaskProps => (
                                            <FormGroupInput
                                                {...inputMaskProps}
                                                label="CEP *"
                                                errorMessage={this.state.zipcodeError}
                                                {...this.inputProps}
                                            />
                                        )}
                                    </InputMask>
                                </div>
                                <div className="input-item">
                                    <FormGroupSelect
                                        label="Estado *"
                                        placeholder="Selecione"
                                        onChange={e => {
                                            this.handleStateChange(e.target.value, 'user');
                                            setUserAttribute('state', e.target.value);
                                            this.setState({ stateError: '' });
                                        }}
                                        value={user.state}
                                        options={
                                            states &&
                                            states.map(item => ({
                                                value: item.initials,
                                                content: item.name,
                                            }))
                                        }
                                        errorMessage={this.state.stateError}
                                        {...this.inputProps}
                                    />
                                </div>
                            </div>
                            <div className="input-linha">
                                <div className="input-item">
                                    <FormGroupSelect
                                        label="Cidade *"
                                        placeholder="Selecione"
                                        onChange={e => {
                                            setUserAttribute('city', e.target.value);
                                            this.setState({ cityError: '' });
                                        }}
                                        value={user.city}
                                        options={
                                            userCities &&
                                            userCities.map(item => ({
                                                value: item.name,
                                                content: item.name,
                                            }))
                                        }
                                        errorMessage={this.state.cityError}
                                        {...this.inputProps}
                                    />
                                </div>
                                <div className="input-item">
                                    <FormGroupInput
                                        label="Bairro *"
                                        onChange={e => {
                                            setUserAttribute('neighborhood', e.target.value);
                                            this.setState({ neighborhoodError: '' });
                                        }}
                                        value={user.neighborhood}
                                        errorMessage={this.state.neighborhoodError}
                                        {...this.inputProps}
                                    />
                                </div>
                            </div>
                            <div className="input-linha logradouro">
                                <div className="input-item">
                                    <FormGroupInput
                                        label="Logradouro *"
                                        onChange={e => {
                                            setUserAttribute('address', e.target.value);
                                            this.setState({ addressError: '' });
                                        }}
                                        value={user.address}
                                        errorMessage={this.state.addressError}
                                        {...this.inputProps}
                                    />
                                </div>
                                <div className="input-item">
                                    <FormGroupInput
                                        label="Número *"
                                        onChange={e => {
                                            setUserAttribute('number', e.target.value);
                                            this.setState({ numberError: '' });
                                        }}
                                        value={user.number}
                                        errorMessage={this.state.numberError}
                                        {...this.inputProps}
                                    />
                                </div>
                            </div>
                        </div>

                        {((user.user_type !== '3' && user.user_type !== '4') ||
                            user.hasOptionPlano) && (
                            <div className="area-cadastro">
                                <div className="subtitulo">Dados da loja</div>
                                <div className="texto-desc">
                                    Preencha os dados da loja. Eles serão visualizados pelos demais
                                    usuários e passaram muito mais confiança em suas negociações!
                                </div>
                                <FormGroupInput
                                    label="Razão social *"
                                    onChange={e => {
                                        setUserAttribute('dealership_company_name', e.target.value);
                                        this.setState({ dealership_company_nameError: '' });
                                    }}
                                    value={user.dealership_company_name}
                                    errorMessage={this.state.dealership_company_nameError}
                                    {...this.inputProps}
                                />

                                <div className="input-linha">
                                    <div className="input-item">
                                        <FormGroupInput
                                            label="Nome Fantasia *"
                                            onChange={e => {
                                                setUserAttribute(
                                                    'dealership_trade_name',
                                                    e.target.value
                                                );
                                                this.setState({
                                                    dealership_trade_nameError: '',
                                                });
                                            }}
                                            value={user.dealership_trade_name}
                                            errorMessage={this.state.dealership_trade_nameError}
                                            {...this.inputProps}
                                        />
                                    </div>

                                    <div className="input-item">
                                        <InputMask
                                            mask="99.999.999/9999-99"
                                            onChange={e => {
                                                setUserAttribute('dealership_cnpj', e.target.value);
                                                this.setState({ dealership_cnpjError: '' });
                                            }}
                                            value={user.dealership_cnpj}
                                        >
                                            {inputMaskProps => (
                                                <FormGroupInput
                                                    {...inputMaskProps}
                                                    label="CNPJ *"
                                                    errorMessage={this.state.dealership_cnpjError}
                                                    helpMessage={
                                                        Number(user.user_type) === 2 &&
                                                        'Não tem CNPJ? Entre em contato conosco no atendimento online'
                                                    }
                                                    {...this.inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </div>
                                </div>

                                <div className="subtitulo">LOCALIZAÇÃO</div>
                                <div className="input-linha">
                                    <div className="input-item">
                                        <InputMask
                                            mask="99999-999"
                                            onChange={e => {
                                                setUserAttribute(
                                                    'dealership_zipcode',
                                                    e.target.value
                                                );
                                                this.setState({ dealership_zipcodeError: '' });
                                                this.getAddressByDealershipCEP(
                                                    e.target.value,
                                                    states,
                                                    this.setCepDealershipInvalido
                                                );
                                            }}
                                            value={user.dealership_zipcode}
                                        >
                                            {inputMaskProps => (
                                                <FormGroupInput
                                                    {...inputMaskProps}
                                                    label="CEP *"
                                                    errorMessage={
                                                        this.state.dealership_zipcodeError
                                                    }
                                                    {...this.inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </div>

                                    <div className="input-item">
                                        <FormGroupSelect
                                            label="Estado *"
                                            placeholder="Selecione"
                                            onChange={e => {
                                                this.handleStateChange(
                                                    e.target.value,
                                                    'dealership'
                                                );
                                                setUserAttribute(
                                                    'dealership_state',
                                                    e.target.value
                                                );
                                                this.setState({ dealership_stateError: '' });
                                            }}
                                            value={user.dealership_state}
                                            options={
                                                states &&
                                                states.map(item => ({
                                                    value: item.initials,
                                                    content: item.name,
                                                }))
                                            }
                                            errorMessage={this.state.dealership_stateError}
                                            {...this.inputProps}
                                        />
                                    </div>
                                </div>
                                <div className="input-linha">
                                    <div className="input-item">
                                        <FormGroupSelect
                                            label="Cidade *"
                                            placeholder="Selecione"
                                            onChange={e => {
                                                setUserAttribute(
                                                    'dealership_city_id',
                                                    e.target.value
                                                );
                                                this.setState({ dealership_city_idError: '' });
                                            }}
                                            value={user.dealership_city_id}
                                            options={
                                                dealershipCities &&
                                                dealershipCities.map(item => ({
                                                    value: item.id,
                                                    content: item.name,
                                                }))
                                            }
                                            errorMessage={this.state.dealership_city_idError}
                                            {...this.inputProps}
                                        />
                                    </div>
                                    <div className="input-item">
                                        <FormGroupInput
                                            label="Bairro *"
                                            onChange={e => {
                                                setUserAttribute(
                                                    'dealership_neighborhood',
                                                    e.target.value
                                                );
                                                this.setState({
                                                    dealership_neighborhoodError: '',
                                                });
                                            }}
                                            value={user.dealership_neighborhood}
                                            errorMessage={this.state.dealership_neighborhoodError}
                                            {...this.inputProps}
                                        />
                                    </div>
                                </div>
                                <div className="input-linha logradouro">
                                    <div className="input-item">
                                        <FormGroupInput
                                            label="Logradouro *"
                                            onChange={e => {
                                                setUserAttribute(
                                                    'dealership_street',
                                                    e.target.value
                                                );
                                                this.setState({ dealership_streetError: '' });
                                            }}
                                            value={user.dealership_street}
                                            errorMessage={this.state.dealership_streetError}
                                            {...this.inputProps}
                                        />
                                    </div>
                                    <div className="input-item">
                                        <FormGroupInput
                                            label="Número *"
                                            onChange={e => {
                                                setUserAttribute(
                                                    'dealership_number',
                                                    e.target.value
                                                );
                                                this.setState({ dealership_numberError: '' });
                                            }}
                                            value={user.dealership_number}
                                            errorMessage={this.state.dealership_numberError}
                                            {...this.inputProps}
                                        />
                                    </div>
                                </div>

                                <div className="input-linha">
                                    <div className="input-item">
                                        <FormGroupInput
                                            label="Complemento"
                                            onChange={e => {
                                                setUserAttribute(
                                                    'dealership_complement',
                                                    e.target.value
                                                );
                                            }}
                                            value={user.dealership_complement}
                                            {...this.inputProps}
                                        />
                                    </div>
                                    <div className="input-item">
                                        <FormGroupInput
                                            label="Ponto de referência"
                                            onChange={e => {
                                                setUserAttribute(
                                                    'dealership_landmark',
                                                    e.target.value
                                                );
                                            }}
                                            value={user.dealership_landmark}
                                            {...this.inputProps}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="area-cadastro">
                            <div className="subtitulo">Dados de Acesso</div>
                            <FormGroupInput
                                label="E-mail *"
                                onChange={e => {
                                    setUserAttribute('email', e.target.value);
                                    this.setState({ emailError: '' });
                                }}
                                value={user.email}
                                errorMessage={this.state.emailError}
                                {...this.inputProps}
                            />
                            <div className="input-linha">
                                <div className="input-item">
                                    <FormGroupInputPassword
                                        label="Senha *"
                                        onChange={e => {
                                            setUserAttribute('password', e.target.value);
                                            this.setState({
                                                passwordError: '',
                                                confPasswordError: '',
                                            });
                                        }}
                                        value={user.password}
                                        errorMessage={this.state.passwordError}
                                        {...this.inputProps}
                                    />
                                </div>
                                <div className="input-item">
                                    <FormGroupInputPassword
                                        label="Confirmar Senha *"
                                        onChange={e =>
                                            this.setState({
                                                confPassword: e.target.value,
                                                passwordError: '',
                                                confPasswordError: '',
                                            })
                                        }
                                        value={this.state.confPassword}
                                        errorMessage={this.state.confPasswordError}
                                        {...this.inputProps}
                                    />
                                </div>
                            </div>

                            <div
                                className="input-item"
                                onClick={() => this.openModal(this.modalTermos)}
                            >
                                <input
                                    type="checkbox"
                                    name="termos"
                                    checked={this.state.useTermsChecked}
                                />{' '}
                                <label className="label-checkbox" htmlFor="termos">
                                    Li e aceito os{' '}
                                    <Link className="link-termos-uso" to="#">
                                        Termos de Uso
                                    </Link>
                                </label>
                                {this.state.useTermsError && (
                                    <div className="form-validation-danger is-visible">
                                        <FontAwesomeIcon icon="exclamation-circle" />{' '}
                                        {this.state.useTermsError}
                                    </div>
                                )}
                            </div>
                        </div>

                        {this.state.photoError && (
                            <MessageBox type="danger" size="normal">
                                {this.state.photoError}
                            </MessageBox>
                        )}

                        {this.state.error7dias && (
                            <MessageBox type="danger" size="normal">
                                Houve um problema ao escolher testar por 7 dias grátis
                            </MessageBox>
                        )}

                        <div className="botoes-container">
                            <div className="botao-item">
                                <Link to="/cadastro-usuario-um">
                                    <Button outline textButton="Voltar" />
                                </Link>
                            </div>

                            <div className="botao-item">
                                <Button
                                    textButton={user.hasOptionPlano ? 'Plano' : 'Confirmar dados'}
                                    onClick={async () => {
                                        if (await this.validateFields()) {
                                            this.props.setUserAttribute('currentStep', 3);
                                            this.props.history.push('/cadastro-usuario-tres');
                                        }
                                    }}
                                />

                                {!user.is_testing && user.hasOptionPlano && (
                                    <div onClick={() => this.selectFreePeriod()}>
                                        <div
                                            style={{
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                color: '#37474f',
                                                textTransform: 'uppercase',
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Testar por 7 dias grátis
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <Modal
                        ref={this.modalTermos}
                        title="Atualização dos termos de uso"
                        className="ModalTermoUso"
                        isStrict
                    >
                        <div className="ModalTermoUso-content margin-bottom--big">
                            <h2>Termos de uso</h2>

                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        this.state.termsUpdated.content &&
                                        this.state.termsUpdated.content.rendered,
                                }}
                            />
                        </div>

                        {/* <CheckboxGroup
                            label="Li e aceito os termos de uso"
                            name="termos-uso"
                            id="termos-uso"
                            onChange={e =>
                                this.setState({
                                    useTermsChecked: e.target.checked,
                                    useTermsError: '',
                                })
                            }
                            checked={this.state.useTermsChecked}
                        /> */}

                        {/* {this.state.useTermsError && (
                            <div style={{ textAlign: 'center', marginBottom: 15 }}>
                                <MessageBox type="danger" inlineBlock>
                                    {this.state.useTermsError}
                                </MessageBox>
                            </div>
                        )} */}

                        <div className="ModalTermoUso-button-container">
                            <button
                                className="button primary"
                                onClick={() => {
                                    // if (!this.state.useTermsChecked)
                                    //     this.setState({
                                    //         useTermsError: 'Você não aceitou os termos de uso',
                                    //     });
                                    // else {
                                    //     this.closeModal(this.modalTermos);
                                    //     this.setState({
                                    //         useTermsChecked: true,
                                    //         useTermsError: '',
                                    //     });
                                    // }
                                    this.closeModal(this.modalTermos);
                                    this.setState({
                                        useTermsChecked: true,
                                        useTermsError: '',
                                    });
                                }}
                            >
                                Aceitar
                            </button>
                            <button
                                className="button outline primary"
                                onClick={() => {
                                    this.closeModal(this.modalTermos);
                                    this.setState({
                                        useTermsChecked: false,
                                        useTermsError: '',
                                    });
                                }}
                            >
                                Rejeitar
                            </button>
                        </div>
                    </Modal>
                </CadastroUsuarioContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, userCreate }) => ({
    roleUser: auth.roleUser,
    states: userCreate.states,
    userCities: userCreate.userCities,
    dealershipCities: userCreate.dealershipCities,
    user: userCreate.user,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { ...Creators, getUser, getAddressByCEP, getAddressByDealershipCEP, getStates, getCities },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CadastroUsuarioDois);
