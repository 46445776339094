import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Style
import './style.sass';

// Images and Assets
import logo from '../../assets/logo-repasse.png';

// Components
import Menu from './components/Menu';
import UsuarioBar from '../UsuarioBar';
import BurgerMenu from '../BurgerMenu';
import InfoItem from '../../components/InfoItem';
import Breadcrumbs from '../Breadcrumbs';

class TopBar extends Component {
    constructor() {
        super();
        this.state = {
            isMobileMenuEnabled: false,
            data: {
                veiculos_anunciados: '',
                transacoes_neste_mes: '',
                vendedores_cadastrados: '',
                veiculos_com_turbo: '',
            },
            store: null,
        };

        window.addEventListener('resize', () => {
            if (window.outerWidth > 860) {
                this.setState({ height: 'auto' });
            }
        });
    }

    async componentDidMount() {
        const { user, storeId } = this.props;

        if (user.stores)
            this.setState({ store: user.stores.find(item => Number(item.id) === Number(storeId)) });
    }

    toggleMobileMenu() {
        this.setState({
            isMobileMenuEnabled: !this.state.isMobileMenuEnabled,
        });
    }

    render() {
        const { store } = this.state;
        const { isDirectorWithPlan, scoreboard } = this.props;

        return (
            <React.Fragment>
                <header className="main-header-container">
                    <div className="main-header has-border">
                        <div className="inner-container">
                            <Link className="main-header-logo" to="/">
                                <img src={logo} alt="Logo Repasse" />
                            </Link>

                            <div className="main-header-action-container">
                                {/* <InputPesquisa /> */}
                                {/* <Notificacoes numNotification="17" /> */}
                                <UsuarioBar
                                    nomeUsuario="Yan Gondim"
                                    onClick={() => console.log('')}
                                />
                            </div>

                            <BurgerMenu onClick={() => this.toggleMobileMenu()} />

                            <Menu
                                mobileEnabled={this.state.isMobileMenuEnabled}
                                havePlan={this.props.havePlan}
                                clearSearchHome={this.props.clearSearchHome}
                                clearSearchMyRepasses={this.props.clearSearchMyRepasses}
                            />
                        </div>
                    </div>

                    {isDirectorWithPlan && (
                        <div className="main-subheader">
                            <div className="main-subheader-container">
                                <div className="title-container">
                                    <div>
                                        <span
                                            style={{
                                                color: '#335180',
                                                fontWeight: 'bold',
                                                fontSize: 22,
                                            }}
                                        >
                                            {store ? store.trade_name : ''}
                                        </span>
                                        <Breadcrumbs />
                                    </div>

                                    {isDirectorWithPlan && (
                                        <div className="infos-container">
                                            <InfoItem
                                                valor={
                                                    scoreboard.qty_active
                                                        ? scoreboard.qty_active
                                                        : 0
                                                }
                                                titulo={
                                                    <span>
                                                        veículos
                                                        <br />
                                                        ativos
                                                    </span>
                                                }
                                            />
                                            <InfoItem
                                                valor={
                                                    scoreboard.qty_cars ? scoreboard.qty_cars : 0
                                                }
                                                titulo={
                                                    <span>
                                                        veículos
                                                        <br />
                                                        anunciados
                                                        <br />
                                                        neste mês
                                                    </span>
                                                }
                                            />
                                            <InfoItem
                                                valor={
                                                    scoreboard.qty_transactions
                                                        ? scoreboard.qty_transactions
                                                        : 0
                                                }
                                                titulo={
                                                    <span>
                                                        transações
                                                        <br />
                                                        neste mês
                                                    </span>
                                                }
                                            />
                                            <InfoItem
                                                valor={
                                                    scoreboard.qty_salesman
                                                        ? scoreboard.qty_salesman
                                                        : 0
                                                }
                                                titulo={
                                                    <span>
                                                        vendedores
                                                        <br />
                                                        cadastrados
                                                    </span>
                                                }
                                            />
                                            <InfoItem
                                                valor={
                                                    scoreboard.qty_turbos
                                                        ? scoreboard.qty_turbos
                                                        : 0
                                                }
                                                titulo={
                                                    <span>
                                                        veículos
                                                        <br />
                                                        com turbo
                                                    </span>
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </header>

                {isDirectorWithPlan ? (
                    <div className="padding-header-full" />
                ) : (
                    <div className="padding-header" />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
    isDirectorWithPlan: auth.isDirectorWithPlan,
    scoreboard: auth.scoreboard,
    storeId: auth.storeId,
});

export default connect(mapStateToProps)(TopBar);
