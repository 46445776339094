import api from '../services/api';

const checkExistField = async (field, value) => {
    try {
        const response = await api.get(`/api/check-exist-field?field=${field}&value=${value}`);
        const { exist } = response.data.data;

        return exist;
    } catch (error) {
        console.log(error);
    }
};

export { checkExistField };
