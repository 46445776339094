import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import TopBar from '../../components/TopBar';
import CardConteudo from '../../components/CardConteudo';
import BotaoCarregarMais from '../../components/BotaoCarregarMais';
import MessageBox from '../../components/MessageBox';
import RepasseInfo from '../../components/RepasseInfo';
import RepasseValores from '../../components/RepasseValores';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FiltroVeiculo from './FiltroVeiculo';

import { Creators as AuthActions } from '../../store/ducks/auth';
import { Creators as RepassesListActions } from '../../store/ducks/repassesList';
import { store } from '../../store';
import { baseURL } from '../../services/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.sass';
import TitleAside from '../../components/TitleAside';
import CheckboxGroup from '../../components/CheckboxGroup';

const intlMonetary = new Intl.NumberFormat('pt-BR', {
    // style: 'currency',
    // currency: 'BRL',
    minimumFractionDigits: 2,
});

class PageHome extends Component {
    state = {
        /**
         * searchClick é alterado para true quando
         * o usuário clica no botão de busca. A partir daí todo o carregamento
         * da lista de repasses é feito pelo endpoint /api/search, enviando o objeto
         * toSearch logo abaixo.
         *
         * Quando falso é feito pelo endpoint /api/purchase
         */
        termsUpdated: '',
        formError: '',
        useTermsChecked: false,
        useTermsError: '',
        checkedOptionals: new Map(),
        toSearch: {
            stock_id: null, // integer
            car_type: 0, // integer
            brand_id: null, // integer
            model_id: null, // integer
            start_year: null, // integer
            end_year: null, // integer
            fuel: [], // array
            start_price: null, // integer
            end_price: null, // integer
            doors: [], // array
            eixos: [], // array
            color_ids: [], // array
            start_km: null, // integer
            end_km: null, // integer
            optionals_id: [], // integer
            state_id: null, // integer
            ddds: [], // array
            user_type: [], // array
            order_by: null, // string
            direction: null, // string
            page: 1, // integer
        },
        saveSearch: false,
    };

    constructor(props) {
        super(props);

        this.initialState = this.state;
        this.modalTermos = React.createRef();

        this.linkStyle = {
            margin: 'auto',
            color: '#335180',
            textAlign: 'center',
            display: 'block',
            fontWeight: '500',
            fontSize: '1.4rem',
            marginTop: '.5rem',
        };

        this.handleTermos = this.handleTermos.bind(this);
    }

    openModal = ref => {
        ref.current.openModal();
    };

    closeModal = ref => {
        ref.current.closeModal();
    };

    async componentDidMount() {
        this.scrollToTop();

        const start = new Date(this.props.lastUpdatedAt);
        const end = new Date();

        const timeDiff = (end - start) / 1000;

        if (timeDiff > 600 || !timeDiff) this.props.requestRepasses();

        if (!this.props.terms) {
            let termsUpdated = await fetch('https://www.repasses.com.br/wp-json/wp/v2/pages/50');
            termsUpdated = await termsUpdated.json();

            this.setState({ termsUpdated });

            this.openModal(this.modalTermos);
        }

        if (JSON.stringify(this.props.searchedFilters) !== '{}')
            this.setState({ toSearch: { ...this.state.toSearch, ...this.props.searchedFilters } });
    }

    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    getPorcentagemColor = valorPorcentagem => {
        let color = '';

        if (valorPorcentagem < 15) color = '#D0021B';
        if (valorPorcentagem >= 15 && valorPorcentagem <= 16) color = '#FFAA00';
        if (valorPorcentagem > 16 && valorPorcentagem <= 19) color = '#F78C46';
        if (valorPorcentagem > 20) color = '#34A853';

        return color;
    };

    timeElapsed = start_date => {
        let start = new Date(start_date);
        let end = new Date();

        let timeDiff = (end - start) / 1000;
        let seconds = Math.round(timeDiff);

        return Math.floor(seconds / 86400);
    };

    isOwner = id_repasse => {
        return this.props.user.id === id_repasse;
    };

    validateFields = () => {
        const { toSearch } = this.state;

        if (this.compareStartToEnd(toSearch.start_year, toSearch.end_year)) return false;

        if (this.compareStartToEnd(toSearch.start_price, toSearch.end_price)) return false;

        if (this.compareStartToEnd(toSearch.start_km, toSearch.end_km)) return false;

        return true;
    };

    compareStartToEnd = (start_value, end_value) => {
        if (start_value && end_value && Number(start_value) > Number(end_value)) return true;
        else return false;
    };

    handleInputToSearchChange = (name, value) => {
        if (value[0] === '') console.log({ value });

        this.setState(
            {
                toSearch: {
                    ...this.state.toSearch,
                    [name]: value !== '' ? value : null,
                },
            },
            () => this.props.setFieldValue(name, value)
        );
    };

    handleBrandToSearchChange = brand_id => {
        if (brand_id !== '') this.props.populateSearchByBrand(brand_id);

        this.handleInputToSearchChange('brand_id', brand_id);
        this.props.setFieldValue('brand_id', brand_id);
    };

    handleStateToSearchChange = state_id => {
        if (state_id !== '') this.props.populateSearchByState(state_id);

        this.handleInputToSearchChange('state_id', state_id);
        this.props.setFieldValue('state_id', state_id);
    };

    addOptionalToSearch = e => {
        const isChecked = e.target.checked;
        const id = e.target.value;

        let { optionals_id } = this.state.toSearch;

        if (optionals_id.includes(id)) optionals_id = optionals_id.filter(item => item !== id);
        else optionals_id.push(id);

        this.handleInputToSearchChange('optionals_id', optionals_id);
        this.setState(prevState => ({
            checkedOptionals: prevState.checkedOptionals.set(id, isChecked),
        }));
    };

    incrementDoorsValue = () => {
        const { toSearch } = this.state;

        if (!toSearch.doors.length) {
            this.props.setFieldValue('doors', [4]);
            this.handleInputToSearchChange('doors', [4]);
        }

        if (toSearch.doors[0] < 5) {
            this.props.setFieldValue('doors', [toSearch.doors[0] + 1]);
            this.handleInputToSearchChange('doors', [toSearch.doors[0] + 1]);
        }
    };

    decrementDoorsValue = () => {
        const { toSearch } = this.state;

        if (!toSearch.doors.length) {
            this.props.setFieldValue('doors', [4]);
            this.handleInputToSearchChange('doors', [4]);
        }

        if (toSearch.doors[0] > 2) {
            this.props.setFieldValue('doors', [toSearch.doors[0] - 1]);
            this.handleInputToSearchChange('doors', [toSearch.doors[0] - 1]);
        }
    };

    onFilterClick = () => {
        if (this.validateFields()) {
            this.props.buscarRepasses(this.state.toSearch);
            this.props.setFieldValue('isSearchOn', true);
            this.setState({ searchClick: true });
            this.scrollToTop();
        } else {
            this.props.setFormError('Existem erros na pesquisa');
            // this.setState({
            //     formError:
            //         'Existem erros na pesquisa',
            // });
        }
    };

    cleanSearch = () => {
        this.props.requestRepasses();
        this.setState({
            ...this.initialState,
            checkedOptionals: new Map(),
            toSearch: {
                ...this.initialState.toSearch,
                fuel: [],
                doors: [],
                eixos: [],
                color_ids: [],
                optionals_id: [],
                ddds: [],
                user_type: [],
            },
        });
    };


    handleTermos = async () => {
        const { user } = this.props;

        if (!this.state.useTermsChecked)
            this.setState({
                useTermsError: 'Você não aceitou os termos de uso',
            });
        else {
            this.closeModal(this.modalTermos);
            // accept user terms
            store.dispatch(AuthActions.acceptTermsOfUse());
        }
    };

    hasPermissionToFavorite = item =>
        item.user.id !== this.props.user.id &&
        (item.car_status.id === 1 || item.car_status.id === 9);

    render() {
        const { toSearch } = this.state;
        const { searchedFilters, user } = this.props;

        return (
            <React.Fragment>
                <TopBar clearSearchHome={this.cleanSearch} />

                <Helmet>
                    <title>{`${this.props.total} repasses encontrados • Repasse`}</title>
                </Helmet>

                <div className="page-container">
                    <div className="content-container">
                        <TitleAside title="Busca" />

                        <div className="Filtro-home-layout">
                            <div className="Filtro-home__sidebar">
                                <CardConteudo padding="2rem">
                                    <h3 className="sidebar__title">Filtro</h3>

                                    <Tabs
                                        selectedIndex={
                                            searchedFilters && searchedFilters.selectedIndex
                                                ? searchedFilters.selectedIndex
                                                : 0
                                        }
                                        onSelect={index => {
                                            let car_type = 0;

                                            if (index === 1) car_type = 5;

                                            if (index === 2) car_type = 8;

                                            this.props.populateSearchByType(car_type);
                                            this.handleInputToSearchChange('car_type', car_type);

                                            this.props.setFieldValue('selectedIndex', index);
                                        }}
                                        disabledTabClassName="react-tabs__tab filtro-custom disabled"
                                        selectedTabClassName="react-tabs__tab filtro-custom selected"
                                    >
                                        <TabList className="sidebar__vehicle-type-listing button-group">
                                            <Tab className="button blue outline react-tabs__tab filtro-custom">
                                                <FontAwesomeIcon icon="car-side" />
                                            </Tab>
                                            <Tab className="button blue outline react-tabs__tab filtro-custom">
                                                <FontAwesomeIcon icon="truck" />
                                            </Tab>
                                            <Tab className="button blue outline react-tabs__tab filtro-custom">
                                                <FontAwesomeIcon icon="motorcycle" />
                                            </Tab>
                                        </TabList>
                                        <TabPanel>
                                            <FiltroVeiculo
                                                previouslySearched={searchedFilters}
                                                handleInputToSearchChange={
                                                    this.handleInputToSearchChange
                                                }
                                                handleBrandToSearchChange={
                                                    this.handleBrandToSearchChange
                                                }
                                                handleStateToSearchChange={
                                                    this.handleStateToSearchChange
                                                }
                                                search={this.props.search}
                                                toSearch={this.state.toSearch}
                                                checkedOptionals={this.state.checkedOptionals}
                                                formError={this.props.formError}
                                                setFormError={formError =>
                                                    this.setState({ formError })
                                                }
                                                compareStartToEnd={this.compareStartToEnd}
                                                onFilterClick={this.onFilterClick}
                                                addOptionalToSearch={this.addOptionalToSearch}
                                                incrementDoorsValue={this.incrementDoorsValue}
                                                decrementDoorsValue={this.decrementDoorsValue}
                                            />
                                        </TabPanel>
                                        <TabPanel>
                                            <FiltroVeiculo
                                                handleInputToSearchChange={
                                                    this.handleInputToSearchChange
                                                }
                                                handleBrandToSearchChange={
                                                    this.handleBrandToSearchChange
                                                }
                                                handleStateToSearchChange={
                                                    this.handleStateToSearchChange
                                                }
                                                search={this.props.search}
                                                toSearch={this.state.toSearch}
                                                checkedOptionals={this.state.checkedOptionals}
                                                formError={this.props.formError}
                                                setFormError={formError =>
                                                    this.setState({ formError })
                                                }
                                                compareStartToEnd={this.compareStartToEnd}
                                                onFilterClick={this.onFilterClick}
                                                addOptionalToSearch={this.addOptionalToSearch}
                                                incrementDoorsValue={this.incrementDoorsValue}
                                                decrementDoorsValue={this.decrementDoorsValue}
                                            />
                                        </TabPanel>
                                        <TabPanel>
                                            <FiltroVeiculo
                                                handleInputToSearchChange={
                                                    this.handleInputToSearchChange
                                                }
                                                handleBrandToSearchChange={
                                                    this.handleBrandToSearchChange
                                                }
                                                handleStateToSearchChange={
                                                    this.handleStateToSearchChange
                                                }
                                                search={this.props.search}
                                                toSearch={this.state.toSearch}
                                                checkedOptionals={this.state.checkedOptionals}
                                                formError={this.props.formError}
                                                setFormError={formError =>
                                                    this.setState({ formError })
                                                }
                                                compareStartToEnd={this.compareStartToEnd}
                                                onFilterClick={this.onFilterClick}
                                                addOptionalToSearch={this.addOptionalToSearch}
                                                incrementDoorsValue={this.incrementDoorsValue}
                                                decrementDoorsValue={this.decrementDoorsValue}
                                            />
                                        </TabPanel>
                                    </Tabs>
                                </CardConteudo>
                            </div>

                            <div className="Filtro-home__result">
                                <CardConteudo padding="2rem">
                                    {this.props.loading ? (
                                        <div style={{ textAlign: 'center', marginBottom: 10 }}>
                                            <Loader color="#335180" size={20} />
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                marginBottom: this.props.searchedFilters.isSearchOn
                                                    ? 20
                                                    : 0,
                                            }}
                                        >
                                            <div className="result__title">
                                                Existem {this.props.total} repasses disponíveis para
                                                sua busca.
                                                {!this.props.searchedFilters.isSearchOn && (
                                                    <small className="result__title-obs">
                                                        Estes resultados são baseados nos seus{' '}
                                                        <Link to="/perfil-compra">
                                                            perfis de compra
                                                        </Link>
                                                        .
                                                    </small>
                                                )}
                                            </div>

                                            {this.props.searchedFilters.isSearchOn && (
                                                <button
                                                    className="button primary"
                                                    onClick={() => this.cleanSearch()}
                                                >
                                                    Limpar busca
                                                </button>
                                            )}
                                        </div>
                                    )}

                                    {this.props.repasses &&
                                        this.props.repasses.map((item, index) => {
                                            const valorPorcentagem = Math.abs(
                                                item.fipe_percent_off.split('%')[0]
                                            );

                                            return (
                                                <div className="result__item" key={index}>
                                                    <Link
                                                        to={`/repasses/${item.id}/${item.user.id}`}
                                                        className="result__image-container"
                                                    >
                                                        <img
                                                            className="result__image"
                                                            src={`${baseURL}/uploads/gallery/${
                                                                item.image
                                                            }`}
                                                            alt=""
                                                        />
                                                    </Link>

                                                    <div className="result__info">
                                                        <RepasseInfo
                                                            idRepasse={item.id}
                                                            idUsuario={item.user.id}
                                                            tituloRepasse={item.fipe.model}
                                                            ano={item.fipe.year}
                                                            combustivel={item.fipe.fuel}
                                                            cor={item.color.name}
                                                            estado={item.user.state}
                                                            cidade={item.user.city}
                                                            diasNoPatio={
                                                                this.isOwner(item.user.id)
                                                                    ? this.timeElapsed(
                                                                          item.created_at
                                                                      )
                                                                    : item.created_at
                                                            }
                                                            vendedor={item.user.name}
                                                            acompanharNegociacao={true}
                                                            canEdit={this.isOwner(item.user.id)}
                                                            canSee={true}
                                                            canFavorite={this.hasPermissionToFavorite(
                                                                item
                                                            )}
                                                            toggleRepasseFavorite={
                                                                this.props.toggleRepasseFavorite
                                                            }
                                                            isFavorite={item.is_favorite}
                                                            width={250}
                                                            showStockLink
                                                        />
                                                    </div>
                                                    <div className="result__valores">
                                                        <RepasseValores
                                                            idRepasse={item.id}
                                                            idUsuario={item.user.id}
                                                            verRepasseButton={true}
                                                            valorRepasse={intlMonetary.format(
                                                                item.price
                                                            )}
                                                            valorFipe={intlMonetary.format(
                                                                item.fipe_price
                                                            )}
                                                            corElemento={this.getPorcentagemColor(
                                                                valorPorcentagem
                                                            )}
                                                            valorPorcentagem={valorPorcentagem}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </CardConteudo>

                                {!this.props.nothingToLoad && (
                                    <BotaoCarregarMais
                                        text={this.props.loading ? <Loader /> : 'Carregar mais'}
                                        onClick={() => {
                                            const { page } = this.props;

                                            if (!this.props.searchedFilters.isSearchOn)
                                                this.props.requestMoreRepasses(page + 1);
                                            else
                                                this.props.loadMoreFromSearch({
                                                    ...toSearch,
                                                    page: page + 1,
                                                });
                                        }}
                                    />
                                )}
                            </div>
                        </div>

                        {/* <div className="margin-vertical">
                            <BannerTurbine
                                textoBanner="Turbine suas Vendas"
                                textoBotao="Assine o Turbo agora"
                            />
                        </div> */}
                    </div>
                </div>

                <Modal
                    ref={this.modalTermos}
                    title="Atualização dos termos de uso"
                    className="ModalTermoUso"
                    isStrict
                >
                    <div className="ModalTermoUso-content margin-bottom--big">
                        <h2>Termos de uso</h2>

                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    this.state.termsUpdated.content &&
                                    this.state.termsUpdated.content.rendered,
                            }}
                        />
                    </div>

                    <CheckboxGroup
                        label="Li e aceito os termos de uso"
                        name="termos-uso"
                        id="termos-uso"
                        onChange={e =>
                            this.setState({ useTermsChecked: e.target.checked, useTermsError: '' })
                        }
                        checked={this.state.useTermsChecked}
                    />

                    {this.state.useTermsError && (
                        <div style={{ textAlign: 'center', marginBottom: 15 }}>
                            <MessageBox type="danger" inlineBlock>
                                {this.state.useTermsError}
                            </MessageBox>
                        </div>
                    )}

                    <div className="ModalTermoUso-button-container">
                        <button
                            className="button primary"
                            onClick={this.handleTermos}
                        >
                            Aceitar
                        </button>
                        <button
                            className="button outline primary"
                            onClick={this.props.logoutUser.bind(this)}
                        >
                            Rejeitar
                        </button>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ auth, repassesList }) => ({
    user: auth.user,
    terms: auth.terms,
    store_id: auth.storeId,
    total: repassesList.total,
    page: repassesList.page,
    nothingToLoad: repassesList.nothingToLoad,
    lastUpdatedAt: repassesList.lastUpdatedAt,
    loading: repassesList.loading,
    search: repassesList.search,
    repasses: repassesList.repasses,
    searchedFilters: repassesList.searchedFilters,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            ...AuthActions,
            ...RepassesListActions,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageHome);
