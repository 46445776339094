import React, { Component } from 'react';
import CardConteudo from '../CardConteudo';
import './style.sass';
// import GraficoValorTotal from '../GraficoValorTotal';

export default class ContainerValores extends Component {
    render() {
        return (
            <div>
                <CardConteudo>
                    <div className="container-valores">
                        <div className="titulo">
                            <div className="texto">Valor total em repasses</div>
                            <div className="valor">R$ {this.props.valorTotal}</div>
                        </div>

                        {/* <GraficoValorTotal/> */}
                    </div>
                </CardConteudo>

                <div className="listagem-quadrados">
                    <div className="quadrado-item">
                        <div className="titulo">
                            LUCRO
                            <br /> TOTAL
                        </div>
                        <div className="valor">R$ {this.props.lucroTotal}</div>
                    </div>
                    <div className="quadrado-item">
                        <div className="titulo">
                            TICKET MEDIO <br />
                            REPASSADOS
                        </div>
                        <div className="valor">R$ {this.props.ticketsRepassados}</div>
                    </div>
                    <div className="quadrado-item">
                        <div className="titulo">
                            TICKET MEDIO <br />
                            CADASTRADOS
                        </div>
                        <div className="valor">R$ {this.props.ticketsCadastrados}</div>
                    </div>
                </div>
            </div>
        );
    }
}
