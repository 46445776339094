import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import InteressadoRow from '../InteressadoRow';
import InteressadoHeader from '../InteressadoHeader';
import RepasseInfo from '../RepasseInfo';
import RepasseValores from '../RepasseValores';

import iconClose from '../../assets/images/icon-close.png';
import iconTurboMax from '../../assets/images/icon-turbo-max.png';
import iconNoTurbo from '../../assets/images/icon-no-turbo.png';
import iconGallery from '../../assets/images/icon-gallery.png';
import placeholderCarSm from '../../assets/images/placeholder-car-sm.jpg';

import './style.sass';

class ModalPreviaRepasse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
        };

        this.handleEscPress();
    }

    openModal = () => this.setState({ isActive: true });
    closeModal = () => this.setState({ isActive: false });

    handleEscPress = () => {
        window.addEventListener('keydown', event => {
            if (event.code === 'Escape' && this.state.isActive === true) {
                this.closeModal();
            }
        });
    };

    render() {
        const modalStatusClass = classNames({
            Modal: true,
            ModalPreviaRepasse: true,
            'is-active': this.state.isActive,
        });
        return (
            <div className={modalStatusClass}>
                <div className="Modal-bg" onClick={this.closeModal} />
                <div className="Modal-box">
                    <div className="Modal-header">
                        <div className="Modal-title">Prévia do Repasse</div>
                        <div className="Modal-close" onClick={this.closeModal}>
                            <img src={iconClose} alt="Fechar" />
                        </div>
                    </div>
                    <div className="Modal-body">
                        <div className="Modal-content">
                            <div className="ModalPreviaRepasse-content">
                                <div className="ModalPreviaRepasse-section --repasse">
                                    <div className="ModalPreviaRepasse-image-container">
                                        <img
                                            className="ModalPreviaRepasse-image"
                                            src={placeholderCarSm}
                                            alt="Nissan 350Z"
                                        />
                                        <div className="ModalPreviaRepasse-image-overlay">
                                            <img src={iconGallery} alt="" />
                                            Ver Galeria
                                        </div>
                                    </div>

                                    <div className="ModalPreviaRepasse-info-car">
                                        <RepasseInfo
                                            tituloRepasse="Nissan 350Z"
                                            ano="2018"
                                            combustivel="Etanol"
                                            cor="Vermelho"
                                            diasNoPatio="30"
                                            vendedor="Yan Gondim"
                                            acompanharNegociacao={true}
                                            marginAuto={true}
                                        />
                                    </div>
                                    <div className="ModalPreviaRepasse-info-price">
                                        <RepasseValores
                                            valorRepasse="180.000"
                                            valorFipe="240.000"
                                            valorPorcentagem="25"
                                        />

                                        <Link
                                            to="/repasses/1"
                                            className="ModalPreviaRepasse-cta button blue"
                                        >
                                            Ver ficha completa
                                        </Link>
                                    </div>
                                </div>

                                <div className="ModalPreviaRepasse-section --chat">
                                    <InteressadoHeader numberInterested="3" isLite={true} />
                                    <InteressadoRow
                                        name="Pedro Britto"
                                        phoneNumber="(85) 98899-2237"
                                        hasRequestedNumber={true}
                                        hasNegociated={true}
                                        proposal="10000"
                                        isLite={true}
                                    />
                                    <InteressadoRow
                                        name="Pedro Britto"
                                        phoneNumber="(85) 98899-2237"
                                        hasRequestedNumber={true}
                                        hasNegociated={true}
                                        proposal="10000"
                                        isLite={true}
                                    />
                                    <InteressadoRow
                                        name="Pedro Britto"
                                        phoneNumber="(85) 98899-2237"
                                        hasRequestedNumber={true}
                                        hasNegociated={true}
                                        proposal="10000"
                                        isLite={true}
                                    />
                                </div>

                                <div className="ModalPreviaRepasse-section --turbo">
                                    <div className="TurboTab">
                                        <div className="mini-title">
                                            <img
                                                className="mini-title-icon"
                                                src={iconTurboMax}
                                                alt=""
                                            />
                                            Turbo
                                        </div>

                                        <div className="TurboTab-box">
                                            <div className="TurboTab-image">
                                                <img src={iconNoTurbo} alt="" />
                                            </div>
                                            <div className="TurboTab-text">
                                                Você não possui um pacote Turbo. Acelere suas
                                                vendas, obtenha agora!
                                            </div>
                                            <button className="button blue TurboTab-button">
                                                Ver Pacotes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalPreviaRepasse;
