import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { baseURL } from '../../services/api';
import BoxChatItem from '../BoxChatItem';
import RoundedSearchInput from '../RoundedSearchInput';
import UsuarioChatItem from '../usuarioChatItem';
import './style.sass';

// const socketURL = 'ws://beta.aplicativorepasse.com.br:8777?token=';

class ChatComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeChat: '',
            searched: [],
            socket: null,
            messages: [],
            currentChatMessages: this.props.data || [],
            typedMessage: '',
        };
    }

    scrollToTop = () => {
        document.body.scrollTop = 245;
        document.documentElement.scrollTop = 245;
    };

    componentDidMount() {
        // const { user } = this.props;

        this.scrollToTop();

        /* if (user) {
            const socket = new WebSocket(`${socketURL}${this.props.user.chat_token}`);

            socket.onopen = event => {
                console.log('onopen: ', event);
            };

            socket.onmessage = event => {
                // console.log('onMessage: ', JSON.parse(event.data))

                let { currentChatMessages } = this.state;

                let new_msgs = JSON.parse(event.data);
                let pool_ids = [];
                let received_messages = [];
                let msgs_group = [];

                if (!new_msgs.typing && Array.isArray(new_msgs)) {
                    msgs_group = this.groupMessages(new_msgs);

                    new_msgs.forEach(item => {
                        if (item.pool_id && item.sender_id !== user.id) {
                            received_messages = received_messages.concat(item);
                            pool_ids = pool_ids.concat(item.pool_id);
                        }
                    });
                } else if (!new_msgs.typing && typeof new_msgs === 'object') {
                    if (new_msgs.message || new_msgs.audio || new_msgs.image) {
                        msgs_group = [
                            ...msgs_group,
                            {
                                room: new_msgs.room_id,
                                messages: {
                                    date: moment(new_msgs.message_id).format('YYYY-MM-DD'),
                                    msgs: [new_msgs],
                                },
                            },
                        ];
                    }

                    if (new_msgs.pool_id && new_msgs.sender_id !== user.id) {
                        received_messages = received_messages.concat(new_msgs);
                        pool_ids = pool_ids.concat(new_msgs.pool_id);
                    }
                }

                if (pool_ids.length) {
                    this.receiveMessages(pool_ids, received_messages);
                }

                msgs_group.forEach((newMessages, key1) => {
                    currentChatMessages.forEach((oldMessages, key2) => {
                        if (newMessages.room === oldMessages.room_id) {
                            const lastCurrent = currentChatMessages[currentChatMessages.length - 1];
                            const newest = newMessages.messages;

                            let lastId = null;
                            if (lastCurrent.messages[lastCurrent.messages.length - 1]) {
                                const lastMsgs =
                                    lastCurrent.messages[lastCurrent.messages.length - 1].msgs;
                                lastId = lastMsgs[lastMsgs.length - 1].message_id;
                            }

                            if (newest.msgs[0].message_id === lastId) return;

                            let currentChat = currentChatMessages.find(
                                item => item.room_id === this.state.activeChat.room_id
                            );

                            if (currentChat.room_id !== newest.msgs[0].room_id) {
                                let newMessageChat = currentChatMessages.find(
                                    item => item.room_id === newest.msgs[0].room_id
                                );

                                if (
                                    newMessageChat.messages[newMessageChat.messages.length - 1]
                                        .date !== newest.date
                                )
                                    newMessageChat.messages.push(newest);
                                else
                                    newMessageChat.messages[
                                        newMessageChat.messages.length - 1
                                    ].msgs.push(newest.msgs[0]);

                                this.handleChangeTypedMessage('.');
                                this.handleChangeTypedMessage('');
                            } else {
                                if (
                                    currentChat.messages[currentChat.messages.length - 1].date !==
                                    newest.date
                                )
                                    currentChat.messages.push(newest);
                                else
                                    currentChat.messages[currentChat.messages.length - 1].msgs.push(
                                        newest.msgs[0]
                                    );

                                this.handleChangeTypedMessage('.');
                                this.handleChangeTypedMessage('');
                            }

                            this.setState({ currentChatMessages });
                        }
                    });
                });
            };

            socket.onclose = event => {
                console.log('onclose: ', event);
            };

            socket.onerror = event => {
                console.log('onerror: ', event);
            };

            this.setState({ socket });
        } */
    }

    groupMessages = messages => {
        let msgs_group = messages.reduce((acc, item) => {
            if (!acc[item.room_id]) {
                acc[item.room_id] = [];
            }

            acc[item.room_id].push(item);
            return acc;
        }, {});

        let rooms = Object.keys(msgs_group);
        let msgs = Object.values(msgs_group);

        msgs_group = rooms.map((item1, key1) => {
            let messages = [];
            return msgs[key1].map((item2, key2) => {
                return {
                    room: parseInt(item1),
                    messages: {
                        date: moment(item2.message_id).format('YYYY-MM-DD'),
                        msgs: messages.concat(item2),
                    },
                };
            });
        });

        return msgs_group;
    };

    componentDidUpdate = (prevProps, prevState) => {
        this.scrollToTop();

        if (!this.state.activeChat && this.props.activeChatIndex) {
            this.setState({
                activeChat: this.props.data.find(
                    item => item.room_id === this.props.activeChatIndex
                ),
            });
        }
    };

    sendMessage = (room_id, sender_id, receiver_id, type, purchaser) => {
        const { typedMessage, socket } = this.state;

        if (typedMessage) {
            if (type === '1') {
                socket.send(
                    JSON.stringify({
                        room_id: room_id,
                        sender_id: sender_id,
                        receiver_id: receiver_id,
                        message_id: moment().valueOf(),
                        message: typedMessage,
                        image: null,
                        audio: null,
                        car: this.props.ad.car,
                        client: purchaser,
                    })
                );
            }

            if (type === '2') {
                socket.send(
                    JSON.stringify({
                        room_id: room_id,
                        sender_id: sender_id,
                        receiver_id: receiver_id,
                        message_id: moment().valueOf(),
                        message: null,
                        image: typedMessage,
                        audio: null,
                        car: this.props.ad.car,
                        client: purchaser,
                    })
                );
            }

            if (type === '3') {
                socket.send(
                    JSON.stringify({
                        room_id: room_id,
                        sender_id: sender_id,
                        receiver_id: receiver_id,
                        message_id: moment().valueOf(),
                        message: null,
                        image: null,
                        audio: typedMessage,
                        car: this.props.ad.car,
                        client: purchaser,
                    })
                );
            }
        }

        this.setState({ typedMessage: '' });
    };

    receiveMessages = (pool_ids, received_messages) => {
        const { user } = this.props;
        const { activeChat } = this.state;

        received_messages.forEach((item, index) => {
            if (item.receiver_id === user.id) {
                this.state.socket.send(
                    JSON.stringify({
                        room_id: item.room_id,
                        message_id: item.message_id,
                        sender_id: item.sender_id,
                        receiver_id: item.receiver_id,
                        received: true,
                    })
                );

                if (item.room_id === activeChat.room_id) {
                    this.state.socket.send(
                        JSON.stringify({
                            room_id: item.room_id,
                            message_id: item.message_id,
                            sender_id: item.sender_id,
                            receiver_id: item.receiver_id,
                            read: true,
                        })
                    );
                }
            }
        });

        this.state.socket.send(
            JSON.stringify({
                ack: true,
                pool_ids: pool_ids,
            })
        );
    };

    removeAccents = string => {
        const accents =
            'ÀÁÂÃÄÅĄàáâãäåąßÒÓÔÕÕÖØÓòóôõöøóÈÉÊËĘèéêëęðÇĆçćÐÌÍÎÏìíîïÙÚÛÜùúûüÑŃñńŠŚšśŸÿýŽŻŹžżź';
        const accentsOut =
            'AAAAAAAaaaaaaaBOOOOOOOOoooooooEEEEEeeeeeeCCccDIIIIiiiiUUUUuuuuNNnnSSssYyyZZZzzz';
        return string
            .split('')
            .map((letter, index) => {
                const accentIndex = accents.indexOf(letter);
                return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
            })
            .join('');
    };

    handleChangeTypedMessage = value => {
        this.setState({ typedMessage: value });
    };

    render() {
        const { searched, socket, activeChat, currentChatMessages } = this.state;
        const { setChatMessages, roleUser, user } = this.props;
        const data = searched.length ? searched : currentChatMessages;

        const activeChatMessages = currentChatMessages.find(
            item => item.room_id === activeChat.room_id
        );

        return (
            <div className="tab-chat-container">
                {currentChatMessages && currentChatMessages.length > 0 ? (
                    <div className="container-flex">
                        <div className="coluna-esquerda">
                            <div className="container-pesquisa">
                                <RoundedSearchInput
                                    onChange={value =>
                                        this.setState({
                                            searched: currentChatMessages.filter(item =>
                                                this.removeAccents(item.purchaser.name)
                                                    .toLowerCase()
                                                    .includes(
                                                        this.removeAccents(value).toLowerCase()
                                                    )
                                            ),
                                        })
                                    }
                                />
                            </div>

                            <div className="container-usuarios">
                                {data.map((item, key) => (
                                    <div
                                        key={key}
                                        className="usuario-item"
                                        onClick={() => this.setState({ activeChat: item })}
                                    >
                                        <UsuarioChatItem
                                            ativo={item.room_id === activeChat.room_id}
                                            chatProprio={
                                                item.messages[0] &&
                                                item.messages[0].msgs[0] &&
                                                (item.messages[0].msgs[0].sender_id === user.id ||
                                                    item.messages[0].msgs[0].receiver_id ===
                                                        user.id)
                                            }
                                            caminhoImagem={`${baseURL}/uploads/avatar/${
                                                item.purchaser.photo
                                            }`}
                                            nomeUsuario={item.purchaser.name}
                                            mensagensPendentes={item.badge}
                                            valor={item.bid}
                                            ddd="85"
                                            telefone1={item.purchaser.cell_phone}
                                            telefone2={item.purchaser.phone}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="coluna-direita">
                            <BoxChatItem
                                vehicle={this.props.ad.car}
                                data={activeChat || {}}
                                activeChatMessages={activeChatMessages}
                                socket={socket}
                                setChatMessages={setChatMessages}
                                sendMessage={this.sendMessage}
                                typedMessage={this.state.typedMessage}
                                changeTypedMessage={this.handleChangeTypedMessage}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="container-empty-list">Seu anúncio ainda não possui chats.</div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
    storeId: auth.storeId,
});

export default connect(mapStateToProps)(ChatComponent);
