import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.scss';

/**
 *
 * @param {string} props.type
 * @param {string} props.size
 * @param {*} props.children
 */
const MessageBox = props => {
    const { type = '', size = '' } = props;
    const iconClassName = 'MessageBox-icon';

    const iconMap = {
        warning: <FontAwesomeIcon className={iconClassName} icon="exclamation-triangle" />,
        danger: <FontAwesomeIcon className={iconClassName} icon="times" />,
    };

    const className = classNames('MessageBox', [type], [size]);

    return (
        <div className={className}>
            {iconMap[type]}
            <span className="MessageBox-text">{props.children}</span>
        </div>
    );
};

export default MessageBox;
