import { createActions } from 'reduxsauce';

import { Creators as RepasseViewActions } from '../ducks/repasseView';
import api from '../../services/api';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
    setError: ['msg'],
    setLoading: ['value'],
    setFieldsByType: ['data'],
    setFieldsByBrand: ['data'],
    setFieldsByYear: ['data', 'percent'],
    setFieldsByFipe: ['data'],
    resetApp: [''],
});

export const populateAnnounceByType = type_id => async dispatch => {
    try {
        dispatch(Creators.setLoading(true));

        const response = await api.get(`/api/v2/populate-announce/${type_id}`);
        const { data } = response.data;

        dispatch(Creators.setFieldsByType(data));
    } catch (error) {
        console.log(error);
    } finally {
        dispatch(Creators.setLoading(false));
    }
};

export const populateAnnounceByBrand = (type_id, brand_id) => async dispatch => {
    try {
        dispatch(Creators.setLoading(true));

        const response = await api.get(`/api/v2/populate-year/${type_id}/${brand_id}`);
        const { data } = response.data;

        dispatch(Creators.setFieldsByBrand(data));
    } catch (error) {
        console.log(error);
    } finally {
        dispatch(Creators.setLoading(false));
    }
};

export const populateAnnounceByYear = (brand_id, year) => async dispatch => {
    try {
        dispatch(Creators.setLoading(true));

        const response = await api.get(`/api/v2/populate-model/${brand_id}/${year}`);
        const { data, fipe_percent } = response.data;

        dispatch(Creators.setFieldsByYear(data, fipe_percent));
    } catch (error) {
        console.log(error);
    } finally {
        dispatch(Creators.setLoading(false));
    }
};

export const populateAnnounceByFipe = (fipe_id) => async dispatch => {
    try {
        dispatch(Creators.setLoading(true));
        const response = await api.get(`/api/v2/populate-vehicle/${fipe_id}`);
        console.log(response);
        const { data } = response.data;

        dispatch(Creators.setFieldsByFipe(data));

        return response.data;
    } catch (error) {
        console.log(error);
    } finally {
        dispatch(Creators.setLoading(false));
    }
}

export const createCar = (car, storeId, nextStep) => async dispatch => {
    try {
        dispatch(Creators.setLoading(true));
        dispatch(Creators.setError(''));

        /** Criação do carro  */
        const response = await api.post(`/api/v2/car`, {
            color_id: car.vehicleColor,
            doors: car.vehicleDoors,
            eixos: car.vehicleEixo,
            brand_id: car.vehicleBrand,
            fipe_id: car.vehicleModel,
            year: car.vehicleModelYear,
            observation: car.vehicleDescription,
            cost: car.vehicleCostPrice,
            price: car.vehicleDesiredPrice,
            km: car.vehicleKilometrage,
            optionals: car.vehicleOptionals,
            store_id: storeId,
        });

        const { car_id } = response.data;

        /** Upload da primeira imagem  */
        const formData = new FormData();
        formData.append('filename', car.highlightImage, car.highlightImage.name);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        await api.post(`/api/v2/car-image/${car_id}`, formData, config);

        /** Upload das imagens da galeria  */
        await Promise.all(
            car.gallery.map(async file => {
                const formData = new FormData();
                await formData.append('filename', file, file.name);

                await api.post(`/api/v2/car-image/${car_id}`, formData, config);
            })
        );

        await api.post(`/api/v2/car-publish`, {
            id: car_id,
            user_types: [1, 2, 3, 4, 5],
        });

        nextStep();
        return car_id;
    } catch ({ response }) {
        // const { msg } = response.data;
        console.log({ response });

        dispatch(
            Creators.setError('Um erro ocorreu ao criar o anúncio, tente novamente mais tarde')
        );
    } finally {
        dispatch(Creators.setLoading(false));
    }
};

export const editCar = (car, nextStep) => async dispatch => {
    try {
        dispatch(Creators.setLoading(true));
        dispatch(Creators.setError(''));

        /** Edição do veículo  */
        const response = await api.post(`/api/v2/car-edit`, {
            car_id: car.car_id,
            color_id: car.vehicleColor,
            doors: car.vehicleDoors,
            eixos: car.vehicleEixo,
            observation: car.vehicleDescription,
            cost: car.vehicleCostPrice,
            price: car.vehicleDesiredPrice,
            km: car.vehicleKilometrage,
            optionals: car.vehicleOptionals,
        });

        const { car_id } = response.data;

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        /** Upload da primeira imagem  */

        if (car.highlightImage && car.highlightImage.name) {
            const formData = new FormData();
            formData.append('filename', car.highlightImage, car.highlightImage.name);

            await api.post(`/api/v2/car-image/${car_id}`, formData, config);
        }

        /** Upload das imagens da galeria  */
        await Promise.all(
            car.gallery.map(async file => {
                if (file.name) {
                    const formData = new FormData();
                    await formData.append('filename', file, file.name);

                    await api.post(`/api/v2/car-image/${car_id}`, formData, config);
                }
            })
        );

        /** Remove imagens da galeria  */
        await Promise.all(
            car.galleryDelete.map(
                async photo_id => await api.get(`/api/v2/car-photo-remove/${photo_id}`)
            )
        );

        dispatch(RepasseViewActions.cleanRepasse());

        nextStep();
    } catch (error) {
        // const { msg } = response.data;
        console.log(error);

        dispatch(
            Creators.setError('Um erro ocorreu ao editar o anúncio, tente novamente mais tarde')
        );
    } finally {
        dispatch(Creators.setLoading(false));
    }
};
