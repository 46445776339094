import React from 'react';

import './style.sass';

/**
 *
 * @param {*} props.fuelType
 * @param {*} props.modelYear
 * @param {*} props.doors
 * @param {*} props.color
 * @param {*} props.kilometrage
 * @param {*} props.optionalExtras
 * @param {*} props.description
 */
const DetalhesVeiculoTab = props => {
    return (
        <div className="DetalhesVeiculoTab">
            <div className="DetalhesVeiculoTab-listing-obrigatorio">
                <div className="DetalhesVeiculoTab-item">
                    <div className="DetalhesVeiculoTab-item-title">Tipo de Combustível</div>
                    <div className="DetalhesVeiculoTab-content">{props.fuelType}</div>
                </div>
                <div className="DetalhesVeiculoTab-item">
                    <div className="DetalhesVeiculoTab-item-title">Ano do modelo</div>
                    <div className="DetalhesVeiculoTab-content">{props.modelYear}</div>
                </div>
                <div className="DetalhesVeiculoTab-item">
                    <div className="DetalhesVeiculoTab-item-title">Portas</div>
                    <div className="DetalhesVeiculoTab-content">{props.doors}</div>
                </div>
                <div className="DetalhesVeiculoTab-item">
                    <div className="DetalhesVeiculoTab-item-title">Cor</div>
                    <div className="DetalhesVeiculoTab-content">{props.color}</div>
                </div>
                <div className="DetalhesVeiculoTab-item">
                    <div className="DetalhesVeiculoTab-item-title">Quilometragem</div>
                    <div className="DetalhesVeiculoTab-content">{props.kilometrage}</div>
                </div>
            </div>

            <div className="DetalhesVeiculoTab-item">
                <div className="DetalhesVeiculoTab-item-title">Opcionais</div>
                <div className="DetalhesVeiculoTab-content">
                    <ul>
                        {props.optionalExtras.map((item, index) => {
                            return <li key={index}>{item};</li>;
                        })}
                    </ul>
                </div>
            </div>

            {props.description && (
                <div className="DetalhesVeiculoTab-item">
                    <div className="DetalhesVeiculoTab-item-title">Descrição</div>
                    <div className="DetalhesVeiculoTab-content">{props.description}</div>
                </div>
            )}
        </div>
    );
};

export default DetalhesVeiculoTab;
