import React, { Component } from 'react'
// import GraficoVendas from '../../components/GraficoVendas';
import ContainerVendas from '../../components/ContainerVendas';
import ContainerValores from '../../components/ContainerValores';
import './style.sass';

export default class ContainerInfografico extends Component {
  render() {
    return (
        <div className="container-infograficos">
            <div className="coluna-esquerda">
                <ContainerVendas/>
            </div>
            <div className="coluna-direita">
                <ContainerValores valorTotal="175.456" lucroTotal="50.789" ticketsRepassados="20.456" ticketsCadastrados="30.567"/>
            </div>
        </div>
    )
  }
}
