import React, { Component } from 'react';
import MessageBox from '../../components/MessageBox';

import './style.sass';
import FormGroupSelect from '../../components/FormGroupSelect';
import FormGroupInputNumber from '../../components/FormGroupInputNumber';
import CheckboxGroup from '../../components/CheckboxGroup';
import Collapse from '../../components/Collapse';

const intlMonetary = new Intl.NumberFormat('pt-BR', {
    // style: 'currency',
    // currency: 'BRL',
    minimumFractionDigits: 2,
});

const intlKilometer = new Intl.NumberFormat('pt-BR', {
    useGrouping: true,
});

export default class FiltroVeículo extends Component {
    componentDidMount = () => {
        const { previouslySearched } = this.props;

        if (previouslySearched && previouslySearched.brand_id) {
            this.props.handleBrandToSearchChange(previouslySearched.brand_id);
        }

        if (previouslySearched && previouslySearched.state_id) {
            this.props.handleStateToSearchChange(previouslySearched.state_id);
        }
    };

    render() {
        const { search, toSearch, previouslySearched, checkedOptionals } = this.props;

        let checked = Array.from(checkedOptionals.keys());

        return (
            <React.Fragment>
                <FormGroupSelect
                    label="Marca"
                    labelSize="small"
                    inputSize="small"
                    onChange={e => this.props.handleBrandToSearchChange(e.target.value)}
                    value={
                        previouslySearched && previouslySearched.brand_id
                            ? previouslySearched.brand_id
                            : toSearch.brand_id !== null
                            ? toSearch.brand_id
                            : ''
                    }
                    options={
                        search.brands && [
                            { value: '', content: 'Selecione' },
                            ...search.brands
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map(item => ({
                                    value: item.id,
                                    content: item.name,
                                })),
                        ]
                    }
                />

                <FormGroupSelect
                    label="Modelo"
                    labelSize="small"
                    inputSize="small"
                    onChange={e => this.props.handleInputToSearchChange('model_id', e.target.value)}
                    value={
                        previouslySearched && previouslySearched.model_id
                            ? previouslySearched.model_id
                            : toSearch.model_id !== null
                            ? toSearch.model_id
                            : ''
                    }
                    options={
                        search.models &&
                        (toSearch.brand_id ||
                            (previouslySearched && previouslySearched.brand_id)) && [
                            { value: '', content: 'Selecione' },
                            ...search.models
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map(item => ({
                                    value: item.id,
                                    content: item.name,
                                })),
                        ]
                    }
                    placeholder="Selecione"
                />

                <div className="sidebar__section-title">ANO</div>

                <div className="form-row">
                    <div className="form-col">
                        <FormGroupSelect
                            label="De"
                            labelSize="small"
                            inputSize="small"
                            onChange={e =>
                                this.props.handleInputToSearchChange('start_year', e.target.value)
                            }
                            value={
                                previouslySearched && previouslySearched.start_year
                                    ? previouslySearched.start_year
                                    : toSearch.start_year !== null
                                    ? toSearch.start_year
                                    : ''
                            }
                            options={
                                search.years && [
                                    {
                                        value: '',
                                        content: 'Selecione',
                                    },
                                    ...search.years.map(item => ({
                                        value: item,
                                        content: item,
                                    })),
                                ]
                            }
                            errorMessage={
                                this.props.compareStartToEnd(toSearch.start_year, toSearch.end_year)
                                    ? 'Ano inicial maior que final'
                                    : null
                            }
                        />
                    </div>
                    <div className="form-col">
                        <FormGroupSelect
                            label="Até"
                            labelSize="small"
                            inputSize="small"
                            onChange={e =>
                                this.props.handleInputToSearchChange('end_year', e.target.value)
                            }
                            value={
                                previouslySearched && previouslySearched.end_year
                                    ? previouslySearched.end_year
                                    : toSearch.end_year !== null
                                    ? toSearch.end_year
                                    : ''
                            }
                            options={
                                search.years && [
                                    {
                                        value: '',
                                        content: 'Selecione',
                                    },
                                    ...search.years.map(item => ({
                                        value: item,
                                        content: item,
                                    })),
                                ]
                            }
                        />
                    </div>
                </div>

                <div className="sidebar__section-title">PREÇO</div>

                <div className="form-row">
                    <div className="form-col">
                        <FormGroupSelect
                            label="De"
                            labelSize="small"
                            inputSize="small"
                            onChange={e =>
                                this.props.handleInputToSearchChange('start_price', e.target.value)
                            }
                            value={
                                previouslySearched && previouslySearched.start_price
                                    ? previouslySearched.start_price
                                    : toSearch.start_price !== null
                                    ? toSearch.start_price
                                    : ''
                            }
                            options={
                                search.price && [
                                    {
                                        value: '',
                                        content: 'Selecione',
                                    },
                                    ...search.price.map(item => ({
                                        value: item,
                                        content: intlMonetary.format(item),
                                    })),
                                ]
                            }
                            errorMessage={
                                this.props.compareStartToEnd(
                                    toSearch.start_price,
                                    toSearch.end_price
                                )
                                    ? 'Ano inicial maior que final'
                                    : null
                            }
                        />
                    </div>

                    <div className="form-col">
                        <FormGroupSelect
                            label="Até"
                            labelSize="small"
                            inputSize="small"
                            onChange={e =>
                                this.props.handleInputToSearchChange('end_price', e.target.value)
                            }
                            value={
                                previouslySearched && previouslySearched.end_price
                                    ? previouslySearched.end_price
                                    : toSearch.end_price !== null
                                    ? toSearch.end_price
                                    : ''
                            }
                            options={
                                search.price && [
                                    {
                                        value: '',
                                        content: 'Selecione',
                                    },
                                    ...search.price.map(item => ({
                                        value: item,
                                        content: intlMonetary.format(item),
                                    })),
                                ]
                            }
                        />
                    </div>
                </div>

                <div className="sidebar__section-title">KILOMETRAGEM</div>

                <div className="form-row">
                    <div className="form-col">
                        <FormGroupSelect
                            label="De"
                            labelSize="small"
                            inputSize="small"
                            onChange={e =>
                                this.props.handleInputToSearchChange('start_km', e.target.value)
                            }
                            value={
                                previouslySearched && previouslySearched.start_km
                                    ? previouslySearched.start_km
                                    : toSearch.start_km !== null
                                    ? toSearch.start_km
                                    : ''
                            }
                            options={
                                search.km && [
                                    {
                                        value: '',
                                        content: 'Selecione',
                                    },
                                    ...search.km.map(item => ({
                                        value: item,
                                        content: intlKilometer.format(item),
                                    })),
                                ]
                            }
                            errorMessage={
                                this.props.compareStartToEnd(toSearch.start_km, toSearch.end_km)
                                    ? 'Quilometragem inicial maior que final'
                                    : null
                            }
                        />
                    </div>

                    <div className="form-col">
                        <FormGroupSelect
                            label="Até"
                            labelSize="small"
                            inputSize="small"
                            onChange={e =>
                                this.props.handleInputToSearchChange('end_km', e.target.value)
                            }
                            value={
                                previouslySearched && previouslySearched.end_km
                                    ? previouslySearched.end_km
                                    : toSearch.end_km !== null
                                    ? toSearch.end_km
                                    : ''
                            }
                            options={
                                search.km && [
                                    {
                                        value: '',
                                        content: 'Selecione',
                                    },
                                    ...search.km.map(item => ({
                                        value: item,
                                        content: intlKilometer.format(item),
                                    })),
                                ]
                            }
                        />
                    </div>
                </div>

                <FormGroupSelect
                    label="Cor"
                    labelSize="small"
                    inputSize="small"
                    onChange={e =>
                        e.target.value !== ''
                            ? this.props.handleInputToSearchChange('color_ids', [e.target.value])
                            : this.props.handleInputToSearchChange('color_ids', [])
                    }
                    value={
                        previouslySearched &&
                        previouslySearched.color_ids &&
                        previouslySearched.color_ids.length &&
                        previouslySearched.color_ids[0] !== undefined
                            ? previouslySearched.color_ids[0]
                            : toSearch.color_ids[0] !== undefined
                            ? toSearch.color_ids[0]
                            : ''
                    }
                    options={
                        search.colors && [
                            {
                                value: '',
                                content: 'Selecione',
                            },
                            ...search.colors.map(item => ({
                                value: item.id,
                                content: item.name,
                            })),
                        ]
                    }
                />

                <FormGroupSelect
                    label="Combustível"
                    labelSize="small"
                    inputSize="small"
                    onChange={e =>
                        e.target.value !== ''
                            ? this.props.handleInputToSearchChange('fuel', [e.target.value])
                            : this.props.handleInputToSearchChange('fuel', [])
                    }
                    value={
                        previouslySearched &&
                        previouslySearched.fuel &&
                        previouslySearched.fuel.length &&
                        previouslySearched.fuel[0] !== undefined
                            ? previouslySearched.fuel[0]
                            : toSearch.fuel[0] !== undefined
                            ? toSearch.fuel[0]
                            : ''
                    }
                    options={
                        search.fuel && [
                            {
                                value: '',
                                content: 'Selecione',
                            },
                            ...search.fuel.map(item => ({
                                value: item.value,
                                content: item.label,
                            })),
                        ]
                    }
                />

                {toSearch.car_type === 5 && (
                    <FormGroupSelect
                        label="Eixos"
                        labelSize="small"
                        inputSize="small"
                        onChange={e =>
                            e.target.value !== ''
                                ? this.props.handleInputToSearchChange('eixos', [e.target.value])
                                : this.props.handleInputToSearchChange('eixos', [])
                        }
                        value={
                            previouslySearched &&
                            previouslySearched.eixos &&
                            previouslySearched.eixos.length &&
                            previouslySearched.eixos[0] !== undefined
                                ? previouslySearched.eixos[0]
                                : toSearch.eixos[0] !== undefined
                                ? toSearch.eixos[0]
                                : ''
                        }
                        options={
                            search.eixos && [
                                {
                                    value: '',
                                    content: 'Selecione',
                                },
                                ...search.eixos.map(item => ({
                                    value: item,
                                    content: item,
                                })),
                            ]
                        }
                    />
                )}

                {toSearch.car_type !== 8 && (
                    <div className="form-row">
                        <div className="form-col--5">
                            <FormGroupInputNumber
                                label="Portas"
                                labelSize="small"
                                inputSize="small"
                                incrementValue={this.props.incrementDoorsValue}
                                decrementValue={this.props.decrementDoorsValue}
                                value={
                                    previouslySearched &&
                                    previouslySearched.doors &&
                                    previouslySearched.doors.length
                                        ? previouslySearched.doors[0]
                                        : toSearch.doors.length
                                        ? toSearch.doors[0]
                                        : 4
                                }
                                min={2}
                                max={5}
                            />
                        </div>
                    </div>
                )}

                <div className="sidebar__section">
                    <Collapse title="OPCIONAIS">
                        <div className="optional-listing">
                            {search.optionals &&
                                search.optionals.map(item => (
                                    <CheckboxGroup
                                        key={item.id}
                                        id={item.id}
                                        label={item.name}
                                        checked={
                                            checked.includes(item.id.toString()) ||
                                            (previouslySearched &&
                                                previouslySearched.optionals_id &&
                                                previouslySearched.optionals_id.length &&
                                                previouslySearched.optionals_id.includes(
                                                    item.id.toString()
                                                ))
                                        }
                                        onChange={this.props.addOptionalToSearch}
                                        value={item.id}
                                    />
                                ))}
                        </div>
                    </Collapse>
                </div>

                <FormGroupSelect
                    label="Tipo de vendedor"
                    labelSize="small"
                    inputSize="small"
                    onChange={e =>
                        e.target.value !== ''
                            ? this.props.handleInputToSearchChange('user_type', [e.target.value])
                            : this.props.handleInputToSearchChange('user_type', [])
                    }
                    value={
                        previouslySearched &&
                        previouslySearched.user_type &&
                        previouslySearched.user_type.length &&
                        previouslySearched.user_type[0] !== undefined
                            ? previouslySearched.user_type[0]
                            : toSearch.user_type[0] !== undefined
                            ? toSearch.user_type[0]
                            : ''
                    }
                    options={
                        search.user_types && [
                            { value: '', content: 'Selecione' },
                            ...search.user_types.map(item => ({
                                value: item.id,
                                content: item.name,
                            })),
                        ]
                    }
                />

                <FormGroupSelect
                    label="Estado"
                    labelSize="small"
                    inputSize="small"
                    onChange={e => this.props.handleStateToSearchChange(e.target.value)}
                    value={
                        previouslySearched && previouslySearched.state_id
                            ? previouslySearched.state_id
                            : toSearch.state_id !== null
                            ? toSearch.state_id
                            : ''
                    }
                    options={
                        search.states && [
                            { value: '', content: 'Selecione' },
                            ...search.states.map(item => ({
                                value: item.id,
                                content: item.name,
                            })),
                        ]
                    }
                />

                <FormGroupSelect
                    label="Região"
                    labelSize="small"
                    inputSize="small"
                    onChange={e =>
                        e.target.value !== ''
                            ? this.props.handleInputToSearchChange('ddds', [e.target.value])
                            : this.props.handleInputToSearchChange('ddds', [])
                    }
                    options={
                        search.ddds && toSearch.state_id
                            ? [
                                  { value: '', content: 'Selecione' },
                                  ...search.ddds.map(item => ({
                                      value: item.ddd,
                                      content: item.name,
                                  })),
                              ]
                            : [{ value: '', content: 'Selecione' }]
                    }
                    value={
                        previouslySearched &&
                        previouslySearched.ddds &&
                        previouslySearched.ddds.length
                            ? previouslySearched.ddds[0]
                            : toSearch.ddds[0] !== undefined
                            ? toSearch.ddds[0]
                            : ''
                    }
                />

                {this.props.formError && (
                    <MessageBox type="danger" inlineBlock>
                        {this.props.formError}
                    </MessageBox>
                )}

                <div className="margin-top--big">
                    <button className="button primary block" onClick={this.props.onFilterClick}>
                        Buscar
                    </button>
                </div>
            </React.Fragment>
        );
    }
}
