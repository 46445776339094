import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import { Types as AuthTypes } from '../ducks/auth';
import { Types as ProfileUserTypes } from '../ducks/profileUser';
import { Types as RepassesListTypes } from '../ducks/repassesList';
import { Types as RepasseViewTypes } from '../ducks/repasseView';

import { loginUser, loadUserData, acceptTermsOfUse, logoutUser } from './auth';
import { getUser, getStates, updateUser } from './profileUser';
import {
    requestRepasses,
    requestMoreRepasses,
    toggleRepasseFavorite,
    populateSearch,
    populateSearchByType,
    populateSearchByBrand,
    populateSearchByState,
    buscarRepasses,
    loadMoreFromSearch,
} from './repassesList';
import { getRepasse, getScoreboard } from './repasseView';

export default function* rootSaga() {
    yield all([
        takeLatest(AuthTypes.LOGIN_USER, loginUser),
        takeLatest(AuthTypes.LOAD_USER_DATA, loadUserData),
        takeEvery(AuthTypes.ACCEPT_TERMS_OF_USE, acceptTermsOfUse),
        takeEvery(AuthTypes.LOGOUT_USER, logoutUser),

        takeEvery(ProfileUserTypes.GET_USER, getUser),
        takeEvery(ProfileUserTypes.GET_STATES, getStates),
        takeEvery(ProfileUserTypes.UPDATE_USER, updateUser),

        takeLatest(RepassesListTypes.REQUEST_REPASSES, requestRepasses),
        takeEvery(RepassesListTypes.REQUEST_MORE_REPASSES, requestMoreRepasses),
        takeLatest(RepassesListTypes.TOGGLE_REPASSE_FAVORITE, toggleRepasseFavorite),
        takeEvery(RepassesListTypes.POPULATE_SEARCH, populateSearch),
        takeEvery(RepassesListTypes.POPULATE_SEARCH_BY_TYPE, populateSearchByType),
        takeEvery(RepassesListTypes.POPULATE_SEARCH_BY_BRAND, populateSearchByBrand),
        takeEvery(RepassesListTypes.POPULATE_SEARCH_BY_STATE, populateSearchByState),
        takeLatest(RepassesListTypes.BUSCAR_REPASSES, buscarRepasses),
        takeEvery(RepassesListTypes.LOAD_MORE_FROM_SEARCH, loadMoreFromSearch),

        takeLatest(RepasseViewTypes.GET_REPASSE, getRepasse),
        takeEvery(RepasseViewTypes.GET_SCOREBOARD, getScoreboard),
    ]);
}
