import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators as AuthActions } from '../../store/ducks/auth';
import CadastroUsuarioContainer from '../../components/CadastroUsuarioContainer';
import fotoCadastro from '../../assets/images/foto-cadastro.png';
import './style.sass';
class CadastroUsuarioCinco extends Component {
    componentDidMount() {
        if (this.props.user.currentStep === 0 || this.props.user.currentStep < 5)
            this.props.history.push('/cadastro-usuario-quatro');
    }

    render() {
        return (
            <div>
                <CadastroUsuarioContainer currentStep={5}>
                    <div className="container-finish-cadastro">
                        <div className="foto">
                            <img src={fotoCadastro} alt="" />
                        </div>

                        {!this.props.user.id ? (
                            <>
                                <div className="titulo-cadastro">
                                    Seu cadastro foi efetuado com sucesso!
                                </div>
                                <div className="subtitulo-cadastro">
                                    Aguarde contato da nossa equipe comercial para ativação da sua
                                    conta.
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="titulo-cadastro">
                                    Seu cadastro foi atualizado com sucesso!
                                </div>
                                <div className="subtitulo-cadastro">
                                    Agora você pode desfrutar novamente de todos os benefícicios da
                                    sua conta.
                                </div>
                            </>
                        )}
                        <button className="button primary" onClick={() => this.props.logoutUser()}>
                            Ir para login
                        </button>
                    </div>
                </CadastroUsuarioContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ userCreate }) => ({
    user: userCreate.user,
});

const mapDispatchToProps = dispatch => bindActionCreators(AuthActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CadastroUsuarioCinco);
