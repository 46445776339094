import React, { Component } from 'react';
import './style.sass';

import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * @param {*} props.id
 * @param {string} props.label
 * @param {number} props.value
 * @param {number} props.min
 * @param {number} props.max
 * @param {function} props.incrementValue
 * @param {function} props.decrementValue
 */

class FormGroupInputNumber extends Component {
    constructor(props) {
        super(props);
        this.state = { value: 4 };
        this.min = this.props.min || 0;
        this.max = this.props.max || 999;

        if (
            typeof this.min !== 'number' ||
            typeof this.max !== 'number' ||
            typeof this.state.value !== 'number'
        ) {
            console.warn('"min", "max" or "value" props should be of type number');
        }
    }

    componentDidMount() {
        this.setState({ value: this.props.value });
    }

    incrementValue = () => {
        if (this.state.value < this.max) {
            const { value } = this.state;
            this.setState({ value: value + 1 });
        }
    };

    decrementValue = () => {
        if (this.min < this.state.value) {
            const { value } = this.state;
            this.setState({ value: value - 1 });
        }
    };

    render() {
        const { label = 'Label', disabled = '', labelSize = '', inputSize = '' } = this.props;

        const labelClass = classNames('label', [labelSize]);
        const inputClass = classNames('form-control', [inputSize]);
        return (
            <div className="FormGroupInputNumber form-group">
                <label className={labelClass} htmlFor={this.props.id}>
                    {label}
                </label>

                <div className="input-group">
                    <div
                        className="input-group-prepend"
                        style={{ cursor: 'pointer' }}
                        onClick={this.props.decrementValue}
                    >
                        <div className="input-group-content">
                            <FontAwesomeIcon icon="chevron-left" />
                        </div>
                    </div>

                    <input
                        style={{ backgroundColor: '#FFF', textAlign: 'center' }}
                        className={inputClass}
                        id={this.props.id}
                        readOnly
                        disabled={disabled}
                        type="text"
                        value={this.props.value}
                    />

                    <div
                        className="input-group-append"
                        style={{ cursor: 'pointer' }}
                        onClick={this.props.incrementValue}
                    >
                        <div className="input-group-content">
                            <FontAwesomeIcon icon="chevron-right" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormGroupInputNumber;
