import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import createRootReducer from './reducers';
import sagas from './sagas';

const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    createRootReducer(history),
    applyMiddleware(routerMiddleware(history), thunk, sagaMiddleware)
);

sagaMiddleware.run(sagas);

const persistor = persistStore(store);

export { store, persistor, history };
