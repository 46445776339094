import React, { Component } from 'react';
import classNames from 'classnames';

import iconClose from '../../assets/images/icon-close.png';
import iconTurboLite from '../../assets/images/icon-turbo-lite.png';
import iconTurboFast from '../../assets/images/icon-turbo-fast.png';
import iconTurboMax from '../../assets/images/icon-turbo-max.png';

import './style.sass';

class ModalPlanos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
        };

        this.planosDummy = [
            {
                id: 0,
                icon: iconTurboLite,
                name: 'Turbo Lite',
                price: '10',
                description: 'Seu anúncio no topo da lista por um dia',
            },
            {
                id: 1,
                icon: iconTurboFast,
                name: 'Turbo Fast',
                price: '26',
                description: 'Seu anúncio no topo da lista por 3 dias',
            },
            {
                id: 2,
                icon: iconTurboMax,
                name: 'Turbo Max',
                price: '45',
                description: 'Seu anúncio no topo da lista por 5 dias',
            },
        ];

        this.handleEscPress();
    }

    openModal = () => this.setState({ isActive: true });
    closeModal = () => this.setState({ isActive: false });

    handleEscPress = () => {
        window.addEventListener('keydown', event => {
            if (event.code === 'Escape' && this.state.isActive === true) {
                this.closeModal();
            }
        });
    };

    render() {
        const modalStatusClass = classNames({
            Modal: true,
            ModalPlanos: true,
            'is-active': this.state.isActive,
        });
        return (
            <div className={modalStatusClass}>
                <div className="Modal-bg" onClick={this.closeModal} />
                <div className="Modal-box">
                    <div className="Modal-header">
                        <div className="Modal-title">Turbine suas vendas!</div>
                        <div className="Modal-close" onClick={this.closeModal}>
                            <img src={iconClose} alt="Fechar" />
                        </div>
                    </div>
                    <div className="Modal-body">
                        <div className="Modal-content">
                            <div className="ModalPlanos-intro-text">
                                Impulsione seus anúncios com os nossos pacotes turbo!
                                <br />
                                Aumente a visibilidade, receba muito mais propostas e venda mais
                                rápido!
                            </div>

                            <div className="ModalPlanos-plano-listing">
                                {this.planosDummy.map(item => {
                                    return (
                                        <div className="ModalPlanos-plano-item" key={item.id}>
                                            <div className="plano-item__image-container">
                                                <img
                                                    className="plano-item__image"
                                                    src={item.icon}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="plano-item__name">{item.name}</div>
                                            <div className="plano-item__price">R$ {item.price}</div>
                                            <div className="plano-item__description">
                                                {item.description}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="ModalPlanos-cta">
                                Vá até o seu anúncio e turbine agora mesmo!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalPlanos;
