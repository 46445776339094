import React, { Component } from 'react';
import { connect } from 'react-redux';

import CadastroProgressBar from '../CadastroProgressBar';
import { Link } from 'react-router-dom';
import './style.sass';

import logoRepasse from '../../assets/logo-repasse-blue.png';

class CadastroUsuarioContainer extends Component {
    hasOptionPlano = () => {
        const { user, roleUser } = this.props;

        return user.update && roleUser.id === 2;
    };

    render() {
        return (
            <div className="container-page-cadastro">
                <div className="card-cadastro">
                    <div className="logo-topo">
                        <img src={logoRepasse} alt="" />
                    </div>

                    <div className="container-conteudo-cadastro">
                        <div className="coluna-esquerda">
                            <CadastroProgressBar
                                currentStep={this.props.currentStep}
                                steps={[
                                    'Tipo de Perfil',
                                    'Seus dados',
                                    this.props.user.hasOptionPlano && 'Plano',
                                    'Confirmar dados',
                                    'Cadastro concluído',
                                ]}
                            />

                            <div className="link-login">
                                Já tem cadastro? Faça seu login <Link to="/login">aqui</Link>
                            </div>
                        </div>

                        <div className="coluna-direita">
                            <div className="titulo-cadastro">{this.props.tituloPagina}</div>
                            <div className="conteudo-pagina">{this.props.children}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, userCreate }) => ({
    roleUser: auth.roleUser,
    user: userCreate.user,
});

export default connect(mapStateToProps)(CadastroUsuarioContainer);
