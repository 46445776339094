import axios from 'axios';

import { store } from '../store';
import { Creators as AuthActions } from '../store/ducks/auth';

let urlBase = 'https://beta.aplicativorepasse.com.br';

if (process.env.REACT_APP_BASE_URL) {
    urlBase = process.env.REACT_APP_BASE_URL;
}

// if (process.env.NODE_ENV === 'production') urlBase = 'https://srv.aplicativorepasse.com.br';

export const baseURL = urlBase;

const api = axios.create({
    baseURL,
});

api.interceptors.request.use(async request => {
    const token = await localStorage.getItem('@Repasse:token');

    if (token) request.headers.Authorization = `Bearer ${token}`;

    return request;
});

api.interceptors.response.use(
    response => response,
    async error => {
        const {
            // config,
            response: { data },
        } = error;

        console.log({ error });

        if (data.error === 'token_expired') {
            console.log('Token expired error', { error });
            /* try {
                const response = await api.post('/api/v2/refresh');
                const { token } = response.data.data;

                localStorage.setItem('@Repasse:token', token);
                config.headers.Authorization = `Bearer ${token}`;

                return Promise.resolve();
            } catch (err) {
                store.dispatch(AuthActions.logoutUser());
                console.log(err);
            } */

            store.dispatch(AuthActions.logoutUser());
            store.dispatch(AuthActions.loginUserFail('Sua sessão expirou, faça login novamente'));
        } else if (data.error === 'token_not_provided' || data.error === 'token_invalid') {
            store.dispatch(AuthActions.logoutUser());

            return Promise.resolve();
        } else {
            return Promise.reject(error);
        }
    }
);

export default api;
