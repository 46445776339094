import React from 'react';

// Style
import './style.sass';

const InfoItem = props => {
    return (
        <div className="InfoItem">
            <div className="InfoItem-valor">{props.valor}</div>
            <div className="InfoItem-texto">{props.titulo}</div>
        </div>
    );
};

export default InfoItem;
