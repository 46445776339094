import { createActions, createReducer } from 'reduxsauce';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
    /** Async actions */
    getUser: [],
    getStates: [],
    updateUser: ['user'],

    /** State actions */
    setLoading: ['value'],
    setStates: ['data'],
    setUser: ['data'],
    setUserAttribute: ['name', 'value'],
    resetApp: [''],
});

/* Initial State */

const INITIAL_STATE = {
    loading: false,
    states: [],
    user: {},
};

/* Reducers */

const setLoading = (state = INITIAL_STATE, action) => ({
    ...state,
    loading: action.value,
});

const setStates = (state = INITIAL_STATE, action) => ({
    ...state,
    states: action.data,
});

const setUser = (state = INITIAL_STATE, action) => ({
    ...state,
    user: action.data,
});

const setUserAttribute = (state = INITIAL_STATE, action) => ({
    ...state,
    user: {
        ...state.user,
        [action.name]: action.value,
    },
});

const resetApp = (state = INITIAL_STATE) => ({
    ...state,
    ...INITIAL_STATE,
});

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
    [Types.SET_LOADING]: setLoading,
    [Types.SET_STATES]: setStates,
    [Types.SET_USER]: setUser,
    [Types.SET_USER_ATTRIBUTE]: setUserAttribute,
    [Types.RESET_APP]: resetApp,
});
