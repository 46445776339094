import React from 'react';
// import { Link } from 'react-router-dom';

import NameInitials from '../NameInitials';
import classNames from 'classnames';

import iconChatEllipsis from '../../assets/images/icon-chat-ellipsis.png';
import iconPhone from '../../assets/images/icon-phone-small.png';

import './style.sass';

const intlMonetary = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
});

const InteressadoRow = props => {
    let hasRequestedNumber = props.hasRequestedNumber ? 'Sim' : 'Não';
    let hasNegociated = props.hasNegociated ? 'Sim' : 'Não';
    let avatar = <NameInitials name={props.name} />;
    let isLite = props.isLite ? props.isLite : false;

    if (props.avatarUrl) {
        avatar = <img className="InteressadoRow-avatar" src={props.avatarUrl} alt="" />;
    }

    const InteressadoRowClass = classNames({
        InteressadoRow: true,
        'is-lite': isLite,
    });

    return (
        <div className={InteressadoRowClass}>
            <div className="InteressadoRow-item">
                <div className="InteressadoRow-avatar-container">{avatar}</div>
            </div>

            <div className="InteressadoRow-item">
                <div className="InteressadoRow-name-number">
                    <div className="InteressadoRow-name">{props.name}</div>
                    <div className="InteressadoRow-number-container">
                        <img className="InteressadoRow-icon" src={iconPhone} alt="" />
                        <span className="InteressadoRow-number">{props.phoneNumber}</span>
                    </div>
                </div>
            </div>

            {!isLite && (
                <>
                    <div className="InteressadoRow-item">
                        <div className="InteressadoRow-field-container">
                            <div className="InteressadoRow-field">{hasRequestedNumber}</div>
                            <div className="InteressadoRow-field-caption">Solicitou número?</div>
                        </div>
                    </div>

                    <div className="InteressadoRow-item">
                        <div className="InteressadoRow-field-container">
                            <div className="InteressadoRow-field">{hasNegociated}</div>
                            <div className="InteressadoRow-field-caption">Negociou no chat?</div>
                        </div>
                    </div>

                    <div className="InteressadoRow-item">
                        <div className="InteressadoRow-field-container">
                            <div className="InteressadoRow-field">
                                {intlMonetary.format(props.proposal)}
                            </div>
                            <div className="InteressadoRow-field-caption">Proposta</div>
                        </div>
                    </div>
                </>
            )}

            <div className="InteressadoRow-item">
                <button
                    className="InteressadoRow-icon-field-container button clear"
                    onClick={props.onChatClick}
                >
                    <img
                        className="InteressadoRow-icon"
                        src={props.hasNegociated ? iconChatEllipsis : ''}
                        alt=""
                    />
                    <div className="InteressadoRow-field">
                        {props.hasNegociated ? 'Ver Chat' : ''}
                    </div>
                </button>
            </div>

            <div className="InteressadoRow-item">{props.children}</div>
        </div>
    );
};

export default InteressadoRow;
