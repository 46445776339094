/**
 * @NOTA
 * !! Esse arquivo existe apenas como referência. !!
 *
 * Para editar a página inicial,
 * ver /src/container/PageHome.
 */

import React, { Component } from 'react';
import TopBar from '../../components/TopBar';
// import Grafico from '../../components/Grafico';
import CardConteudo from '../../components/CardConteudo';
import TituloComBotao from '../../components/TituloComBotao';
import RepasseItemHome from '../../components/RepasseItemHome';
import BotaoCarregarMais from '../../components/BotaoCarregarMais';
import BannerTurbine from '../../components/BannerTurbine';
import ContainerInfografico from '../../components/ContainerInfografico';
import GestaoEquipeRow from '../../components/GestaoEquipeRow';
import GestaoEquipeHeaderHome from '../../components/GestaoEquipeHeaderHome';
import PerfilVendedor from '../../components/PerfilVendedor';
import ModalPlanos from '../../components/ModalPlanos';

import { Link } from 'react-router-dom';

import './style.sass';

export default class HomeContainer extends Component {
    constructor(props) {
        super(props);

        this.modalPlanos = React.createRef();

        console.log('modalPlanos', this.modalPlanos);
        this.linkStyle = {
            margin: 'auto',
            color: '#335180',
            textAlign: 'center',
            display: 'block',
            fontWeight: '500',
            fontSize: '1.4rem',
            marginTop: '.5rem',
        };
    }

    openModalPlanos = () => this.modalPlanos.current.openModal();

    render() {
        return (
            <>
                <TopBar />
                <ModalPlanos ref={this.modalPlanos} />
                <div className="page-container">
                    <div className="content-container">
                        <div style={{ marginBottom: '2rem' }}>
                            <div className="button-group">
                                <button
                                    className="button outline primary"
                                    onClick={this.openModalPlanos}
                                >
                                    Abrir Planos Turbo
                                </button>
                            </div>
                        </div>

                        <CardConteudo>
                            <div className="titulo-card">Repasses Anunciados</div>
                            <div className="subtitulo-detalhe">Oportunidades de Vendas</div>
                            {/* <Grafico /> */}
                        </CardConteudo>

                        <div style={{ margin: '3rem 0' }}>
                            <TituloComBotao
                                textoTitulo="Você tem 8 repasses cadastrados"
                                textoBotao="Cadastrar novo repasse"
                                nomeIcone="plus-circle"
                            />
                        </div>

                        <CardConteudo>
                            <div className="container-repasses-home">
                                <RepasseItemHome />
                                <RepasseItemHome />
                                <RepasseItemHome />
                            </div>
                        </CardConteudo>
                        <BotaoCarregarMais />
                        <BannerTurbine
                            textoBanner="Turbine suas Vendas"
                            textoBotao="Assine o Turbo agora"
                        />
                        <ContainerInfografico />
                        <div className="flex-container-bottom">
                            <div className="coluna-esquerda">
                                <PerfilVendedor
                                    fotoURL="http://i.pravatar.cc/150?img=57"
                                    nomeVendedor="Yan Gondim"
                                    emailVendedor="yan.gondim@indexdigital.com.br"
                                    totalCadastro="17"
                                    totalRepasses="185.456"
                                    lucroRepasses="1.938.345"
                                    meta="157"
                                />
                            </div>
                            <div className="coluna-direita">
                                <CardConteudo>
                                    <GestaoEquipeHeaderHome />
                                    <GestaoEquipeRow
                                        imageUrl=""
                                        name="Samuel Batista"
                                        jobTitle="Administrador"
                                        sales="8"
                                        salesVariation="pos"
                                        avgTime="2"
                                        avgTimeVariation="pos"
                                        repassesAmount="5"
                                        isLite={true}
                                    />
                                    <GestaoEquipeRow
                                        imageUrl="https://picsum.photos/201/201"
                                        name="Pedro Britto"
                                        jobTitle="Desenvolvedor Front End"
                                        sales="9"
                                        salesVariation="pos"
                                        avgTime="3"
                                        avgTimeVariation="neg"
                                        repassesAmount="3"
                                        isLite={true}
                                    />
                                    <GestaoEquipeRow
                                        imageUrl="https://picsum.photos/201/201"
                                        name="Pedro Britto"
                                        jobTitle="Desenvolvedor Front End"
                                        sales="9"
                                        salesVariation="pos"
                                        avgTime="3"
                                        avgTimeVariation="neg"
                                        repassesAmount="3"
                                        isLite={true}
                                    />

                                    <Link to="#" style={this.linkStyle}>
                                        Ver Ranking Completo
                                    </Link>
                                </CardConteudo>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
