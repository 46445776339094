import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators, getUserTypes } from '../../store/userCreate/actions';
import CadastroUsuarioContainer from '../../components/CadastroUsuarioContainer';
import FormGroupSelect from '../../components/FormGroupSelect';

import ProfilePlaceholder from '../../assets/profile-placeholder.jpg';

import './style.sass';

class CadastroUsuarioUm extends Component {
    state = {
        userTypeError: '',
    };

    componentDidMount() {
        this.props.setUserAttribute('currentStep', 1);

        this.props.getUserTypes();
    }

    validateFields = () => {
        let validated = true;

        if (!this.props.user.user_type) {
            validated = false;
            this.setState({ userTypeError: 'Selecione o tipo de perfil' });
        }

        return validated;
    };

    render() {
        const { user, setUserAttribute, userTypes } = this.props;

        return (
            <div>
                <CadastroUsuarioContainer
                    currentStep={1}
                    tituloPagina={user.update ? 'Atualização de cadastro' : 'Cadastro'}
                >
                    <Helmet>
                        <title>Cadastro de usuário • Repasse</title>
                    </Helmet>
                    <div className="cadastro-usuario-page-um">
                        <div className="subtitulo-pagina">Escolha o seu tipo de perfil:</div>
                        <div className="container-tipo-perfil">
                            <div className="foto">
                                <img src={ProfilePlaceholder} alt="" />
                            </div>

                            <div className="select-perfil">
                                <FormGroupSelect
                                    label="Tipo de perfil *"
                                    labelSize="small"
                                    inputSize="small"
                                    placeholder="Selecione"
                                    onChange={e => {
                                        setUserAttribute('user_type', e.target.value);
                                        this.setState({ userTypeError: '' });
                                    }}
                                    value={user.user_type}
                                    options={userTypes.map(item => ({
                                        value: item.id,
                                        content: item.name,
                                    }))}
                                    errorMessage={this.state.userTypeError}
                                />
                            </div>

                            <button
                                className="botao-continuar-cadastro"
                                style={{ border: 0 }}
                                onClick={() =>
                                    this.validateFields() &&
                                    this.props.setUserAttribute('currentStep', 2) &&
                                    this.props.history.push('/cadastro-usuario-dois')
                                }
                            >
                                Continuar
                            </button>
                        </div>
                    </div>
                </CadastroUsuarioContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ userCreate }) => ({
    userTypes: userCreate.userTypes,
    user: userCreate.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...Creators, getUserTypes }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CadastroUsuarioUm);
