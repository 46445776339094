import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators as RepasseViewActions } from '../../store/ducks/repasseView';
import { Creators as RepassesListActions } from '../../store/ducks/repassesList';
import { baseURL } from '../../services/api';
import api from '../../services/api';

import Swiper from 'react-id-swiper';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import TopBar from '../../components/TopBar';
import ActionButton from '../../components/ActionButton';
import RepasseInfo from '../../components/RepasseInfo';
import RepasseValores from '../../components/RepasseValores';
import Seller from '../../components/Seller';
import StatusLabel from '../../components/StatusLabel';
import CardConteudo from '../../components/CardConteudo';
import NotificationBullet from '../../components/NotificationBullet';

import DetalhesVeiculoTab from '../../components/DetalhesVeiculoTab';
import Modal from '../../components/Modal';

import iconArrowRightWhite from '../../assets/images/icon-arrow-right-white.png';
import iconArrowLeftWhite from '../../assets/images/icon-arrow-left-white.png';
import iconArrowRightBlue from '../../assets/images/icon-arrow-right-blue.png';
import iconArrowLeftBlue from '../../assets/images/icon-arrow-left-blue.png';

import avatarPlaceholder from '../../assets/user-fundo-branco.png';

import SpinnerFull from '../../components/SpinnerFull';
import Spinner from 'react-spinner-material';

import './style.sass';

import ChatComponent from '../../components/ChatComponent';
import TurboCallModal from '../../components/TurboCallModal';
import TabContentInteressados from '../../components/TabContentInteressados';
import InteressadoRow from '../../components/InteressadoRow';

const intlMonetary = new Intl.NumberFormat('pt-BR', {
    // style: 'currency',
    // currency: 'BRL',
    minimumFractionDigits: 2,
});

class PageRepasse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chats: [],
            gallerySwiper: null,
            thumbnailSwiper: null,
            seller_phone: '',
            loadingFavorite: false,
            favorite: true,
            loading: false,
            errorMessage: '',
            idClienteVenda: '',
            turboReach: null,
            call_id: '',
            selectedTab: 0,
            showModalTurbo: false,
            selectedChat: null,
        };

        this.style = {
            notificationBullet: {
                marginRight: '1rem',
            },
        };

        this.modalGaleria = React.createRef();
        this.modalVendido = React.createRef();
        this.modalConfirmationDesistir = React.createRef();
        this.modalConfirmationVendaFora = React.createRef();
        this.modalConfirmationVendaCliente = React.createRef();
        this.modalSuccess = React.createRef();
        this.modalError = React.createRef();
        this.galleryRef = this.galleryRef.bind(this);
        this.thumbRef = this.thumbRef.bind(this);
    }

    async componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        try {
            const { id_repasse, id_usuario } = this.props.match.params;
            this.props.getRepasse(id_repasse, id_usuario);

            // const { storeId } = this.props;
            // const { repasse } = this.props;

            if (this.props.location.state && this.props.location.state.selectedTab) {
                this.setState({ selectedTab: this.props.location.state.selectedTab });
            }

            if (this.props.location.state && this.props.location.state.showModalTurbo) {
                this.setState({ showModalTurbo: this.props.location.state.showModalTurbo });
            }

            /* if (this.hasPermissionToAdditionalTabs()) {
                await this.props.getInteressados(id_repasse);
                await this.props.getChats(storeId, repasse.car.id);

                const { chats } = this.state;

                chats.map(item => this.props.getChatMessages(repasse.car.id, item.room_id));
            } */

            /* if (this.hasPermissionToTurboCall()) {
                this.props.getScoreboard();

                const responseTurboReach = await api.get(
                    `${baseURL}/api/v2/products/reach/${this.props.match.params.id_repasse}`
                );

                if (responseTurboReach.data.hasOwnProperty('calls'))
                    this.setState({ turboReach: responseTurboReach.data });
            } */
        } catch (error) {
            console.log(error);
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.gallerySwiper && nextState.thumbnailSwiper) {
            const { gallerySwiper, thumbnailSwiper } = nextState;

            if (gallerySwiper.controller && thumbnailSwiper.controller) {
                gallerySwiper.controller.control = thumbnailSwiper;
                thumbnailSwiper.controller.control = gallerySwiper;
            }
        }
    }

    /* componentDidUpdate = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }; */

    /* getChats = async (store_id, car_id) => {
        try {
            const res = await api.get(`api/v2/store/${store_id}/car/${car_id}/chats`);

            const { items } = res.data.data;

            this.setState({ chats: items });
        } catch ({ response }) {
            console.log({ response });
        }
    };

    getChatMessages = async (car_id, room_id) => {
        try {
            const res = await api.get(`api/v2/car/${car_id}/chat/${room_id}`);

            const { dates } = res.data.data;

            let messages = dates;

            let chat = this.state.chats.find(item => item.room_id === room_id);
            let new_chats = this.state.chats;

            new_chats = new_chats.filter(item => item !== chat);

            chat = { ...chat, messages };

            new_chats = [...new_chats, chat];

            this.setState({ chats: new_chats });
        } catch ({ response }) {
            console.log({ response });
        }
    }; */

    /* hasPermissionToTurboCall = () => {
        if (!this.props.havePlan) return false;

        if (this.props.repasse.car) {
            if (
                this.props.repasse.car.car_status_id === 3 ||
                this.props.repasse.car.car_status.id === 5
            )
                return false;

            return this.isDirectorFromStore();
        }
    };

    hasPermissionToAdditionalTabs = () => {
        if (this.props.repasse.car) {
            return (
                this.isDirectorFromStore() ||
                (this.isManagerFromStore() && this.isRepasseFromSellerFromStore()) ||
                this.isOwner(this.props.repasse.car.user.id)
            );
        } else return false;
    };

    isDirectorFromStore = () => {
        if (this.props.repasse.car) {
            const { storeId, roleUser } = this.props;
            const { store_id } = this.props.repasse.car;
            const user_store_id = storeId;

            return store_id === Number(user_store_id) && roleUser.id === 2;
        }
    };

    isManagerFromStore = () => {
        if (this.props.repasse.car) {
            const { storeId, roleUser } = this.props;
            const { store_id } = this.props.repasse.car;
            const user_store_id = storeId;

            return store_id === Number(user_store_id) && roleUser.id === 3;
        }
    };

    isRepasseFromSellerFromStore = () => {
        if (this.props.repasse.car) {
            const { storeId, roleUser } = this.props;
            const { store_id } = this.props.repasse.car;
            const user_store_id = storeId;

            return store_id === Number(user_store_id) && roleUser.id === 4;
        }
    }; */

    isOwner = id_user_repasse => this.props.user.id === id_user_repasse;

    galleryRef = ref => {
        if (ref) {
            this.setState({ gallerySwiper: ref.swiper });
        }
    };

    thumbRef = ref => {
        if (ref) {
            this.setState({ thumbnailSwiper: ref.swiper });
        }
    };

    openModal = () => {
        this.modalGaleria.current.openModal();
    };

    openModalVendido = () => {
        this.modalVendido.current.openModal();
    };

    closeModalVendido = () => {
        this.modalVendido.current.closeModal();
    };

    openModalConfirmationDesistir = () => {
        this.modalConfirmationDesistir.current.openModal();
    };

    closeModalConfirmationDesistir = () => {
        this.modalConfirmationDesistir.current.closeModal();
    };

    openModalConfirmationVendaFora = () => {
        this.modalConfirmationVendaFora.current.openModal();
    };

    closeModalConfirmationVendaFora = () => {
        this.modalConfirmationVendaFora.current.closeModal();
    };

    openModalConfirmationVendaCliente = () => {
        this.modalConfirmationVendaCliente.current.openModal();
    };

    closeModalConfirmationVendaCliente = () => {
        this.modalConfirmationVendaCliente.current.closeModal();
    };

    openModalSuccess = () => {
        this.modalSuccess.current.openModal();
    };

    closeModalSuccess = () => {
        this.modalSuccess.current.closeModal();
    };

    openModalError = () => {
        this.modalError.current.openModal();
    };

    closeModalError = () => {
        this.modalError.current.closeModal();
    };

    slideGalleryTo = index => {
        this.state.thumbnailSwiper.slideTo(index);
        this.state.gallerySwiper.slideTo(index);
    };

    toggleFavorite = async (id_repasse, id_usuario) => {
        try {
            this.setState({ loadingFavorite: true });

            await this.props.toggleRepasseFavorite(id_repasse);
            this.props.getRepasse(id_repasse, id_usuario);
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({ loadingFavorite: false });
        }
    };

    timeElapsed = start_date => {
        const start = new Date(start_date);
        const end = new Date();

        const timeDiff = (end - start) / 1000;
        const seconds = Math.round(timeDiff);

        return Math.floor(seconds / 86400);
    };

    getSellerPhone = seller_id => {
        const { user } = this.props;
        const { repasse } = this.props;

        let body = {
            car_id: repasse.car.id,
            comprador_id: user.id,
        };

        api.post(`${baseURL}/api/v2/call`, body)
            .then(_ => {
                api.get(`${baseURL}/api/v2/user/${seller_id}`)
                    .then(res => {
                        this.setState({
                            seller_phone: {
                                cellphone: res.data.data.cell_phone,
                                phone: res.data.data.phone,
                            },
                        });
                    })
                    .catch(error => console.log(error));
            })
            .catch(error => console.log(error));
    };

    formatPhone = number => {
        if (number) {
            let ddd = '';
            let first_part = '';
            let last_part = '';
            if (number.length === 8) {
                first_part = number.substring(0, 4);
                last_part = number.substring(4, number.length);
                return `${first_part}.${last_part}`;
            }
            if (number.length === 9) {
                first_part = number.substring(0, 5);
                last_part = number.substring(5, number.length);
                return `${first_part}.${last_part}`;
            }
            if (number.length === 10) {
                ddd = number.substring(0, 2);
                first_part = number.substring(2, 6);
                last_part = number.substring(6, number.length);
            }
            if (number.length === 11) {
                ddd = number.substring(0, 2);
                first_part = number.substring(2, 7);
                last_part = number.substring(7, number.length);
            }
            return `(${ddd}) ${first_part}.${last_part}`;
        }
    };

    carSold = async (car_id, reason_id, buyer_id = null) => {
        this.closeModalConfirmationDesistir();
        this.closeModalConfirmationVendaFora();
        this.closeModalConfirmationVendaCliente();

        this.setState({ loading: true });

        let body = {};

        if (buyer_id) {
            body = {
                car_id,
                reason_id,
                buyer_id,
            };
        } else {
            body = {
                car_id,
                reason_id,
            };
        }

        try {
            let res = await api.post(`${baseURL}/api/v2/car-sold`, body);
            console.log(res);
            this.openModalSuccess();
            this.setState({ loading: false });
        } catch (error) {
            this.setState(
                {
                    loading: false,
                    errorMessage: 'Ocorreu um erro. Tente novamente mais tarde.',
                },
                () => this.openModalError()
            );

            // console.log({ error });
            // let err = { error };
            // this.setState(
            //     {
            //         loading: false,
            //         errorMessage: err.error.response.data
            //             ? err.error.response.data.msg
            //             : 'Ocorreu um erro. Tente novamente mais tarde.',
            //     },
            //     () => this.openModalError()
            // );
        }
    };

    render() {
        const gallerySwiperParams = {
            containerClass: 'swiper-container ModalGaleria-SwiperGallery',
            spaceBetween: 10,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            autoHeight: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
            },
            keyboard: true,
            renderNextButton: () => {
                return (
                    <div className="swiper-button-next">
                        <img src={iconArrowRightWhite} alt="" />
                    </div>
                );
            },
            renderPrevButton: () => {
                return (
                    <div className="swiper-button-prev">
                        <img src={iconArrowLeftWhite} alt="" />
                    </div>
                );
            },
            shouldSwiperUpdate: true,
        };

        const thumbnailSwiperParams = {
            containerClass: 'swiper-container ModalGaleria-SwiperThumbnail',
            spaceBetween: 10,
            centeredSlides: true,
            slidesPerView: 8,
            touchRatio: 0.2,
            slideToClickedSlide: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            renderNextButton: () => {
                return (
                    <div className="swiper-button-next">
                        <img src={iconArrowRightBlue} alt="" />
                    </div>
                );
            },
            renderPrevButton: () => {
                return (
                    <div className="swiper-button-prev">
                        <img src={iconArrowLeftBlue} alt="" />
                    </div>
                );
            },
            breakpoints: {
                1200: {
                    slidesPerView: 6,
                },
                800: {
                    slidesPerView: 4,
                },
            },
            shouldSwiperUpdate: true,
        };

        const {
            repasse,
            interessados,
            loading,
            additionalTabsPermission,
            turboCallPermission,
            chats,
            setChatMessages,
            chatBadge,
            roleUser,
        } = this.props;
        const { loadingFavorite } = this.state;

        let selectedChat = this.state.selectedChat
            ? this.state.selectedChat
            : chats.length > 0
            ? chats[0].room_id
            : this.state.selectedChat;

        return (
            <>
                <TopBar />

                <Helmet>
                    {repasse.car ? (
                        <title>{repasse.car.fipe.model} • Repasse</title>
                    ) : (
                        <title>Repasse</title>
                    )}
                </Helmet>

                <Modal
                    ref={this.modalGaleria}
                    customClass="ModalGaleria"
                    title="Imagens do veículo"
                >
                    <Swiper {...gallerySwiperParams} ref={this.galleryRef}>
                        {repasse.car &&
                            repasse.car.photos.map((item, index) => {
                                return (
                                    <div className="ModalGaleria-SwiperGallery-item" key={index}>
                                        <img
                                            className="ModalGaleria-SwiperGallery-image"
                                            src={`${baseURL}/uploads/gallery/${item.filename}`}
                                            alt=""
                                        />
                                    </div>
                                );
                            })}
                    </Swiper>
                    <Swiper {...thumbnailSwiperParams} ref={this.thumbRef}>
                        {repasse.car &&
                            repasse.car.photos.map((item, index) => {
                                return (
                                    <div
                                        className="ModalGaleria-SwiperThumbnail-item responsive-embed"
                                        key={index}
                                    >
                                        <img
                                            className="ModalGaleria-SwiperThumbnail-image responsive-embed-item"
                                            src={`${baseURL}/uploads/gallery/${item.filename}`}
                                            alt=""
                                        />
                                    </div>
                                );
                            })}
                    </Swiper>
                </Modal>

                <Modal ref={this.modalVendido} title="Confirmar venda de Repasse">
                    <div className="ModalPreviaRepasse-content">
                        <div className="ModalPreviaRepasse-section --dentro">
                            <div className="mini-title">Venda pelo Repasse</div>
                            {interessados &&
                                interessados.length > 0 &&
                                interessados.map((item, key) => (
                                    <InteressadoRow
                                        key={key}
                                        name={item.client.name}
                                        phoneNumber={item.client.phone}
                                        hasRequestedNumber={true}
                                        hasNegociated={item.chat}
                                        proposal="10000"
                                        isLite={true}
                                        noChat
                                        onChatClick={() => {
                                            this.setState({ selectedTab: 2 });
                                            this.closeModalVendido();
                                        }}
                                    >
                                        <button
                                            className="button outline blue smaller"
                                            style={{ flex: 'none' }}
                                            onClick={() => {
                                                this.setState(
                                                    { idClienteVenda: item.client.id },
                                                    () => {
                                                        this.closeModalVendido();
                                                        this.openModalConfirmationVendaCliente();
                                                    }
                                                );
                                            }}
                                        >
                                            Vendido para
                                            <br />
                                            este usuário
                                        </button>
                                    </InteressadoRow>
                                ))}
                        </div>

                        <div className="ModalPreviaRepasse-section --fora">
                            <div className="mini-title">Venda por fora</div>
                            <p
                                style={{
                                    fontSize: '1.4rem',
                                    fontWeight: '500',
                                    lineHeight: '1.2',
                                    textAlign: 'center',
                                }}
                            >
                                Caso você tenha vendido este Repasse por outros meios fora da
                                plataforma, confirme através do botão abaixo.
                            </p>
                            <div className="margin-top" style={{ textAlign: 'center' }}>
                                <button
                                    className="button blue"
                                    onClick={() => {
                                        this.closeModalVendido();
                                        this.openModalConfirmationVendaFora();
                                    }}
                                >
                                    Vendido fora da repasse
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    ref={this.modalConfirmationDesistir}
                    title={'Desistir da venda'}
                    size="small"
                >
                    <div className="margin-top">
                        <div>Tem certeza de que deseja desistir da venda deste Repasse?</div>
                        <div
                            className="margin-top"
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <button
                                className="button primary big margin-right--small"
                                onClick={() => this.carSold(repasse.car.id, 3)}
                            >
                                Sim
                            </button>
                            <button
                                className="button grey big"
                                onClick={() => this.closeModalConfirmationDesistir()}
                            >
                                Não
                            </button>
                        </div>
                    </div>
                </Modal>

                <Modal ref={this.modalConfirmationVendaFora} title={'Confirmar venda'} size="small">
                    <div className="margin-top">
                        <div>
                            Você confirma que realizou a venda deste Repasse por outros meios?
                        </div>
                        <div
                            className="margin-top"
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <button
                                className="button primary big margin-right--small"
                                onClick={() => this.carSold(repasse.car.id, 2)}
                            >
                                Sim
                            </button>
                            <button
                                className="button grey big"
                                onClick={() => {
                                    this.closeModalConfirmationVendaFora();
                                    this.openModalVendido();
                                }}
                            >
                                Não
                            </button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    ref={this.modalConfirmationVendaCliente}
                    title={'Confirmar venda'}
                    size="small"
                >
                    <div className="margin-top">
                        <div>
                            Você confirma que realizou a venda deste Repasse para esta pessoa?
                        </div>
                        <div
                            className="margin-top"
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <button
                                className="button primary big margin-right--small"
                                onClick={() => this.carSold(repasse.car.id, 1)}
                            >
                                Sim
                            </button>
                            <button
                                className="button grey big"
                                onClick={() => {
                                    this.closeModalConfirmationVendaCliente();
                                    this.openModalVendido();
                                }}
                            >
                                Não
                            </button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    ref={this.modalSuccess}
                    title={'Anúncio despublicado com sucesso!'}
                    size="small"
                >
                    <div className="margin-top">
                        <div>
                            Seu anúncio não estará mais disponível para ser encontrado na Repasse.
                        </div>
                        <div
                            className="margin-top"
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <button
                                className="button primary big margin-right--small"
                                onClick={() => {
                                    this.closeModalSuccess();
                                    this.props.history.replace('/repasses');
                                }}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </Modal>

                <Modal ref={this.modalError} title={'Aviso'} size="small">
                    <div className="margin-top">
                        <div>{this.state.errorMessage}</div>
                        <div
                            className="margin-top"
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <button
                                className="button primary big margin-right--small"
                                onClick={() => this.closeModalError()}
                            >
                                Fechar
                            </button>
                        </div>
                    </div>
                </Modal>

                <div className="page-container">
                    <div className="content-container">
                        <SpinnerFull
                            size={40}
                            spinnerColor={'#335180'}
                            spinnerWidth={3}
                            visible={loading && !loadingFavorite}
                        />
                        <SpinnerFull
                            size={40}
                            spinnerColor={'#335180'}
                            spinnerWidth={3}
                            visible={this.state.loading}
                        />
                        <div className="header-page">
                            <div className="header-page-actions">
                                <div
                                    className="header-page-action"
                                    onClick={() => this.props.history.goBack()}
                                >
                                    <ActionButton
                                        nameIcon="chevron-left"
                                        modifierClasses="action-link"
                                        textAction="Voltar"
                                        // actionUrl="/repasses"
                                    />
                                </div>
                            </div>
                            <div className="header-page-actions">
                                {repasse.car &&
                                    !this.isOwner(repasse.car.user.id) &&
                                    (repasse.car.car_status.id === 1 ||
                                        repasse.car.car_status.id === 9) && (
                                        <div
                                            className="header-page-action"
                                            onClick={() =>
                                                this.toggleFavorite(
                                                    repasse.car.id,
                                                    repasse.client.id
                                                )
                                            }
                                        >
                                            <Spinner
                                                size={10}
                                                spinnerColor={'#333'}
                                                spinnerWidth={2}
                                                visible={loadingFavorite}
                                            />
                                            {repasse.car && repasse.car.is_favorite ? (
                                                <ActionButton
                                                    nameIcon={['fas', 'star']}
                                                    colorIcon="#F5A623"
                                                    textAction="Desfavoritar"
                                                />
                                            ) : (
                                                <ActionButton
                                                    nameIcon={['far', 'star']}
                                                    colorIcon="#F5A623"
                                                    textAction="Favoritar"
                                                />
                                            )}
                                        </div>
                                    )}

                                {/* {!loading && !this.isOwner(repasse.car.user.id) && (
                                    <div onClick={() => this.getSellerPhone(repasse.car.user.id)}>
                                        <ActionButton
                                            nameIcon={'phone'}
                                            colorIcon="#008ae7"
                                            textAction={
                                                this.state.seller_phone ? (
                                                    <Fragment>
                                                        <span>
                                                            {this.state.seller_phone.phone
                                                                ? this.formatPhone(
                                                                      this.state.seller_phone.phone
                                                                  )
                                                                : ''}
                                                        </span>{' '}
                                                        {this.state.seller_phone.phone && <br />}
                                                        <span>
                                                            {this.state.seller_phone.cellphone
                                                                ? this.formatPhone(
                                                                      this.state.seller_phone
                                                                          .cellphone
                                                                  )
                                                                : ''}
                                                        </span>
                                                    </Fragment>
                                                ) : (
                                                    'Ver telefone do anunciante'
                                                )
                                            }
                                        />
                                    </div>
                                )} */}

                                {repasse.car &&
                                    this.isOwner(repasse.car.user.id) &&
                                    (repasse.car.car_status.id !== 3 &&
                                        repasse.car.car_status.id !== 5 &&
                                        repasse.car.car_status.id !== 8) && (
                                        <div className="header-page-actions">
                                            <div
                                                className="header-page-action"
                                                onClick={() =>
                                                    this.props.history.push({
                                                        pathname: '/editar-cadastro-repasse',
                                                        state: { car: repasse.car },
                                                    })
                                                }
                                            >
                                                <ActionButton
                                                    nameIcon="pencil-alt"
                                                    colorIcon="#008ae7"
                                                    textAction="Editar"
                                                />
                                            </div>
                                            {repasse.car.car_status.id !== 4 && (
                                                <div
                                                    className="header-page-action"
                                                    onClick={() => this.openModalVendido()}
                                                >
                                                    <ActionButton
                                                        nameIcon="clipboard-check"
                                                        colorIcon="#34bfa3"
                                                        textAction="Confirmar venda"
                                                    />
                                                </div>
                                            )}
                                            <div
                                                className="header-page-action"
                                                onClick={() => this.openModalConfirmationDesistir()}
                                            >
                                                <ActionButton
                                                    nameIcon="trash"
                                                    colorIcon="#d52710"
                                                    textAction="Desistir da venda"
                                                />
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>

                        <CardConteudo padding="0">
                            <div className="repasse-resume">
                                <div className="repasse-resume-main-info">
                                    <div className="repasse-image responsive-embed">
                                        <img
                                            className="responsive-embed-item"
                                            src={
                                                repasse.car
                                                    ? `${baseURL}/uploads/gallery/${
                                                          repasse.car.image
                                                      }`
                                                    : ''
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <div className="repasse-content-info">
                                        <RepasseInfo
                                            tituloRepasse={
                                                repasse.car ? repasse.car.fipe.model : ''
                                            }
                                            ano={repasse.car ? repasse.car.fipe.year : ''}
                                            combustivel={repasse.car ? repasse.car.fipe.fuel : ''}
                                            cor={repasse.car ? repasse.car.color.name : ''}
                                            diasNoPatio={
                                                repasse.car
                                                    ? this.isOwner(repasse.car.user.id)
                                                        ? this.timeElapsed(repasse.car.created_at)
                                                        : repasse.car.created_at
                                                    : ''
                                            }
                                            width={220}
                                        />
                                    </div>
                                    <div className="repasse-other-info">
                                        <StatusLabel
                                            statusValue={
                                                repasse.car ? repasse.car.car_status.name : ''
                                            }
                                            statusCode={
                                                repasse.car ? repasse.car.car_status.id : ''
                                            }
                                        />
                                        <Seller
                                            sellerId={repasse.car ? repasse.car.user.id : ''}
                                            sellerImage={
                                                repasse.client &&
                                                `${baseURL}/uploads/avatar/${repasse.client.photo}`
                                                    ? `${baseURL}/uploads/avatar/${
                                                          repasse.client.photo
                                                      }`
                                                    : avatarPlaceholder
                                            }
                                            sellerName={repasse.client ? repasse.client.name : ''}
                                            sellerCity={
                                                repasse.client
                                                    ? `${repasse.client.city} - ${
                                                          repasse.client.state
                                                      }`
                                                    : ''
                                            }
                                            showStockButton={true}
                                        />
                                    </div>

                                    <div className="repasse-telefone-info">
                                        <div
                                            onClick={() => this.getSellerPhone(repasse.car.user.id)}
                                        >
                                            <ActionButton
                                                nameIcon={'phone'}
                                                colorIcon="#008ae7"
                                                textAction={
                                                    this.state.seller_phone ? (
                                                        <Fragment>
                                                            <span>
                                                                {this.state.seller_phone.phone
                                                                    ? this.formatPhone(
                                                                          this.state.seller_phone
                                                                              .phone
                                                                      )
                                                                    : ''}
                                                            </span>{' '}
                                                            {this.state.seller_phone.phone && (
                                                                <br />
                                                            )}
                                                            <span>
                                                                {this.state.seller_phone.cellphone
                                                                    ? this.formatPhone(
                                                                          this.state.seller_phone
                                                                              .cellphone
                                                                      )
                                                                    : ''}
                                                            </span>
                                                        </Fragment>
                                                    ) : (
                                                        'Ver telefone do anunciante'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <ActionButton
                                                nameIcon={'car'}
                                                colorIcon="#008ae7"
                                                textAction={'Ver estoque de repasses'}
                                                linkAction={
                                                    repasse.car
                                                        ? `/estoque/${repasse.car.user.id}`
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="repasse-resume-values">
                                    <RepasseValores
                                        valorRepasse={
                                            repasse.car
                                                ? intlMonetary.format(repasse.car.price)
                                                : ''
                                        }
                                        valorCusto={
                                            repasse.car &&
                                            Number(this.props.storeId) ===
                                                Number(repasse.car.store_id) &&
                                            (Number(repasse.car.cost) > 0
                                                ? intlMonetary.format(repasse.car.cost)
                                                : null)
                                        }
                                        valorFipe={
                                            repasse.car
                                                ? intlMonetary.format(repasse.car.fipe_price)
                                                : ''
                                        }
                                        corElemento="#ffaa00"
                                        valorPorcentagem={
                                            repasse.car
                                                ? repasse.car.fipe_percent_off.replace(/\D/g, '')
                                                : ''
                                        }
                                    />
                                </div>
                            </div>
                            <div className="RepasseTab">
                                <Tabs
                                    // defaultIndex={0}
                                    selectedIndex={this.state.selectedTab}
                                    disabledTabClassName="react-tabs__tab custom disabled"
                                    selectedTabClassName="react-tabs__tab custom selected"
                                    onSelect={index => this.setState({ selectedTab: index })}
                                >
                                    <TabList>
                                        <Tab>Informações</Tab>

                                        {!loading && repasse && additionalTabsPermission && (
                                            <>
                                                <Tab>Interessados</Tab>

                                                <Tab>
                                                    <span style={this.style.notificationBullet}>
                                                        Chat
                                                    </span>
                                                    {chatBadge > 0 && (
                                                        <NotificationBullet
                                                            number={
                                                                chatBadge > 99 ? '99+' : chatBadge
                                                            }
                                                            bgColor="#d0021b"
                                                            squared={chatBadge > 99}
                                                        />
                                                    )}
                                                </Tab>
                                            </>
                                        )}
                                    </TabList>

                                    <TabPanel>
                                        <div className="RepasseTab-Informacoes">
                                            <DetalhesVeiculoTab
                                                fuelType={repasse.car ? repasse.car.fipe.fuel : ''}
                                                modelYear={repasse.car ? repasse.car.fipe.year : ''}
                                                doors={repasse.car ? repasse.car.doors : ''}
                                                color={repasse.car ? repasse.car.color.name : ''}
                                                kilometrage={repasse.car ? repasse.car.km : ''}
                                                optionalExtras={
                                                    repasse.car
                                                        ? repasse.car.optionals.map(
                                                              item => item.name
                                                          )
                                                        : []
                                                }
                                                description={
                                                    repasse.car ? repasse.car.observation : ''
                                                }
                                            />

                                            {turboCallPermission && (
                                                <TurboCallModal
                                                    carId={repasse.car && repasse.car.id}
                                                    userId={repasse.client && repasse.client.id}
                                                    turbo={
                                                        repasse.car &&
                                                        Number(repasse.car.car_status.id) === 9
                                                    }
                                                    activeTurbo={this.props.consumeTurbo}
                                                    qtyFranchise={
                                                        this.props.scoreboard.qty_franchise
                                                    }
                                                    turboReach={this.props.turboReach}
                                                    getScoreboard={this.props.getScoreboard}
                                                    showModalTurbo={this.state.showModalTurbo}
                                                />
                                            )}

                                            <div className="GaleriaFotosTab">
                                                <div className="mini-title">Galeria de Fotos</div>

                                                <div className="GaleriaFotos-listing">
                                                    {repasse.car &&
                                                        repasse.car.photos.map((item, index) => {
                                                            return (
                                                                <div
                                                                    className="GaleriaFotos-item"
                                                                    key={index}
                                                                    onClick={() => {
                                                                        this.openModal();
                                                                        this.slideGalleryTo(index);
                                                                    }}
                                                                >
                                                                    <div className="responsive-embed">
                                                                        <img
                                                                            className="responsive-embed-item"
                                                                            src={`${baseURL}/uploads/gallery/${
                                                                                item.filename
                                                                            }`}
                                                                            alt={`imagem${index}`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    {!loading && repasse && additionalTabsPermission && (
                                        <>
                                            <TabPanel>
                                                <TabContentInteressados
                                                    data={interessados}
                                                    onChatClick={room_id =>
                                                        this.setState({
                                                            selectedTab: 2,
                                                            selectedChat: room_id,
                                                        })
                                                    }
                                                />
                                            </TabPanel>

                                            <TabPanel>
                                                <ChatComponent
                                                    data={this.props.chats}
                                                    ad={repasse}
                                                    activeChatIndex={selectedChat}
                                                    setChatMessages={setChatMessages}
                                                    roleUser={roleUser}
                                                />
                                            </TabPanel>
                                        </>
                                    )}
                                </Tabs>
                            </div>
                        </CardConteudo>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ auth, repasseView }) => ({
    loading: repasseView.loading,
    turboReach: repasseView.turboReach,
    additionalTabsPermission: repasseView.additionalTabsPermission,
    turboCallPermission: repasseView.turboCallPermission,
    repasse: repasseView.repasse,
    interessados: repasseView.interessados,
    user: auth.user,
    scoreboard: auth.scoreboard,
    storeId: auth.storeId,
    chats: repasseView.chats,
    chatBadge: repasseView.chatBadge,
    messages: repasseView.messages,
    havePlan: auth.havePlan,
    roleUser: auth.roleUser,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...RepassesListActions,
            ...RepasseViewActions,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageRepasse);
