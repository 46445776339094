import React, { Component } from 'react';
import classNames from 'classnames';

import './style.sass';

export default class BotaoCarregarMais extends Component {
    static defaultProps = {
        text: 'Carregar mais',
        size: 'small',
        margin: '4rem auto 0',
    };

    render() {
        const { text, size, margin } = this.props;

        const containerClass = classNames({
            'BotaoCarregarMais-container': true,
            [size]: true,
        });

        return (
            <div className={containerClass} style={{ margin }} onClick={this.props.onClick}>
                <div className="BotaoCarregarMais">{text}</div>
            </div>
        );
    }
}
