import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TopBar from '../../components/TopBar';
import TitleAside from '../../components/TitleAside';
import CardConteudo from '../../components/CardConteudo';
import FormGroupInput from '../../components/FormGroupInput';
import MessageBox from '../../components/MessageBox';
import Modal from '../../components/Modal';
import ModalConfirmation from '../../components/ModalConfirmation';
import SpinnerFull from '../../components/SpinnerFull';

import api, { baseURL } from '../../services/api';
import { connect } from 'react-redux';

class PageAlterarSenha extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            currentPassMask: true,
            newPassMask: true,
            newPassConfirmMask: true,
            errorMessage: '',
            password: '',
            newPassword: '',
            newPasswordConfirmation: '',
            submitted: false,
            passwordChanged: false,
        };

        this.modalConfirmationRef = React.createRef();
        this.modalSuccessRef = React.createRef();

        this.inputProps = {
            size: 'small',
            labelColor: '#335180',
            appendPosition: 'right',
        };
    }

    /**
     * @param {string} stateItem
     */
    toggleMask = stateItem => {
        this.setState({ [stateItem]: !this.state[stateItem] });
    };

    /**
     * @param {string} stateItem
     */
    changeIconColor = stateItem => {
        return this.state[stateItem] ? '#aaa' : '#1e88e5';
    };

    openModal = modalRef => {
        modalRef.current.openModal();
    };

    closeModal = modalRef => {
        modalRef.current.closeModal();
    };

    confirmateChange = () => {
        let { password, newPassword, newPasswordConfirmation } = this.state;

        if (!password || !newPassword || !newPasswordConfirmation) {
            return this.setState({
                loading: false,
                errorMessage: 'Todos os campos devem ser preenchidos!',
            });
        }

        if (newPassword !== newPasswordConfirmation) {
            return this.setState({
                loading: false,
                errorMessage: 'As senhas não correspondem!',
            });
        }

        if (newPassword.lenght < 6 || newPasswordConfirmation.length < 6) {
            return this.setState({
                loading: false,
                errorMessage: 'A senha deve conter no mínimo 6 caracteres!',
            });
        }

        if (!newPassword.match(/\d+/g) || !newPasswordConfirmation.match(/\d+/g)) {
            return this.setState({
                loading: false,
                errorMessage: 'A senha deve conter números e pelo menos uma letra maiúscula!',
            });
        }

        if (!newPassword.match(/[A-Z]/g) || !newPasswordConfirmation.match(/[A-Z]/g)) {
            return this.setState({
                loading: false,
                errorMessage: 'A senha deve conter números e pelo menos uma letra maiúscula!',
            });
        }

        this.openModal(this.modalConfirmationRef);
    };

    changePassword = async () => {
        this.closeModal(this.modalConfirmationRef);
        this.setState({ loading: true });

        let { user } = this.props;
        let { password, newPassword } = this.state;

        if (user) {
            let loginBody = {
                email: user.email,
                password: password,
            };

            try {
                const response = await api.post(`${baseURL}/api/v2/login`, loginBody);

                if (response.status === 200) {
                    let body = {
                        name: user.name,
                        dealership: user.dealership,
                        zipcode: user.zipcode,
                        state: user.state,
                        city: user.city,
                        cell_phone: user.cell_phone,
                        password: newPassword,
                    };

                    try {
                        const response = await api.post(`${baseURL}/api/v2/user/update`, body);
                        console.log(response);
                        this.setState({ loading: false });
                        if (response.status === 200) {
                            this.openModal(this.modalSuccessRef);
                        }
                    } catch (error) {
                        console.log({ error });
                        this.setState({ loading: false, errorMessage: error.message });
                    }
                }
            } catch (error) {
                console.log({ error });
                this.setState({ loading: false, errorMessage: 'Senha atual inválida!' });
            }
        }
    };

    closeRedirect = () => {
        this.props.history.replace('/login');
    };

    render() {
        return (
            <React.Fragment>
                <TopBar />

                <Helmet>
                    <title>Alterar senha • Repasse</title>
                </Helmet>

                <Modal
                    isActive={this.state.passwordChanged}
                    title="Alteração de senha"
                    size="small"
                    ref={this.modalSuccessRef}
                    onClose={this.closeRedirect}
                >
                    <ModalConfirmation text="Sua senha foi alterada com sucesso" icon="check" />
                </Modal>

                <Modal ref={this.modalConfirmationRef} title={'Aviso'} size="small">
                    <div className="margin-top">
                        <div>Deseja realmente alterar a senha?</div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '2rem auto 0',
                            }}
                        >
                            <button
                                className="button primary outline margin-right"
                                onClick={() => this.closeModal(this.modalConfirmationRef)}
                            >
                                Não
                            </button>
                            <button
                                onClick={() => this.changePassword()}
                                className="button primary"
                            >
                                Sim
                            </button>
                        </div>
                    </div>
                </Modal>

                <div className="page-container">
                    <SpinnerFull
                        size={40}
                        spinnerColor={'#335180'}
                        spinnerWidth={3}
                        visible={this.state.loading}
                    />
                    <div className="content-container">
                        <TitleAside title="Alterar senha" />
                        <CardConteudo padding="2.5rem">
                            <div
                                className="margin-bottom"
                                style={{
                                    textAlign: 'center',
                                    fontSize: '1.6rem',
                                    fontWeight: '500',
                                    color: '#555',
                                }}
                            >
                                Digite sua senha atual e a nova nos campos abaixo. Sua senha deve
                                ter no mínimo 6 caracteres, uma letra maiúscula e números.
                            </div>
                            <div
                                style={{
                                    maxWidth: '30rem',
                                    margin: '3rem auto',
                                }}
                            >
                                <FormGroupInput
                                    label="Senha atual"
                                    value={this.state.password}
                                    type={this.state.currentPassMask ? 'password' : 'text'}
                                    appendContent={
                                        <div
                                            onClick={() => this.toggleMask('currentPassMask')}
                                            style={{
                                                color: this.changeIconColor('currentPassMask'),
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <FontAwesomeIcon icon="eye" />
                                        </div>
                                    }
                                    onChange={event =>
                                        this.setState({ password: event.target.value })
                                    }
                                    {...this.inputProps}
                                />

                                <FormGroupInput
                                    label="Nova senha"
                                    value={this.state.newPassword}
                                    type={this.state.newPassMask ? 'password' : 'text'}
                                    appendContent={
                                        <div
                                            onClick={() => this.toggleMask('newPassMask')}
                                            style={{
                                                color: this.changeIconColor('newPassMask'),
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <FontAwesomeIcon icon="eye" />
                                        </div>
                                    }
                                    onChange={event =>
                                        this.setState({ newPassword: event.target.value })
                                    }
                                    {...this.inputProps}
                                />

                                <FormGroupInput
                                    label="Confirmar nova senha"
                                    value={this.state.newPasswordConfirmation}
                                    type={this.state.newPassConfirmMask ? 'password' : 'text'}
                                    appendContent={
                                        <div
                                            onClick={() => this.toggleMask('newPassConfirmMask')}
                                            style={{
                                                color: this.changeIconColor('newPassConfirmMask'),
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <FontAwesomeIcon icon="eye" />
                                        </div>
                                    }
                                    onChange={event =>
                                        this.setState({
                                            newPasswordConfirmation: event.target.value,
                                        })
                                    }
                                    {...this.inputProps}
                                />

                                {this.state.errorMessage && (
                                    <div style={{ margin: '2rem auto', textAlign: 'center' }}>
                                        <MessageBox type="danger" inlineBlock>
                                            {this.state.errorMessage}
                                        </MessageBox>
                                    </div>
                                )}

                                <button
                                    className="button primary big block"
                                    onClick={() => this.confirmateChange()}
                                >
                                    Alterar senha
                                </button>
                            </div>
                        </CardConteudo>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
});

export default connect(mapStateToProps)(PageAlterarSenha);
