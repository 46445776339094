import { createReducer } from 'reduxsauce';

import { Types } from './actions';

/* Initial State */

export const INITIAL_STATE = {
    loading: false,
    error: '',
    plans: {},
    userTypes: [],
    states: [],
    userCities: [],
    dealershipCities: [],
    user: {
        currentStep: 0,
        hasOptionPlano: false,
        update: false,
        latestEmail: '',
        latestDocument: '',
        latestDealership_cnpj: '',
        photo: '',
        photoFile: '',
        user_type: '',
        store_id: 0,
        dealership_company_name: '',
        dealership_trade_name: '',
        dealership_cnpj: '',
        dealership_city_id: '',
        dealership_neighborhood: '',
        dealership_street: '',
        dealership_number: '',
        dealership_complement: '',
        dealership_landmark: '',
        dealership_zipcode: '',
        dealership_state: '',
        plan: '',
        plan_id: '',
        name: '',
        document: '',
        email: '',
        zipcode: '',
        state: '',
        city: '',
        cell_phone: '',
        password: '',
        address: '',
        number: '',
        neighborhood: '',
        phone: '',
    },
};

/* Reducers */

const toggleLoading = (state = INITIAL_STATE, action) => ({
    ...state,
    loading: action.bool,
});

const setError = (state = INITIAL_STATE, action) => ({
    ...state,
    error: action.msg,
});

const setPlans = (state = INITIAL_STATE, action) => ({
    ...state,
    plans: action.data,
});

const setUserTypes = (state = INITIAL_STATE, action) => ({
    ...state,
    userTypes: action.data,
});

const setStates = (state = INITIAL_STATE, action) => ({
    ...state,
    states: action.data,
});

const setUserCities = (state = INITIAL_STATE, action) => ({
    ...state,
    userCities: action.data,
});

const setDealershipCities = (state = INITIAL_STATE, action) => ({
    ...state,
    dealershipCities: action.data,
});

const setUserAttribute = (state = INITIAL_STATE, action) => ({
    ...state,
    user: {
        ...state.user,
        [action.name]: action.value,
    },
});

const setUserToUpdate = (state = INITIAL_STATE, action) => ({
    ...state,
    user: {
        ...state.user,
        ...action.data,
    },
});

const cleanUser = (state = INITIAL_STATE) => ({
    ...state,
    user: INITIAL_STATE.user,
});

const resetApp = (state = INITIAL_STATE) => ({
    ...state,
    ...INITIAL_STATE,
});

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
    [Types.TOGGLE_LOADING]: toggleLoading,
    [Types.SET_ERROR]: setError,
    [Types.SET_PLANS]: setPlans,
    [Types.SET_USER_TYPES]: setUserTypes,
    [Types.SET_STATES]: setStates,
    [Types.SET_USER_CITIES]: setUserCities,
    [Types.SET_DEALERSHIP_CITIES]: setDealershipCities,
    [Types.SET_USER_ATTRIBUTE]: setUserAttribute,
    [Types.SET_USER_TO_UPDATE]: setUserToUpdate,
    [Types.CLEAN_USER]: cleanUser,
    [Types.RESET_APP]: resetApp,
});
