import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import BarraPorcentagem from '../BarraPorcentagem';

import logoFipe from '../../assets/logo-fipe.png';

import './style.sass';

/**
 * RepasseValores
 *
 * @param {string} props.valorPorcentagem Valor percentual a ser exibido
 * @param {string} props.corElemento Cor do elemento
 * @param {string} props.valorRepasse Valor de repasse do veículo
 * @param {string} props.valorFipe Valor do veículo na tabela Fipe
 */
class RepasseValores extends Component {
    render() {
        return (
            <div className="RepasseValores">
                <BarraPorcentagem
                    corElemento={this.props.corElemento}
                    valorPorcentagem={this.props.valorPorcentagem}
                />

                <div className="RepasseValores-destaque">R$ {this.props.valorRepasse}</div>
                {this.props.valorCusto && (
                    <div className="RepasseValores-custo-valor">
                        Custo: R$ {this.props.valorCusto}
                    </div>
                )}

                {this.props.valorFipe && (
                    <div className="RepasseValores-fipe">
                        <div className="RepasseValores-fipe-titulo">
                            <div className="texto-tabela">Tabela</div>
                            <img src={logoFipe} alt="Fipe" />
                        </div>
                        <div className="RepasseValores-fipe-valor">R$ {this.props.valorFipe}</div>
                    </div>
                )}

                {this.props.verRepasseButton && (
                    <button
                        className="button primary"
                        style={{ marginTop: 15 }}
                        onClick={() =>
                            this.props.history.push(
                                `/repasses/${this.props.idRepasse}/${this.props.idUsuario}`
                            )
                        }
                    >
                        Ver repasse
                    </button>
                )}

                {this.props.maisInfos && (
                    <div className="RepasseValores-button-container">
                        <div className="RepasseValores-button button outline primary block">
                            Maiores informações
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(RepasseValores);
