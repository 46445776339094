import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators as AuthActions } from '../../store/ducks/auth';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';

import logoRepasse from '../../assets/logo-repasse-blue.png';
import bgImage from '../../assets/images/bg-default.jpg';

import './style.sass';
import api from '../../services/api';
import MessageBox from '../../components/MessageBox';

class PageResetPassword extends Component {
    state = {
        password: '',
        confirmPassword: '',
        loading: '',
        error: '',
        passwordMaskNew: true,
        passwordMaskConfirmNew: true,
    };

    constructor(props) {
        super(props);

        this.modal = React.createRef();

        this.pageContainerStyle = { backgroundColor: 'transparent' };
        this.PageLoginStyle = {
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
        };
    }

    openModal = modalRef => {
        return modalRef.current.openModal();
    };

    onResetPassword = async () => {
        const values = queryString.parse(this.props.location.search);
        const { email, token } = values;
        const { password, confirmPassword } = this.state;

        if (password !== confirmPassword) {
            const error = 'Senhas diferentes';

            return this.setState({ error });
        }

        try {
            this.setState({ loading: true });

            await api.post('/alterar-senha', {
                email,
                senha: password,
                token,
            });

            this.openModal(this.modal);
        } catch ({ response }) {
            console.log({ response });

            if (response) {
                const { msg } = response.data;

                return this.setState({ error: msg });
            }
        } finally {
            this.setState({ loading: false });
        }
    };

    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    componentDidMount() {
        this.scrollToTop();

        const values = queryString.parse(this.props.location.search);
        console.log({ values });
    }

    render() {
        return (
            <div className="PageLogin" style={this.PageLoginStyle}>
                <Helmet>
                    <title>Redefinir Senha • Repasse</title>
                </Helmet>
                <div className="page-container" style={this.pageContainerStyle}>
                    <div className="content-container">
                        <div className="PageLogin-card">
                            <div className="PageLogin-logo-container">
                                <img
                                    src={logoRepasse}
                                    alt="Logo Repasse"
                                    className="PageLogin-logo"
                                />
                            </div>

                            <React.Fragment>
                                <Modal
                                    title="Redefinir senha"
                                    customClass="Modal-forgot-password"
                                    ref={this.modal}
                                >
                                    <div className="Modal-forgot-password__text">
                                        Sua senha foi redefinida com sucesso
                                    </div>

                                    <Link to="/login" className="button primary block">
                                        Ir para login
                                    </Link>
                                </Modal>

                                <div className="PageLogin-step-title">Redefinir Senha</div>

                                <div className="PageLogin-step-subtitle">
                                    Redefina sua senha nos campos abaixo.
                                </div>

                                <form className="PageLogin-form">
                                    <div className="form-group">
                                        <label className="label" htmlFor="form-login-new-password">
                                            Nova senha
                                        </label>
                                        <div className="input-icon right">
                                            <div
                                                className="input-icon__icon-container"
                                                onClick={() =>
                                                    this.setState({
                                                        passwordMaskNew: !this.state
                                                            .passwordMaskNew,
                                                    })
                                                }
                                                style={{
                                                    color: this.state.passwordMaskNew
                                                        ? '#aaa'
                                                        : '#1e88e5',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <FontAwesomeIcon icon="eye" />
                                            </div>
                                            <input
                                                className="form-control"
                                                id="form-login-new-password"
                                                type={
                                                    this.state.passwordMaskNew ? 'password' : 'text'
                                                }
                                                onChange={e =>
                                                    this.setState({ password: e.target.value })
                                                }
                                                value={this.state.password}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label
                                            className="label"
                                            htmlFor="form-login-confirm-password"
                                        >
                                            Confirmar senha
                                        </label>
                                        <div className="input-icon right">
                                            <div
                                                className="input-icon__icon-container"
                                                onClick={() =>
                                                    this.setState({
                                                        passwordMaskConfirmNew: !this.state
                                                            .passwordMaskConfirmNew,
                                                    })
                                                }
                                                style={{
                                                    color: this.state.passwordMaskConfirmNew
                                                        ? '#aaa'
                                                        : '#1e88e5',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <FontAwesomeIcon icon="eye" />
                                            </div>
                                            <input
                                                className="form-control"
                                                id="form-login-confirm-password"
                                                type={
                                                    this.state.passwordMaskConfirmNew
                                                        ? 'password'
                                                        : 'text'
                                                }
                                                onChange={e =>
                                                    this.setState({
                                                        confirmPassword: e.target.value,
                                                    })
                                                }
                                                value={this.state.confirmPassword}
                                            />
                                        </div>
                                    </div>

                                    {this.state.error && (
                                        <div
                                            className="form-group"
                                            style={{ width: '100%', textAlign: 'center' }}
                                        >
                                            <MessageBox type="danger" size="normal">
                                                {this.state.error}
                                            </MessageBox>
                                        </div>
                                    )}

                                    <button
                                        className="button block primary"
                                        type="button"
                                        onClick={this.onResetPassword}
                                    >
                                        {this.state.loading ? <Loader /> : 'Redefinir'}
                                    </button>
                                </form>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    error: auth.error,
    loading: auth.loading,
});

const mapDispatchToProps = dispatch => bindActionCreators(AuthActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageResetPassword);
