import React from 'react';
import classNames from 'classnames';
import Spinner from 'react-spinner-material';

import './style.sass';

/**
 * @param {number} props.size
 * @param {string} props.spinnerColor
 * @param {number} props.spinnerWidth
 * @param {boolean} props.visible
 */

const SpinnerFull = props => {
    const { visible } = props;

    const containerClass = classNames('SpinnerFull', {
        'is-active': visible,
    });

    return (
        <div className={containerClass}>
            <div className="SpinnerFull-bg" />
            <div className="SpinnerFull-box">
                <Spinner
                    size={props.size}
                    spinnerColor={props.spinnerColor}
                    spinnerWidth={props.spinnerWidth}
                    visible={true}
                />
            </div>
        </div>
    );
};

export default SpinnerFull;
