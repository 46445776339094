import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import CurrencyInput from 'react-currency-input';

/**
 *
 * @param {string} props.id
 * @param {string} props.label
 * @param {string} props.labelColor
 * @param {string} props.labelSize
 * @param {string} props.inputSize
 * @param {string} props.size
 * @param {string} props.type
 * @param {string} props.readOnly
 * @param {string} props.disabled
 * @param {string} props.errorMessage
 * @param {string} props.appendPosition
 * @param {string} props.appendContent
 * @param {string} props.placeholder
 * @param {function} props.onChange
 */
const FormGroupInputMoney = props => {
    const {
        label = 'Label',
        labelSize = '',
        // inputSize = '',
        value = '',
        appendPosition = 'left',
        errorMessage = '',
        size = '',
    } = props;

    const labelClass = classNames('label', [labelSize], [size]);
    // const inputClass = classNames('form-control', [inputSize], [size])
    const inputIconClass = classNames('input-icon', [appendPosition]);
    const groupClass = classNames('FormGroupInput', 'form-group', {
        'is-invalid': errorMessage,
    });

    return (
        <div className={groupClass}>
            <label className={labelClass} htmlFor={props.id} style={{ color: props.labelColor }}>
                {label}
            </label>

            <div className={inputIconClass}>
                <div className="input-icon__icon-container">R$</div>
                <CurrencyInput
                    value={value}
                    onChangeEvent={(event, maskedvValue, floatValue) => props.onChange(floatValue)}
                    decimalSeparator=","
                    thousandSeparator="."
                    className="form-control"
                />
            </div>

            {errorMessage && (
                <div className="form-validation-danger is-visible">
                    <FontAwesomeIcon icon="exclamation-circle" /> {errorMessage}
                </div>
            )}
        </div>
    );
};

export default FormGroupInputMoney;
