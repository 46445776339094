import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import InputMask from 'react-input-mask';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators as RepasseViewActions } from '../../store/ducks/repasseView';
import api from '../../services/api';
import { validateCPF, checkExistField } from '../../helpers';
import TopBar from '../../components/TopBar';
import CardConteudo from '../../components/CardConteudo';
import GestaoEquipeRow from '../../components/GestaoEquipeRow';
import GestaoEquipeSearchRow from '../../components/GestaoEquipeSearchRow';
import BotaoCarregarMais from '../../components/BotaoCarregarMais';
import Modal from '../../components/Modal';
import MessageBox from '../../components/MessageBox';
import SpinnerFull from '../../components/SpinnerFull';
import FormGroupInput from '../../components/FormGroupInput';
import FormGroupInputPassword from '../../components/FormGroupInputPassword';
import FormGroupSelect from '../../components/FormGroupSelect';
import TitleAside from '../../components/TitleAside';
import { Link } from 'react-router-dom';

import './style.sass';

class PageGestaoEquipe extends Component {
    style = {
        headerClearance: {
            paddingTop: '80px',
        },
        pageContainer: {
            backgroundColor: '#eee',
            padding: '40px 0',
        },
        contentContainer: {
            maxWidth: '1200px',
            padding: '0 20px',
            margin: 'auto',
        },
    };

    inputProps = {
        size: 'small',
        labelColor: '#335180',
    };

    modalCreateUpdate = React.createRef();
    modalRemove = React.createRef();
    modalTransferResponsibility = React.createRef();
    modalSearch = React.createRef();

    state = {
        staff_id: '',
        successMessage: false,
        nameError: '',
        phoneError: '',
        cell_phoneError: '',
        cpfError: '',
        emailError: '',
        roleError: '',
        passwordError: '',
        confPasswordError: '',
        roles: [
            { value: '2', content: 'Diretor' },
            { value: '3', content: 'Gerente' },
            { value: '4', content: 'Vendedor' },
        ],
        user: {
            name: '',
            lastEmail: '',
            email: '',
            phone: '',
            cell_phone: '',
            lastCpf: '',
            cpf: '',
            role: '',
            password: '',
            confPassword: '',
        },
        transfer: {
            user_from: '',
            user_to: '',
        },
        originalUser: null,
        loading: false,
        nothingToLoad: false,
        error: '',
        current_page: '',
        totals: '',
        staff: [],

        modalTab: 1,
        tabs: [{ id: 1, name: 'Buscar Usuário' }, { id: 2, name: 'Novo Usuário' }],

        userSearch: null,
        cpfSearch: '',
        roleSearch: '4',
    };

    componentDidMount() {
        const { storeId } = this.props;

        this.getStaff(storeId);
    }

    getStaff = async store_id => {
        try {
            this.setState({ loading: true });

            const response = await api.get(`/api/v2/store/${store_id}/staff`);
            const { items, current_page, totals } = response.data.data;

            if (!items.length || items.length === totals) this.setState({ nothingToLoad: true });
            else this.setState({ nothingToLoad: false });

            this.setState({ staff: items.filter(i => i.status !== 0), current_page, totals });
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    loadMoreStaff = async (store_id, current_page) => {
        try {
            this.setState({ loading: true });

            const response = await api.get(
                `/api/v2/store/${store_id}/staff?current_page=${current_page}`
            );
            const { items, totals } = response.data.data;

            if (!items.length || items.length === totals) this.setState({ nothingToLoad: true });
            else this.setState({ nothingToLoad: false });

            this.setState({
                staff: this.state.staff.concat(items.filter(i => i.status !== 0)),
                current_page,
                totals,
            });
        } catch (error) {
            console.log({ error });
        } finally {
            this.setState({ loading: false });
        }
    };

    addUser = async (store_id, user) => {
        try {
            this.setState({ loading: true, error: '' });

            const response = await api.post(`/api/v2/store/${store_id}/staff`, {
                name: user.name,
                email: user.email,
                phone: user.phone.replace(/\D/g, ''),
                cell_phone: user.cell_phone.replace(/\D/g, ''),
                cpf: user.cpf.replace(/\D/g, ''),
                role: user.role,
                password: user.password,
            });

            const { data } = response.data;

            this.setState({ staff: [data, ...this.state.staff] });
            this.props.getScoreboard();
        } catch ({ response }) {
            console.log({ response });
            if (response) {
                const { msg } = response.data;

                this.setState({ error: msg });
            }
        } finally {
            this.setState({ loading: false });
        }
    };

    updateUser = async (store_id, staff_id, userEditInfo) => {
        try {
            this.setState({ loading: true, error: '' });

            /** Atualiza membro */
            await api.put(`/api/v2/store/${store_id}/staff/${staff_id}`, userEditInfo);

            /** Pega as novas informações e atualiza no state */
            try {
                const {
                    data: { data },
                } = await api.get(`/api/v2/store/${store_id}/staff/${staff_id}`);

                this.setState({
                    staff: this.state.staff.map(item => (item.id === staff_id ? data : item)),
                });
            } catch (error) {
                console.log({ error });
            }
        } catch ({ response }) {
            console.log({ response });
            if (response) {
                const { msg } = response.data;

                this.setState({ error: msg });
            }
        } finally {
            this.setState({ loading: false });
        }
    };

    removeUser = async (store_id, staff_id) => {
        try {
            this.setState({ loading: true, error: '' });

            await api.delete(`/api/v2/store/${store_id}/staff/${staff_id}`);

            this.setState({ staff: this.state.staff.filter(item => item.id !== staff_id) });
            this.props.getScoreboard();
        } catch ({ response }) {
            console.log({ response });
            if (response) {
                const { msg } = response.data;

                this.setState({ error: msg });
            }
        } finally {
            this.setState({ loading: false });
        }
    };

    transferResponsibility = async (store_id, user_from, user_to) => {
        try {
            this.setState({ loading: true, error: '' });

            await api.put(`/api/v2/store/${store_id}/migrate/cars`, {
                user_from,
                user_to,
            });

            await this.removeUser(store_id, user_from);
            await this.getStaff(store_id);
        } catch ({ response }) {
            console.log({ response });
            if (response) {
                const { msg } = response.data;

                this.setState({ error: msg });
            }
        } finally {
            this.setState({ loading: false });
        }
    };

    getEditInfo = () => {
        const { originalUser, user } = this.state;

        let editInfo = {};

        if (
            user.name !== originalUser.name ||
            user.email !== originalUser.email ||
            user.phone.replace(/\D/g, '') !== originalUser.phone ||
            user.cell_phone.replace(/\D/g, '') !== originalUser.cell_phone ||
            user.cpf.replace(/\D/g, '') !== originalUser.cpf ||
            user.password ||
            (user.role === '2' && originalUser.role !== 'Diretor') ||
            (user.role === '3' && originalUser.role !== 'Gerente') ||
            (user.role === '4' && originalUser.role !== 'Vendedor')
        )
            editInfo = user;

        if (JSON.stringify(editInfo) === '{}') return null;

        editInfo.phone = user.phone.replace(/\D/g, '');
        editInfo.cell_phone = user.cell_phone.replace(/\D/g, '');
        editInfo.cpf = user.cpf.replace(/\D/g, '');

        if (!editInfo.password) delete editInfo.password;

        return editInfo;
    };

    openModalCreateUpdate = staff_id => {
        this.cleanErrors();
        this.setState({ error: '' });

        // Se staff_id for null abre modal com parâmetros vazios para criação
        if (staff_id === null)
            this.setState(
                {
                    staff_id: '',
                    successMessage: false,
                    user: {
                        name: '',
                        email: '',
                        phone: '',
                        cell_phone: '',
                        cpf: '',
                        role: '',
                        password: '',
                        confPassword: '',
                        cpfSearch: '',
                    },
                    originalUser: null,
                },
                this.modalCreateUpdate.current.openModal()
            );
        /**
         * Se houver um id, staff_id !== null, (capturado pelo botão "Editar")
         * abre modal com parâmetros do usuário selecionado para edição
         */ else {
            /** Busca no array um funcionário com mesmo id */
            const item = this.state.staff.find(item => item.id === staff_id);

            /** Busca no array um role com mesmo nome (role.content) */
            const role = this.state.roles.find(role => item.role === role.content);

            this.setState(
                {
                    staff_id: item.id,
                    successMessage: false,
                    user: {
                        name: item.name === null ? '' : item.name,
                        lastEmail: item.email === null ? '' : item.email,
                        email: item.email === null ? '' : item.email,
                        phone: item.phone === null ? '' : item.phone,
                        cell_phone: item.cell_phone === null ? '' : item.cell_phone,
                        lastCpf: item.cpf === null ? '' : item.cpf,
                        cpf: item.cpf === null ? '' : item.cpf,
                        role: role.value,
                        password: '',
                        confPassword: '',
                    },
                    originalUser: item,
                    modalTab: 2,
                },
                this.modalCreateUpdate.current.openModal()
            );
        }
    };

    openModalSearch = () => {
        this.cleanErrors();
        this.setState({ error: '' }, this.modalSearch.current.openModal());
    };

    openModalRemove = staff_id => {
        const { qty_cars } = this.state.staff.find(item => item.id === staff_id);

        if (qty_cars > 0)
            this.setState(
                {
                    transfer: { user_from: staff_id, user_to: '' },
                    successMessage: false,
                    error: '',
                },
                this.modalTransferResponsibility.current.openModal()
            );
        else
            this.setState(
                { staff_id, successMessage: false, error: '' },
                this.modalRemove.current.openModal()
            );
    };

    closeModalRemove = () => this.setState({ staff_id: '' }, this.modalRemove.current.closeModal());

    closeModalTransferResponsibility = () =>
        this.setState({ staff_id: '' }, this.modalTransferResponsibility.current.closeModal());

    handleStateUserChange = (name, value) =>
        this.setState({
            user: {
                ...this.state.user,
                [name]: value,
            },
        });

    handleStateErrorChange = (name, value) =>
        this.setState({
            [`${name}Error`]: value,
        });

    cleanErrors = () =>
        this.setState({
            nameError: '',
            cpfError: '',
            emailError: '',
            phoneError: '',
            cell_phoneError: '',
            roleError: '',
            passwordError: '',
            confPasswordError: '',
            cpfSearchError: '',
        });

    validateFields = async () => {
        let validated = true;
        const { user, staff_id } = this.state;

        this.cleanErrors();

        if (validator.isEmpty(user.name)) {
            validated = false;
            this.handleStateErrorChange('name', 'Nome requerido');
        }

        if (!validator.isEmail(user.email)) {
            validated = false;
            this.handleStateErrorChange('email', 'Email inválido');

            if (validator.isEmpty(user.email))
                this.handleStateErrorChange('email', 'Email requerido');
        }

        if (user.phone.replace(/\D/g, '').length < 10) {
            validated = false;
            this.handleStateErrorChange('phone', 'Preencha o Telefone corretamente');

            if (validator.isEmpty(user.phone))
                this.handleStateErrorChange('phone', 'Telefone requerido');
        }

        if (user.cell_phone.replace(/\D/g, '').length < 11) {
            validated = false;
            this.handleStateErrorChange('cell_phone', 'Preencha o Celular corretamente');

            if (validator.isEmpty(user.cell_phone))
                this.handleStateErrorChange('cell_phone', 'Celular requerido');
        }

        if (!validateCPF(user.cpf)) {
            validated = false;
            this.handleStateErrorChange('cpf', 'CPF inválido');

            if (validator.isEmpty(user.cpf)) this.handleStateErrorChange('cpf', 'CPF requerido');
        }

        if (validator.isEmpty(user.role.toString())) {
            validated = false;
            this.handleStateErrorChange('role', 'Cargo requerido');
        }

        if (
            !staff_id ||
            (!validator.isEmpty(user.password) || !validator.isEmpty(user.confPassword))
        ) {
            if (user.password.length < 8) {
                validated = false;
                this.handleStateErrorChange(
                    'password',
                    'Senha precisa ter pelo menos 8 caracteres'
                );

                if (validator.isEmpty(user.password))
                    this.handleStateErrorChange('password', 'Senha requerida');
            }

            if (validator.isEmpty(user.confPassword)) {
                validated = false;
                this.handleStateErrorChange('confPassword', 'Confirmação de senha requerida');
            }

            if (user.password !== user.confPassword) {
                validated = false;
                this.handleStateErrorChange('password', 'Senhas diferentes');
                this.handleStateErrorChange('confPassword', 'Senhas diferentes');
            }
        }

        this.setState({ loading: true });

        /**
         * Faz verificação na criação
         * staff_id só setado na edição
         * Caso não exista, ele entra nesse if
         */
        if (!this.state.staff_id) {
            if (!validator.isEmpty(user.email) && (await checkExistField('email', user.email))) {
                validated = false;
                this.handleStateErrorChange('email', 'Email já está em uso');
            }

            if (
                !validator.isEmpty(user.cpf) &&
                ((await checkExistField('cpf', user.cpf)) ||
                    (await checkExistField('cpf', user.cpf.replace(/\D/g, ''))))
            ) {
                validated = false;
                this.handleStateErrorChange('cpf', 'CPF já em uso');
            }
        } else {
            /**
             * Se houver staff_id (edição de membro) a
             * validação checa se o usuário alterou o email, e/ou cpf do membro.
             * Se tiver alterado, faz verifição se existe na base de dados ou não
             */
            if (user.email !== user.lastEmail) {
                if (
                    !validator.isEmpty(user.email) &&
                    (await checkExistField('email', user.email))
                ) {
                    validated = false;
                    this.handleStateErrorChange('email', 'Email já está em uso');
                }
            }

            if (user.cpf !== user.lastCpf) {
                if (
                    !validator.isEmpty(user.cpf) &&
                    ((await checkExistField('cpf', user.cpf)) ||
                        (await checkExistField('cpf', user.cpf.replace(/\D/g, ''))))
                ) {
                    validated = false;
                    this.handleStateErrorChange('cpf', 'CPF já em uso');
                }
            }
        }

        this.setState({ loading: false });

        return validated;
    };

    searchUserByCpf = async () => {
        this.setState({ userSearch: null, error: '' });

        let { cpfSearch } = this.state;
        cpfSearch = cpfSearch.replace(/[^a-zA-Z0-9 ]/g, '');

        let validated = true;

        if (!validateCPF(cpfSearch)) {
            validated = false;
            this.handleStateErrorChange('cpfSearch', 'CPF inválido');

            if (validator.isEmpty(cpfSearch)) this.handleStateErrorChange('cpf', 'CPF requerido');
        }

        if (!validated) return;

        this.handleStateErrorChange('cpfSearch', '');

        try {
            this.setState({ loading: true });

            const response = await api.get(`/api/v2/user/search?field=document&value=${cpfSearch}`);
            const user = response.data.data;

            this.setState({ userSearch: user, loading: false });
        } catch (error) {
            if (error.response.status === 404) this.searchUserByCpfMask();
        }
    };

    searchUserByCpfMask = async () => {
        try {
            const response = await api.get(
                `/api/v2/user/search?field=document&value=${this.state.cpfSearch}`
            );
            const user = response.data.data;
            this.setState({ userSearch: user });
        } catch (error) {
            if (error.response.status === 404) this.setState({ error: 'Usuário não encontrado' });
            else this.setState({ error: 'Ocorreu um erro. Tente mais tarde.' });
        } finally {
            this.setState({ loading: false });
        }
    };

    onInviteUser = async () => {
        const { storeId } = this.props;
        const { userSearch, roleSearch } = this.state;

        try {
            this.setState({ error: '', loading: true });
            await api.put(
                `/api/v2/store/${storeId}/staff/${userSearch.id}/invite?role=${roleSearch}`
            );
            this.setState({
                successMessage: true,
                userSearch: null,
                cpfSearch: '',
                roleSearch: '4',
            });
        } catch (error) {
            if (error.response.status === 400)
                this.setState({ error: 'Este usuário já é membro da sua equipe!' });
            else this.setState({ error: 'Ocorreu um erro. Tente mais tarde.' });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { storeId } = this.props;
        const { loading, staff, current_page } = this.state;

        return (
            <>
                <TopBar />

                <Helmet>
                    <title>Gestão da Equipe • Repasse</title>
                </Helmet>

                <div className="page-container" style={this.style.pageContainer}>
                    <div className="content-container" style={this.style.contentContainer}>
                        <SpinnerFull
                            size={40}
                            spinnerColor={'#335180'}
                            spinnerWidth={3}
                            visible={loading}
                        />

                        <div style={{ marginBottom: '3rem' }}>
                            <TitleAside title="Gestão da Equipe">
                                <Link className="button blue" to="/equipe-convites">
                                    Ver convites enviados
                                </Link>
                                <button
                                    className="button primary"
                                    onClick={() => this.openModalCreateUpdate(null)}
                                    style={{ marginLeft: '1.5rem' }}
                                >
                                    Adicionar membro
                                </button>
                            </TitleAside>
                        </div>

                        <CardConteudo>
                            {!loading && staff && !staff.length ? (
                                <div className="message-error">
                                    <div className="result__title">
                                        Você não tem nenhum membro cadastrado na sua equipe
                                    </div>
                                </div>
                            ) : (
                                <div className="gestao-equipe-header">
                                    <div className="gestao-equipe-header-item">Profissional</div>
                                    {/* <div className="gestao-equipe-header-item">Vendas</div> */}
                                    {/* <div className="gestao-equipe-header-item">
                                        Tempo de resposta
                                    </div> */}
                                    {/* <div className="gestao-equipe-header-item is-dropdown">
                                        Este mês
                                        <div className="dropdown-icon">
                                            <FontAwesomeIcon icon="chevron-down" />
                                        </div>
                                    </div> */}
                                </div>
                            )}

                            {staff &&
                                staff.map(item => (
                                    <GestaoEquipeRow
                                        key={item.id}
                                        openEditModal={() => this.openModalCreateUpdate(item.id)}
                                        openRemoveModal={() => this.openModalRemove(item.id)}
                                        openUserRepasses={() =>
                                            this.props.history.push(`/repasses/${item.id}`)
                                        }
                                        imageUrl=""
                                        name={item.name}
                                        jobTitle={item.role}
                                        phone={item.phone}
                                        cellPhone={item.cell_phone}
                                        sales="9"
                                        salesVariation="pos"
                                        avgTime="3"
                                        avgTimeVariation="neg"
                                        repassesAmount={item.qty_cars}
                                    />
                                ))}
                        </CardConteudo>

                        {!this.state.nothingToLoad && (
                            <div className="pagination-container">
                                <BotaoCarregarMais
                                    onClick={() => this.loadMoreStaff(storeId, current_page + 1)}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {this.renderModalAddUpdate()}
                {this.renderModalRemove()}
                {this.renderModalTransferResponsibility()}
            </>
        );
    }

    renderModalAddUpdate = () => {
        const { user, staff_id } = this.state;
        const { storeId } = this.props;
        const { loading } = this.state;

        return (
            <Modal
                title={!staff_id ? 'Adicionar membro' : 'Editar membro'}
                customClass="Modal-GestaoEquipe"
                ref={this.modalCreateUpdate}
            >
                {!this.state.successMessage && (
                    <React.Fragment>
                        <SpinnerFull
                            size={40}
                            spinnerColor={'#335180'}
                            spinnerWidth={3}
                            visible={loading}
                        />

                        {!this.state.staff_id && (
                            <div className="form-select">
                                {this.state.tabs.map(item => (
                                    <div className="checkbox-group" key={item.id}>
                                        <input
                                            type="radio"
                                            id={item.id}
                                            checked={this.state.modalTab === item.id}
                                            onChange={() => this.setState({ modalTab: item.id })}
                                            value={item.id}
                                        />
                                        <label htmlFor={item.id} value={item.id} className="label">
                                            {item.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        )}

                        {this.state.modalTab === 1 ? (
                            <React.Fragment>
                                <div className="cpf-search">
                                    <div className="cpf-search__item">
                                        <InputMask
                                            mask="999.999.999-99"
                                            onChange={e => {
                                                this.setState({ cpfSearch: e.target.value });
                                            }}
                                            value={this.state.cpfSearch}
                                            errorMessage={this.state.cpfSearchError}
                                        >
                                            {inputMaskProps => (
                                                <FormGroupInput
                                                    {...inputMaskProps}
                                                    {...this.inputProps}
                                                    label="CPF"
                                                    noMargin
                                                />
                                            )}
                                        </InputMask>
                                    </div>
                                    <div className="cpf-search__item">
                                        <div className="form-search-button-container">
                                            <button
                                                className="button blue"
                                                style={{
                                                    maxWidth: '30rem',
                                                    width: '100%',
                                                    padding: '0 2rem',
                                                    height: '3.4rem',
                                                }}
                                                onClick={this.searchUserByCpf}
                                            >
                                                Buscar
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {this.state.userSearch && (
                                    <GestaoEquipeSearchRow
                                        key={269}
                                        imageUrl=""
                                        name={this.state.userSearch.name}
                                        phone={this.state.userSearch.cell_phone}
                                        email={this.state.userSearch.email}
                                        roles={this.state.roles}
                                        onChangeUserSearchRole={value =>
                                            this.setState({ roleSearch: value })
                                        }
                                        onInvite={this.onInviteUser}
                                        roleSearch={this.state.roleSearch}
                                    />
                                )}
                            </React.Fragment>
                        ) : (
                            <div>
                                <div className="form-row">
                                    <div className="form-col">
                                        <FormGroupInput
                                            label="Nome"
                                            onChange={e => {
                                                this.handleStateUserChange('name', e.target.value);
                                                this.handleStateErrorChange('name', '');
                                            }}
                                            value={user.name}
                                            errorMessage={this.state.nameError}
                                            {...this.inputProps}
                                        />
                                    </div>

                                    <div className="form-col">
                                        <FormGroupInput
                                            label="Email"
                                            onChange={e => {
                                                this.handleStateUserChange('email', e.target.value);
                                                this.handleStateErrorChange('email', '');
                                            }}
                                            value={user.email}
                                            errorMessage={this.state.emailError}
                                            {...this.inputProps}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-col">
                                        <InputMask
                                            mask="999.999.999-99"
                                            onChange={e => {
                                                this.handleStateUserChange('cpf', e.target.value);
                                                this.handleStateErrorChange('cpf', '');
                                            }}
                                            value={user.cpf}
                                            errorMessage={this.state.cpfError}
                                        >
                                            {inputMaskProps => (
                                                <FormGroupInput
                                                    {...inputMaskProps}
                                                    label="CPF"
                                                    {...this.inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </div>

                                    <div className="form-col">
                                        <FormGroupSelect
                                            label="Cargo"
                                            onChange={e => {
                                                this.handleStateUserChange('role', e.target.value);
                                                this.handleStateErrorChange('role', '');
                                            }}
                                            placeholder="Selecione"
                                            options={this.state.roles}
                                            value={user.role}
                                            errorMessage={this.state.roleError}
                                            {...this.inputProps}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-col">
                                        <InputMask
                                            mask="(99) 9999-9999"
                                            onChange={e => {
                                                this.handleStateUserChange('phone', e.target.value);
                                                this.handleStateErrorChange('phone', '');
                                            }}
                                            value={user.phone}
                                            errorMessage={this.state.phoneError}
                                        >
                                            {inputMaskProps => (
                                                <FormGroupInput
                                                    {...inputMaskProps}
                                                    label="Telefone"
                                                    {...this.inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </div>
                                    <div className="form-col">
                                        <InputMask
                                            mask="(99) 99999-9999"
                                            onChange={e => {
                                                this.handleStateUserChange(
                                                    'cell_phone',
                                                    e.target.value
                                                );
                                                this.handleStateErrorChange('cell_phone', '');
                                            }}
                                            value={user.cell_phone}
                                            errorMessage={this.state.cell_phoneError}
                                        >
                                            {inputMaskProps => (
                                                <FormGroupInput
                                                    {...inputMaskProps}
                                                    label="Celular"
                                                    {...this.inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-col">
                                        <FormGroupInputPassword
                                            label="Senha"
                                            onChange={e => {
                                                this.handleStateUserChange(
                                                    'password',
                                                    e.target.value
                                                );
                                                this.handleStateErrorChange('password', '');
                                            }}
                                            value={user.password}
                                            errorMessage={this.state.passwordError}
                                            {...this.inputProps}
                                        />
                                    </div>

                                    <div className="form-col">
                                        <FormGroupInputPassword
                                            label="Confirmar senha"
                                            onChange={e => {
                                                this.handleStateUserChange(
                                                    'confPassword',
                                                    e.target.value
                                                );
                                                this.handleStateErrorChange('confPassword', '');
                                            }}
                                            value={user.confPassword}
                                            errorMessage={this.state.confPasswordError}
                                            {...this.inputProps}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.error && (
                            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                                <MessageBox type="danger" size="normal">
                                    {this.state.error}
                                </MessageBox>
                            </div>
                        )}

                        {this.state.modalTab === 2 && (
                            <div className="margin-top" style={{ textAlign: 'center' }}>
                                <button
                                    className="button primary big"
                                    style={{ maxWidth: '30rem', width: '100%' }}
                                    onClick={async () => {
                                        if (await this.validateFields()) {
                                            if (!staff_id) {
                                                await this.addUser(storeId, user);
                                                if (!this.state.error)
                                                    this.setState({ successMessage: true });
                                            } else {
                                                const userEditInfo = this.getEditInfo();
                                                if (userEditInfo) {
                                                    await this.updateUser(
                                                        storeId,
                                                        staff_id,
                                                        userEditInfo
                                                    );
                                                    if (!this.state.error)
                                                        this.setState({ successMessage: true });
                                                } else this.modalCreateUpdate.current.closeModal();
                                            }
                                        }
                                    }}
                                >
                                    Salvar
                                </button>
                            </div>
                        )}
                    </React.Fragment>
                )}

                {this.state.successMessage && (
                    <React.Fragment>
                        <div
                            style={{
                                marginBottom: '2rem',
                                textAlign: 'center',
                            }}
                        >
                            <FontAwesomeIcon
                                style={{
                                    color: '#549e00',
                                    fontSize: '3rem',
                                }}
                                icon="check"
                            />
                        </div>
                        <div
                            style={{
                                fontSize: '1.6rem',
                                textAlign: 'center',
                            }}
                        >
                            {this.state.modalTab === 1
                                ? 'Usuário convidado com sucesso!'
                                : !staff_id
                                ? 'Membro criado com sucesso!'
                                : 'Membro editado com sucesso!'}
                        </div>
                    </React.Fragment>
                )}
            </Modal>
        );
    };

    renderModalRemove = () => {
        const { staff_id } = this.state;
        const { storeId } = this.props;

        return (
            <Modal title="Remover membro" customClass="Modal-GestaoEquipe" ref={this.modalRemove}>
                {!this.state.successMessage ? (
                    <React.Fragment>
                        <SpinnerFull
                            size={40}
                            spinnerColor={'#335180'}
                            spinnerWidth={3}
                            visible={this.state.loading}
                        />
                        <div className="remove__title">
                            Tem certeza que deseja excluir esse membro da equipe?
                        </div>
                        {this.state.error && (
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: '2rem',
                                    marginBottom: '2rem',
                                }}
                            >
                                <MessageBox type="danger" size="normal">
                                    {this.state.error}
                                </MessageBox>
                            </div>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: 'auto',
                            }}
                        >
                            <button
                                className="button primary outline margin-right"
                                style={{ maxWidth: '20rem', width: '100%' }}
                                onClick={this.closeModalRemove}
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={async () => {
                                    await this.removeUser(storeId, staff_id);

                                    if (!this.state.error) this.setState({ successMessage: true });
                                }}
                                className="button primary"
                                style={{ maxWidth: '20rem', width: '100%' }}
                            >
                                Excluir
                            </button>
                        </div>{' '}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div
                            style={{
                                marginBottom: '2rem',
                                textAlign: 'center',
                            }}
                        >
                            <FontAwesomeIcon
                                style={{
                                    color: '#549e00',
                                    fontSize: '3rem',
                                }}
                                icon="check"
                            />
                        </div>
                        <div
                            style={{
                                fontSize: '1.6rem',
                                textAlign: 'center',
                            }}
                        >
                            Membro da equipe removido com sucesso!
                        </div>
                    </React.Fragment>
                )}
            </Modal>
        );
    };

    renderModalTransferResponsibility = () => {
        const { staff, transfer } = this.state;
        const { storeId } = this.props;

        return (
            <Modal
                title="Transferência de responsabilidade de repasses"
                customClass="Modal-GestaoEquipe"
                ref={this.modalTransferResponsibility}
            >
                {!this.state.successMessage ? (
                    <React.Fragment>
                        <SpinnerFull
                            size={40}
                            spinnerColor={'#335180'}
                            spinnerWidth={3}
                            visible={this.state.loading}
                        />
                        <div style={{ marginBottom: '2rem' }}>
                            O usuário selecionado possui repasses cadastrados. Para removê-lo é
                            necessário transferir a responsabilidade para outro membro da equipe.
                            Selecione abaixo para qual membro os repasses serão transferidos:
                        </div>
                        <div
                            style={{
                                width: '250px',
                                margin: '0 auto',
                            }}
                        >
                            <div className="form-row">
                                <div className="form-col">
                                    <FormGroupSelect
                                        label="Membro"
                                        onChange={e => {
                                            this.setState({
                                                transfer: {
                                                    user_from: transfer.user_from,
                                                    user_to: Number(e.target.value),
                                                },
                                            });
                                        }}
                                        placeholder="Selecione"
                                        options={staff
                                            .filter(item => item.id !== transfer.user_from)
                                            .map(item => ({
                                                value: item.id,
                                                content: item.name,
                                            }))}
                                        value={transfer.user_to}
                                        errorMessage={this.state.roleError}
                                        {...this.inputProps}
                                    />
                                </div>
                            </div>
                        </div>
                        {this.state.error && (
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: '2rem',
                                    marginBottom: '2rem',
                                }}
                            >
                                <MessageBox type="danger" size="normal">
                                    {this.state.error}
                                </MessageBox>
                            </div>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: 'auto',
                            }}
                        >
                            <button
                                className="button primary outline margin-right"
                                style={{ maxWidth: '20rem', width: '100%' }}
                                onClick={this.closeModalTransferResponsibility}
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={async () => {
                                    await this.transferResponsibility(
                                        storeId,
                                        transfer.user_from,
                                        transfer.user_to
                                    );

                                    if (!this.state.error) this.setState({ successMessage: true });
                                }}
                                className="button primary"
                                style={{ maxWidth: '20rem', width: '100%' }}
                            >
                                Transferir
                            </button>
                        </div>{' '}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div
                            style={{
                                marginBottom: '2rem',
                                textAlign: 'center',
                            }}
                        >
                            <FontAwesomeIcon
                                style={{
                                    color: '#549e00',
                                    fontSize: '3rem',
                                }}
                                icon="check"
                            />
                        </div>
                        <div
                            style={{
                                fontSize: '1.6rem',
                                textAlign: 'center',
                            }}
                        >
                            Repasses transferidos com sucesso!
                        </div>
                    </React.Fragment>
                )}
            </Modal>
        );
    };
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
    storeId: auth.storeId,
});

const mapDispatchToProps = dispatch => bindActionCreators(RepasseViewActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageGestaoEquipe);
