import React, { Component } from 'react';
import './style.sass';
import PlanoCadastroDesktopItem from '../PlanoCadastroDesktopItem';

export default class PlanoCadastroDesktop extends Component {
    render() {
        return (
            <div className="plano-cadastro-desktop">
                <div className="coluna-titulos">
                    <div className="area-titulo" style={{ height: 134 }} />
                    <div className="area-titulo">
                        <div className="titulo-item">Transações Mensais</div>
                        <div className="titulo-item">Adicional por Transação</div>
                    </div>
                    <div className="area-titulo">
                        <div className="titulo-item">Fotos por Veículo</div>
                    </div>
                    <div className="area-titulo">
                        <div className="titulo-item">Turbos por Mês</div>
                        <div className="titulo-item">Turbos Avulso</div>
                    </div>
                    <div className="area-titulo">
                        <div className="titulo-item">
                            Consulta Veicular <span className="tag-mes">(1º mês)</span>
                        </div>
                        <div className="titulo-item">Consulta Veicular</div>
                    </div>
                    <div className="area-titulo">
                        <div className="titulo-item">Diretores</div>
                        <div className="titulo-item">Gerentes</div>
                        <div className="titulo-item">Vendedores</div>
                    </div>
                </div>

                {this.props.items &&
                    this.props.items.map(item => (
                        <PlanoCadastroDesktopItem
                            key={item.id}
                            idPlano={item.id}
                            choosePlan={this.props.choosePlan}
                            planObject={item}
                            nomePlano={item.title}
                            valorPlano={item.price}
                            transacoesMensais={item.qty_transactions}
                            adicionalTransacao={item.transaction_price}
                            fotosVeiculo="Sem Limites"
                            turbosMes={item.qty_turbos}
                            valorTurboAvulso="50"
                            consultaPrimeiroMes={item.qty_car_queries}
                            consultaVeicular="60"
                            diretores="Sem Limites"
                            gerentes="Sem Limites"
                            vendedores="Sem Limites"
                        />
                    ))}
            </div>
        );
    }
}
