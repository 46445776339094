import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

/**
 *
 * @param {string} props.id
 * @param {string} props.label
 * @param {string} props.labelColor
 * @param {string} props.labelSize
 * @param {string} props.inputSize
 * @param {string} props.size
 * @param {string} props.type
 * @param {string} props.readOnly
 * @param {string} props.disabled
 * @param {string} props.errorMessage
 * @param {string} props.appendPosition
 * @param {string} props.appendContent
 * @param {string} props.placeholder
 * @param {function} props.onChange
 */
const FormGroupInput = props => {
    const {
        label = 'Label',
        labelSize = '',
        inputSize = '',
        type = 'text',
        value = '',
        appendPosition = 'left',
        appendContent = '',
        errorMessage = '',
        helpMessage = '',
        placeholder = '',
        size = '',
    } = props;

    const labelClass = classNames('label', [labelSize], [size]);
    const inputClass = classNames('form-control', [inputSize], [size]);
    const inputIconClass = classNames('input-icon', [appendPosition]);
    const groupClass = classNames('FormGroupInput', 'form-group', {
        'is-invalid': errorMessage,
        'no-margin': props.noMargin,
    });

    return (
        <div className={groupClass}>
            <label
                className={labelClass}
                htmlFor={props.id}
                style={{ color: props.labelColor, ...props.customStyle }}
            >
                {label}
            </label>
            {appendContent === '' ? (
                <input
                    className={inputClass}
                    id={props.id}
                    readOnly={props.readOnly}
                    disabled={props.disabled}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    onChange={props.onChange}
                    {...props}
                />
            ) : (
                <div className={inputIconClass}>
                    <div className="input-icon__icon-container">{props.appendContent}</div>
                    <input
                        className={inputClass}
                        id={props.id}
                        readOnly={props.readOnly}
                        disabled={props.disabled}
                        type={type}
                        value={value}
                        onChange={props.onChange}
                        {...props}
                    />
                </div>
            )}

            {errorMessage && (
                <div className="form-validation-danger is-visible">
                    <FontAwesomeIcon icon="exclamation-circle" /> {errorMessage}
                </div>
            )}

            {helpMessage && (
                <div className="form-validation is-visible" style={{ color: '#666', fontSize: 12 }}>
                    <FontAwesomeIcon icon="question-circle" /> {helpMessage}
                </div>
            )}
        </div>
    );
};

export default FormGroupInput;
