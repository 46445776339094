import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import 'react-tabs/style/react-tabs.css';
import ActionButton from '../../components/ActionButton';
import MessageBox from '../../components/MessageBox';
import Modal from '../../components/Modal';
import SpinnerFull from '../../components/SpinnerFull';
import TopBar from '../../components/TopBar';
import CardConteudo from '../../components/CardConteudo';
import GestaoEquipeInviteRow from '../../components/GestaoEquipeInviteRow';
import './style.sass';

import { connect } from 'react-redux';
import api from '../../services/api';

class PageConvitesEnviados extends Component {
    modalRemove = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: '',
            idUser: '',
        };

        this.style = {
            notificationBullet: {
                marginRight: '1rem',
            },
            invites: [],
        };
    }

    async componentDidMount() {
        const { storeId } = this.props;

        this.getStaff(storeId);
    }

    getStaff = async store_id => {
        try {
            this.setState({ loading: true });

            const response = await api.get(`/api/v2/store/${store_id}/staff`);
            const { items, current_page, totals } = response.data.data;

            console.log(items.filter(item => item.status === 0));

            this.setState({
                invites: items.filter(item => item.status === 0),
                current_page,
                totals,
            });
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    openModalRemove = idUser =>
        this.setState(
            { idUser, successMessage: false, error: '' },
            this.modalRemove.current.openModal()
        );

    closeModalRemove = () => this.setState({ idUser: '' }, this.modalRemove.current.closeModal());

    removeInvite = async () => {
        try {
            this.setState({ loading: true });

            await api.put(
                `/api/v2/store/${this.props.storeId}/cancel/invite/staff/${this.state.idUser}`
            );

            this.setState({ successMessage: true, loading: false }, () =>
                this.getStaff(this.props.storeId)
            );
        } catch (error) {
            this.setState({ loading: false, error: 'Ocorreu um erro. Tente mais tarde.' });
        }
    };

    render() {
        const { loading, invites } = this.state;

        return (
            <>
                <TopBar />

                <Helmet>
                    <title>Gestão da Equipe • Repasse</title>
                </Helmet>

                <Modal
                    title="Remover convite"
                    customClass="Modal-GestaoEquipe"
                    ref={this.modalRemove}
                >
                    {!this.state.successMessage ? (
                        <React.Fragment>
                            <SpinnerFull
                                size={40}
                                spinnerColor={'#335180'}
                                spinnerWidth={3}
                                visible={this.state.loading}
                            />
                            <div className="remove__title">
                                Tem certeza que deseja excluir esse convite?
                            </div>
                            {this.state.error && (
                                <div style={{ textAlign: 'center' }}>
                                    <MessageBox type="danger" size="normal">
                                        {this.state.error}
                                    </MessageBox>
                                </div>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: 'auto',
                                }}
                            >
                                <button
                                    className="button primary outline margin-right"
                                    style={{ maxWidth: '20rem', width: '100%' }}
                                    onClick={this.closeModalRemove}
                                >
                                    Cancelar
                                </button>
                                <button
                                    onClick={this.removeInvite}
                                    className="button primary"
                                    style={{ maxWidth: '20rem', width: '100%' }}
                                >
                                    Excluir
                                </button>
                            </div>{' '}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div
                                style={{
                                    marginBottom: '2rem',
                                    textAlign: 'center',
                                }}
                            >
                                <FontAwesomeIcon
                                    style={{
                                        color: '#549e00',
                                        fontSize: '3rem',
                                    }}
                                    icon="check"
                                />
                            </div>
                            <div
                                style={{
                                    fontSize: '1.6rem',
                                    textAlign: 'center',
                                }}
                            >
                                Convite removido com sucesso!
                            </div>
                        </React.Fragment>
                    )}
                </Modal>

                <div className="page-container">
                    <div className="content-container">
                        <SpinnerFull
                            size={40}
                            spinnerColor={'#335180'}
                            spinnerWidth={3}
                            visible={loading}
                        />
                        <SpinnerFull
                            size={40}
                            spinnerColor={'#335180'}
                            spinnerWidth={3}
                            visible={this.state.loading}
                        />
                        <div className="header-page">
                            <div className="header-page-actions">
                                <div
                                    className="header-page-action"
                                    onClick={() => this.props.history.goBack()}
                                >
                                    <ActionButton
                                        nameIcon="chevron-left"
                                        modifierClasses="action-link"
                                        textAction="Voltar"
                                    />
                                </div>
                            </div>
                        </div>

                        <CardConteudo>
                            {!loading && invites && !invites.length ? (
                                <div className="message-error">
                                    <div className="result__title">
                                        Você não tem nenhum convite cadastrado a sua loja
                                    </div>
                                </div>
                            ) : (
                                <div className="gestao-equipe-header">
                                    <div className="gestao-equipe-header-item">
                                        Convites enviados
                                    </div>
                                </div>
                            )}

                            {invites &&
                                invites.map(item => (
                                    <GestaoEquipeInviteRow
                                        key={item.id}
                                        onClick={() => this.openModalRemove(item.id)}
                                        imageUrl=""
                                        name={item.name}
                                        jobTitle={item.role}
                                        phone={item.phone}
                                        email={item.email}
                                    />
                                ))}
                        </CardConteudo>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
    storeId: auth.storeId,
});

export default connect(mapStateToProps)(PageConvitesEnviados);
