import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './style.sass';

import TopBar from '../../components/TopBar';
import TitleAside from '../../components/TitleAside';
import PageContainer from '../../components/PageContainer';
import CardConteudo from '../../components/CardConteudo';
import Modal from '../../components/Modal';
import RepasseInfo from '../../components/RepasseInfo';
import RepasseValores from '../../components/RepasseValores';
// import InteressadoRow from '../../components/InteressadoRow';
import SpinnerFull from '../../components/SpinnerFull';

import iconTurbo from '../../assets/images/icon-turbo-max.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import iconGallery from '../../assets/images/icon-gallery.png';
// import placeholderCarSm from '../../assets/images/placeholder-car-sm.jpg';

import { connect } from 'react-redux';
import api, { baseURL } from '../../services/api';
import moment from 'moment';

const intlMonetary = new Intl.NumberFormat('pt-BR', {
    // style: 'currency',
    // currency: 'BRL',
    minimumFractionDigits: 2,
});

class PageExtratoConta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            plan: {},
            monthFilterOptions: [],
            monthFilter: '',
            modalDetalhesInfo: {
                id: '',
                user_id: '',
                photo: '',
                name: '',
                year: '',
                fuel: '',
                color: '',
                created_at: '',
                seller: '',
                price: '',
                price_fipe: '',
                percentage_fipe: '',
            },
        };

        this.modalDetalhes = React.createRef();
    }

    openModal = () => {
        this.modalDetalhes.current.openModal();
    };

    componentDidMount = async () => {
        this.setState({ loading: true });

        const { user } = this.props;

        this.getRangeOfDates(moment(user.created_at), moment(moment().format()), 'month');

        try {
            const response = await api.get(`${baseURL}/api/v2/report/billing`);

            this.setState({
                loading: false,
                plan: response.data.data,
            });
        } catch (error) {
            this.setState({ loading: false });
            console.log('Erro componentDidMount: ', error);
        }
    };

    getRangeOfDates = (
        start,
        end,
        key,
        arr = [
            {
                start_date: start.startOf(key).format('YYYY-MM-DD'),
                end_date: start.endOf(key).format('YYYY-MM-DD'),
            },
        ]
    ) => {
        let next = start
            .startOf(key)
            .add(1, key)
            .format('YYYY-MM-DD');

        while (!moment(next).isAfter(end)) {
            arr = arr.concat({
                start_date: moment(next)
                    .startOf(key)
                    .format('YYYY-MM-DD'),
                end_date: moment(next)
                    .endOf(key)
                    .format('YYYY-MM-DD'),
            });

            next = moment(next)
                .startOf(key)
                .add(1, key)
                .format('YYYY-MM-DD');
        }

        return this.setState({ monthFilterOptions: arr, monthFilter: arr[arr.length - 1] });
    };

    filterByMonth = async dates => {
        this.setState({ loading: true, monthFilter: JSON.parse(dates) });

        try {
            const response = await api.get(`${baseURL}/api/v2/report/billing`, {
                params: JSON.parse(dates),
            });

            this.setState({ loading: false, plan: response.data.data });
        } catch (error) {
            console.log('Erro filterByMonth: ', error);
        }
    };

    getDetails = async id => {
        this.setState({ loading: true });

        const { user } = this.props;

        try {
            const response = await api.get(`${baseURL}/api/v2/info-chat/${id}/${user.id}`);

            let res = response.data.data;

            this.setState(
                {
                    loading: false,
                    modalDetalhesInfo: {
                        ...this.state.modalDetalhesInfo,
                        id: res.car.id,
                        user_id: res.car.user.id,
                        photo: res.car.image,
                        name: res.car.fipe.model,
                        year: res.car.fipe.year,
                        fuel: res.car.fipe.fuel,
                        color: res.car.color.name,
                        created_at: res.car.created_at,
                        seller: res.car.user.name,
                        price: res.car.price,
                        price_fipe: res.car.fipe_price,
                        percentage_fipe: res.car.fipe_percent_off,
                    },
                },
                () => this.openModal()
            );
        } catch (error) {
            this.setState({ loading: false });
            console.log('Erro getDetails: ', error);
        }
    };

    render() {
        let { loading, plan, monthFilter, monthFilterOptions, modalDetalhesInfo } = this.state;

        return (
            <React.Fragment>
                <TopBar />

                <Helmet>
                    <title>Meu plano • Repasse</title>
                </Helmet>
                <PageContainer>
                    <SpinnerFull
                        size={40}
                        spinnerColor={'#335180'}
                        spinnerWidth={3}
                        visible={loading}
                    />
                    <TitleAside title="Meu plano" />
                    <CardConteudo>
                        <TitleAside
                            title={plan.plan ? `Plano ${plan.plan.name}` : ''}
                            size="small"
                            image={iconTurbo}
                            imageMaxWidth="2.5rem"
                        >
                            <div className="select-date">
                                <select
                                    className="select-date__select"
                                    value={JSON.stringify(monthFilter)}
                                    onChange={event => this.filterByMonth(event.target.value)}
                                >
                                    {monthFilterOptions.map((item, key) => (
                                        <option key={key} value={JSON.stringify(item)}>
                                            {`${moment(item.start_date).format('MMMM')}, ${moment(
                                                item.start_date
                                            ).year()}`}
                                        </option>
                                    ))}
                                </select>
                                <FontAwesomeIcon
                                    className="select-date__icon"
                                    icon="chevron-down"
                                    color="#1e88e5"
                                />
                            </div>
                        </TitleAside>

                        <div className="ExtratoDash">
                            <div className="ExtratoDash-container">
                                <div className="ExtratoDash-item">
                                    <div className="ExtratoDash-value">
                                        {plan.plan
                                            ? `${plan.qty_transactions} de ${
                                                  plan.plan.qty_transactions
                                              }`
                                            : ''}
                                    </div>
                                    <div className="ExtratoDash-name">
                                        negociações concretizadas
                                    </div>
                                </div>
                                <div className="ExtratoDash-item">
                                    <div className="ExtratoDash-value">
                                        {plan.extra_charges
                                            ? `${plan.extra_charges.transactions_extra}`
                                            : ''}
                                    </div>
                                    <div className="ExtratoDash-name">negociações adicionais</div>
                                </div>
                                <div className="ExtratoDash-item">
                                    <div className="ExtratoDash-value">
                                        {plan.plan
                                            ? `${plan.qty_turbos} de ${plan.plan.qty_turbos}`
                                            : ''}
                                    </div>
                                    <div className="ExtratoDash-name">
                                        turbos da franquia utilizados
                                    </div>
                                </div>
                                <div className="ExtratoDash-item">
                                    <div className="ExtratoDash-value">
                                        {plan.extra_charges
                                            ? `${plan.extra_charges.turbos_extras}`
                                            : ''}
                                    </div>
                                    <div className="ExtratoDash-name">turbos adicionais</div>
                                </div>
                                <div className="ExtratoDash-item">
                                    <div className="ExtratoDash-value">
                                        {plan.plan ? `${plan.qty_queries}` : ''}
                                    </div>
                                    <div className="ExtratoDash-name">
                                        consultas veiculares realizadas
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mini-title margin-bottom">
                            {plan.plan
                                ? `Extrato de consumo de ${moment(plan.start_date).format(
                                      'DD/MM/YYYY'
                                  )} até ${moment(plan.end_date).format('DD/MM/YYYY')}`
                                : 'Extrato de consumo do mês atual'}
                        </div>

                        <div className="ExtratoHeader">
                            <div className="ExtratoHeader-item">Data</div>
                            <div className="ExtratoHeader-item">Serviço</div>
                            <div className="ExtratoHeader-item">Valor</div>
                        </div>
                        <div className="ExtratoList">
                            <div className="ExtratoRow">
                                <div className="ExtratoRow-item">
                                    <span className="ExtratoRow-date">
                                        {plan.plan
                                            ? moment(plan.start_date).format('DD/MM/YYYY')
                                            : ''}
                                    </span>
                                </div>
                                <div className="ExtratoRow-item">
                                    <div className="ExtratoRow-name">
                                        {plan.plan ? `Plano ${plan.plan.name}` : ''}
                                    </div>
                                </div>
                                <div className="ExtratoRow-item" style={{ color: '#d22' }}>
                                    <span className="ExtratoRow-value">
                                        {plan.plan
                                            ? `R$ ${intlMonetary.format(plan.plan.price)}`
                                            : ''}
                                    </span>
                                </div>
                            </div>
                            {plan.transactions && plan.transactions.length
                                ? plan.transactions.map((item, index) => {
                                      return (
                                          <div className="ExtratoRow" key={index}>
                                              <div className="ExtratoRow-item">
                                                  <span className="ExtratoRow-date">
                                                      {moment(item.created_at).format('DD/MM/YYYY')}
                                                  </span>
                                              </div>
                                              <div className="ExtratoRow-item">
                                                  <div className="ExtratoRow-name">
                                                      {`Negociação concretizada (${index + 1}/${
                                                          plan.plan.qty_transactions
                                                      })`}
                                                  </div>
                                                  <div
                                                      className="ExtratoRow-link"
                                                      onClick={() => this.getDetails(item.car.id)}
                                                  >
                                                      Ver detalhes
                                                  </div>
                                              </div>
                                              {index < plan.plan.qty_transactions ? (
                                                  <div
                                                      className="ExtratoRow-item"
                                                      style={{ color: '#3c3' }}
                                                  >
                                                      <span className="ExtratoRow-value">
                                                          Franquia
                                                      </span>
                                                  </div>
                                              ) : (
                                                  <div
                                                      className="ExtratoRow-item"
                                                      style={{ color: '#3c3' }}
                                                  >
                                                      <span className="ExtratoRow-value">
                                                          {`R$ ${intlMonetary.format(
                                                              plan.plan.transaction_price
                                                          )}`}
                                                      </span>
                                                  </div>
                                              )}
                                          </div>
                                      );
                                  })
                                : null}
                            {plan.turbos && plan.turbos.length
                                ? plan.turbos.map((item, index) => {
                                      return (
                                          <div className="ExtratoRow" key={index}>
                                              <div className="ExtratoRow-item">
                                                  <span className="ExtratoRow-date">
                                                      {moment(item.created_at).format('DD/MM/YYYY')}
                                                  </span>
                                              </div>
                                              <div className="ExtratoRow-item">
                                                  <div className="ExtratoRow-name">
                                                      {`Turbo adicionado em Repasse (${index + 1}/${
                                                          plan.plan.qty_turbos
                                                      })`}
                                                  </div>
                                                  <div
                                                      className="ExtratoRow-link"
                                                      onClick={() => this.getDetails(item.car.id)}
                                                  >
                                                      Ver detalhes
                                                  </div>
                                              </div>
                                              {index < plan.plan.qty_turbos ? (
                                                  <div
                                                      className="ExtratoRow-item"
                                                      style={{ color: '#3c3' }}
                                                  >
                                                      <span className="ExtratoRow-value">
                                                          Franquia
                                                      </span>
                                                  </div>
                                              ) : (
                                                  <div
                                                      className="ExtratoRow-item"
                                                      style={{ color: '#3c3' }}
                                                  >
                                                      <span className="ExtratoRow-value">
                                                          {`R$ ${intlMonetary.format(
                                                              plan.plan.transaction_price
                                                          )}`}
                                                      </span>
                                                  </div>
                                              )}
                                          </div>
                                      );
                                  })
                                : null}
                            {plan.historic_car_queries && plan.historic_car_queries.length
                                ? plan.historic_car_queries.map((item, index) => {
                                      return (
                                          <div className="ExtratoRow" key={index}>
                                              <div className="ExtratoRow-item">
                                                  <span className="ExtratoRow-date">
                                                      {moment(item.created_at).format('DD/MM/YYYY')}
                                                  </span>
                                              </div>
                                              <div className="ExtratoRow-item">
                                                  <div className="ExtratoRow-name">
                                                      Consulta veicular
                                                  </div>
                                                  <div className="ExtratoRow-link">
                                                      <Link
                                                          to={`/consulta-veicular/${item.id}`}
                                                          style={{ color: '#335180' }}
                                                      >
                                                          Ver detalhes
                                                      </Link>
                                                  </div>
                                              </div>
                                              {index < plan.plan.qty_car_queries ? (
                                                  <div
                                                      className="ExtratoRow-item"
                                                      style={{ color: '#3c3' }}
                                                  >
                                                      <span className="ExtratoRow-value">
                                                          Franquia
                                                      </span>
                                                  </div>
                                              ) : (
                                                  <div
                                                      className="ExtratoRow-item"
                                                      style={{ color: '#d22' }}
                                                  >
                                                      <span className="ExtratoRow-value">
                                                          {`R$ ${intlMonetary.format(
                                                              plan.plan.transaction_price
                                                          )}`}
                                                      </span>
                                                  </div>
                                              )}
                                          </div>
                                      );
                                  })
                                : null}
                        </div>

                        <div className="ExtratoResumo">
                            <div className="ExtratoResumo-item">
                                <span className="ExtratoResumo-name">Plano</span>
                                <span className="ExtratoResumo-value">
                                    {plan.plan ? `R$ ${intlMonetary.format(plan.plan.price)}` : ''}
                                </span>
                            </div>
                            <div className="ExtratoResumo-item">
                                <span className="ExtratoResumo-name">
                                    {plan.extra_charges
                                        ? `Turbos adicionais (${plan.extra_charges.turbos_extras})`
                                        : 'Turbos adicionais'}
                                </span>
                                <span className="ExtratoResumo-value">
                                    {plan.extra_charges
                                        ? `R$ ${intlMonetary.format(
                                              plan.extra_charges.amount_turbos_extra
                                          )}`
                                        : ''}
                                </span>
                            </div>
                            <div className="ExtratoResumo-item">
                                <span className="ExtratoResumo-name">
                                    {plan.extra_charges
                                        ? `Consulta veicular (${
                                              plan.extra_charges.car_queries_extra
                                          })`
                                        : 'Consulta veicular'}
                                </span>
                                <span className="ExtratoResumo-value">
                                    {plan.extra_charges
                                        ? `R$ ${intlMonetary.format(
                                              plan.extra_charges.amount_queries_extra
                                          )}`
                                        : ''}
                                </span>
                            </div>
                            <div className="ExtratoResumo-item">
                                <span className="ExtratoResumo-name">
                                    {plan.extra_charges
                                        ? `Negociações adicionais (${
                                              plan.extra_charges.transactions_extra
                                          })`
                                        : 'Negociações adicionais'}
                                </span>
                                <span className="ExtratoResumo-value">
                                    {plan.extra_charges
                                        ? `R$ ${intlMonetary.format(
                                              plan.extra_charges.amount_transactions_extra
                                          )}`
                                        : ''}
                                </span>
                            </div>
                            <div className="ExtratoResumo-item">
                                <span className="ExtratoResumo-name">Total</span>
                                <span className="ExtratoResumo-value">
                                    {plan.extra_charges
                                        ? `R$ ${intlMonetary.format(plan.extra_charges.total)}`
                                        : ''}
                                </span>
                            </div>
                        </div>
                    </CardConteudo>
                </PageContainer>

                <Modal ref={this.modalDetalhes} title="Detalhes do consumo">
                    <div className="ModalPreviaRepasse-content">
                        <div className="ModalPreviaRepasse-section --repasse">
                            <div className="ModalPreviaRepasse-image-container">
                                <img
                                    className="ModalPreviaRepasse-image"
                                    src={`${baseURL}/uploads/gallery/${modalDetalhesInfo.photo}`}
                                    alt="Avatar do repasse"
                                />
                            </div>

                            <div className="ModalPreviaRepasse-info-car">
                                <RepasseInfo
                                    tituloRepasse={modalDetalhesInfo.name}
                                    ano={modalDetalhesInfo.year}
                                    combustivel={modalDetalhesInfo.fuel}
                                    cor={modalDetalhesInfo.color}
                                    vendedor={modalDetalhesInfo.seller}
                                    acompanharNegociacao
                                    marginAuto
                                />
                            </div>
                            <div className="ModalPreviaRepasse-info-price">
                                <RepasseValores
                                    valorRepasse={intlMonetary.format(modalDetalhesInfo.price)}
                                    valorFipe={intlMonetary.format(modalDetalhesInfo.price_fipe)}
                                    valorPorcentagem={modalDetalhesInfo.percentage_fipe.replace(
                                        /\D/g,
                                        ''
                                    )}
                                />

                                <Link
                                    to={`/repasses/${modalDetalhesInfo.id}/${
                                        modalDetalhesInfo.user_id
                                    }`}
                                    className="ModalPreviaRepasse-cta button blue"
                                >
                                    Ver repasse
                                </Link>
                            </div>
                        </div>

                        {/* <div className="ModalPreviaRepasse-section --dentro">
                            <div className="mini-title">Venda pelo Repasse</div>
                            <InteressadoRow
                                name="Pedro Britto"
                                phoneNumber="(85) 98899-2237"
                                hasRequestedNumber={true}
                                hasNegociated={true}
                                proposal="10000"
                                isLite={true}
                                noChat
                            >
                                <button
                                    className="button outline blue smaller"
                                    style={{ flex: 'none' }}
                                >
                                    Vendido para
                                    <br />
                                    este usuário
                                </button>
                            </InteressadoRow>
                            <InteressadoRow
                                name="Pedro Britto"
                                phoneNumber="(85) 98899-2237"
                                hasRequestedNumber={true}
                                hasNegociated={true}
                                proposal="10000"
                                isLite={true}
                                noChat
                            >
                                <button
                                    className="button outline blue smaller"
                                    style={{ flex: 'none' }}
                                >
                                    Vendido para
                                    <br />
                                    este usuário
                                </button>
                            </InteressadoRow>
                            <InteressadoRow
                                name="Pedro Britto"
                                phoneNumber="(85) 98899-2237"
                                hasRequestedNumber={true}
                                hasNegociated={true}
                                proposal="10000"
                                isLite={true}
                                noChat
                            >
                                <button
                                    className="button outline blue smaller"
                                    style={{ flex: 'none' }}
                                >
                                    Vendido para
                                    <br />
                                    este usuário
                                </button>
                            </InteressadoRow>
                        </div>

                        <div className="ModalPreviaRepasse-section --fora">
                            <div className="mini-title">Venda por fora</div>
                            <p
                                style={{
                                    fontSize: '1.4rem',
                                    fontWeight: '500',
                                    lineHeight: '1.2',
                                    textAlign: 'center',
                                }}
                            >
                                Caso você tenha vendido este Repasse por outros meios fora da
                                plataforma, confirme através do botão abaixo."
                            </p>
                            <div className="margin-top" style={{ textAlign: 'center' }}>
                                <button className="button blue">Vendido fora da repasse</button>
                            </div>
                        </div> */}
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
});

export default connect(
    mapStateToProps,
    {}
)(PageExtratoConta);
