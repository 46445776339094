import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.sass';

const TituloComBotao = props => {
    let titleStyle = { fontWeight: 'bold' };
    titleStyle.fontWeight = props.isBold === true ? 'bold' : '500';

    return (
        <div className="titulo-com-botao" style={titleStyle}>
            <div className="titulo">{props.textoTitulo}</div>

            {(props.textoBotao || props.nomeIcone) && (
                <React.Fragment>
                    <div className="botao-mobile" onClick={props.onClick}>
                        <FontAwesomeIcon icon={props.nomeIcone} />
                    </div>
                    <button className="botao-desktop" onClick={props.onClick}>
                        {props.textoBotao}
                    </button>
                </React.Fragment>
            )}
        </div>
    );
};

export default TituloComBotao;
