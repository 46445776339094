import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import iconTime from '../../assets/images/icon-time-grey.png';
import iconChat from '../../assets/images/icon-chat-grey.png';

import StatusLabel from '../StatusLabel';
import Seller from '../Seller';

import ActionButton from '../ActionButton';

import moment from 'moment';

import './style.sass';

/**
 * RepasseInfo
 *
 * @param {string} props.tituloRepasse Nome do veículo
 * @param {boolean} props.canEdit Se verdadeiro add ícone de editar
 * @param {boolean} props.canFavorite Se verdadeiro add ícone de favoritar
 * @param {string|number} props.ano Ano do veículo
 * @param {string} props.modelo Modelo do veículo
 * @param {string} props.cor Cor do veículo
 * @param {boolean} props.acompanharNegociacao Ativa link para acompanhar negociação
 * @param {string|number} props.diasNoPatio Número de dias no pátio
 * @param {string} props.vendedor Nome do vendedor
 * @param {boolean} props.marginAuto Habilita espaçamento automático dentro de seção do component
 */

export default class RepasseInfo extends Component {
    favoriteIconStyle = {
        color: '#1e88e5',
        marginLeft: '1rem',
        cursor: 'pointer',
    };

    render() {
        return (
            <div className="RepasseInfo">
                <div
                    className="RepasseInfo-titulo"
                    style={{
                        fontWeight: this.props.canEdit ? '600' : '',
                        width: this.props.width,
                    }}
                >
                    {this.props.canSee ? (
                        <Link to={`/repasses/${this.props.idRepasse}/${this.props.idUsuario}`}>
                            {this.props.tituloRepasse}
                        </Link>
                    ) : (
                        this.props.tituloRepasse
                    )}
                    {/* {this.props.canEdit === true && (
                        <Link to={`/repasses/${this.props.idRepasse}/${this.props.idUsuario}`}>
                            <img
                                src={iconEdit}
                                alt=""
                                style={{ width: '1.6rem', marginLeft: '1rem' }}
                            />
                        </Link>
                    )} */}
                    {this.props.canFavorite && !this.props.canEdit && (
                        <div onClick={() => this.props.toggleRepasseFavorite(this.props.idRepasse)}>
                            {this.props.isFavorite ? (
                                /* Ícone preenchido */
                                <FontAwesomeIcon
                                    style={this.favoriteIconStyle}
                                    icon={['fas', 'star']}
                                />
                            ) : (
                                /* Ícone vazado */
                                <FontAwesomeIcon
                                    style={this.favoriteIconStyle}
                                    icon={['far', 'star']}
                                />
                            )}
                        </div>
                    )}
                </div>

                <div className="RepasseInfo-subcontainer">
                    <div>
                        {this.props.estado && this.props.cidade && (
                            <div className="RepasseInfo-item">
                                {this.props.cidade} - {this.props.estado}
                            </div>
                        )}
                        <div className="RepasseInfo-item">
                            {this.props.ano} - {this.props.combustivel}
                        </div>
                        <div className="RepasseInfo-item">{this.props.cor.toUpperCase()}</div>

                        {this.props.marginAuto && <div style={{ margin: 'auto' }} />}

                        {this.props.diasNoPatio ? (
                            <div className="RepasseInfo-icon-info">
                                <img src={iconTime} alt="" style={{ width: '1.8rem' }} />
                                <span className="icon-text">
                                    {this.props.canEdit
                                        ? `${this.props.diasNoPatio} ${
                                              this.props.diasNoPatio === 1 ? 'dia' : 'dias'
                                          } no pátio`
                                        : moment(this.props.diasNoPatio).format('DD/MM/YYYY') !==
                                          moment().format('DD/MM/YYYY')
                                        ? `Cadastrado em ${
                                              moment(this.props.diasNoPatio).format(
                                                  'DD/MM/YYYY'
                                              ) !== 'Invalid date'
                                                  ? moment(this.props.diasNoPatio).format(
                                                        'DD/MM/YYYY'
                                                    )
                                                  : moment().format('DD/MM/YYYY')
                                          }`
                                        : 'Cadastrado hoje'}
                                </span>
                            </div>
                        ) : (
                            <div className="RepasseInfo-icon-info">
                                <img src={iconTime} alt="" style={{ width: '1.8rem' }} />
                                <span className="icon-text">
                                    {this.props.canEdit ? `1 dia no pátio` : `Cadastrado hoje`}
                                </span>
                            </div>
                        )}

                        {this.props.canEdit && this.props.hasChat && (
                            <div className="RepasseInfo-icon-info">
                                {this.props.qtdChats === 0 ? (
                                    <img src={iconChat} alt="" style={{ width: '1.8rem' }} />
                                ) : (
                                    <div
                                        className="badge"
                                        style={
                                            this.props.qtdChats > 99
                                                ? {
                                                      borderRadius: '45%',
                                                      height: '1.5rem',
                                                      width: '2.5rem',
                                                  }
                                                : null
                                        }
                                    >
                                        {this.props.qtdChats > 99 ? '99+' : this.props.qtdChats}
                                    </div>
                                )}
                                <Link
                                    to={{
                                        pathname: `/repasses/${this.props.idRepasse}/${
                                            this.props.idUsuario
                                        }`,
                                        state: { selectedTab: 2 },
                                    }}
                                >
                                    <span className="RepasseInfo-subcontainer-link">
                                        Acompanhar negociação
                                    </span>
                                </Link>
                            </div>
                        )}

                        {this.props.vendedor && (
                            <Fragment>
                                <div className="RepasseInfo-vendedor">
                                    <div className="icon" style={{ color: '#008ae7' }}>
                                        <FontAwesomeIcon icon="user" />
                                    </div>
                                    <div className="text">Vendedor: {this.props.vendedor}</div>
                                </div>
                                {this.props.showStockLink && (
                                    <div>
                                        <ActionButton
                                            nameIcon={'car'}
                                            colorIcon="#008ae7"
                                            textAction={'Ver estoque de repasses'}
                                            linkAction={`/estoque/${this.props.idUsuario}`}
                                        />
                                    </div>
                                )}
                            </Fragment>
                        )}
                    </div>

                    {this.props.statusValue && this.props.sellerName && (
                        <div className="RepassesItem-info-availability">
                            <StatusLabel
                                statusValue={this.props.statusValue.name.toUpperCase()}
                                statusCode={this.props.statusValue.id}
                                isLite={false}
                            />

                            <div style={{ marginBottom: '1.5rem' }} />

                            <Seller
                                sellerImage={this.props.sellerImage}
                                sellerName={this.props.sellerName}
                                sellerPhone={this.props.sellerPhone}
                                sellerCellPhone={this.props.sellerCellPhone}
                                isLite={true}
                                showStockButton={this.props.showStockButton}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
