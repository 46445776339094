import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createCar, editCar } from '../../store/repasseCreate/actions';
import './style.sass';

import Modal from '../Modal';
import Swiper from 'react-id-swiper';
import Loader from '../../components/Loader';

import iconArrowRightWhite from '../../assets/images/icon-arrow-right-white.png';
import iconArrowLeftWhite from '../../assets/images/icon-arrow-left-white.png';
import iconArrowRightBlue from '../../assets/images/icon-arrow-right-blue.png';
import iconArrowLeftBlue from '../../assets/images/icon-arrow-left-blue.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RepasseInfo from '../RepasseInfo';
import RepasseValores from '../RepasseValores';
import DetalhesVeiculoTab from '../DetalhesVeiculoTab';
import MessageBox from '../MessageBox';

import carImage from '../../assets/images/placeholder-car.jpg';

const intlMonetary = new Intl.NumberFormat('pt-BR', {
    // style: 'currency',
    // currency: 'BRL',
    minimumFractionDigits: 2,
});

class CRConfirmation extends Component {
    state = {
        vehicleColor: '',
        vehicleModel: {},
        vehicleOptionals: [],
        gallerySwiper: null,
        thumbnailSwiper: null,
    };

    style = {
        messageBoxContainer: {
            textAlign: 'center',
            margin: '3rem auto ',
        },
    };

    constructor(props) {
        super(props);

        this.galleryModal = React.createRef();
        this.galleryRef = this.galleryRef.bind(this);
        this.thumbRef = this.thumbRef.bind(this);
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.gallerySwiper && nextState.thumbnailSwiper) {
            const { gallerySwiper, thumbnailSwiper } = nextState;

            if (gallerySwiper.controller && thumbnailSwiper.controller) {
                gallerySwiper.controller.control = thumbnailSwiper;
                thumbnailSwiper.controller.control = gallerySwiper;
            }
        }
    }

    componentDidMount() {
        this.getObjectsById();
    }

    getObjectsById = () => {
        const { fields } = this.props;

        let vehicleColor = '';
        let vehicleModel = {};
        let vehicleOptionals = [];

        fields.colors &&
            fields.colors.forEach(item => {
                if (Number(item.id) === Number(this.props.vehicleColor)) vehicleColor = item.name;
            });

        fields.models &&
            fields.models.forEach(item => {
                if (Number(item.id) === Number(this.props.vehicleModel)) vehicleModel = item;
            });

        fields.optional &&
            fields.optional.forEach(item => {
                if (this.props.vehicleOptionals.includes(item.id)) vehicleOptionals.push(item.name);
            });

        this.setState({ vehicleColor, vehicleModel, vehicleOptionals });
    };

    galleryRef = ref => {
        if (ref) {
            this.setState({ gallerySwiper: ref.swiper });
        }
    };

    thumbRef = ref => {
        if (ref) {
            this.setState({ thumbnailSwiper: ref.swiper });
        }
    };

    openGalleryModal = () => {
        this.galleryModal.current.openModal();
    };

    slideGalleryTo = index => {
        this.state.thumbnailSwiper.slideTo(index);
        this.state.gallerySwiper.slideTo(index);
    };

    createCar = async () => {
        const car_id = await this.props.createCar(
            this.props.carData,
            this.props.storeId,
            this.props.nextStep
        );
        this.props.handleStateChange('car_id', car_id);
    };

    editCar = async () => {
        await this.props.editCar(this.props.carData, this.props.nextStep);
    };

    render() {
        const gallerySwiperParams = {
            containerClass: 'swiper-container ModalGaleria-SwiperGallery',
            spaceBetween: 10,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            autoHeight: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
            },
            keyboard: true,
            renderNextButton: () => {
                return (
                    <div className="swiper-button-next">
                        <img src={iconArrowRightWhite} alt="" />
                    </div>
                );
            },
            renderPrevButton: () => {
                return (
                    <div className="swiper-button-prev">
                        <img src={iconArrowLeftWhite} alt="" />
                    </div>
                );
            },
            shouldSwiperUpdate: true,
        };

        const thumbnailSwiperParams = {
            containerClass: 'swiper-container ModalGaleria-SwiperThumbnail',
            spaceBetween: 10,
            centeredSlides: true,
            slidesPerView: 8,
            touchRatio: 0.2,
            slideToClickedSlide: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            renderNextButton: () => {
                return (
                    <div className="swiper-button-next">
                        <img src={iconArrowRightBlue} alt="" />
                    </div>
                );
            },
            renderPrevButton: () => {
                return (
                    <div className="swiper-button-prev">
                        <img src={iconArrowLeftBlue} alt="" />
                    </div>
                );
            },
            breakpoints: {
                1200: {
                    slidesPerView: 6,
                },
                800: {
                    slidesPerView: 4,
                },
            },
            shouldSwiperUpdate: true,
        };

        return (
            <React.Fragment>
                <Modal
                    ref={this.galleryModal}
                    customClass="ModalGaleria"
                    title="Imagens do veículo"
                >
                    <Swiper {...gallerySwiperParams} ref={this.galleryRef}>
                        {this.props.gallery.map((item, index) => {
                            return (
                                <div className="ModalGaleria-SwiperGallery-item" key={index}>
                                    <img
                                        className="ModalGaleria-SwiperGallery-image"
                                        src={item}
                                        alt=""
                                    />
                                </div>
                            );
                        })}
                    </Swiper>
                    <Swiper {...thumbnailSwiperParams} ref={this.thumbRef}>
                        {this.props.gallery.map((item, index) => {
                            return (
                                <div
                                    className="ModalGaleria-SwiperThumbnail-item responsive-embed"
                                    key={index}
                                >
                                    <img
                                        className="ModalGaleria-SwiperThumbnail-image responsive-embed-item"
                                        src={item}
                                        alt=""
                                    />
                                </div>
                            );
                        })}
                    </Swiper>
                </Modal>

                <h2 className="CadastroRepasse-title">Confira os dados do repasse</h2>
                <div className="CadastroRepasse-Confirmation">
                    <div className="CadastroRepasse__summary">
                        <div className="summary__title">
                            Detalhes do veículo
                            <div
                                className="summary__title-icon"
                                onClick={() =>
                                    !this.props.update
                                        ? this.props.goToStep(2)
                                        : this.props.goToStep(1)
                                }
                            >
                                <FontAwesomeIcon icon="pencil-alt" />
                            </div>
                        </div>

                        <div className="summary-header">
                            <div className="summary-header__image-container">
                                <img
                                    src={
                                        this.props.highlightImage && this.props.highlightImage.path
                                            ? this.props.highlightImage.path
                                            : carImage
                                    }
                                    className="summary-header__image"
                                    alt=""
                                />
                            </div>

                            <div className="summary-header__info-container">
                                <RepasseInfo
                                    tituloRepasse={this.state.vehicleModel.model}
                                    ano={this.props.vehicleModelYear}
                                    combustivel={this.state.vehicleModel.fuel}
                                    cor={this.state.vehicleColor}
                                />
                                <RepasseValores
                                    valorPorcentagem={Number(
                                        this.props.vehicleFipePercent.toFixed(2)
                                    )}
                                    valorRepasse={intlMonetary.format(
                                        this.props.vehicleDesiredPrice
                                    )}
                                />
                            </div>
                        </div>

                        <DetalhesVeiculoTab
                            fuelType={this.state.vehicleModel.fuel}
                            modelYear={this.state.vehicleModel.year}
                            doors={this.props.vehicleDoors}
                            color={this.state.vehicleColor}
                            kilometrage={this.props.vehicleKilometrage}
                            optionalExtras={this.state.vehicleOptionals.map(item => item)}
                            description={this.props.vehicleDescription}
                        />

                        <div className="summary__title">
                            Galeria de fotos
                            <div
                                className="summary__title-icon"
                                onClick={() =>
                                    !this.props.update
                                        ? this.props.goToStep(3)
                                        : this.props.goToStep(2)
                                }
                            >
                                <FontAwesomeIcon icon="pencil-alt" />
                            </div>
                        </div>

                        <div className="summary__gallery-listing">
                            {this.props.gallery.map((item, index) => {
                                return (
                                    <div
                                        className="summary__gallery-item responsive-embed"
                                        key={index}
                                        onClick={() => {
                                            this.openGalleryModal();
                                            this.slideGalleryTo(index);
                                        }}
                                    >
                                        <img
                                            className="responsive-embed-item"
                                            src={item && item.path}
                                            alt=""
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {/* MENSAGEM DE ERRO */}
                <div style={this.style.messageBoxContainer}>
                    {this.props.error.length > 0 && (
                        <MessageBox type="danger" inlineBlock>
                            {this.props.error}
                        </MessageBox>
                    )}
                </div>

                <div className="CadastroRepasse-navigation margin-top">
                    <button className="button primary outline" onClick={this.props.prevStep}>
                        <FontAwesomeIcon className="navigation-icon" icon="chevron-left" />
                        Galeria
                    </button>
                    <button
                        className="button primary"
                        disabled={this.props.loading}
                        onClick={async () =>
                            !this.props.update ? await this.createCar() : await this.editCar()
                        }
                    >
                        {this.props.loading ? <Loader /> : 'Concluir'}
                        <FontAwesomeIcon className="navigation-icon" icon="chevron-right" />
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ repasseCreate, auth }) => ({
    loading: repasseCreate.loading,
    error: repasseCreate.error,
    fields: repasseCreate.fields,
    storeId: auth.storeId,
});

export default connect(
    mapStateToProps,
    { createCar, editCar }
)(CRConfirmation);
