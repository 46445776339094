import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Button from '../../components/Button';
import CadastroUsuarioContainer from '../../components/CadastroUsuarioContainer';
import PlanoCadastroDesktop from '../../components/PlanoCadastroDesktop';
import PlanoCadastroMobile from '../../components/PlanoCadastroMobile';
import SpinnerFull from '../../components/SpinnerFull';
import api, { baseURL } from '../../services/api';
import { Creators, getPlans } from '../../store/userCreate/actions';
import './style.sass';
import MessageBox from '../../components/MessageBox';

class CadastroUsuarioTres extends Component {
    state = {
        loading: false,
        error: false,
    };

    componentDidMount() {
        if (this.props.user.currentStep === 0 || this.props.user.currentStep < 3)
            return this.props.history.push('/cadastro-usuario-dois');

        this.props.getPlans();
        this.scrollToTop();
    }

    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    choosePlan = (idPlano, planObject) => {
        console.log({ planObject, idPlano });
        this.props.setUserAttribute('plan_id', idPlano);
        this.props.setUserAttribute('plan', planObject);
        this.props.setUserAttribute('currentStep', 4);
        this.props.history.push('/cadastro-usuario-quatro');
    };

    selectFreePeriod = async () => {
        try {
            const response = await api.put(`${baseURL}/api/v2/user/trial`);

            console.log(response);
            this.props.setUserAttribute('currentStep', 4);
            this.props.history.push('/cadastro-usuario-quatro');
        } catch (error) {
            console.log({ error });

            this.setState({ error: true });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { plans, loading, user } = this.props;

        return (
            <div>
                <CadastroUsuarioContainer
                    currentStep={3}
                    tituloPagina={user.update ? 'Atualização de cadastro' : 'Cadastro'}
                >
                    <div className="cadastro-usuario-tres">
                        <div className="subtitulo">Escolha um dos nossos planos:</div>
                        <SpinnerFull
                            size={40}
                            spinnerColor={'#335180'}
                            spinnerWidth={3}
                            visible={loading}
                        />

                        {!loading && (
                            <>
                                <PlanoCadastroMobile
                                    items={plans.items}
                                    setUserAttribute={this.props.setUserAttribute}
                                    choosePlan={this.choosePlan}
                                />
                                <PlanoCadastroDesktop
                                    items={plans.items}
                                    setUserAttribute={this.props.setUserAttribute}
                                    choosePlan={this.choosePlan}
                                />

                                {this.state.error && (
                                    <MessageBox type="danger" size="normal">
                                        Houve um problema ao escolher testar por 7 dias grátis
                                    </MessageBox>
                                )}
                            </>
                        )}

                        <div className="botoes-container">
                            <div className="botao-item">
                                <Link to="/cadastro-usuario-dois">
                                    <Button outline textButton="Voltar" />
                                </Link>
                            </div>

                            {!user.is_testing && (
                                <div className="botao-item" onClick={() => this.selectFreePeriod()}>
                                    <div
                                        style={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            color: '#37474f',
                                            textTransform: 'uppercase',
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Testar por 7 dias grátis
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </CadastroUsuarioContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ userCreate }) => ({
    loading: userCreate.loading,
    plans: userCreate.plans,
    user: userCreate.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...Creators, getPlans }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CadastroUsuarioTres);
