import { call, put, select } from 'redux-saga/effects';

import { Creators } from '../ducks/profileUser';
import api, { baseURL } from '../../services/api';

export function* getUser() {
    try {
        yield put(Creators.setLoading(true));

        const { id } = yield select(state => state.auth.user);

        const response = yield call(api.get, `/api/v2/user/${id}`);
        const { data } = response.data;

        yield put(Creators.setUser(data));
        yield put(Creators.setUserAttribute('photo', `${baseURL}/uploads/avatar/${data.photo}`));
    } catch ({ response }) {
        console.log({ response });
    } finally {
        yield put(Creators.setLoading(false));
    }
}

export function* getStates() {
    try {
        const response = yield call(api.get, `/api/states`);
        const { data } = response.data;

        yield put(Creators.setStates(data));
    } catch ({ response }) {
        console.log({ response });
    }
}

export function* updateUser({ user }) {
    try {
        yield put(Creators.setLoading(true));

        yield call(api.post, '/api/v2/user/update', {
            user_type: user.user_type,
            name: user.name,
            email: user.email,
            dealership: user.dealership,
            document: user.document,
            zipcode: user.zipcode,
            address: user.address,
            number: user.number,
            neighborhood: user.neighborhood,
            city: user.city,
            state: user.state,
            phone: user.phone,
            cell_phone: user.cell_phone,
        });

        if (user.photoFile) {
            const formData = new FormData();
            formData.append('filename', user.photoFile, user.photoFile.name);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            yield call(api.post, `/api/v2/user/image/update`, formData, config);
        }

        yield put(Creators.getUser());
    } catch (error) {
        console.log({ error });
    } finally {
        yield put(Creators.setLoading(false));
    }
}
