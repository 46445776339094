import React from 'react';

// AVISO:
// Esse componente não deve ser estilizado diretamente.
// A classe pode ser usada para auxiliar estilização com classes aninhadas.

const NameInitials = props => {
    function returnInitials(name) {
        const names = name.split(' ');
        const initials = names.map(name => name[0]).join('');

        if (initials.length > 2) {
            return '' + initials[0] + initials[initials.length - 1];
        }

        return initials;
    }

    const initials = returnInitials(props.name);

    return <div className="NameInitials">{initials}</div>;
};

export default NameInitials;
