import React, { Component, Fragment } from 'react';
import validator from 'validator';
import { connect } from 'react-redux';

import {
    populateAnnounceByType,
    populateAnnounceByBrand,
    populateAnnounceByYear,
    populateAnnounceByFipe,
} from '../../store/repasseCreate/actions';

import './style.sass';

import iconPicture from '../../assets/images/icon-picture.png';
import logoFipe from '../../assets/logo-fipe.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormGroupSelect from '../FormGroupSelect';
import FormGroupInput from '../FormGroupInput';
import FormGroupInputNumber from '../FormGroupInputNumber';
import FormGroupInputMoney from '../FormGroupInputMoney';
import CheckboxGroup from '../CheckboxGroup';
import BarraPorcentagem from '../BarraPorcentagem';
import MessageBox from '../MessageBox';
import SpinnerFull from '../SpinnerFull';

const intlMonetary = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
});

class CRVehicleDetails extends Component {
    style = {
        messageBoxContainer: {
            textAlign: 'center',
            // maxWidth: '60rem',
            margin: '3rem auto 0',
        },
    };

    state = {
        checkedOptionals: new Map(),
        imageError: '',
        vehicleBrandError: '',
        vehicleModelYearError: '',
        vehicleModelError: '',
        vehicleDesiredPriceError: '',
        vehicleCostPriceError: '',
        vehicleColorError: '',
        vehicleEixoError: '',
        vehicleKilometrageError: '',
        vehicleDescriptionError: '',

        loadOriginalVehicleData: false, // para quando voltar de outra etapa do processo de criacao
    };

    /* constructor(props) {
        super(props);

        // this.handleVehicleDesiredPriceChange = _.debounce(
        //     this.handleVehicleDesiredPriceChange,
        //     500
        // );
    } */

    componentDidMount() {
        this.props.populateAnnounceByType(this.props.vehicleType);

        this.scrollToTop();

        if (this.props.vehicleBrand && this.props.vehicleModelYear && this.props.vehicleModel) {
            this.setState({ loadOriginalVehicleData: true }, async () => {
                await this.handleVehicleBrandChange(this.props.vehicleBrand);
                await this.handleVehicleModelYearChange(this.props.vehicleModelYear);
                await this.handleVehicleModelChange(this.props.vehicleModel);
                this.props.vehicleOptionals.map(id =>
                    this.setState(prevState => ({
                        checkedOptionals: prevState.checkedOptionals.set(id, true),
                    }))
                );
            });
        }
    }

    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    handleImageChange = e => {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = error => console.log(error);
        reader.onload = upload => {
            if (this.props.highlightImage.id)
                this.props.handleStateChange('galleryDelete', [
                    ...this.props.galleryDelete,
                    this.props.highlightImage.id,
                ]);

            file.path = upload.target.result;
            this.props.handleStateChange('highlightImage', file);
        };
    };

    handleVehicleBrandChange = async brand_id => {
        await this.props.populateAnnounceByBrand(this.props.vehicleType, brand_id);
        this.props.handleStateChange('vehicleBrand', brand_id);
        this.setState({ vehicleBrandError: '' });


        if (this.state.loadOriginalVehicleData) {
            this.handleVehicleModelYearChange(this.props.vehicleModelYear);
        } else {
            // Tem que limpar o ano selecionado e o modelo selecionado.
            this.props.handleStateChange('vehicleModelYear', '');
            this.props.handleStateChange('vehicleModel', '');
        }

    };

    handleVehicleModelYearChange = async year => {
        await this.props.populateAnnounceByYear(this.props.vehicleBrand, year);
        this.props.handleStateChange('vehicleModelYear', year);
        this.setState({ vehicleModelYearError: '' });

        if (this.state.loadOriginalVehicleData)
            this.handleVehicleModelChange(this.props.vehicleModel);
    };

    handleVehicleModelChange = async vehicleModel => {
        console.log('Selecionou modelo ' + vehicleModel);

        let vehicleFipePrice = '';
        await this.props.populateAnnounceByFipe(vehicleModel).then(function(data) {
            vehicleFipePrice = data.price;
        });
        /*
        .then(function(data) {
            vehicleFipePrice = data.price;
        })
         */

          const { models } = this.props.fields;

        models.forEach(item => {

            if (Number(item.id) === Number(vehicleModel)) vehicleFipePrice = item.price;
        });

  //      console.log(vehicleFipePrice);

        this.props.handleStateChange('vehicleModel', vehicleModel);
        this.props.handleStateChange('vehicleFipePrice', vehicleFipePrice);
        this.setState({ vehicleModelError: '' });

        this.handleVehicleDesiredPriceChange(this.props.vehicleDesiredPrice);

        if (this.state.loadOriginalVehicleData) this.setState({ loadOriginalVehicleData: false });
    };

    handleVehicleDesiredPriceChange = vehicleDesiredPrice => {
        const { fields, vehicleFipePrice } = this.props;

        const vehicleFipePercent = 100 - (vehicleDesiredPrice * 100) / vehicleFipePrice;

        if (vehicleFipePercent >= fields.percent && vehicleFipePercent <= 100) {
            this.props.handleStateChange('vehicleFipePercent', vehicleFipePercent);
            this.props.handleStateChange('vehicleDesiredPrice', vehicleDesiredPrice);
            this.setState({ vehicleDesiredPriceError: '' });
        } else if (vehicleFipePercent < fields.percent) {
            this.props.handleStateChange('vehicleFipePercent', vehicleFipePercent);
            this.props.handleStateChange('vehicleDesiredPrice', vehicleDesiredPrice);
            this.setState({
                vehicleDesiredPriceError: `Valor máximo permitido ${intlMonetary.format(
                    vehicleFipePrice * (1 - fields.percent / 100)
                )} (${fields.percent}% FIPE)`,
            });
        } else {
            this.props.handleStateChange('vehicleFipePercent', 0);
            this.props.handleStateChange('vehicleDesiredPrice', vehicleDesiredPrice);
            this.setState({ vehicleDesiredPriceError: 'Este valor não é válido' });
        }
    };

    handleVehicleCostPriceChange = vehicleCostPrice => {
        const { vehicleDesiredPrice } = this.props;

        if (vehicleCostPrice >= vehicleDesiredPrice) {
            this.props.handleStateChange('vehicleCostPrice', vehicleCostPrice);
            this.setState({
                vehicleCostPriceError:
                    'É interessante que este valor seja abaixo do preço de venda',
            });
        } else {
            this.props.handleStateChange('vehicleCostPrice', vehicleCostPrice);
            this.setState({ vehicleCostPriceError: '' });
        }
    };

    incrementDoorsValue = () => {
        const { vehicleDoors } = this.props;

        if (vehicleDoors < 5) this.props.handleStateChange('vehicleDoors', vehicleDoors + 1);
    };

    decrementDoorsValue = () => {
        const { vehicleDoors } = this.props;

        if (vehicleDoors > 2) this.props.handleStateChange('vehicleDoors', vehicleDoors - 1);
    };

    addOptionalToRegister = e => {
        const isChecked = e.target.checked;
        const id = Number(e.target.value);

        let { vehicleOptionals } = this.props;

        if (vehicleOptionals.includes(id))
            vehicleOptionals = vehicleOptionals.filter(item => item !== id);
        else vehicleOptionals.push(id);

        this.props.handleStateChange('vehicleOptionals', vehicleOptionals);
        this.setState(prevState => ({
            checkedOptionals: prevState.checkedOptionals.set(id, isChecked),
        }));
    };

    validateFields = () => {
        this.scrollToTop();
        let validated = true;
        const { vehicleType } = this.props;

        if (this.props.highlightImage && validator.isEmpty(this.props.highlightImage.path)) {
            validated = false;
            this.setState({ imageError: 'Espaço para imagem vazio, selecione uma imagem' });
        }

        if (validator.isEmpty(this.props.vehicleBrand)) {
            validated = false;
            this.setState({ vehicleBrandError: 'Selecione uma marca' });
        }

        if (validator.isEmpty(this.props.vehicleModelYear)) {
            validated = false;
            this.setState({ vehicleModelYearError: 'Selecione um ano' });
        }

        if (validator.isEmpty(this.props.vehicleModel)) {
            validated = false;
            this.setState({ vehicleModelError: 'Selecione um modelo' });
        }

        /*if (this.props.vehicleDesiredPrice > this.props.vehicleFipePrice) {
            validated = false;
            this.setState({ vehicleDesiredPriceError: 'Digite um valor abaixo da tabela FIPE' });
        }*/

        if (
            validator.isEmpty(this.props.vehicleDesiredPrice.toString()) ||
            Number(this.props.vehicleDesiredPrice) === 0
        ) {
            validated = false;
            this.setState({ vehicleDesiredPriceError: 'Digite um preço para venda' });
        }

        if (this.props.vehicleCostPrice > this.props.vehicleDesiredPrice) {
            validated = false;
            this.setState({
                vehicleCostPriceError:
                    'É interessante que este valor seja abaixo do preço de venda',
            });
        }

        if (validator.isEmpty(this.props.vehicleColor)) {
            validated = false;
            this.setState({ vehicleColorError: 'Selecione uma cor' });
        }

        if (vehicleType === 5 && validator.isEmpty(this.props.vehicleEixo)) {
            validated = false;
            this.setState({ vehicleEixoError: 'Selecione um eixo' });
        }

        if (!validator.isNumeric(this.props.vehicleKilometrage)) {
            validated = false;
            this.setState({
                vehicleKilometrageError: 'Digite um valor numérico para a quilometragem',
            });
        }

        if (validator.isEmpty(this.props.vehicleKilometrage)) {
            validated = false;
            this.setState({ vehicleKilometrageError: 'Digite a quilometragem' });
        }

        if (validator.isEmpty(this.props.vehicleDescription)) {
            validated = false;
            this.setState({ vehicleDescriptionError: 'Você deve fornecer uma descrição' });
        }

        return validated;
    };

    render() {
        const { fields, loading, update } = this.props;

        return (
            <React.Fragment>
                <h2 className="CadastroRepasse-title">
                    {!update ? 'Informe os detalhes do veículo' : 'Edite os detalhes do veículo'}
                </h2>

                <h3 className="CadastroRepasse-subtitle">
                    Selecione uma foto do veículo para ser o avatar deste repasse.
                </h3>

                <SpinnerFull
                    size={40}
                    spinnerColor={'#335180'}
                    spinnerWidth={3}
                    visible={loading}
                />

                <div className="CadastroRepasse-image-upload-big">
                    <label className="image-upload-label" htmlFor="form-vehicle-highlight-image">
                        <img
                            style={
                                this.props.highlightImage && this.props.highlightImage.path
                                    ? { width: 300, height: 'auto' }
                                    : null
                            }
                            className="image-upload-icon"
                            src={
                                this.props.highlightImage && this.props.highlightImage.path
                                    ? this.props.highlightImage.path
                                    : iconPicture
                            }
                            alt=""
                        />
                        {!this.props.highlightImage.path ? (
                            <Fragment>
                                <div className="image-upload-title">
                                    Adicionar foto paisagem (16:9)
                                </div>
                                <div className="image-upload-subtitle">
                                    Clique aqui para procurar em seu dispositivo.
                                </div>
                            </Fragment>
                        ) : null}
                    </label>
                    <input
                        className="image-upload-input"
                        type="file"
                        name=""
                        accept="image/*"
                        id="form-vehicle-highlight-image"
                        onChange={e => {
                            this.handleImageChange(e);
                            this.setState({ imageError: '' });
                        }}
                    />
                </div>

                {/* Mensagem de erro */}
                <div style={this.style.messageBoxContainer}>
                    {this.state.imageError ? (
                        <MessageBox type="danger" inlineBlock>
                            {/* A foto enviada não está no formato aceito pela plataforma (16:9) */}
                            {this.state.imageError}
                        </MessageBox>
                    ) : null}
                </div>

                <h3 className="CadastroRepasse-subtitle">
                    Publique seu veículo usando a tabela FIPE
                </h3>

                <div className="CadastroRepasse-form-container">
                    <div className="form-row">
                        <div className="form-col--4">
                            <FormGroupSelect
                                id="form-vehicle-brand"
                                label="Marcas"
                                disabled={update}
                                onChange={e => this.handleVehicleBrandChange(e.target.value)}
                                value={this.props.vehicleBrand}
                                options={
                                    fields.brands &&
                                    fields.brands.map(item => ({
                                        value: item.id,
                                        content: item.name,
                                    }))
                                }
                                errorMessage={this.state.vehicleBrandError}
                                placeholder="Selecione"
                            />
                        </div>

                        <div className="form-col--3">
                            <FormGroupSelect
                                id="form-vehicle-model-year"
                                label="Ano/Modelo"
                                disabled={update}
                                onChange={e => this.handleVehicleModelYearChange(e.target.value)}
                                value={this.props.vehicleModelYear}
                                options={
                                    fields.years &&
                                    fields.years.map(item => ({
                                        value: item,
                                        content: item,
                                    }))
                                }
                                errorMessage={this.state.vehicleModelYearError}
                                placeholder="Selecione"
                            />
                        </div>
                        <div className="form-col--5">
                            <FormGroupSelect
                                id="form-vehicle-model"
                                label="Modelo"
                                disabled={update}
                                onChange={e => {
                                    this.handleVehicleModelChange(e.target.value);
                                }}
                                value={this.props.vehicleModel}
                                options={
                                    fields.models &&
                                    fields.models.map(item => ({
                                        value: item.id,
                                        content: item.model,
                                    }))
                                }
                                errorMessage={this.state.vehicleModelError}
                                placeholder="Selecione"
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col--3">
                            <FormGroupInputMoney
                                id="form-vehicle-desired-price"
                                label="Preço desejado para venda"
                                onChange={this.handleVehicleDesiredPriceChange}
                                value={this.props.vehicleDesiredPrice}
                                errorMessage={this.state.vehicleDesiredPriceError}
                            />
                        </div>

                        <div className="form-col--3">
                            <FormGroupInputMoney
                                id="form-vehicle-cost-price"
                                label="Custo do Repasse"
                                onChange={this.handleVehicleCostPriceChange}
                                value={this.props.vehicleCostPrice}
                                errorMessage={this.state.vehicleCostPriceError}
                            />
                        </div>
                        <div className="form-col--2">
                            <img src={logoFipe} alt="Fipe" className="LogoFipe" />
                            <FormGroupInput
                                id="form-vehicle-fipe"
                                label=""
                                readOnly={true}
                                value={intlMonetary.format(this.props.vehicleFipePrice)}
                            />
                        </div>

                        <div className="form-col--3 PorcentagemFipe">
                            <BarraPorcentagem
                                valorPorcentagem={
                                    validator.isNumeric(this.props.vehicleFipePercent.toString())
                                        ? Number(this.props.vehicleFipePercent.toFixed(2))
                                        : 0
                                }
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col--4">
                            <FormGroupSelect
                                id="form-vehicle-color"
                                label="Cor"
                                onChange={e => {
                                    this.props.handleStateChange('vehicleColor', e.target.value);
                                    this.setState({ vehicleColorError: '' });
                                }}
                                value={this.props.vehicleColor}
                                options={
                                    fields.colors &&
                                    fields.colors.map(item => ({
                                        value: item.id,
                                        content: item.name,
                                    }))
                                }
                                errorMessage={this.state.vehicleColorError}
                                placeholder="Selecione"
                            />
                        </div>

                        {/* Se o tipo do veículo for caminhão */}
                        {this.props.vehicleType === 5 && (
                            <div className="form-col--3">
                                <FormGroupSelect
                                    id="form-vehicle-color"
                                    label="Eixo"
                                    onChange={e => {
                                        this.props.handleStateChange('vehicleEixo', e.target.value);
                                        this.setState({ vehicleEixoError: '' });
                                    }}
                                    value={this.props.vehicleEixo}
                                    options={
                                        fields.eixos &&
                                        fields.eixos.map(item => ({
                                            value: item,
                                            content: item,
                                        }))
                                    }
                                    errorMessage={this.state.vehicleEixoError}
                                    placeholder="Selecione"
                                />
                            </div>
                        )}

                        {/* Se o tipo do veículo não for moto (for carro ou caminhão) */}
                        {this.props.vehicleType !== 8 && (
                            <div className="form-col--2">
                                <FormGroupInputNumber
                                    id="form-vehicle-doors"
                                    label="Portas"
                                    incrementValue={() => this.incrementDoorsValue()}
                                    decrementValue={() => this.decrementDoorsValue()}
                                    value={this.props.vehicleDoors}
                                    min={2}
                                    max={5}
                                />
                            </div>
                        )}

                        <div className="form-col--3">
                            <FormGroupInput
                                id="form-vehicle-kilometrage"
                                label="Quilometragem"
                                appendContent="Km"
                                appendPosition="right"
                                type="number"
                                onChange={e => {
                                    this.props.handleStateChange(
                                        'vehicleKilometrage',
                                        e.target.value
                                    );
                                    this.setState({ vehicleKilometrageError: '' });
                                }}
                                value={this.props.vehicleKilometrage}
                                errorMessage={this.state.vehicleKilometrageError}
                            />
                        </div>
                    </div>
                </div>

                <h3 className="CadastroRepasse-subtitle">
                    Selecione os opcionais presentes neste veículo
                </h3>

                <div className="CadastroRepasse-options-list">
                    {fields.optional &&
                        fields.optional.map((item, index) => {
                            return (
                                <CheckboxGroup
                                    label={item.name}
                                    value={item.id}
                                    id={item.id}
                                    key={index}
                                    checked={this.state.checkedOptionals.get(item.id)}
                                    onChange={this.addOptionalToRegister}
                                />
                            );
                        })}
                </div>

                <h3
                    className="CadastroRepasse-subtitle margin-top--big margin-bottom"
                    style={this.state.vehicleDescriptionError ? { color: '#f4516c' } : null}
                >
                    Escreva uma breve descrição sobre o veículo
                </h3>

                <textarea
                    className="form-control"
                    style={
                        this.state.vehicleDescriptionError
                            ? { borderColor: '#f4516c', borderWidth: 1 }
                            : null
                    }
                    name=""
                    id=""
                    rows="7"
                    placeholder="Sua descrição..."
                    onChange={e => {
                        this.props.handleStateChange('vehicleDescription', e.target.value);
                        this.setState({ vehicleDescriptionError: '' });
                    }}
                    value={this.props.vehicleDescription}
                />

                {this.state.vehicleDescriptionError && (
                    <div className="form-validation-danger is-visible">
                        <FontAwesomeIcon icon="exclamation-circle" />{' '}
                        {this.state.vehicleDescriptionError}
                    </div>
                )}

                <div
                    className="CadastroRepasse-navigation margin-top"
                    style={!update ? null : { float: 'right' }}
                >
                    {!update && (
                        <button className="button primary outline" onClick={this.props.prevStep}>
                            <FontAwesomeIcon className="navigation-icon" icon="chevron-left" />
                            Tipo de veículo
                        </button>
                    )}
                    <button
                        className="button primary"
                        onClick={() => (this.validateFields() ? this.props.nextStep() : null)}
                    >
                        Galeria de fotos
                        <FontAwesomeIcon className="navigation-icon" icon="chevron-right" />
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ repasseCreate }) => ({
    loading: repasseCreate.loading,
    fields: repasseCreate.fields,
});

export default connect(
    mapStateToProps,
    {
        populateAnnounceByType,
        populateAnnounceByBrand,
        populateAnnounceByYear,
        populateAnnounceByFipe,
    }
)(CRVehicleDetails);
