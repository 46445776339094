import React from 'react';
import classNames from 'classnames';

import './style.sass';

const ReportStatusItem = props => {
    const { bgColor = '', color = '', border = '' } = props;

    const containerClass = classNames({
        ReportStatusItem: 'true',
        big: props.big,
    });

    return (
        <div className={containerClass} style={{ color, backgroundColor: bgColor, border }}>
            <div className="ReportStatusItem-content">{props.content}</div>
            <div className="ReportStatusItem-title">{props.title}</div>
        </div>
    );
};

export default ReportStatusItem;
