import React, { Component } from 'react';
import { connect } from 'react-redux';
// import './style.sass';

import { Link } from 'react-router-dom';
import RepasseInfo from '../RepasseInfo';
import RepasseValores from '../RepasseValores';

import carImage from '../../assets/images/placeholder-car.jpg';

const intlMonetary = new Intl.NumberFormat('pt-BR', {
    // style: 'currency',
    // currency: 'BRL',
    minimumFractionDigits: 2,
});
class CRFinish extends Component {
    state = {
        vehicleColor: '',
        vehicleModel: {},
    };

    componentDidMount() {
        this.getObjectsById();
    }

    getObjectsById = () => {
        const { fields } = this.props;

        let vehicleColor = '';
        let vehicleModel = {};

        fields.colors.forEach(item => {
            if (Number(item.id) === Number(this.props.vehicleColor)) vehicleColor = item.name;
        });

        fields.models.forEach(item => {
            if (Number(item.id) === Number(this.props.vehicleModel)) vehicleModel = item;
        });

        this.setState({ vehicleColor, vehicleModel });
    };

    render() {
        return (
            <React.Fragment>
                <h2 className="CadastroRepasse-title">
                    {!this.props.update
                        ? 'Repasse cadastrado com sucesso'
                        : 'Repasse editado com sucesso'}
                </h2>
                <div className="CadastroRepasse-Confirmation">
                    <div className="CadastroRepasse__summary">
                        <div className="summary-header">
                            <div className="summary-header__image-container">
                                <img
                                    src={
                                        this.props.highlightImage && this.props.highlightImage.path
                                            ? this.props.highlightImage.path
                                            : carImage
                                    }
                                    className="summary-header__image"
                                    alt=""
                                />
                            </div>

                            <div className="summary-header__info-container">
                                <RepasseInfo
                                    tituloRepasse={this.state.vehicleModel.model}
                                    ano={this.props.vehicleModelYear}
                                    combustivel={this.state.vehicleModel.fuel}
                                    cor={this.state.vehicleColor}
                                />
                                <RepasseValores
                                    valorPorcentagem={Number(
                                        this.props.vehicleFipePercent.toFixed(2)
                                    )}
                                    valorRepasse={intlMonetary.format(
                                        this.props.vehicleDesiredPrice
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="CadastroRepasse-navigation margin-top">
                    <Link
                        to={`/repasses/${this.props.car_id}/${this.props.user_id}`}
                        className="button primary"
                        onClick={this.props.nextStep}
                    >
                        Ver repasse
                    </Link>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ auth: { user }, repasseCreate }) => ({
    user_id: user.id,
    fields: repasseCreate.fields,
});

export default connect(
    mapStateToProps,
    {}
)(CRFinish);
