import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * @param {string} props.icon Nome do ícone (fontawesome)
 * @param {string} props.iconColor Cor do ícone
 * @param {string} props.text Mensagem de confirmação
 */
const ModalConfirmation = props => {
    const { iconColor = '#549e00', icon = 'check' } = props;

    const containerStyle = {
        padding: '3rem 0',
    };

    const iconStyle = {
        color: iconColor,
        fontSize: '3rem',
    };

    const iconContainerStyle = {
        marginBottom: '2rem',
        textAlign: 'center',
    };

    const textStyle = {
        fontSize: '1.6rem',
        textAlign: 'center',
    };

    return (
        <div style={containerStyle}>
            <div style={iconContainerStyle}>
                <FontAwesomeIcon style={iconStyle} icon={icon} />
            </div>
            <div style={textStyle}>{props.text}</div>
        </div>
    );
};

export default ModalConfirmation;
