import React from 'react';

import './style.sass';

const CardConteudo = props => {
    const style = {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '4px',
        boxShadow: '0 1px 2px rgba(0, 0 ,0 , .1)',
    };

    if (props.padding) {
        style.padding = props.padding;
    }

    return (
        <div className="CardConteudo" style={style}>
            {props.children}
        </div>
    );
};

export default CardConteudo;
