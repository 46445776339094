import React from 'react';
import InteressadoHeader from '../InteressadoHeader';
import InteressadoRow from '../InteressadoRow';

import './style.sass';

const TabContentInteressados = props => {
    return (
        <div className="TabContentInteressados">
            {props.data && props.data.length ? (
                <InteressadoHeader numberInterested={props.data.length ? props.data.length : 0} />
            ) : (
                <div className="container-empty-list">
                    Seu anúncio ainda não possui usuários interessados.
                </div>
            )}
            <div className="InteressadoRow-listing">
                {props.data &&
                    props.data.length > 0 &&
                    props.data.map((item, key) => (
                        <InteressadoRow
                            key={key}
                            name={item.client.name}
                            phoneNumber={item.client.phone}
                            hasRequestedNumber={item.call}
                            hasNegociated={item.chat}
                            proposal={item.bid}
                            onChatClick={() => props.onChatClick(item.room_id)}
                        />
                    ))}
            </div>
        </div>
    );
};

export default TabContentInteressados;
