import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.sass';

export default class BoxChatTitleBar extends Component {
    render() {
        return (
            <div className="titulo">
                <div className="nome-usuario">{this.props.nomeUsuario}</div>
                {this.props.valorProposta && (
                    <div className="info">
                        <div className="icone">
                            <FontAwesomeIcon icon="dollar-sign" />
                        </div>
                        <div className="proposta">Proposta: {this.props.valorProposta}</div>
                        <div className="icone">
                            <FontAwesomeIcon icon="info-circle" />
                        </div>

                        <div className="icone">
                            <FontAwesomeIcon icon="ellipsis-v" />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
