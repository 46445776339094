import React, { Component } from 'react';
import './style.sass';
import CadastroUsuarioContainer from '../../components/CadastroUsuarioContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { createUser, updateUser } from '../../store/userCreate/actions';
import DescricaoPlano from '../../components/DescricaoPlano';
import Loader from '../../components/Loader';
import MessageBox from '../../components/MessageBox';

class CadastroUsuarioQuatro extends Component {
    state = {
        token: '',
    };

    async componentDidMount() {
        if (this.props.user.currentStep === 0 || this.props.user.currentStep < 4)
            this.props.history.push('/cadastro-usuario-tres');

        const token = await localStorage.getItem('@Repasse:token');

        if (token) this.setState({ token });
    }

    render() {
        const { user } = this.props;

        return (
            <div>
                <CadastroUsuarioContainer
                    currentStep={user.hasOptionPlano ? 4 : 3}
                    tituloPagina={user.update ? 'Atualização de cadastro' : 'Cadastro'}
                >
                    <div className="cadastro-usuario-quatro">
                        <div className="area-cadastro">
                            <div className="subtitulo">
                                LOJA E DADOS PESSOAIS
                                <Link to="/cadastro-usuario-dois">
                                    <FontAwesomeIcon className="icon-pencil" icon="pencil-alt" />
                                </Link>
                            </div>
                            <div className="topo">
                                <div className="foto">
                                    <img src={user.photo} alt="" />
                                </div>

                                {((user.user_type !== '3' && user.user_type !== '4') ||
                                    user.hasOptionPlano) && (
                                    <div className="info-lateral">
                                        <div className="tipo">Concessionária</div>
                                        <div className="nome">{user.dealership_company_name}</div>
                                    </div>
                                )}
                            </div>

                            <div className="linha-info">
                                <div className="info-item">
                                    <div className="info-titulo">Nome do responsável</div>
                                    <div className="info-conteudo">{user.name}</div>
                                </div>
                            </div>

                            <div className="linha-info">
                                <div className="info-item">
                                    <div className="info-titulo">Email</div>
                                    <div className="info-conteudo">{user.email}</div>
                                </div>

                                <div className="info-item">
                                    <div className="info-titulo">CPF</div>
                                    <div className="info-conteudo">{user.document}</div>
                                </div>

                                <div className="info-item">
                                    <div className="info-titulo">Telefone Fixo</div>
                                    <div className="info-conteudo">{user.phone}</div>
                                </div>

                                <div className="info-item">
                                    <div className="info-titulo">Celular</div>
                                    <div className="info-conteudo">{user.cell_phone}</div>
                                </div>
                            </div>
                        </div>
                        <div className="area-cadastro">
                            <div className="subtitulo">
                                Localização
                                <Link to="/cadastro-usuario-dois">
                                    <FontAwesomeIcon className="icon-pencil" icon="pencil-alt" />
                                </Link>
                            </div>

                            <div className="linha-info">
                                <div className="info-item">
                                    <div className="info-titulo">Logradouro</div>
                                    <div className="info-conteudo">{user.address}</div>
                                </div>

                                <div className="info-item">
                                    <div className="info-titulo">Número</div>
                                    <div className="info-conteudo">{user.number}</div>
                                </div>

                                <div className="info-item">
                                    <div className="info-titulo">Bairro</div>
                                    <div className="info-conteudo">{user.neighborhood}</div>
                                </div>

                                <div className="info-item">
                                    <div className="info-titulo">Cidade</div>
                                    <div className="info-conteudo">{user.city}</div>
                                </div>

                                <div className="info-item">
                                    <div className="info-titulo">Estado</div>
                                    <div className="info-conteudo">{user.state}</div>
                                </div>

                                <div className="info-item">
                                    <div className="info-titulo">CEP</div>
                                    <div className="info-conteudo">{user.zipcode}</div>
                                </div>
                            </div>
                        </div>
                        {((user.user_type !== '3' && user.user_type !== '4') ||
                            user.hasOptionPlano) && (
                            <div className="area-cadastro">
                                <div className="subtitulo">
                                    Dados da loja
                                    <Link to="/cadastro-usuario-dois">
                                        <FontAwesomeIcon
                                            className="icon-pencil"
                                            icon="pencil-alt"
                                        />
                                    </Link>
                                </div>

                                <div className="linha-info">
                                    <div className="info-item">
                                        <div className="info-titulo">Razão Social</div>
                                        <div className="info-conteudo">
                                            {user.dealership_company_name}
                                        </div>
                                    </div>

                                    <div className="info-item">
                                        <div className="info-titulo">Nome fantasia</div>
                                        <div className="info-conteudo">
                                            {user.dealership_trade_name}
                                        </div>
                                    </div>

                                    <div className="info-item">
                                        <div className="info-titulo">CNPJ</div>
                                        <div className="info-conteudo">{user.dealership_cnpj}</div>
                                    </div>
                                </div>

                                <div className="linha-info">
                                    <div className="info-item">
                                        <div className="info-titulo">Logradouro</div>
                                        <div className="info-conteudo">
                                            {user.dealership_street}
                                        </div>
                                    </div>

                                    <div className="info-item">
                                        <div className="info-titulo">Número</div>
                                        <div className="info-conteudo">
                                            {user.dealership_number}
                                        </div>
                                    </div>

                                    <div className="info-item">
                                        <div className="info-titulo">Bairro</div>
                                        <div className="info-conteudo">
                                            {user.dealership_neighborhood}
                                        </div>
                                    </div>

                                    <div className="info-item">
                                        <div className="info-titulo">Cidade</div>
                                        <div className="info-conteudo">
                                            {user.dealership_city_id}
                                        </div>
                                    </div>

                                    <div className="info-item">
                                        <div className="info-titulo">Estado</div>
                                        <div className="info-conteudo">{user.dealership_state}</div>
                                    </div>

                                    <div className="info-item">
                                        <div className="info-titulo">CEP</div>
                                        <div className="info-conteudo">
                                            {user.dealership_zipcode}
                                        </div>
                                    </div>

                                    <div className="info-item">
                                        <div className="info-titulo">Complemento</div>
                                        <div className="info-conteudo">
                                            {user.dealership_complement}
                                        </div>
                                    </div>

                                    <div className="info-item">
                                        <div className="info-titulo">Ponto de referência</div>
                                        <div className="info-conteudo">
                                            {user.dealership_landmark}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {user.hasOptionPlano && user.plan && (
                            <div className="area-cadastro">
                                <div className="subtitulo">
                                    Plano
                                    <Link to="/cadastro-usuario-tres">
                                        <FontAwesomeIcon
                                            className="icon-pencil"
                                            icon="pencil-alt"
                                        />
                                    </Link>
                                </div>

                                <DescricaoPlano
                                    tituloPlano={user.plan.title}
                                    valorPlano={user.plan.price}
                                    transacoesMensais={user.plan.qty_transactions}
                                    valorAdicionalTransacao={user.plan.transaction_price}
                                    valorConsulta="60"
                                    quantidadeVoucher="1"
                                />
                            </div>
                        )}{' '}
                        {this.props.error && (
                            <div style={{ marginBottom: 15 }}>
                                <MessageBox type="danger" size="normal">
                                    {this.props.error}
                                </MessageBox>
                            </div>
                        )}
                        <div className="container-botao">
                            <button
                                className="button primary"
                                onClick={() =>
                                    !this.props.user.id
                                        ? this.props.createUser(user)
                                        : this.props.updateUser(user)
                                }
                            >
                                {this.props.loading ? (
                                    <div style={{ width: 100 }}>
                                        <Loader />
                                    </div>
                                ) : (
                                    'Concluir Cadastro'
                                )}
                            </button>
                        </div>
                    </div>
                </CadastroUsuarioContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, userCreate }) => ({
    loading: userCreate.loading,
    error: userCreate.error,
    user: userCreate.user,
    isAuthenticated: auth.isAuthenticated,
});

const mapDispatchToProps = dispatch => bindActionCreators({ createUser, updateUser }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CadastroUsuarioQuatro);
