import { call, fork, put, select } from 'redux-saga/effects';

import { Creators } from '../ducks/repassesList';
import api from '../../services/api';

/** Carregamento da lista de repasses */
export function* requestRepasses() {
    try {
        const {
            data: { badge, data },
        } = yield call(api.post, '/api/v2/purchase', {
            page: 1,
        });

        if (!data.length || data.length === badge) yield put(Creators.setNothingToLoad(true));
        else yield put(Creators.setNothingToLoad(false));

        yield put(Creators.setRepasses(badge, 1, data));

        yield call(populateSearch);
        yield fork(populateSearchByType, { type_id: 0 });
    } catch (error) {
        console.log(error);
    } finally {
        yield put(Creators.setLoading(false));
    }
}

/** Carregamento de mais repasses de acordo com paginação */
export function* requestMoreRepasses({ page }) {
    try {
        yield put(Creators.setLoading(true));

        const {
            data: { badge, data },
        } = yield call(api.post, '/api/v2/purchase', {
            page,
        });

        if (!data.length) yield put(Creators.setNothingToLoad(true));
        else yield put(Creators.setNothingToLoad(false));

        const { repasses } = yield select(state => state.repassesList);

        yield put(Creators.setRepasses(badge, page, [...repasses, ...data]));
    } catch (error) {
        console.log(error);
    } finally {
        yield put(Creators.setLoading(false));
    }
}

/** Toggle do favorito na página inicial  */
export function* toggleRepasseFavorite({ id_repasse }) {
    try {
        yield call(api.get, `/api/v2/favorites/${id_repasse}/change`);

        yield put(Creators.setRepasseFavorite(id_repasse));
    } catch (error) {
        console.log(error);
    }
}

/** Popula campos para busca de repasses */
export function* populateSearch() {
    try {
        const {
            data: { data },
        } = yield call(api.get, '/api/v2/search/populate');

        yield put(Creators.setSearch(data));
    } catch (error) {
        console.log(error);
    }
}

/** Popula campos por tipo de veículo (moto, carro, caminhão) */
export function* populateSearchByType({ type_id }) {
    try {
        const {
            data: {
                data: { brands, models, optional },
            },
        } = yield call(api.get, `/api/v2/search/populate/type/${type_id}`);

        yield put(Creators.setSearchByType(brands, models, optional));
    } catch (error) {
        console.log(error);
    }
}

/** Popula campos por marca */
export function* populateSearchByBrand({ brand_id }) {
    try {
        const {
            data: {
                data: { models },
            },
        } = yield call(api.get, `/api/v2/search/populate/models/${brand_id}`);

        yield put(Creators.setSearchByBrand(models));
    } catch (error) {
        console.log(error);
    }
}

/** Popula campos por estado */
export function* populateSearchByState({ state_id }) {
    try {
        const {
            data: { data },
        } = yield call(api.get, `/api/ddd/${state_id}`);

        yield put(Creators.setSearchByState(data));
    } catch (error) {
        console.log(error);
    }
}

/** Busca de repasses a partir dos parâmetros setados pelo usuário */
export function* buscarRepasses({ toSearch }) {
    try {
        yield put(Creators.setLoading(true));

        const {
            data: { total, data },
        } = yield call(api.post, `/api/v2/search`, toSearch);

        if (!data.length || data.length === total) yield put(Creators.setNothingToLoad(true));
        else yield put(Creators.setNothingToLoad(false));

        yield put(Creators.setRepasses(total, 1, data));
    } catch (error) {
        console.log(error);
    } finally {
        yield put(Creators.setLoading(false));
    }
}

/** Carregamento de mais repasses de acordo com paginação e pelo endpoint de busca */
export function* loadMoreFromSearch({ toSearch }) {
    try {
        yield put(Creators.setLoading(true));

        const {
            data: { total, data },
        } = yield call(api.post, `/api/v2/search`, toSearch);

        if (!data.length) yield put(Creators.setNothingToLoad(true));
        else yield put(Creators.setNothingToLoad(false));

        const { repasses } = yield select(state => state.repassesList);
        yield put(Creators.setRepasses(total, toSearch.page, [...repasses, ...data]));
    } catch (error) {
        console.log(error);
    } finally {
        yield put(Creators.setLoading(false));
    }
}
