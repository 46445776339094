import React from 'react';

import './style.sass';

const CadastroProgressBar = props => {
    const { currentStep = 1 } = props;

    const baseClass = 'CadastroProgressBar-item';
    const pastClass = 'CadastroProgressBar-item past';
    const currentClass = 'CadastroProgressBar-item current';

    return (
        <div className="CadastroProgressBar">
            {props.steps.map((item, index) => {
                let itemClass = currentClass;

                if (currentStep < index + 1) {
                    itemClass = baseClass;
                } else if (currentStep > index + 1) {
                    itemClass = pastClass;
                }

                return (
                    <React.Fragment key={index + 1}>
                        <div className={itemClass}>
                            <div className="CadastroProgressBar-item-text">{item}</div>
                            <div className="CadastroProgressBar-item-bar" />
                        </div>

                        <div className="CadastroProgressBar-standalone-text">{item}</div>
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default CadastroProgressBar;
