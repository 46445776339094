import React, { Component } from 'react';
import instagram from '../../assets/insta.png';
import facebook from '../../assets/facebook.png';
import twitter from '../../assets/twitter.png';

// Style
import './style.sass';

export default class SocialBar extends Component {
    render() {
        return (
            <div className="social-bar">
                <div className="container-social">
                    <a
                        className="social-item"
                        href="https://www.facebook.com/AplicativoRepasse"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={facebook} alt="Red Dragon" />
                    </a>
                    <a
                        className="social-item"
                        href="https://twitter.com/RepasseApp"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={twitter} alt="Red Dragon" />
                    </a>
                    <a
                        className="social-item"
                        href="https://www.instagram.com/AplicativoRepasse"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={instagram} alt="Red Dragon" />
                    </a>
                </div>
            </div>
        );
    }
}
