import React from 'react';
import { Link } from 'react-router-dom';

import './style.sass';

const intlMonetary = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
});

const RepasseInteressados = props => {
    const values = props.values;

    return (
        <div className="RepasseInteressados">
            <div className="RepasseInteressados-title">
                <div className="RepasseInteressados-number">{props.interestedNumber}</div>
                <div className="RepasseInteressados-text">
                    {Number(props.interestedNumber) === 1 ? 'interessado' : 'interessados'}.
                </div>

                {Number(props.interestedNumber) > 0 && (
                    <Link
                        className="RepasseInteressados-link"
                        to={{
                            pathname: `/repasses/${props.idRepasse}/${props.idUsuario}`,
                            state: { selectedTab: 1 },
                        }}
                    >
                        Ver Todos
                    </Link>
                )}
            </div>

            <div className="RepasseInteressados-item-listing">
                {values.map(
                    (item, index) =>
                        item && (
                            <div className="RepasseInteressados-item" key={index}>
                                <div className="RepasseInteressados-item-number">{index + 1}</div>
                                <div className="RepasseInteressados-item-value">
                                    {intlMonetary.format(item)}
                                </div>
                            </div>
                        )
                )}
            </div>
        </div>
    );
};

export default RepasseInteressados;
