const validateCPF = cpf => {
    if ((cpf = cpf.replace(/[^\d]/g, '')).length !== 11) return false;

    if (
        cpf === '00000000000' ||
        cpf === '11111111111' ||
        cpf === '22222222222' ||
        cpf === '33333333333' ||
        cpf === '44444444444' ||
        cpf === '55555555555' ||
        cpf === '66666666666' ||
        cpf === '77777777777' ||
        cpf === '88888888888' ||
        cpf === '99999999999'
    )
        return false;

    let r;
    let s = 0;

    for (let i = 1; i <= 9; i++) s = s + parseInt(cpf[i - 1], 10) * (11 - i);

    r = (s * 10) % 11;

    if (r === 10 || r === 11) r = 0;

    if (r !== parseInt(cpf[9], 10)) return false;

    s = 0;

    for (let i = 1; i <= 10; i++) s = s + parseInt(cpf[i - 1], 10) * (12 - i);

    r = (s * 10) % 11;

    if (r === 10 || r === 11) r = 0;

    if (r !== parseInt(cpf[10], 10)) return false;

    return true;
};

export { validateCPF };
