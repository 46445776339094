import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import TopBar from '../../components/TopBar';
import CardConteudo from '../../components/CardConteudo';
import RepasseInfo from '../../components/RepasseInfo';
import RepasseValores from '../../components/RepasseValores';
import BotaoCarregarMais from '../../components/BotaoCarregarMais';
import SpinnerFull from '../../components/SpinnerFull';
import ActionButton from '../../components/ActionButton';

import { Creators as RepassesListActions } from '../../store/ducks/repassesList';

import api, { baseURL } from '../../services/api';

import './style.sass';

const intlMonetary = new Intl.NumberFormat('pt-BR', {
    // style: 'currency',
    // currency: 'BRL',
    minimumFractionDigits: 2,
});

class PageEstoqueRepasses extends Component {
    state = {
        status: [
            { id: '1', name: 'Em aberto' },
            { id: '4', name: 'Em cadastro' },
            { id: '3', name: 'Vendido' },
        ],
        userTypes: [
            { id: '2', content: 'Diretor' },
            { id: '3', content: 'Gerente' },
            { id: '4', content: 'Vendedor' },
        ],
        repasses: [],
        loading: false,
        page: 1,
        cantLoad: false,
        stockId: '',
        user: '',
        totalRepasses: 0,
    };

    constructor(props) {
        super(props);

        this.initialState = this.state;
    }

    async componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        if (this.props.match.params && this.props.match.params.user_id) {
            const { user_id } = this.props.match.params;

            this.getStock(user_id);
        }
    }

    getStock = async stockId => {
        let { page, repasses } = this.state;

        try {
            this.setState({ loading: true });

            const response = await api.post(`/api/v2/search/`, { stock_id: stockId });

            const cantLoad = response.data.total < page * 10 ? true : false;

            if (page === 1) repasses = response.data.data;
            else repasses = repasses.concat(response.data.data);

            this.setState({
                repasses,
                page: page + 1,
                cantLoad,
                stockId,
                user: repasses[0].user,
                totalRepasses: response.data.total,
            });
        } catch ({ response }) {
            console.log(response);
            this.setState({ cantLoad: true });
        } finally {
            this.setState({ loading: false });
        }
    };

    timeElapsed = start_date => {
        const start = new Date(start_date);
        const end = new Date();

        const timeDiff = (end - start) / 1000;
        const seconds = Math.round(timeDiff);

        return Math.floor(seconds / 86400);
    };

    toggleFavorite = id => {
        this.setState({
            repasses: this.state.repasses.map(item =>
                item.id === id ? { ...item, is_favorite: !item.is_favorite } : item
            ),
        });

        this.props.toggleRepasseFavorite(id);
    };

    hasPermissionToFavorite = item =>
        item.user.id !== this.props.user.id &&
        (item.car_status.id === 1 || item.car_status.id === 9);

    render() {
        const { page, loading, repasses, cantLoad, stockId, user, totalRepasses } = this.state;

        return (
            <>
                <TopBar />

                <Helmet>
                    <title>Estoque de Repasses • Repasse</title>
                </Helmet>
                <div className="page-container">
                    <div className="content-container">
                        <SpinnerFull
                            size={40}
                            spinnerColor={'#335180'}
                            spinnerWidth={3}
                            visible={loading}
                        />

                        <div className="header-page">
                            <div className="header-page-actions">
                                <div
                                    className="header-page-action"
                                    onClick={() => this.props.history.goBack()}
                                >
                                    <ActionButton
                                        nameIcon="chevron-left"
                                        modifierClasses="action-link"
                                        textAction="Voltar"
                                        // actionUrl="/repasses"
                                    />
                                </div>
                            </div>
                        </div>

                        <CardConteudo>
                            <div className="EstoqueContainer">
                                <div className="EstoqueContainer-left">
                                    <div className="GestaoRepasseHeader-title">Vendedor</div>
                                    <div className="EstoqueContainer-Seller-info">
                                        {!loading && user && (
                                            <Fragment>
                                                <div className="Seller-image">
                                                    <img
                                                        src={`${baseURL}/uploads/avatar/${
                                                            user.photo
                                                        }`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="Seller-name">{user.name}</div>

                                                <div className="Seller-address">
                                                    {`${user.type.name}`}
                                                </div>

                                                <div className="Seller-address">
                                                    {`${user.city}, ${user.state}`}
                                                </div>

                                                {/* <div className="Seller-repasses">
                                                    <span>{totalRepasses}</span> repasses
                                                    cadastrados
                                                </div> */}
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                                <div className="EstoqueContainer-right">
                                    <div className="GestaoRepasseHeader-title">
                                        {totalRepasses === 1
                                            ? `${totalRepasses} repasse cadastrado`
                                            : `${totalRepasses} repasses cadastrados`}
                                    </div>
                                    <div className="RepassesListing">
                                        {!loading &&
                                            repasses &&
                                            repasses.length > 0 &&
                                            repasses.map((item, index) =>
                                                this.renderRepasses(item, index)
                                            )}
                                    </div>
                                </div>
                            </div>
                        </CardConteudo>

                        {!cantLoad && (
                            <BotaoCarregarMais
                                onClick={() => {
                                    this.setState({ page: page + 1 }, () => this.getStock(stockId));
                                }}
                            />
                        )}
                    </div>
                </div>
            </>
        );
    }

    renderRepasses = (item, index) => {
        const valorPorcentagem = 100 - (item.price * 100) / item.fipe_price;

        return (
            <div className="RepassesItem" key={index}>
                <Link
                    to={`/repasses/${item.id}/${item.user.id}`}
                    className="RepassesItem-image-container"
                >
                    <img
                        className="RepassesItem-image"
                        src={
                            item.photos &&
                            item.photos[0] &&
                            `${baseURL}/uploads/gallery/${item.photos[0].filename}`
                        }
                        alt=""
                    />
                </Link>

                <div className="RepassesItem-info-car">
                    <RepasseInfo
                        idRepasse={item.id}
                        idUsuario={item.user.id}
                        tituloRepasse={item.fipe.model}
                        ano={item.year}
                        combustivel={item.fipe.fuel}
                        cor={item.color.name}
                        diasNoPatio={item.created_at}
                        marginAuto={true}
                        canEdit={false}
                        canSee={true}
                        statusValue={item.car_status}
                        sellerName={item.user.name}
                        showStockButton={false}
                        canFavorite={this.hasPermissionToFavorite(item)}
                        isFavorite={item.is_favorite}
                        toggleRepasseFavorite={this.toggleFavorite}
                    />
                </div>

                <div className="RepassesItem-info-values">
                    <RepasseValores
                        idRepasse={item.id}
                        idUsuario={item.user.id}
                        valorRepasse={intlMonetary.format(item.price)}
                        valorFipe={intlMonetary.format(item.fipe_price)}
                        valorPorcentagem={Number(valorPorcentagem.toFixed(2))}
                        verRepasseButton={true}
                    />
                </div>
            </div>
        );
    };
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
    storeId: auth.storeId,
    havePlan: auth.havePlan,
});

const mapDispatchToProps = dispatch => bindActionCreators(RepassesListActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageEstoqueRepasses);
