import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import CardConteudo from '../../components/CardConteudo';
import Modal from '../../components/Modal';
import SpinnerFull from '../../components/SpinnerFull';
import TitleAside from '../../components/TitleAside';
import TopBar from '../../components/TopBar';
import api from '../../services/api';
import './style.sass';

class PageConvitesRecebidos extends Component {
    style = {
        headerClearance: {
            paddingTop: '80px',
        },
        pageContainer: {
            backgroundColor: '#eee',
            padding: '40px 0',
        },
        contentContainer: {
            maxWidth: '1200px',
            padding: '0 20px',
            margin: 'auto',
        },
    };

    inputProps = {
        size: 'small',
        labelColor: '#335180',
    };

    modalAccept = React.createRef();
    modalReject = React.createRef();
    modalSuccess = React.createRef();
    modalError = React.createRef();

    state = {
        loading: false,
        invites: [],
        totalInvites: null,
        page: 1,
        error: '',
        success: '',
        acceptName: '',
        acceptId: '',
        inviteIndex: '',
        rejectName: '',
        rejectId: '',
    };

    componentDidMount() {
        this.getInvites();
    }

    getInvites = async () => {
        try {
            this.setState({ loading: true, error: '' });

            const response = await api.get(`/api/v2/store/staff/invites`);

            this.setState({
                invites: response.data.data.items,
                totalInvites: response.data.totals,
            });
        } catch ({ error }) {
            console.log(error);
        } finally {
            this.setState({ loading: false, error: '' });
        }
    };

    acceptInvite = async store_id => {
        try {
            this.setState({ loading: true, error: '' });

            const response = await api.put(`/api/v2/store/${store_id}/accept/invite`);

            const { data } = response;

            this.setState(
                {
                    success: data.msg,
                    invites: this.state.invites.filter(
                        (item, key) => key !== this.state.inviteIndex
                    ),
                },
                () => this.openModalSuccess()
            );
        } catch ({ response }) {
            console.log({ response });

            if (response) {
                const { data } = response;
                this.setState({ error: data.msg }, () => this.openModalError());
            } else {
                this.setState(
                    {
                        error:
                            'Ocorreu um erro na aceitação do convite. Tente novamente mais tarde.',
                    },
                    () => this.openModalError()
                );
            }
        } finally {
            this.closeModalAccept();
            this.setState({ loading: false });
        }
    };

    openModalAccept = () => this.modalAccept.current.openModal();
    closeModalAccept = () => this.modalAccept.current.closeModal();

    rejectInvite = async store_id => {
        try {
            this.setState({ loading: true, error: '' });

            const response = await api.put(`/api/v2/store/${store_id}/reject/invite`);

            const { data } = response;

            this.setState(
                {
                    success: data.msg,
                    invites: this.state.invites.filter(
                        (item, key) => key !== this.state.inviteIndex
                    ),
                },
                () => this.openModalSuccess()
            );
        } catch ({ response }) {
            console.log({ response });

            if (response) {
                const { data } = response;
                this.setState({ error: data.msg }, () => this.openModalError());
            } else {
                this.setState(
                    {
                        error:
                            'Ocorreu um erro na rejeição do convite. Tente novamente mais tarde.',
                    },
                    () => this.openModalError()
                );
            }
        } finally {
            this.closeModalReject();
            this.setState({ loading: false });
        }
    };

    openModalReject = () => this.modalReject.current.openModal();
    closeModalReject = () => this.modalReject.current.closeModal();

    openModalSuccess = () => this.modalSuccess.current.openModal();
    closeModalSuccess = () => this.modalSuccess.current.closeModal();

    openModalError = () => this.modalError.current.openModal();
    closeModalError = () => this.modalError.current.closeModal();

    render() {
        const { loading, invites, acceptId, acceptName, rejectId, rejectName } = this.state;

        return (
            <>
                <TopBar />

                <Helmet>
                    <title>Gestão de Convites • Repasse</title>
                </Helmet>

                <div className="page-container" style={this.style.pageContainer}>
                    <div className="content-container" style={this.style.contentContainer}>
                        <SpinnerFull
                            size={40}
                            spinnerColor={'#335180'}
                            spinnerWidth={3}
                            visible={loading}
                        />

                        <TitleAside title="Gestão de convites recebidos" />

                        <CardConteudo>
                            {!loading && invites && !invites.length ? (
                                <div className="message-empty-list">
                                    <div className="empty-list-title">
                                        Você não tem nenhum convite.
                                    </div>
                                </div>
                            ) : (
                                <div className="gestao-equipe-header">
                                    <div className="gestao-equipe-header-item">Loja</div>
                                </div>
                            )}

                                {invites.length > 0 &&
                                    invites.map((item, key) => (
                                        <div className="GestaoEquipeRow">
                                            <div key={key} className="convite-container">
                                                <div>
                                                    <div className="gestao-equipe-name-container">
                                                        <div className="gestao-equipe-name">
                                                            {item.trade_name}
                                                        </div>
                                                        <div className="gestao-equipe-phones">
                                                            {item.role}
                                                        </div>
                                                        <div className="gestao-equipe-phones">
                                                            {`Convite enviado às ${moment(
                                                                item.created_at
                                                            ).format('HH:mm')} de ${moment(
                                                                item.created_at
                                                            ).format('DD/MM/YYYY')}`}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="convite-buttons-container">
                                                    <button
                                                        className="button primary outline"
                                                        style={{ marginRight: 15 }}
                                                        onClick={() =>
                                                            this.setState(
                                                                {
                                                                    rejectName: item.trade_name,
                                                                    rejectId: item.id,
                                                                    inviteIndex: key,
                                                                },
                                                                () => this.openModalReject()
                                                            )
                                                        }
                                                    >
                                                        Recusar
                                                    </button>
                                                    <button
                                                        className="button primary"
                                                        onClick={() =>
                                                            this.setState(
                                                                {
                                                                    acceptName: item.trade_name,
                                                                    acceptId: item.id,
                                                                    inviteIndex: key,
                                                                },
                                                                () => this.openModalAccept()
                                                            )
                                                        }
                                                    >
                                                        Aceitar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                        </CardConteudo>

                        {/* {!this.state.nothingToLoad && (
                            <div className="pagination-container">
                                <BotaoCarregarMais
                                    onClick={() =>
                                        this.loadMoreStaff(storeId, staff, current_page + 1)
                                    }
                                />
                            </div>
                        )} */}
                    </div>

                    <Modal
                        title="Aceitar convite"
                        customClass="Modal-GestaoEquipe"
                        ref={this.modalAccept}
                    >
                        <Fragment>
                            <SpinnerFull
                                size={40}
                                spinnerColor={'#335180'}
                                spinnerWidth={3}
                                visible={loading}
                            />
                            <div className="convite-action-title">
                                {`Tem certeza que deseja aceitar o convite de ${acceptName}?`}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: 'auto',
                                }}
                            >
                                <button
                                    className="button primary outline margin-right"
                                    style={{ maxWidth: '20rem', width: '100%' }}
                                    onClick={this.closeModalAccept}
                                >
                                    Não
                                </button>
                                <button
                                    onClick={() => this.acceptInvite(acceptId)}
                                    className="button primary"
                                    style={{ maxWidth: '20rem', width: '100%' }}
                                >
                                    Sim
                                </button>
                            </div>{' '}
                        </Fragment>
                    </Modal>

                    <Modal
                        title="Rejeitar convite"
                        customClass="Modal-GestaoEquipe"
                        ref={this.modalReject}
                    >
                        <Fragment>
                            <SpinnerFull
                                size={40}
                                spinnerColor={'#335180'}
                                spinnerWidth={3}
                                visible={loading}
                            />
                            <div className="convite-action-title">
                                {`Tem certeza que deseja rejeitar o convite de ${rejectName}?`}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: 'auto',
                                }}
                            >
                                <button
                                    className="button primary outline margin-right"
                                    style={{ maxWidth: '20rem', width: '100%' }}
                                    onClick={this.closeModalReject}
                                >
                                    Não
                                </button>
                                <button
                                    onClick={() => this.rejectInvite(rejectId)}
                                    className="button primary"
                                    style={{ maxWidth: '20rem', width: '100%' }}
                                >
                                    Sim
                                </button>
                            </div>{' '}
                        </Fragment>
                    </Modal>

                    <Modal title="Sucesso" customClass="Modal-GestaoEquipe" ref={this.modalSuccess}>
                        <Fragment>
                            <div
                                style={{
                                    marginBottom: '2rem',
                                    textAlign: 'center',
                                }}
                            >
                                <FontAwesomeIcon
                                    style={{
                                        color: '#549e00',
                                        fontSize: '3rem',
                                    }}
                                    icon="check"
                                />
                            </div>
                            <div
                                style={{
                                    fontSize: '1.6rem',
                                    textAlign: 'center',
                                }}
                            >
                                {this.state.success}
                            </div>
                        </Fragment>
                    </Modal>

                    <Modal title="Erro" customClass="Modal-GestaoEquipe" ref={this.modalError}>
                        <Fragment>
                            <div
                                style={{
                                    marginBottom: '2rem',
                                    textAlign: 'center',
                                }}
                            >
                                <FontAwesomeIcon
                                    style={{
                                        color: '#549e00',
                                        fontSize: '3rem',
                                    }}
                                    icon="times"
                                />
                            </div>
                            <div
                                style={{
                                    fontSize: '1.6rem',
                                    textAlign: 'center',
                                }}
                            >
                                {this.state.error}
                            </div>
                        </Fragment>
                    </Modal>
                </div>
            </>
        );
    }
}

export default PageConvitesRecebidos;
