import React, { Component } from 'react';
import TopBar from '../../components/TopBar';
import InteressadoRow from '../../components/InteressadoRow';
import InteressadoHeader from '../../components/InteressadoHeader';
import CardConteudo from '../../components/CardConteudo';

export default class MasterPage extends Component {
    showSettings(event) {
        event.preventDefault();
    }

    render() {
        return (
            <>
                <TopBar />
                <div className="page-container">
                    <div className="content-container">
                        <div className="master-page-section">
                            <h4 className="master-page-title">
                                InteressadoHeader e InteressadoRow
                            </h4>

                            <CardConteudo>
                                <InteressadoHeader numberInterested="2" />
                                <InteressadoRow
                                    name="Pedro Britto"
                                    phoneNumber="12341234"
                                    hasRequestedNumber={true}
                                    hasNegociated={true}
                                    proposal="10000"
                                />
                                <InteressadoRow
                                    name="Yan Gondim"
                                    phoneNumber="12341234"
                                    hasRequestedNumber={true}
                                    hasNegociated={false}
                                    proposal="30000"
                                />
                                <InteressadoRow
                                    name="Nigga Man"
                                    avatarUrl="https://picsum.photos/200/200"
                                    phoneNumber="12341234"
                                    hasRequestedNumber={false}
                                    hasNegociated={false}
                                    proposal="120000"
                                />
                            </CardConteudo>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
