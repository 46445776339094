import React from 'react';
import classNames from 'classnames';

import './style.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * @param {string} props.title Título a ser exibido
 * @param {string} props.maxWidth Largura máxima do container do título (innerWidth > 800)
 * @param {HTMLElement} props.children Conteúdo a ser colocado ao lado do título
 * @param {string} props.icon
 * @param {string} props.iconColor
 * @param {image} props.image
 */
const TitleAside = props => {
    const containerClass = classNames('TitleAside', [props.size]);
    return (
        <div className={containerClass}>
            <div className="TitleAside-title">
                {props.icon && (
                    <FontAwesomeIcon
                        className="TitleAside-icon"
                        icon={props.icon}
                        color={props.iconColor}
                    />
                )}

                {props.image && (
                    <div
                        className="TitleAside-image-container"
                        style={{ maxWidth: props.imageMaxWidth }}
                    >
                        <img className="TitleAside-image" src={props.image} alt="" />
                    </div>
                )}
                {props.title}
            </div>
            <div className="TitleAside-aside">{props.children}</div>
        </div>
    );
};

export default TitleAside;
