import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.sass';

const GestaoEquipeHeaderHome = props => {
    return (
        <div className="GestaoEquipeHeaderHome">
            <div className="GestaoEquipeHeaderHome-title-listing">
                <div className="GestaoEquipeHeaderHome-title">
                    Visão de Equipe
                </div>
                <div className="GestaoEquipeHeaderHome-select">
                    Esta semana
                    <div className="dropdown-icon">
                        <FontAwesomeIcon icon="chevron-down" />
                    </div>
                </div>
            </div>

            <div className="GestaoEquipeHeaderHome-item-listing">
                <div className="GestaoEquipeHeaderHome-item">Profissional</div>
                <div className="GestaoEquipeHeaderHome-item">Vendas</div>
                <div className="GestaoEquipeHeaderHome-item">Tempo de Entrega</div>
            </div>
        </div>
    );
};

export default GestaoEquipeHeaderHome;
