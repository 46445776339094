import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import AuthReducer from './ducks/auth';
import ProfileUserReducer from './ducks/profileUser';
import RepasseCreateReducer from './repasseCreate/reducer';
import RepassesListReducer from './ducks/repassesList';
import RepasseViewReducer from './ducks/repasseView';

import UserCreateReducer from './userCreate/reducer';

const authPersistConfig = {
    key: 'auth',
    storage: storage,
    blacklist: ['loading'],
    stateReconciler: autoMergeLevel2,
};

const repassesListPersistConfig = {
    key: 'repassesList',
    storage: storage,
    stateReconciler: autoMergeLevel2,
};

/* const userCreatePersistConfig = {
    key: 'userCreate',
    storage: storage,
    blacklist: ['error', 'loading'],
    stateReconciler: autoMergeLevel2,
}; */

const reducers = history =>
    combineReducers({
        auth: persistReducer(authPersistConfig, AuthReducer),
        profileUser: ProfileUserReducer,
        repasseCreate: RepasseCreateReducer,
        repassesList: persistReducer(repassesListPersistConfig, RepassesListReducer),
        repasseView: RepasseViewReducer,
        // userCreate: persistReducer(userCreatePersistConfig, UserCreateReducer),
        userCreate: UserCreateReducer,
        router: connectRouter(history),
    });

export default reducers;
