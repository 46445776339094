import React, { Component } from 'react';
import classNames from 'classnames';
import './style.sass';

import { baseURL } from '../../services/api';

import moment from 'moment';

export default class MensagemItem extends Component {
    render() {
        const messageItemControl = classNames('mensagem-item-container', {
            right: this.props.right,
        });

        return (
            <div className={messageItemControl}>
                {!this.props.right && (
                    <div className="foto-usuario">
                        <img
                            src={
                                this.props.data.client
                                    ? `${baseURL}/uploads/avatar/${this.props.data.client.photo}`
                                    : ''
                            }
                            alt=""
                        />
                    </div>
                )}
                <div className="conteudo-mensagem">{this.props.children}</div>
                <div className="horario-mensagem">
                    {moment(this.props.data.message_id).format('HH:mm')}
                </div>
            </div>
        );
    }
}
