import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators as RepasseViewActions } from '../../store/ducks/repasseView';
import TopBar from '../../components/TopBar';
import CardConteudo from '../../components/CardConteudo';
import RepasseInfo from '../../components/RepasseInfo';
import RepasseValores from '../../components/RepasseValores';
import RepasseInteressados from '../../components/RepasseInteressados';
import RepasseTurboStatus from '../../components/RepasseTurboStatus';
import BotaoCarregarMais from '../../components/BotaoCarregarMais';
import SpinnerFull from '../../components/SpinnerFull';
import TitleAside from '../../components/TitleAside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import api, { baseURL } from '../../services/api';

// import placeholderCarSm from '../../assets/images/placeholder-car-sm.jpg';

import './style.sass';

const intlMonetary = new Intl.NumberFormat('pt-BR', {
    // style: 'currency',
    // currency: 'BRL',
    minimumFractionDigits: 2,
});

class PageGestaoRepasse extends Component {
    state = {
        searchClick: false,
        status: [
            { id: '1', name: 'Em aberto' },
            { id: '4', name: 'Em cadastro' },
            { id: '3', name: 'Vendido' },
        ],
        userTypes: [
            { id: '2', content: 'Diretor' },
            { id: '3', content: 'Gerente' },
            { id: '4', content: 'Vendedor' },
        ],
        userTypeName: '',
        checkedStatus: new Map(),
        toSearch: {
            searchText: '',
            userType: '',
            carStatus: [],
            seller: '',
        },
        sellers: [],
        repasses: [],
        loading: false,
        page: 1,
        cantLoad: false,
    };

    constructor(props) {
        super(props);

        this.initialState = this.state;
    }

    async componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        this.getSellers();
        this.props.getScoreboard();

        if (this.props.match.params && this.props.match.params.id_usuario) {
            const { id_usuario } = this.props.match.params;

            this.setState({ searchClick: true });
            let { carStatus } = this.state.toSearch;

            await this.handleToSearchChange('seller', id_usuario);
            this.setState(
                {
                    loading: true,
                    toSearch: {...this.state.toSearch, carStatus: carStatus.push('1')},
                },
                () => {
                    this.handleToSearchChange('carStatus', carStatus);
                    this.setState(prevState => ({
                        checkedStatus: prevState.checkedStatus.set(carStatus.toString(), true),
                    }));
                }
            );

            this.searchRepasses();
        } else this.getMyRepasses();
    }

    getSellers = async () => {
        const { storeId } = this.props;

        try {
            const response = await api.get(`/api/v2/store/${storeId}/staff?per_page=100`);
            const { items } = response.data.data;

            this.setState({ sellers: items });
        } catch (error) {
            console.log({ error });
        }
    };

    searchRepasses = async () => {
        const { storeId } = this.props;
        let { toSearch, page, repasses } = this.state;

        try {
            this.setState({ loading: true });

            const roles = toSearch.userType ? `roles[0]=${toSearch.userType}` : '';

            if (toSearch.carStatus.includes('1') && !(toSearch.carStatus.includes('2') || toSearch.carStatus.includes('9')) ) {
                toSearch.carStatus.push('2', '9');
            } else if (!toSearch.carStatus.includes('1') && (toSearch.carStatus.includes('2') && toSearch.carStatus.includes('9'))) {
                toSearch.carStatus.splice(toSearch.carStatus.indexOf('2'), 1);
                toSearch.carStatus.splice(toSearch.carStatus.indexOf('9'), 1);
            }

            let response = null;

            if (
                roles ||
                toSearch.searchText ||
                toSearch.userType ||
                toSearch.carStatus.length > 0 ||
                toSearch.seller
            ) {
                    response = await api.get(`/api/v2/store/${storeId}/cars?current_page=${page}&${roles}`, {
                    params: {
                        model: toSearch.searchText,
                        user_id: toSearch.seller,
                        status: toSearch.carStatus,
                    },
                    paramsSerializer: params => {
                        return qs.stringify(params);
                    },
                });
            } else {
                response = await api.get(
                    `/api/v2/store/${storeId}/cars?current_page=${page}`
                );
            }

            const cantLoad = response.data.data.totals < page * 10 ? true : false;

            if (page === 1) repasses = response.data.data.items;
            else repasses = repasses.concat(response.data.data.items);

            this.setState({ repasses, page: page , cantLoad });
        } catch (error) {
            console.log({ error });
        } finally {
            this.setState({ loading: false });
        }
    };

    getMyRepasses = async () => {
        const { storeId } = this.props;
        let { page, repasses } = this.state;
        let { carStatus } = this.state.toSearch;

        try {
            this.setState(
                {
                    loading: true,
                    toSearch: { ...this.state.toSearch, carStatus: carStatus.push('1') },
                },
                () => {
                    this.handleToSearchChange('carStatus', carStatus);
                    this.setState(prevState => ({
                        checkedStatus: prevState.checkedStatus.set('1', true),
                    }));
                }
            );

            const response = await api.get(
                `/api/v2/store/${storeId}/cars?current_page=${page}&status[0]=1&status[1]=2&status[2]=9`
            );

            const cantLoad = response.data.data.totals < page * 10 ? true : false;

            if (page === 1) repasses = response.data.data.items;
            else repasses = repasses.concat(response.data.data.items);

            this.setState({ repasses, page: page, cantLoad });
        } catch (error) {
            console.log({ error });
            this.setState({ cantLoad: true });
        } finally {
            this.setState({ loading: false });
        }
    };

    handleStatusChange = e => {
        const isChecked = e.target.checked;
        const id = e.target.value;

        let { carStatus } = this.state.toSearch;

        if (carStatus.includes(id)) carStatus = carStatus.filter(item => item !== id);
        else carStatus.push(id);

        this.handleToSearchChange('carStatus', carStatus);
        this.setState(prevState => ({
            checkedStatus: prevState.checkedStatus.set(id, isChecked),
        }));
    };

    handleToSearchChange = (name, value) => {
        this.setState({
            toSearch: {
                ...this.state.toSearch,
                [name]: value,
            },
        });

        if (name === 'userType')
            this.state.userTypes.forEach(item => {
                if (item.id === value) this.setState({ userTypeName: item.content });
            });

        if (value === '') this.setState({ userTypeName: value });
    };

    cleanSearch = () => {
        this.setState(
            {
                searchClick: true,
                repasses: [],
                page: 1,
            },
            () => {
                this.getMyRepasses();
                this.getSellers();
                this.props.history.replace('/repasses');
            }
        );

        this.setState({
            ...this.initialState,
            checkedStatus: new Map(),
            toSearch: {
                ...this.initialState.toSearch,
                carStatus: [],
            },
        });
    };

    timeElapsed = start_date => {
        const start = new Date(start_date);
        const end = new Date();

        const timeDiff = (end - start) / 1000;
        const seconds = Math.round(timeDiff);

        return Math.floor(seconds / 86400);
    };

    render() {
        const { page, loading, sellers, repasses, cantLoad } = this.state;

        return (
            <>
                <TopBar clearSearchMyRepasses={this.cleanSearch} />

                <Helmet>
                    <title>Gestão de Repasses • Repasse</title>
                </Helmet>

                <div className="page-container">
                    <div className="content-container">
                        <SpinnerFull
                            size={40}
                            spinnerColor={'#335180'}
                            spinnerWidth={3}
                            visible={loading}
                        />

                        <div style={{ marginBottom: '3rem' }}>
                            <TitleAside title="Gestão de Repasses">
                                <button
                                    className="button primary"
                                    onClick={() => this.props.history.push('/cadastro-repasse')}
                                >
                                    Cadastrar Repasse
                                </button>
                            </TitleAside>
                        </div>

                        <CardConteudo>
                            {this.props.havePlan && (
                                <div className="GestaoRepasseHeader">
                                    <div className="GestaoRepasseHeader-title">Filtro</div>

                                    <div className="GestaoRepasseHeader-search-form">
                                        <div className="form-group --busca">
                                            <div className="input-icon right">
                                                <button className="input-icon__icon-container button clear">
                                                    <FontAwesomeIcon icon="search" />
                                                </button>
                                                <input
                                                    className="form-control"
                                                    id="form-login-password"
                                                    type={
                                                        this.state.passwordMask
                                                            ? 'password'
                                                            : 'text'
                                                    }
                                                    placeholder="Buscar"
                                                    onChange={e =>
                                                        this.handleToSearchChange(
                                                            'searchText',
                                                            e.target.value
                                                        )
                                                    }
                                                    value={this.state.toSearch.searchText}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group --usuario">
                                            <label htmlFor="form-tipo-usuario" className="label">
                                                Tipo de Usuário
                                            </label>
                                            <select
                                                className="form-control"
                                                id="form-tipo-usuario"
                                                onChange={e =>
                                                    this.handleToSearchChange(
                                                        'userType',
                                                        e.target.value
                                                    )
                                                }
                                                value={this.state.toSearch.userType}
                                            >
                                                <option value="">Selecione</option>
                                                {this.state.userTypes.map((item, key) => (
                                                    <option value={item.id} key={key}>
                                                        {item.content}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group --vendedor">
                                            <label htmlFor="form-vendedor" className="label">
                                                Vendedor
                                            </label>
                                            <select
                                                className="form-control"
                                                id="form-vendedor"
                                                onChange={e =>
                                                    this.handleToSearchChange(
                                                        'seller',
                                                        e.target.value
                                                    )
                                                }
                                                value={this.state.toSearch.seller}
                                            >
                                                <option value="">Selecione</option>
                                                {sellers &&
                                                    sellers
                                                        .filter(
                                                            item =>
                                                                !this.state.userTypeName ||
                                                                this.state.userTypeName ===
                                                                    item.role
                                                        )
                                                        .map(item => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                            </select>
                                        </div>

                                        <div className="form-group --status">
                                            <label className="label">Status</label>

                                            {this.state.status.map(item => (
                                                <div className="checkbox-group" key={item.id}>
                                                    <input
                                                        type="checkbox"
                                                        id={item.id}
                                                        checked={this.state.checkedStatus.get(
                                                            item.id
                                                        )}
                                                        onChange={this.handleStatusChange}
                                                        value={item.id}
                                                    />
                                                    <label
                                                        htmlFor={item.id}
                                                        value={item.id}
                                                        className="label"
                                                    >
                                                        {item.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="form-group --button">
                                            <button
                                                className="button blue"
                                                type="submit"
                                                onClick={() => {
                                                    this.setState(
                                                        {
                                                            searchClick: true,
                                                            repasses: [],
                                                            page: 1,
                                                        },
                                                        () => {
                                                            this.searchRepasses();
                                                        }
                                                    );
                                                }}
                                            >
                                                Buscar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="RepassesListing --gestao-repasses">
                                {!loading && repasses && !repasses.length ? (
                                    <div style={{ textAlign: 'center', width: '100%' }}>
                                        <div className="result__title">
                                            {!this.state.searchClick ? (
                                                <div>
                                                    Nenhum repasse encontrado.{' '}
                                                    <small className="result__title-obs">
                                                        Listagem baseada nos seus filtros de busca.
                                                    </small>
                                                </div>
                                            ) : (
                                                'Nenhum repasse corresponde a sua busca'
                                            )}
                                        </div>

                                        {!this.state.searchClick ? (
                                            <button
                                                className="button primary"
                                                onClick={() =>
                                                    this.props.history.push('/cadastro-repasse')
                                                }
                                            >
                                                Cadastrar repasse
                                            </button>
                                        ) : (
                                            <button
                                                className="button primary"
                                                onClick={() => this.cleanSearch()}
                                            >
                                                Limpar busca
                                            </button>
                                        )}
                                    </div>
                                ) : (
                                    this.state.searchClick && (
                                        <div
                                            style={{
                                                textAlign: 'right',
                                                width: '100%',
                                                marginTop: 10,
                                            }}
                                        >
                                            <button
                                                className="button primary"
                                                onClick={() => this.cleanSearch()}
                                            >
                                                Limpar busca
                                            </button>
                                        </div>
                                    )
                                )}

                                {repasses &&
                                    repasses.map((item, index) => this.renderRepasses(item, index))}
                            </div>
                        </CardConteudo>

                        {!cantLoad && (
                            <BotaoCarregarMais
                                onClick={() => {
                                    if (this.state.searchClick) {
                                        this.setState({ page: page + 1 }, () =>
                                            this.searchRepasses()
                                        );
                                    } else {
                                        this.setState({ page: page + 1 }, () =>
                                            this.getMyRepasses()
                                        );
                                    }
                                }}
                            />
                        )}
                    </div>
                </div>
            </>
        );
    }

    renderRepasses = (item, index) => {
        const valorPorcentagem = 100 - (item.price * 100) / item.fipe_price;

        return (
            <div className="RepassesItem" key={index}>
                <Link
                    to={`/repasses/${item.id}/${item.user.id}`}
                    className="RepassesItem-image-container"
                >
                    <img
                        className="RepassesItem-image"
                        src={
                            item.photos[0] &&
                            `${baseURL}/uploads/gallery/${item.photos[0].filename}`
                        }
                        alt=""
                    />
                </Link>

                <div className="RepassesItem-info-car">
                    <RepasseInfo
                        idRepasse={item.id}
                        idUsuario={item.user.id}
                        tituloRepasse={item.model}
                        ano={item.year}
                        combustivel={item.fuel}
                        cor={item.color}
                        qtdChats={item.badge}
                        diasNoPatio={this.timeElapsed(item.created_at)}
                        marginAuto={true}
                        canEdit={true}
                        canSee={true}
                        statusValue={item.status}
                        sellerName={item.user.name}
                        // sellerPhone={item.user.phone}
                        // sellerCellPhone={item.user.cell_phone}
                        hasChat={item.has_chat}
                    />
                </div>
                {/* <div className="RepassesItem-info-availability">
                    <StatusLabel
                        statusValue={item.car_status.name}
                        isLite={true}
                    />

                    <div style={{ marginBottom: '0.5rem' }} />

                    <Seller
                        sellerImage={`${baseURL}/uploads/avatar/${
                            item.user.photo
                        }`}
                        sellerName={item.user.name}
                        isLite={true}
                    />
                </div> */}

                <div className="RepassesItem-info-values">
                    <RepasseValores
                        valorRepasse={intlMonetary.format(item.price)}
                        valorCusto={Number(item.cost) > 0 ? intlMonetary.format(item.cost) : null}
                        valorFipe={intlMonetary.format(item.fipe_price)}
                        valorPorcentagem={Number(valorPorcentagem.toFixed(2))}
                    />
                </div>
                <div className="RepassesItem-info-interested">
                    <RepasseInteressados
                        idRepasse={item.id}
                        idUsuario={item.user.id}
                        interestedNumber={item.interested}
                        values={item.bids}
                    />
                </div>
                {this.props.havePlan && (
                    <div className="RepassesItem-info-turbo">
                        <RepasseTurboStatus
                            idRepasse={item.id}
                            idUsuario={item.user.id}
                            status={item.status.id}
                            turboStatus={Number(item.status.id) !== 9 ? 'no-turbo' : 'turbo-full'}
                        />
                    </div>
                )}
            </div>
        );
    };
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
    storeId: auth.storeId,
    havePlan: auth.havePlan,
});

const mapDispatchToProps = dispatch => bindActionCreators(RepasseViewActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageGestaoRepasse);
