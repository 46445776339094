import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { connect } from 'react-redux';
import {
    faBell,
    faCaretDown,
    faCaretUp,
    faCarSide,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faClock,
    faComment,
    faEllipsisH,
    faEye,
    faExclamationCircle,
    faExclamationTriangle,
    faQuestionCircle,
    faMotorcycle,
    faPencilAlt,
    faPlusCircle,
    faSearch,
    faStar as fasStar,
    faSyncAlt,
    faTachometerAlt,
    faTimes,
    faTrash,
    faTruck,
    faPhone,
    faExchangeAlt,
    faDollarSign,
    faEllipsisV,
    faPaperclip,
    faPaperPlane,
    faInfoCircle,
    faCheckCircle,
    faTimesCircle,
    faClipboardCheck,
    faFile,
    faCar,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';

import './style.sass';

import ComponentsContainer from '../Components';
import MasterPage from '../MasterPage';
import FooterBar from '../../components/FooterBar';
import PageHome from '../PageHome';
import PageHomeOld from '../Home';
import PageGestaoEquipe from '../PageGestaoEquipe';
import PageRepasse from '../PageRepasse';
import PageLogin from '../PageLogin';
import PageForgotPassword from '../PageForgotPassword';
import PageResetPassword from '../PageResetPassword';
import PageGestaoRepasse from '../PageGestaoRepasse';
import PageGestaoFavoritos from '../PageGestaoFavoritos';
import PageRelatorios from '../PageRelatorios';
import Page404 from '../Page404';
import PageNaoAutorizado from '../PageNaoAutorizado';
import PageCadastroRepasse from '../PageCadastroRepasse';
import PagePerfilCompra from '../PagePerfilCompra';
import PagePerfil from '../PagePerfil';
import PageAlterarSenha from '../PageAlterarSenha';
import CadastroUsuarioUm from '../PageCadastroUsuarioUm';
import CadastroUsuarioDois from '../PageCadastroUsuarioDois';
import CadastroUsuarioTres from '../PageCadastroUsuarioTres';
import CadastroUsuarioQuatro from '../PageCadastroUsuarioQuatro';
import CadastroUsuarioCinco from '../PageCadastroUsuarioCinco';
// import PageConsultaVeicular from '../PageConsultaVeicular';
// import SingleConsultaVeicular from '../SingleConsultaVeicular';
import PageExtratoConta from '../PageExtratoConta';
import PageSelectLoja from '../PageSelectLoja';
import PageEstoqueRepasses from '../PageEstoqueRepasses';
import PageConvitesEnviados from '../PageConvitesEnviados';
import PageConvitesRecebidos from '../PageConvitesRecebidos';

library.add(
    faBell,
    faCaretDown,
    faCaretUp,
    faCarSide,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faClock,
    faComment,
    faEllipsisH,
    faEye,
    faExclamationCircle,
    faExclamationTriangle,
    faQuestionCircle,
    faMotorcycle,
    faPencilAlt,
    faPlusCircle,
    faSearch,
    fasStar,
    farStar,
    faSyncAlt,
    faTachometerAlt,
    faTimes,
    faTrash,
    faTruck,
    faPhone,
    faExchangeAlt,
    faDollarSign,
    faEllipsisV,
    faPaperclip,
    faPaperPlane,
    faInfoCircle,
    faCheckCircle,
    faTimesCircle,
    faClipboardCheck,
    faFile,
    faCar,
    faUser
);

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
            }
        />
    );
};

const App = props => {
    const { isAuthenticated, hasInvites, isDirectorWithPlan, hasPJPermissions } = props;

    return (
        <div className="Site">
            <div className="Site-content">
                <Switch>
                    {hasPJPermissions ? (
                        <PrivateRoute
                            exact
                            path="/"
                            component={PageHome}
                            isAuthenticated={isAuthenticated}
                        />
                    ) : (
                        <PrivateRoute
                            exact
                            path="/"
                            component={PageGestaoRepasse}
                            isAuthenticated={isAuthenticated}
                        />
                    )}

                    <Route exact path="/home-old" component={PageHomeOld} />
                    <Route path="/components" component={ComponentsContainer} />
                    <PrivateRoute
                        path="/masterpage"
                        component={MasterPage}
                        isAuthenticated={isAuthenticated}
                    />

                    {isDirectorWithPlan ? (
                        <PrivateRoute
                            path="/equipe"
                            component={PageGestaoEquipe}
                            isAuthenticated={isAuthenticated}
                        />
                    ) : (
                        <PrivateRoute
                            path="/equipe"
                            component={PageNaoAutorizado}
                            isAuthenticated={isAuthenticated}
                        />
                    )}

                    {isDirectorWithPlan ? (
                        <PrivateRoute
                            path="/equipe-convites"
                            component={PageConvitesEnviados}
                            isAuthenticated={isAuthenticated}
                        />
                    ) : (
                        <PrivateRoute
                            path="/equipe-convites"
                            component={PageNaoAutorizado}
                            isAuthenticated={isAuthenticated}
                        />
                    )}
                    <PrivateRoute
                        exact
                        path="/repasses"
                        component={PageGestaoRepasse}
                        isAuthenticated={isAuthenticated}
                    />

                    {isDirectorWithPlan ? (
                        <PrivateRoute
                            exact
                            path="/repasses/:id_usuario"
                            component={PageGestaoRepasse}
                            isAuthenticated={isAuthenticated}
                        />
                    ) : (
                        <PrivateRoute
                            exact
                            path="/repasses/:id_usuario"
                            component={PageNaoAutorizado}
                            isAuthenticated={isAuthenticated}
                        />
                    )}

                    <PrivateRoute
                        path="/repasses/:id_repasse/:id_usuario"
                        component={PageRepasse}
                        isAuthenticated={isAuthenticated}
                    />

                    {isDirectorWithPlan ? (
                        <PrivateRoute
                            path="/relatorios"
                            component={PageRelatorios}
                            isAuthenticated={isAuthenticated}
                        />
                    ) : (
                        <PrivateRoute
                            path="/relatorios"
                            component={PageNaoAutorizado}
                            isAuthenticated={isAuthenticated}
                        />
                    )}

                    {hasPJPermissions ? (
                        <PrivateRoute
                            path="/favoritos"
                            component={PageGestaoFavoritos}
                            isAuthenticated={isAuthenticated}
                        />
                    ) : (
                        <PrivateRoute
                            path="/favoritos"
                            component={PageNaoAutorizado}
                            isAuthenticated={isAuthenticated}
                        />
                    )}

                    <Route path="/login" component={PageLogin} />
                    <Route path="/selecionar-loja" component={PageSelectLoja} />
                    <Route path="/forgot-password" component={PageForgotPassword} />
                    <Route path="/recuperar-senha" component={PageResetPassword} />
                    <PrivateRoute
                        path="/cadastro-repasse"
                        component={PageCadastroRepasse}
                        isAuthenticated={isAuthenticated}
                    />

                    <PrivateRoute
                        path="/editar-cadastro-repasse"
                        component={PageCadastroRepasse}
                        isAuthenticated={isAuthenticated}
                    />

                    {hasPJPermissions ? (
                        <PrivateRoute
                            path="/perfil-compra"
                            component={PagePerfilCompra}
                            isAuthenticated={isAuthenticated}
                        />
                    ) : (
                        <PrivateRoute
                            path="/perfil-compra"
                            component={PageNaoAutorizado}
                            isAuthenticated={isAuthenticated}
                        />
                    )}

                    <PrivateRoute
                        path="/meu-perfil"
                        component={PagePerfil}
                        isAuthenticated={isAuthenticated}
                    />

                    {/* {isDirectorWithPlan ? (
                            <PrivateRoute
                                exact
                                path="/consulta-veicular"
                                component={PageConsultaVeicular}
                                isAuthenticated={isAuthenticated}
                            />
                        ) : (
                            <PrivateRoute
                                path="/consulta-veicular"
                                component={PageNaoAutorizado}
                                isAuthenticated={isAuthenticated}
                            />
                        )}
                        {isDirectorWithPlan ? (
                            <PrivateRoute
                                exact
                                path="/consulta-veicular/:id"
                                component={SingleConsultaVeicular}
                                isAuthenticated={isAuthenticated}
                            />
                        ) : (
                            <PrivateRoute
                                path="/consulta-veicular/:id"
                                component={PageNaoAutorizado}
                                isAuthenticated={isAuthenticated}
                            />
                        )}
                        {isDirectorWithPlan ? (
                            <PrivateRoute
                                exact
                                path="/consulta-veicular/"
                                component={SingleConsultaVeicular}
                                isAuthenticated={isAuthenticated}
                            />
                        ) : (
                            <PrivateRoute
                                path="/consulta-veicular/"
                                component={PageNaoAutorizado}
                                isAuthenticated={isAuthenticated}
                            />
                        )} */}

                    {isDirectorWithPlan ? (
                        <PrivateRoute
                            path="/meu-extrato"
                            component={PageExtratoConta}
                            isAuthenticated={isAuthenticated}
                        />
                    ) : (
                        <PrivateRoute
                            path="/meu-extrato"
                            component={PageNaoAutorizado}
                            isAuthenticated={isAuthenticated}
                        />
                    )}

                    <PrivateRoute
                        path="/estoque/:user_id"
                        component={PageEstoqueRepasses}
                        isAuthenticated={isAuthenticated}
                    />

                    <PrivateRoute
                        path="/alterar-senha"
                        component={PageAlterarSenha}
                        isAuthenticated={isAuthenticated}
                    />

                    {hasPJPermissions || hasInvites ? (
                        <PrivateRoute
                            path="/convites"
                            component={PageConvitesRecebidos}
                            isAuthenticated={isAuthenticated}
                        />
                    ) : (
                        <PrivateRoute
                            path="/convites"
                            component={PageNaoAutorizado}
                            isAuthenticated={isAuthenticated}
                        />
                    )}

                    <Route path="/cadastro-usuario-um" component={CadastroUsuarioUm} />
                    <Route exact path="/cadastro-usuario-dois" component={CadastroUsuarioDois} />
                    <Route
                        exact
                        path="/cadastro-usuario-dois/:store_id/:user_id"
                        component={CadastroUsuarioDois}
                    />
                    <Route
                        path="/cadastro-usuario-dois/:store_id/:user_id/:token"
                        component={CadastroUsuarioDois}
                    />

                    <Route
                        exact
                        path="/atualizacao-cadastral"
                        render={() =>
                            isAuthenticated ? (
                                <Redirect
                                    to={`/cadastro-usuario-dois/${props.store_id}/${props.user.id}`}
                                />
                            ) : (
                                <Redirect
                                    to={{
                                        pathname: '/login',
                                        state: { goto: '/atualizacao-cadastral' },
                                    }}
                                />
                            )
                        }
                    />

                    {props.hasOptionPlano ? (
                        <Route path="/cadastro-usuario-tres" component={CadastroUsuarioTres} />
                    ) : (
                        <Route path="/cadastro-usuario-tres" component={CadastroUsuarioQuatro} />
                    )}

                    {props.hasOptionPlano ? (
                        <Route path="/cadastro-usuario-quatro" component={CadastroUsuarioQuatro} />
                    ) : (
                        <Route path="/cadastro-usuario-quatro" component={CadastroUsuarioCinco} />
                    )}

                    {props.hasOptionPlano && (
                        <Route path="/cadastro-usuario-cinco" component={CadastroUsuarioCinco} />
                    )}

                    <Route component={Page404} />
                </Switch>
            </div>
            <FooterBar />
        </div>
    );
};

const mapStateToProps = ({ auth, userCreate }) => ({
    user: auth.user,
    store_id: auth.storeId,
    isDirectorWithPlan: auth.isDirectorWithPlan,
    hasPJPermissions: auth.hasPJPermissions,
    roleUser: auth.roleUser,
    isAuthenticated: auth.isAuthenticated,
    havePlan: auth.havePlan,
    hasInvites: auth.hasInvites,
    hasOptionPlano: userCreate.user.hasOptionPlano,
});

export default withRouter(connect(mapStateToProps)(App));
