import React, { Component } from 'react';
import logo from '../../assets/logo-repasse.png';
import android from '../../assets/google-play.png';
import ios from '../../assets/app-store.png';
import { Link } from 'react-router-dom';
// Style
import './style.sass';
import SocialBar from '../SocialBar';

export default class FooterBar extends Component {
    render() {
        return (
            <div className="footer-bar">
                <div className="footer-content">
                    <div className="coluna-esquerda">
                        <div className="logo-footer">
                            <img src={logo} alt="Repasse" />
                        </div>
                        <div className="store-container">
                            <a
                                href="https://itunes.apple.com/us/app/repasse-motors/id1189648633?l=pt&ls=1&mt=8"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="store-container-link"
                            >
                                <img src={ios} alt="Repasse" />
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=br.com.apprepasse&hl=pt_BR"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="store-container-link"
                            >
                                <img src={android} alt="Repasse" />
                            </a>
                        </div>
                    </div>
                    <div className="coluna-direita">
                        <div className="titulo">Navegação</div>
                        <ul className="listagem-links">
                            <li className="link-item">
                                <Link to='/' className="link-footer" >
                                    Comprar
                                </Link>
                            </li>
                            <li className="link-item">
                                <Link to='/repasses' className="link-footer" >
                                    Meus Anúncios
                                </Link>
                            </li>
                            <li className="link-item">
                                <Link to='/favoritos' className="link-footer" >
                                    Favoritos
                                </Link>
                            </li>
                            <li className="link-item">
                                <Link to='/perfil-compra' className="link-footer" >
                                    Perfis de Compra
                                </Link>
                            </li>
                            <li className="link-item">
                                <a
                                    className="link-footer"
                                    href="https://www.repasses.com.br/politica-de-privacidade/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Termos de Uso
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <SocialBar />
            </div>
        );
    }
}
