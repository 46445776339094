import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.sass';

export default class CustomSelect extends Component {
  render() {
    return (
    <div className="custom-select">
        <select name="" id="">
            <option value="">item 1</option>
            <option value="">item 2</option>
            <option value="">item 3</option>
        </select>
        <div className="icone-caret">
            <FontAwesomeIcon icon="caret-down" />
        </div>
    </div>
    )
  }
}
