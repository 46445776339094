import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators as AuthActions } from '../../store/ducks/auth';
import fotoSlide1 from '../../assets/images/Repasse-BannerNovidades001.jpg';
import fotoSlide2 from '../../assets/images/Repasse-BannerNovidades002.jpg';
import fotoSlide3 from '../../assets/images/Repasse-BannerNovidades003.jpg';
import fotoSlide4 from '../../assets/images/Repasse-BannerNovidades004.jpg';
import fotoSlide5 from '../../assets/images/Repasse-BannerNovidades005.jpg';
import iconArrowRightBlue from '../../assets/images/icon-arrow-right-blue.png';
import iconArrowLeftBlue from '../../assets/images/icon-arrow-left-blue.png';
// Style
import './style.sass';
import NonAvatar from '../NonAvatar';
import Modal from '../Modal';
import Swiper from 'react-id-swiper';

class UsuarioBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
        this.modalNovidades = React.createRef();
    }

    openModal = ref => ref.current.openModal();

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    render() {
        const params = {
            navigation: {
                nextEl: '.swiper-button-next-novidades',
                prevEl: '.swiper-button-prev-novidades',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            renderNextButton: () => {
                return (
                    <div className="swiper-button-next-novidades">
                        <img src={iconArrowRightBlue} alt="" />
                    </div>
                );
            },
            renderPrevButton: () => {
                return (
                    <div className="swiper-button-prev-novidades">
                        <img src={iconArrowLeftBlue} alt="" />
                    </div>
                );
            },
        };

        const { user, hasInvites, hasPJPermissions } = this.props;

        return (
            <div className="usuario-bar">
                <div className="nome-usuario">
                    <div
                        onClick={() => this.openModal(this.modalNovidades)}
                        className="botao-novidades"
                    >
                        Novidades
                    </div>
                    <div className="nome-usuario" onClick={() => this.toggleModal()}>
                        <div className="nome">{user.name}</div>
                        <div className="icone-dropdown">
                            <FontAwesomeIcon icon="chevron-down" />
                        </div>
                        <NonAvatar userPhoto={user.photo} iniciais={user.name[0]} />
                    </div>
                </div>

                <div
                    className="modal-usuario"
                    style={{
                        display: this.state.showModal ? 'block' : 'none',
                        height: !hasPJPermissions && !hasInvites && '150px',
                        bottom: !hasPJPermissions && !hasInvites && '-150px',
                    }}
                >
                    <div className="icone-caret">
                        <FontAwesomeIcon icon="caret-up" />
                    </div>
                    <div className="listagem-dropdown">
                        <div className="dropdown-item">
                            <Link to="/meu-perfil">Alterar Perfil</Link>
                        </div>

                        <div className="dropdown-item">
                            <Link to="/alterar-senha">Alterar Senha</Link>
                        </div>

                        {(hasPJPermissions || hasInvites) && (
                            <div className="dropdown-item">
                                <Link to="/convites">Convites recebidos</Link>
                            </div>
                        )}

                        <div className="dropdown-item">
                            <a
                                href="https://www.repasses.com.br/politica-de-privacidade/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Termos de uso
                            </a>
                        </div>
                        <div className="dropdown-item" onClick={this.props.logoutUser}>
                            Sair
                        </div>
                    </div>
                </div>

                <Modal title="Novidades" ref={this.modalNovidades}>
                    <Swiper {...params}>
                        <div className="slide-novidades-item">
                            <img src={fotoSlide1} alt="" />
                        </div>
                        <div className="slide-novidades-item">
                            <img src={fotoSlide2} alt="" />
                        </div>
                        <div className="slide-novidades-item">
                            <img src={fotoSlide3} alt="" />
                        </div>
                        <div className="slide-novidades-item">
                            <img src={fotoSlide4} alt="" />
                        </div>
                        <div className="slide-novidades-item">
                            <img src={fotoSlide5} alt="" />
                        </div>
                    </Swiper>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
    hasInvites: auth.hasInvites,
    hasPJPermissions: auth.hasPJPermissions,
});

const mapDispatchToProps = dispatch => bindActionCreators(AuthActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsuarioBar);
