import { createReducer, createActions } from 'reduxsauce';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
    /** Async actions */
    loginUser: ['email', 'password'],
    loadUserData: ['storeId', 'user'],
    acceptTermsOfUse: [],
    logoutUser: [],

    /** State actions */
    toggleAuthLoading: ['payload'],
    setAuthError: ['payload'],
    loginUserSuccess: ['payload'],
    loginUserFail: ['payload'],
    setGoto: ['payload'],
    setTermsOfUse: ['payload'],
    setScoreboard: ['data'],
    setUser: ['data'],
    setAdditionalUserData: ['payload'],
});

/* Initial State */

export const INITIAL_STATE = {
    user: {},
    terms: true,
    error: '',
    loading: false,
    isAuthenticated: false,
    storeId: 0,
    roleUser: {},
    havePlan: false,
    hasInvites: false,
    isDirectorWithPlan: false,
    hasPJPermissions: false,
    scoreboard: {},
    goto: '',
};

/* Reducers */

const toggleAuthLoading = (state = INITIAL_STATE, action) => ({
    ...state,
    loading: action.payload,
});

const setAuthError = (state = INITIAL_STATE, action) => ({ ...state, error: action.payload });

const loginUser = (state = INITIAL_STATE) => ({ ...state, loading: true, error: '' });

const loginUserSuccess = (state = INITIAL_STATE, action) => ({
    ...state,
    loading: false,
    user: action.payload,
    isAuthenticated: true,
});

const loginUserFail = (state = INITIAL_STATE, action) => ({
    ...state,
    loading: false,
    error: action.payload,
});

const logoutUser = (state = INITIAL_STATE) => ({
    ...state,
    ...INITIAL_STATE,
});

const setGoto = (state = INITIAL_STATE, action) => ({ ...state, goto: action.payload });

const setTermsOfUse = (state = INITIAL_STATE, action) => ({ ...state, terms: action.payload });

const setScoreboard = (state = INITIAL_STATE, action) => ({
    ...state,
    scoreboard: action.data,
});

const setUser = (state = INITIAL_STATE, action) => ({ ...state, user: action.data });

const setAdditionalUserData = (state = INITIAL_STATE, action) => ({
    ...state,
    terms: action.payload.terms,
    storeId: action.payload.storeId,
    havePlan: action.payload.havePlan,
    hasInvites: action.payload.hasInvites,
    roleUser: action.payload.roleUser,
    isDirectorWithPlan: action.payload.isDirectorWithPlan,
    hasPJPermissions: action.payload.hasPJPermissions,
    scoreboard: action.payload.scoreboard,
});

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
    [Types.TOGGLE_AUTH_LOADING]: toggleAuthLoading,
    [Types.SET_AUTH_ERROR]: setAuthError,
    [Types.LOGIN_USER]: loginUser,
    [Types.LOGIN_USER_SUCCESS]: loginUserSuccess,
    [Types.LOGIN_USER_FAIL]: loginUserFail,
    [Types.LOGOUT_USER]: logoutUser,
    [Types.SET_GOTO]: setGoto,
    [Types.SET_TERMS_OF_USE]: setTermsOfUse,
    [Types.SET_SCOREBOARD]: setScoreboard,
    [Types.SET_USER]: setUser,
    [Types.SET_ADDITIONAL_USER_DATA]: setAdditionalUserData,
});
