import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputMask from 'react-input-mask';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators } from '../../store/ducks/profileUser';
import { validateCPF } from '../../helpers';
import './style.sass';

import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';

import TopBar from '../../components/TopBar';
import TitleAside from '../../components/TitleAside';
import CardConteudo from '../../components/CardConteudo';
import FormGroupInput from '../../components/FormGroupInput';
import FormGroupSelect from '../../components/FormGroupSelect';
import SmallAction from '../../components/SmallAction';
import MessageBox from '../../components/MessageBox';
import Modal from '../../components/Modal';
import ModalConfirmation from '../../components/ModalConfirmation';
import SpinnerFull from '../../components/SpinnerFull';

class PagePerfil extends Component {
    state = {
        canEdit: false,
        valError: true,
        photoError: '',
        dealershipError: '',
        nameError: '',
        documentError: '',
        phoneError: '',
        cell_phoneError: '',
        zipcodeError: '',
        stateError: '',
        neighborhoodError: '',
        cityError: '',
        addressError: '',
        numberError: '',
    };

    constructor(props) {
        super(props);

        this.modalConfirmationRef = React.createRef();
        this.modalSuccessRef = React.createRef();

        this.inputProps = {
            size: 'small',
            labelColor: '#335180',
        };
    }

    componentWillUnmount() {
        URL.revokeObjectURL(this.props.user.photo);
    }

    async componentDidMount() {
        // const { userId } = this.props;

        this.props.getUser();
        this.props.getStates();
    }

    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    toggleFormEditing = () => {
        this.setState({ canEdit: !this.state.canEdit });
    };

    changeProfileAvatar = e => {
        const file = e.target.files[0];

        URL.revokeObjectURL(this.props.user.photo);
        this.props.setUserAttribute('photo', URL.createObjectURL(file));
        this.props.setUserAttribute('photoFile', file);
        this.handleErrorChange('photo', '');
    };

    removeProfileAvatar = () => {
        URL.revokeObjectURL(this.props.user.photo);

        this.props.setUserAttribute('photo', '');
    };

    openModal = modalRef => {
        modalRef.current.openModal();
    };

    closeModal = modalRef => {
        modalRef.current.closeModal();
    };

    handleInputChange = (name, value) => {
        this.props.setUserAttribute(name, value);
        this.handleErrorChange(name, '');
    };

    handleErrorChange = (name, value) => {
        this.setState({ [`${name}Error`]: value });
    };

    validateFields = () => {
        let validated = true;
        const { user } = this.props;

        if (validator.isEmpty(user.photo)) {
            validated = false;
            this.handleErrorChange('photo', 'Selecione uma imagem para o perfil');
        }

        if (validator.isEmpty(user.dealership)) {
            validated = false;
            this.handleErrorChange('dealership', 'Nome da loja não pode ficar vazio');
        }

        if (validator.isEmpty(user.name)) {
            validated = false;
            this.handleErrorChange('name', 'Nome do responsável está vazio');
        }

        if (!validateCPF(user.document)) {
            validated = false;
            this.handleErrorChange('document', 'CPF inválido');

            if (validator.isEmpty(user.document))
                this.handleErrorChange('document', 'CPF está vazio');
        }

        if (validator.isEmpty(user.phone)) {
            validated = false;
            this.handleErrorChange('phone', 'Telefone está vazio');
        }

        if (validator.isEmpty(user.cell_phone)) {
            validated = false;
            this.handleErrorChange('cell_phone', 'Celular está vazio');
        }

        if (validator.isEmpty(user.zipcode)) {
            validated = false;
            this.handleErrorChange('zipcode', 'CEP está vazio');
        }

        if (validator.isEmpty(user.state)) {
            validated = false;
            this.handleErrorChange('state', 'Selecione um estado');
        }

        if (validator.isEmpty(user.neighborhood)) {
            validated = false;
            this.handleErrorChange('neighborhood', 'Bairro está vazio');
        }

        if (validator.isEmpty(user.city)) {
            validated = false;
            this.handleErrorChange('city', 'Cidade está vazia');
        }

        if (validator.isEmpty(user.address)) {
            validated = false;
            this.handleErrorChange('address', 'Logradouro está vazia');
        }

        if (validator.isEmpty(user.number)) {
            validated = false;
            this.handleErrorChange('number', 'Número está vazio');
        }

        return validated;
    };

    render() {
        const { canEdit } = this.state;
        const { loading, states, user } = this.props;

        return (
            <React.Fragment>
                <TopBar />

                <Helmet>
                    <title>Meu Perfil • Repasse</title>
                </Helmet>

                <Modal title="Alteração de senha" size="small" ref={this.modalSuccessRef}>
                    <ModalConfirmation text="Seu perfil foi atualizado com sucesso" icon="check" />
                </Modal>

                <Modal ref={this.modalConfirmationRef} title={'Aviso'} size="small">
                    <div className="margin-top">
                        <div>Deseja realmente alterar as informações de perfil?</div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '2rem auto 0',
                            }}
                        >
                            <button
                                className="button primary outline margin-right"
                                onClick={() => this.closeModal(this.modalConfirmationRef)}
                            >
                                Não
                            </button>
                            <button
                                onClick={() => {
                                    this.toggleFormEditing();
                                    this.closeModal(this.modalConfirmationRef);
                                    this.props.updateUser(user);
                                }}
                                className="button primary"
                            >
                                Sim
                            </button>
                        </div>
                    </div>
                </Modal>

                <div className="page-container">
                    <div className="content-container">
                        <SpinnerFull
                            size={40}
                            spinnerColor={'#335180'}
                            spinnerWidth={3}
                            visible={loading}
                        />

                        <TitleAside title="Meu Perfil">
                            <button
                                className={classNames('button', 'primary', 'reveal', {
                                    hide: canEdit,
                                })}
                                onClick={this.toggleFormEditing}
                            >
                                Editar meus dados
                            </button>
                        </TitleAside>
                        <CardConteudo padding="2.5rem">
                            <h4 className="mini-title margin-bottom">Lojas e dados pessoais</h4>

                            <div className="MeuPerfil-avatar-block">
                                <div className="MeuPerfil-avatar-container">
                                    <img className="MeuPerfil-avatar" src={user.photo} alt="" />
                                </div>

                                <div style={{ flexDirection: 'column' }}>
                                    <input
                                        type="text"
                                        className="MeuPerfil-name form-control"
                                        value={user.dealership}
                                        name="perfilName"
                                        readOnly={!canEdit}
                                        onChange={e =>
                                            this.handleInputChange('dealership', e.target.value)
                                        }
                                    />

                                    {this.state.dealershipError && (
                                        <div className="form-validation-danger is-visible">
                                            <FontAwesomeIcon icon="exclamation-circle" />{' '}
                                            {this.state.dealershipError}
                                        </div>
                                    )}
                                </div>

                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={this.changeProfileAvatar}
                                    className="hidden-input"
                                    id="PagePerfil-avatar-input"
                                />
                            </div>

                            <AnimateHeight height={canEdit ? 'auto' : 0}>
                                <div className="MeuPerfil-avatar-actions">
                                    <div className="MeuPerfil-avatar-action">
                                        <label
                                            htmlFor="PagePerfil-avatar-input"
                                            className="button clean"
                                        >
                                            <SmallAction
                                                title="Mudar foto"
                                                icon="sync-alt"
                                                iconColor="#0f94d3"
                                            />
                                        </label>
                                    </div>
                                    <div className="MeuPerfil-avatar-action">
                                        <button
                                            className="button clean"
                                            onClick={this.removeProfileAvatar}
                                        >
                                            <SmallAction
                                                title="Excluir"
                                                icon="trash"
                                                iconColor="#d0021b"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </AnimateHeight>

                            <div className="MeuPerfil-info-block">
                                <div className="form-row">
                                    <div className="form-col">
                                        <FormGroupInput
                                            label="Nome do responsável"
                                            value={user.name}
                                            readOnly={!canEdit}
                                            onChange={e =>
                                                this.handleInputChange('name', e.target.value)
                                            }
                                            errorMessage={this.state.nameError}
                                            {...this.inputProps}
                                        />
                                    </div>
                                    <div className="form-col">
                                        <InputMask
                                            mask="999.999.999-99"
                                            readOnly={!canEdit}
                                            onChange={e =>
                                                this.handleInputChange('document', e.target.value)
                                            }
                                            value={user.document}
                                        >
                                            {inputMaskProps => (
                                                <FormGroupInput
                                                    {...inputMaskProps}
                                                    label="CPF"
                                                    readOnly={!canEdit}
                                                    errorMessage={this.state.documentError}
                                                    {...this.inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </div>
                                    <div className="form-col">
                                        <InputMask
                                            mask="(99) 9999-9999"
                                            readOnly={!canEdit}
                                            onChange={e =>
                                                this.handleInputChange('phone', e.target.value)
                                            }
                                            value={user.phone}
                                        >
                                            {inputMaskProps => (
                                                <FormGroupInput
                                                    {...inputMaskProps}
                                                    label="Telefone Fixo"
                                                    readOnly={!canEdit}
                                                    errorMessage={this.state.phoneError}
                                                    {...this.inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </div>
                                    <div className="form-col">
                                        <InputMask
                                            mask="(99) 99999-9999"
                                            readOnly={!canEdit}
                                            onChange={e =>
                                                this.handleInputChange('cell_phone', e.target.value)
                                            }
                                            value={user.cell_phone}
                                        >
                                            {inputMaskProps => (
                                                <FormGroupInput
                                                    {...inputMaskProps}
                                                    label="Celular"
                                                    readOnly={!canEdit}
                                                    errorMessage={this.state.cell_phoneError}
                                                    {...this.inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </div>
                                </div>
                            </div>

                            <h4 className="mini-title margin-vertical">Lojas e dados pessoais</h4>

                            <div className="MeuPerfil-info-block">
                                <div className="form-row">
                                    <div className="form-col--3">
                                        <InputMask
                                            mask="99999-999"
                                            readOnly={!canEdit}
                                            onChange={e =>
                                                this.handleInputChange('zipcode', e.target.value)
                                            }
                                            value={user.zipcode}
                                        >
                                            {inputMaskProps => (
                                                <FormGroupInput
                                                    {...inputMaskProps}
                                                    label="CEP"
                                                    readOnly={!canEdit}
                                                    errorMessage={this.state.zipcodeError}
                                                    {...this.inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </div>
                                    <div className="form-col--3">
                                        <FormGroupSelect
                                            label="Estado"
                                            value={user.state}
                                            readOnly={!canEdit}
                                            placeholder="Selecione"
                                            onChange={e =>
                                                this.handleInputChange('state', e.target.value)
                                            }
                                            errorMessage={this.state.stateError}
                                            options={
                                                states &&
                                                states.map(item => ({
                                                    value: item.initials,
                                                    content: item.name,
                                                }))
                                            }
                                            {...this.inputProps}
                                        />
                                    </div>

                                    <div className="form-col--3">
                                        <FormGroupInput
                                            label="Bairro"
                                            value={user.neighborhood}
                                            readOnly={!canEdit}
                                            onChange={e =>
                                                this.handleInputChange(
                                                    'neighborhood',
                                                    e.target.value
                                                )
                                            }
                                            errorMessage={this.state.neighborhoodError}
                                            {...this.inputProps}
                                        />
                                    </div>
                                    <div className="form-col--3">
                                        <FormGroupInput
                                            label="Cidade"
                                            value={user.city}
                                            readOnly={!canEdit}
                                            onChange={e =>
                                                this.handleInputChange('city', e.target.value)
                                            }
                                            errorMessage={this.state.cityError}
                                            {...this.inputProps}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-col--4">
                                        <FormGroupInput
                                            label="Logradouro"
                                            value={user.address}
                                            readOnly={!canEdit}
                                            onChange={e =>
                                                this.handleInputChange('address', e.target.value)
                                            }
                                            errorMessage={this.state.addressError}
                                            {...this.inputProps}
                                        />
                                    </div>
                                    <div className="form-col--2">
                                        <FormGroupInput
                                            label="Número"
                                            value={user.number}
                                            readOnly={!canEdit}
                                            onChange={e =>
                                                this.handleInputChange('number', e.target.value)
                                            }
                                            errorMessage={this.state.numberError}
                                            {...this.inputProps}
                                        />
                                    </div>
                                </div>
                            </div>

                            <AnimateHeight height={this.state.photoError ? 'auto' : 0}>
                                <div
                                    style={{
                                        textAlign: 'center',
                                        margin: '2rem auto',
                                    }}
                                >
                                    <MessageBox type="danger" inlineBlock>
                                        {this.state.photoError}
                                    </MessageBox>
                                </div>
                            </AnimateHeight>

                            <AnimateHeight height={canEdit ? 'auto' : 0}>
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <button
                                        className="button primary"
                                        onClick={() =>
                                            this.validateFields() &&
                                            this.openModal(this.modalConfirmationRef)
                                        }
                                    >
                                        Salvar Alterações
                                    </button>
                                </div>
                            </AnimateHeight>
                        </CardConteudo>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ auth, profileUser }) => ({
    userId: auth.user.id,
    loading: profileUser.loading,
    states: profileUser.states,
    user: profileUser.user,
});

const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PagePerfil);
