import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.sass';

export default class ActionButton extends Component {
    constructor(props) {
        super(props);
        this.actionUrl = '';
    }

    componentDidMount() {
        const { actionUrl = '' } = this.props;

        this.setState({
            actionUrl,
        });
    }

    render() {
        return (
            <div className={`action ${this.props.modifierClasses || ''}`}>
                {this.props.nameIcon && (
                    <div className="icon" style={{ color: this.props.colorIcon }}>
                        <FontAwesomeIcon icon={this.props.nameIcon} />
                    </div>
                )}
                {this.props.linkAction ? (
                    <Link className="text" to={this.props.linkAction}>
                        {this.props.textAction}
                    </Link>
                ) : (
                    <div className="text">{this.props.textAction}</div>
                )}
            </div>
        );
    }
}
