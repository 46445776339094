import React from 'react';

import classNames from 'classnames';

import './style.sass';

/**
 * StatusLabel
 *
 * @param {boolean} props.isLite Add classe .is-lite no componente
 * @param {string} props.statusValue Texto a ser exibido no status
 */
const StatusLabel = props => {
    const containerClass = classNames('StatusLabel', {
        'is-lite': props.isLite,
        'status-code': props.statusCode,
        [`status-${props.statusCode}`]: props.statusCode,
    })
    return (
        <div className={containerClass}>
            Status: {props.statusValue}
        </div>
    );
};

export default StatusLabel;
