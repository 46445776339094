import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import avatarPlaceholder from '../../assets/user-fundo-branco.png';
import api, { baseURL } from '../../services/api';
import BoxChatInfoBar from '../BoxChatInfobar';
import BoxChatMessageContainer from '../BoxChatMessageContainer';
import BoxChatTitleBar from '../BoxChatTitleBar';
import './style.sass';

const intlMonetary = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
});

class BoxChatItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message_type: '1',
            file_type: '',
        };
    }

    uploadFile = async file => {
        try {
            const formData = new FormData();

            formData.append('filename', file);

            let response = '';

            if (file.type.includes('image')) {
                this.setState({ message_type: '2', file_type: 'Imagem' });
                response = await api.post(`${baseURL}/api/v2/chat-image`, formData);
            }

            if (file.type.includes('audio')) {
                this.setState({ message_type: '3', file_type: 'Áudio' });
                response = await api.post(`${baseURL}/api/v2/chat-audio`, formData);
            }

            const { data } = response;

            this.props.changeTypedMessage(data.filename);
        } catch (error) {
            console.log({ error });
        }
    };

    render() {
        const { user, activeChatMessages, vehicle, data } = this.props;

        const _messages = activeChatMessages ? activeChatMessages.messages : [];
        const messages = [..._messages];

        return (
            <div className="box-chat-item">
                {messages.length > 0 && (
                    <Fragment>
                        <BoxChatTitleBar
                            nomeUsuario={data.purchaser ? data.purchaser.name : ''}
                            valorProposta={Number(data.bid) > 0 ? intlMonetary.format(data.bid) : null}
                        />
                        <BoxChatInfoBar
                            fotoVeiculo={vehicle.image ? `${baseURL}/uploads/gallery/${vehicle.image}` : ''}
                            nomeVeiculo={vehicle.fipe.model ? vehicle.fipe.model : ''}
                            anoVeiculo={vehicle.year ? vehicle.year : ''}
                            modeloVeiculo={vehicle.fipe.fuel ? vehicle.fipe.fuel : ''}
                            corVeiculo={vehicle.color.name ? vehicle.color.name : ''}
                            statusVeiculo={vehicle.car_status_name ? vehicle.car_status.name : ''}
                            valorProposta={Number(data.bid) > 0 ? intlMonetary.format(data.bid) : null}
                            nomeVendedor={vehicle.user.name ? vehicle.user.name : ''}
                            fotoVendedor={vehicle.user.image ? `${baseURL}/uploads/avatar/${vehicle.user.image}` : avatarPlaceholder}
                            idVeiculo={vehicle.id ? vehicle.id : ''}
                            idVendedor={vehicle.user.id ? vehicle.user.id : ''}
                        />
                        <BoxChatMessageContainer messages={messages} />
                    </Fragment>
                )}
                {/* <div className="input-chat-container">
                    <input
                        type="text"
                        value={this.props.typedMessage}
                        onChange={event => this.props.changeTypedMessage(event.target.value)}
                        onKeyPress={
                            event => {
                                if (event.which === 13) {
                                    this.props.sendMessage(
                                        data.room_id,
                                        user.id,
                                        data.purchaser.id,
                                        this.state.message_type,
                                        data.purchaser
                                    );
                                }
                            }
                        }
                    />
                    <div className="icone-anexo" onClick={() => this.fileInput.click()}>
                        <FontAwesomeIcon icon="paperclip" />
                        <input
                            ref={fileInput => (this.fileInput = fileInput)}
                            type="file"
                            accept="file_extension|audio/*|image/*"
                            style={{ display: 'none' }}
                            onChange={event => this.uploadFile(event.target.files[0])}
                            onClick={() => (this.fileInput.value = null)}
                        />
                    </div>
                    <div
                        className="icone-enviar"
                        onClick={() =>
                            this.props.sendMessage(
                                data.room_id,
                                user.id,
                                data.purchaser.id,
                                this.state.message_type,
                                data.purchaser
                            )
                        }
                    >
                        <FontAwesomeIcon icon="paper-plane" />
                    </div>
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
});

export default connect(mapStateToProps)(BoxChatItem);
