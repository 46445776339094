import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';

//Style
import './style.sass';

const Breadcrumbs = ({ roleUser }) => {
    const equipeBreadcrumbClass = classNames('titulo-breadcrumb', {
        atual: window.location.pathname === '/equipe',
    });

    const relatorioBreadcrumbClass = classNames('titulo-breadcrumb', {
        atual: window.location.pathname === '/relatorios',
    });

    /* const consultaBreadcrumbClass = classNames('titulo-breadcrumb', {
        atual: window.location.pathname === '/consulta-veicular',
    }); */

    return (
        <div className="Breadcrumbs">
            <Link className={equipeBreadcrumbClass} to="/equipe">
                Equipe
            </Link>
            {roleUser.id === 2 && (
                <>
                    <div className="separador">/</div>
                    <Link className={relatorioBreadcrumbClass} to="/relatorios">
                        Relatórios
                    </Link>
                    {/* <div className="separador">/</div>
                    <Link className={consultaBreadcrumbClass} to="/consulta-veicular">
                        Consulta veicular
                    </Link> */}
                </>
            )}
        </div>
    );
};

const mapStateToProps = ({ auth }) => ({
    roleUser: auth.roleUser,
});

export default connect(mapStateToProps)(Breadcrumbs);
