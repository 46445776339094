import React, { Component } from 'react';

import CardConteudo from '../CardConteudo';
// import GraficoVendas from '../GraficoVendas';
import './style.sass';
import CustomSelect from '../CustomSelect';

export default class ContainerVendas extends Component {
    render() {
        return (
            <div className="container-vendas">
                <CardConteudo>
                    <div className="linha-topo-vendas">
                        <div className="titulo-infograficos">
                            Compare suas vendas coma média dos outros usuários
                        </div>
                        <div className="filtro-select">
                            <CustomSelect />
                        </div>
                    </div>
                    {/* <GraficoVendas/> */}
                </CardConteudo>
            </div>
        );
    }
}
