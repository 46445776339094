import React from 'react';

const NotificationBullet = props => {
    const style = {
        backgroundColor: props.bgColor ? props.bgColor : '#335180',
        color: props.color ? props.color : '#fff',
        fontWeight: 'bold',
        borderRadius: props.squared ? '45%' : '50%',
        width: props.squared ? '2.5rem' : '2rem',
        height: props.squared ? '1.5rem' : '2rem',
        padding: '12px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.9rem',
        lineHeight: 1,
        cursor: 'default',
    };

    return <div style={style}>{props.number}</div>;
};

export default NotificationBullet;
