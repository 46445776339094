import React from 'react'
import CardConteudo from '../../components/CardConteudo';

import './style.sass';

const PerfilVendedor =  (props) =>{
    return (
        <div className="perfil-vendedor">
            <CardConteudo>
                {
                    props.fotoURL &&
                    <div className="container-foto">
                        <div className="foto-circle-crop">
                            <img src={props.fotoURL} alt=""/>
                        </div>
                        <div className="chat-tag"></div>
                    </div>
                }

                <div className="nome-usuario">{props.nomeVendedor}</div>
                <div className="email-usuario">{props.emailVendedor}</div>
                <div className="listagem-infos-vendedor">
                    <div className="info-item">
                        <div className="titulo">Total de cadastros</div>
                        <div className="conteudo">{props.totalCadastro} Veículos</div>
                    </div>
                    <div className="info-item">
                        <div className="titulo">Total em repasses</div>
                        <div className="conteudo">R$ {props.totalRepasses}</div>
                    </div>
                    <div className="info-item">
                        <div className="titulo">Lucro em repasses</div>
                        <div className="conteudo">R$ {props.lucroRepasses}</div>
                    </div>
                    <div className="info-item">
                        <div className="titulo">Meta</div>
                        <div className="conteudo">+{props.meta}%</div>
                    </div>
                </div>

                <div className="exportar-container">
                    <div className="titulo">vendedor destaque</div>
                    <div className="link-download">Exportar todos os dados de vendas (PDF)</div>
                </div>
            </CardConteudo>
        </div>
    )
}
export default PerfilVendedor
