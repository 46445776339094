import React from 'react';
import AnimateHeight from 'react-animate-height';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators as AuthActions } from '../../../../store/ducks/auth';

// Style
import './style.sass';

const Menu = ({
    mobileEnabled,
    hasInvites,
    hasPJPermissions,
    clearSearchHome,
    clearSearchMyRepasses,
    logoutUser,
}) => {
    let menuHeight = 0;

    if (window.innerWidth < 800) {
        if (mobileEnabled) {
            menuHeight = 'auto';
        }
    } else {
        menuHeight = 'auto';
    }

    return (
        <div className="main-header-menu">
            <AnimateHeight duration={500} height={menuHeight}>
                <ul>
                    {hasPJPermissions && (
                        <li onClick={() => clearSearchHome && clearSearchHome()}>
                            <Link to="/">Comprar</Link>
                        </li>
                    )}

                    <li onClick={() => clearSearchMyRepasses && clearSearchMyRepasses()}>
                        <Link to="/repasses">Meus Anúncios</Link>
                    </li>

                    {hasPJPermissions && (
                        <li>
                            <Link to="/favoritos">Favoritos</Link>
                        </li>
                    )}

                    {hasPJPermissions && (
                        <li>
                            <Link to="/perfil-compra">Perfis de Compra</Link>
                        </li>
                    )}

                    <li className="menu-controls-mobile-appearance">
                        <Link to="/meu-perfil">Alterar Perfil</Link>
                    </li>

                    <li className="menu-controls-mobile-appearance">
                        <Link to="/alterar-senha">Alterar Senha</Link>
                    </li>

                    {(hasPJPermissions || hasInvites) && (
                        <li className="menu-controls-mobile-appearance">
                            <Link to="/convites">Convites recebidos</Link>
                        </li>
                    )}

                    <li className="menu-controls-mobile-appearance">
                        <a
                            href="https://www.repasses.com.br/politica-de-privacidade/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Termos de uso
                        </a>
                    </li>

                    <li className="menu-controls-mobile-appearance" onClick={logoutUser}>
                        <div style={{ padding: 5 }}>Sair</div>
                    </li>

                    <li>
                        <Link to="/cadastro-repasse" className="button secondary">
                            Cadastrar Repasse
                        </Link>
                    </li>
                </ul>
            </AnimateHeight>
        </div>
    );
};

const mapStateToProps = ({ auth }) => ({
    hasInvites: auth.hasInvites,
    hasPJPermissions: auth.hasPJPermissions,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            logoutUser: AuthActions.logoutUser,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Menu);
