import React, { Component } from 'react';

import iconTurbo from '../../assets/images/icon-turbo-max.png';

import './style.sass';

export default class InteressadosContainer extends Component {
    render() {
        return (
            <div
                className={
                    this.props.isTurbo ? 'InteressadosContainer' : 'InteressadosContainer no-turbo'
                }
            >
                <div className="InteressadosContainer-data">
                    <div className="InteressadosContainer-data-item">
                        <div className="InteressadosContainer-number">
                            {this.props.numInteressados}
                        </div>
                        <div className="InteressadosContainer-text">Interessados no momento</div>
                    </div>
                    <div className="InteressadosContainer-data-item">
                        <div className="InteressadosContainer-icon-container">
                            <img src={iconTurbo} alt="" />
                        </div>
                        <div className="InteressadosContainer-text">
                            Acelere suas vendas com Turbo
                        </div>
                    </div>
                </div>

                <div className="InteressadosContainer-button-container">
                    {this.props.isTurbo === true ? (
                        <button className="button primary small block">Análise completa</button>
                    ) : (
                        <button className="button outline primary small block" disabled>
                            Análise Completa <small>(apenas&#160;com&#160;Turbo)</small>
                        </button>
                    )}
                </div>
            </div>
        );
    }
}
