import React from 'react';
import './style.sass';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SmallAction = props => {
    return (
        <div className="SmallAction">
            <div className="SmallAction-title" style={{ color: props.titleColor }}>
                {props.title}
            </div>
            <div className="SmallAction-icon-container">
                {props.icon && (
                    <FontAwesomeIcon
                        className="SmallAction-icon"
                        icon={props.icon}
                        style={{ color: props.iconColor }}
                    />
                )}
                {props.image && <img src={props.image} className="SmallAction-icon" alt="" />}
            </div>
        </div>
    );
};

export default SmallAction;
