const colors = {
    primary: '#335180',
    secondary: '#c63f43',
    tertiary: '#37474f',
};

const repassesAnunciados = {
    labels: [
        'Jan/18',
        'Fev/18',
        'Mar/18',
        'Abr/18',
        'Mai/18',
        'Jun/18',
        'Jul/18',
        'Ago/18',
        'Set/18',
        'Out/18',
        'Nov/18',
        'Dez/18',
    ],

    datasets: [
        {
            label: 'Repasses vendidos',
            fill: false,
            backgroundColor: colors.primary,
            borderColor: colors.primary,
            borderDash: [],
            borderJoinStyle: 'round',
            pointBorderColor: colors.primary,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: colors.primary,
            pointHoverBorderColor: '#fff',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 5,
            data: [10, 11, 8, 9, 12, 5, 7, 10, 15, 12, 11, 11],
        },
        {
            label: 'Repasses anunciados',
            fill: false,
            backgroundColor: colors.secondary,
            borderColor: colors.secondary,
            borderDash: [],
            borderJoinStyle: 'round',
            pointBorderColor: colors.secondary,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: colors.secondary,
            pointHoverBorderColor: '#fff',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 5,
            data: [15, 16, 10, 11, 13, 10, 8, 15, 16, 17, 13, 15],
        },
        {
            label: 'Repasses turbinados',
            fill: false,
            backgroundColor: colors.tertiary,
            borderColor: colors.tertiary,
            borderDash: [10, 10],
            borderJoinStyle: 'round',
            pointBorderColor: colors.tertiary,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: colors.tertiary,
            pointHoverBorderColor: '#fff',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 5,
            data: [6, 6, 3, 4, 6, 3, 1, 5, 6, 6, 4, 5],
        },
    ],
};

const repassesValorTotal = {
    labels: [
        'Jan/18',
        'Fev/18',
        'Mar/18',
        'Abr/18',
        'Mai/18',
        'Jun/18',
        'Jul/18',
        'Ago/18',
        'Set/18',
        'Out/18',
        'Nov/18',
        'Dez/18',
    ],
    datasets: [
        {
            label: 'Repasses vendidos',
            fill: false,
            backgroundColor: colors.primary,
            borderColor: colors.primary,
            borderDash: [],
            borderJoinStyle: 'round',
            pointBorderColor: colors.primary,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: colors.primary,
            pointHoverBorderColor: '#fff',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 5,
            data: [500.2, 570, 463, 500, 574, 592, 483, 494, 490, 540, 520, 595],
        },
    ],
};

export { repassesAnunciados, repassesValorTotal };
