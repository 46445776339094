import { createActions, createReducer } from 'reduxsauce';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
    /** Async actions */
    getRepasse: ['car_id', 'user_id'],
    getInteressados: ['car_id'],
    getScoreboard: [],
    getTurboReach: ['car_id'],
    consumeTurbo: ['car_id'],
    getChats: ['store_id', 'car_id'],
    getChatMessages: ['car_id', 'room_id'],

    /** State actions */
    setRepasse: ['repasse'],
    setInteressados: ['interessados'],
    setTurboReach: ['turboReach'],
    setPermissionsRepasse: ['additionalTabs', 'turboCall'],
    cleanRepasse: [],
    setError: ['msg'],
    toggleLoader: ['loading'],
    resetApp: [''],
    setChats: ['chats'],
    setChatMessages: ['messages', 'room_id'],
    setChatBadge: ['chatBadge'],
});

/* Initial State */

const INITIAL_STATE = {
    repasse: {},
    interessados: [],
    qty_turbos: 0,
    turboReach: {},
    error: '',
    loading: false,
    chats: [],
    chatBadge: null,
    additionalTabsPermission: false,
    turboCallPermission: false,
};

/* Reducers */

const toggleLoading = (state = INITIAL_STATE, action) => ({
    ...state,
    loading: action.loading,
});

const setItemRepasse = (state = INITIAL_STATE, action) => ({
    ...state,
    repasse: action.repasse,
});

const setItemInteressados = (state = INITIAL_STATE, action) => ({
    ...state,
    interessados: action.interessados,
});

const setTurboReach = (state = INITIAL_STATE, action) => ({
    ...state,
    turboReach: action.turboReach,
});

const setPermissionsRepasse = (state = INITIAL_STATE, action) => ({
    ...state,
    additionalTabsPermission: action.additionalTabs,
    turboCallPermission: action.turboCall,
});

const setError = (state, action) => ({
    ...state,
    error: action.msg,
});

const cleanRepasseItem = (state = INITIAL_STATE) => ({
    ...state,
    ...INITIAL_STATE,
});

const resetApp = (state = INITIAL_STATE) => ({
    ...state,
    ...INITIAL_STATE,
});

const setChats = (state = INITIAL_STATE, action) => ({
    ...state,
    chats: action.chats,
});

const setChatMessages = (state = INITIAL_STATE, action) => {
    let chat = state.chats.find(item => item.room_id === action.room_id);
    let new_chats = state.chats;

    new_chats = new_chats.filter(item => item !== chat);

    chat = { ...chat, messages: action.messages };

    new_chats = [...new_chats, chat];

    return {
        ...state,
        chats: new_chats,
    };
};

const setChatBadge = (state = INITIAL_STATE, action) => ({
    ...state,
    chatBadge: action.chatBadge,
});

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
    [Types.SET_REPASSE]: setItemRepasse,
    [Types.SET_INTERESSADOS]: setItemInteressados,
    [Types.SET_TURBO_REACH]: setTurboReach,
    [Types.SET_PERMISSIONS_REPASSE]: setPermissionsRepasse,
    [Types.SET_ERROR]: setError,
    [Types.TOGGLE_LOADER]: toggleLoading,
    [Types.CLEAN_REPASSE]: cleanRepasseItem,
    [Types.RESET_APP]: resetApp,
    [Types.SET_CHATS]: setChats,
    [Types.SET_CHAT_MESSAGES]: setChatMessages,
    [Types.SET_CHAT_BADGE]: setChatBadge,
});
