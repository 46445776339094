import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.sass';

export default class AlterarFotoCadastro extends Component {
    render() {
        return (
            <div className="alterar-foto-cadastro">
                <div className="foto">
                    <img src={this.props.photo} alt="" />
                </div>

                <input
                    type="file"
                    accept="image/*"
                    onChange={this.props.onChange}
                    className="hidden-input"
                    id="CadastroUsuario-avatar-input"
                    onClick={event => {
                        event.target.value = null;
                    }}
                />

                <div className="container-botoes">
                    <label className="botao-item" htmlFor="CadastroUsuario-avatar-input">
                        Mudar Foto <FontAwesomeIcon className="icone icone-mudar" icon="sync-alt" />
                    </label>
                    <label className="botao-item" onClick={this.props.onRemove}>
                        Excluir <FontAwesomeIcon className="icone icone-lixo" icon="trash" />
                    </label>
                </div>
            </div>
        );
    }
}
