import { all, call, put, select } from 'redux-saga/effects';

import { Creators } from '../ducks/repasseView';
import { Creators as AuthActions } from '../ducks/auth';
import api from '../../services/api';

export function* getRepasse({ car_id, user_id }) {
    try {
        const {
            auth: { havePlan, roleUser, storeId, user },
            repasseView: {
                repasse: { car },
            },
        } = yield select(state => state);

        /**
         * Verifica senão existe algum anúncio carregado
         * ou se o anúncio carregado é diferente do anterior,
         *
         * caso verdadeiro renova os dados do anúncio,
         * caso contrário não carrega as requisições
         */
        if (!car || Number(car.id) !== Number(car_id)) {
            yield put(Creators.cleanRepasse());
            yield put(Creators.toggleLoader(true));

            const {
                data: { data },
            } = yield call(api.get, `/api/v2/info-chat/${car_id}/${user_id}`);

            yield put(Creators.setRepasse(data));

            const [additionalTabsPermission, turboCallPermission] = yield all([
                call(hasPermissionToAdditionalTabs, {
                    roleUser,
                    storeId,
                    user,
                }),

                call(hasPermissionToTurboCall, {
                    havePlan,
                    roleUser,
                    storeId,
                }),
            ]);

            /** Carrega interessados e chats somente com permissão */
            if (additionalTabsPermission) {
                yield all([
                    call(getInteressados, car_id),
                    call(getChats, storeId, car_id),

                    /** Carrega opções de turbo somente com permissão */
                    // turboCallPermission && call(getScoreboard),
                    turboCallPermission && call(getTurboReach, car_id),
                ]);

                yield put(
                    Creators.setPermissionsRepasse(additionalTabsPermission, turboCallPermission)
                );
            }
        }
    } catch (error) {
        console.log({ error });
    } finally {
        yield put(Creators.toggleLoader(false));
    }
}

function* hasPermissionToAdditionalTabs({ roleUser, storeId, user }) {
    const {
        repasse: { car },
    } = yield select(state => state.repasseView);

    const user_store_id = storeId;

    const isDirectorFromStore = car.store_id === Number(user_store_id) && roleUser.id === 2;
    const isManagerFromStore = car.store_id === Number(user_store_id) && roleUser.id === 3;
    const isRepasseFromSellerFromStore = car.store_id === Number(user_store_id);
    const isOwner = user.id === car.user.id;

    if (isDirectorFromStore || (isManagerFromStore && isRepasseFromSellerFromStore) || isOwner)
        return true;
    else return false;
}

function* getInteressados(car_id) {
    try {
        const {
            data: { data },
        } = yield call(api.get, `/api/v2/car-negotiations/${car_id}`);

        yield put(Creators.setInteressados(data));
    } catch (error) {
        console.log({ error });
    }
}

function* getChats(store_id, car_id) {
    try {
        const { data } = yield call(api.get, `api/v2/store/${store_id}/car/${car_id}/chats`);
        const { items, badge } = data.data;

        /** Armazena a listagem dos chats */
        yield put(Creators.setChats(items));

        /** Armazena o somatório de mensagens pendentes */
        yield put(Creators.setChatBadge(badge));

        /** Busca as mensagens de cada chat */
        yield all(items.map(item => call(getChatMessages, car_id, item.room_id)));
    } catch (error) {
        console.log({ error });
    }
}

function* getChatMessages(car_id, room_id) {
    try {
        const { data } = yield call(api.get, `api/v2/car/${car_id}/chat/${room_id}`);
        const messages = data.data.dates;

        yield put(Creators.setChatMessages(messages, room_id));
    } catch (error) {
        console.log({ error });
    }
}

function* hasPermissionToTurboCall({ havePlan, roleUser, storeId }) {
    const {
        repasse: { car },
    } = yield select(state => state.repasseView);

    const user_store_id = storeId;
    const isDirectorFromStore = car.store_id === Number(user_store_id) && roleUser.id === 2;

    if (!havePlan) return false;

    if (Number(car.car_status.id) === 3 || Number(car.car_status.id) === 5) return false;

    return isDirectorFromStore;
}

export function* getScoreboard() {
    try {
        const {
            data: { data },
        } = yield call(api.get, '/api/v2/report/scoreboard');

        yield put(
            AuthActions.setScoreboard({
                qty_active: data.qty_active,
                qty_cars: data.qty_cars,
                qty_franchise: data.qty_franchise,
                qty_salesman: data.qty_salesman,
                qty_transactions: data.qty_transactions,
                qty_turbos: data.qty_turbos,
            })
        );
    } catch (error) {
        console.log(error);
    }
}

function* getTurboReach(car_id) {
    try {
        const { data } = yield call(api.get, `/api/v2/products/reach/${car_id}`);

        if (data.hasOwnProperty('calls')) yield put(Creators.setTurboReach(data));
    } catch (error) {
        console.log({ error });
    }
}
