import React, { Component } from 'react';
import './style.sass';
import PlanoCadastroMobileItem from '../PlanoCadastroMobileItem';

export default class PlanoCadastroMobile extends Component {
    render() {
        return (
            <div className="plano-cadastro-mobile">
                {this.props.items &&
                    this.props.items.map(item => (
                        <div className="item-cadastro" key={item.id}>
                            <PlanoCadastroMobileItem
                                idPlano={item.id}
                                choosePlan={this.props.choosePlan}
                                planObject={item}
                                nomePlano={item.title}
                                valorPlano={item.price}
                                transacoesMensais={item.qty_transactions}
                                adicionalTransacao={item.transaction_price}
                                fotosVeiculo="Ilimitado"
                                turbosMes={item.qty_turbos}
                                turboAvulso="50"
                                consultaPrimeiroMes={item.qty_car_queries}
                                consultaVeicular="60"
                                diretores="Ilimitado"
                                gerentes="Ilimitado"
                                vendedores="Ilimitado"
                            />
                        </div>
                    ))}
            </div>
        );
    }
}
