import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.sass';
import classNames from 'classnames';

const intlMonetary = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
});

export default class UsuarioChatItem extends Component {
    state = {
        seen: false,
    };

    componentDidUpdate = prevProps => {
        if (this.props.ativo !== prevProps.ativo && this.props.chatProprio) {
            this.setState({ seen: true });
        }
    };

    render() {
        const ActiveUserController = classNames('usuario-item-container', {
            ativo: this.props.ativo,
        });

        return (
            <div className={ActiveUserController}>
                <div className="foto-usuario">
                    <img src={this.props.caminhoImagem} alt="" />
                </div>
                <div className="info-usuario">
                    <div className="nome-usuario">
                        <div className="texto">{this.props.nomeUsuario}</div>
                        {!this.state.seen && this.props.mensagensPendentes > 0 && (
                            <div
                                className="badge"
                                style={
                                    this.props.mensagensPendentes > 99
                                        ? {
                                              borderRadius: '45%',
                                              height: '1.5rem',
                                              width: '2.5rem',
                                          }
                                        : null
                                }
                            >
                                {this.props.mensagensPendentes > 99
                                    ? '99+'
                                    : this.props.mensagensPendentes}
                            </div>
                        )}
                    </div>

                    {Number(this.props.valor) > 0 && (
                        <div className="info-line">
                            <div className="icone">
                                <FontAwesomeIcon icon="dollar-sign" />
                            </div>
                            <div className="texto">{intlMonetary.format(this.props.valor)}</div>
                        </div>
                    )}

                    <div className="info-line">
                        <div className="icone phone">
                            <FontAwesomeIcon icon="phone" />
                        </div>
                        <div className="texto">{this.props.telefone1}</div>
                    </div>
                    <div className="info-line">
                        <div className="icone phone">
                            <FontAwesomeIcon icon="phone" />
                        </div>
                        <div className="texto">{this.props.telefone2}</div>
                    </div>
                </div>
            </div>
        );
    }
}
