import React from 'react';
import classNames from 'classnames';

import iconTeam from '../../assets/images/icon-people.png';

import './style.sass';

/**
 * InteressadoHeader
 *
 * @param {number} props.numberInterested Número de pessoas interessadas.
 */
const InteressadoHeader = props => {
    let numberInterested = props.numberInterested;
    let counterString = 'pessoa interessada';
    let isLite = props.isLite ? props.isLite : false;

    if (typeof numberInterested != 'number') {
        numberInterested = parseInt(numberInterested, 10);
    }

    if (numberInterested > 1) {
        counterString = 'pessoas interessadas';
    }

    const InteressadoHeaderClass = classNames({
        InteressadoHeader: true,
        'is-lite': isLite,
    });

    return (
        <div className={InteressadoHeaderClass}>
            <div className="InteressadoHeader-item">
                <div className="InteressadoHeader-counter">
                    <img className="InteressadoHeader-counter-icon" src={iconTeam} alt="" />
                    <div className="InteressadoHeader-counter-number">{numberInterested}</div>
                    <span className="InteressadoHeader-counter-text">{counterString}</span>
                </div>
            </div>

            {!isLite && (
                <>
                    <div className="InteressadoHeader-item">Solicitou número?</div>
                    <div className="InteressadoHeader-item">Negociou no chat?</div>
                    <div className="InteressadoHeader-item">Proposta</div>
                </>
            )}
        </div>
    );
};

export default InteressadoHeader;
