import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import './style.sass';

import TopBar from '../../components/TopBar';
import CardConteudo from '../../components/CardConteudo';

import CRVehicleDetails from '../../components/CRVehicleDetails';
import CRVehicleSelection from '../../components/CRVehicleSelection';
import CRGallery from '../../components/CRGallery';
import CRConfirmation from '../../components/CRConfirmation';
import CRFinish from '../../components/CRFinish';
import CadastroProgressBar from '../../components/CadastroProgressBar';
import TitleAside from '../../components/TitleAside';
import { baseURL } from '../../services/api';

class PageCadastroRepasse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            update: false,
            vehicleType: '',
            highlightImage: { path: '' },
            vehicleBrand: '',
            vehicleModelYear: '',
            vehicleModel: '',
            vehicleDesiredPrice: '',
            vehicleCostPrice: '',
            vehicleFipePrice: '',
            vehicleFipePercent: 15,
            vehicleColor: '',
            vehicleDoors: 4,
            vehicleKilometrage: '',
            vehicleEixo: '',
            vehicleOptionals: [],
            vehicleDescription: '',
            gallery: [],
            galleryDelete: [],
            seller: '',
            car_id: '',
        };
    }

    componentDidMount() {
        const { state } = this.props.location;

        if (state && state.car) {
            const { car } = state;

            this.setState({
                update: true,
                vehicleType: car.car_type,
                highlightImage: {
                    id: car.photos[0].id,
                    path: `${baseURL}/uploads/gallery/${car.photos[0].filename}`,
                },
                vehicleBrand: car.fipe.brand_id.toString(),
                vehicleModelYear: car.fipe.year,
                vehicleModel: car.fipe.id.toString(),
                vehicleDesiredPrice: car.price,
                vehicleCostPrice: car.cost,
                vehicleFipePrice: car.fipe.price,
                vehicleFipePercent: Math.abs(car.fipe_percent_off.split('%')[0]),
                vehicleColor: car.color.id.toString(),
                vehicleDoors: car.doors,
                vehicleKilometrage: car.km.toString(),
                vehicleEixo: car.eixos,
                vehicleOptionals: car.optionals.map(item => item.id),
                vehicleDescription: car.observation,
                gallery: car.photos.map(item => ({
                    id: item.id,
                    path: `${baseURL}/uploads/gallery/${item.filename}`,
                })),
                seller: '',
                car_id: car.id,
            });
        }
    }

    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
        this.scrollTop();
    };

    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
        this.scrollTop();
    };

    goToStep = step => {
        this.setState({ step });
        this.scrollTop();
    };

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    };

    handleStateChange = (input, stateData) => {
        this.setState({ [input]: stateData });
    };

    scrollTop = () => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };

    render() {
        const { step, update } = this.state;

        return (
            <React.Fragment>
                <TopBar />

                <Helmet>
                    <title>Cadastro de Repasse • Repasse</title>
                </Helmet>

                <div className="page-container">
                    <div className="content-container">
                        <div className="PageCadastroRepasse">
                            <TitleAside title="Cadastro de Repasse" />

                            <CardConteudo>
                                <div className="CadastroRepasse-box">
                                    <div className="CadastroRepasse-progress">
                                        <CadastroProgressBar
                                            currentStep={this.state.step}
                                            steps={
                                                !update
                                                    ? [
                                                          'Tipo de veículo',
                                                          'Detalhes do veículo',
                                                          'Galeria de fotos',
                                                          'Confirmação do repasse',
                                                          'Cadastro concluído',
                                                      ]
                                                    : [
                                                          'Detalhes do veículo',
                                                          'Galeria de fotos',
                                                          'Confirmação do repasse',
                                                          'Edição concluída',
                                                      ]
                                            }
                                        />
                                    </div>

                                    <div className="CadastroRepasse-content">
                                        {!update && step === 1 && (
                                            <div className="CadastroRepasse step-1">
                                                <CRVehicleSelection
                                                    nextStep={this.nextStep}
                                                    handleChange={this.handleChange}
                                                    handleStateChange={this.handleStateChange}
                                                />
                                            </div>
                                        )}

                                        {((!update && step === 2) || (update && step === 1)) && (
                                            <div className="CadastroRepasse step-2">
                                                <CRVehicleDetails
                                                    update={update}
                                                    galleryDelete={this.state.galleryDelete}
                                                    nextStep={this.nextStep}
                                                    prevStep={this.prevStep}
                                                    handleChange={this.handleChange}
                                                    handleStateChange={this.handleStateChange}
                                                    vehicleType={this.state.vehicleType}
                                                    highlightImage={this.state.highlightImage}
                                                    vehicleBrand={this.state.vehicleBrand}
                                                    vehicleModelYear={this.state.vehicleModelYear}
                                                    vehicleModel={this.state.vehicleModel}
                                                    vehicleFipePrice={this.state.vehicleFipePrice}
                                                    vehicleFipePercent={
                                                        this.state.vehicleFipePercent
                                                    }
                                                    vehicleColor={this.state.vehicleColor}
                                                    vehicleDoors={this.state.vehicleDoors}
                                                    vehicleOptionals={this.state.vehicleOptionals}
                                                    vehicleKilometrage={
                                                        this.state.vehicleKilometrage
                                                    }
                                                    vehicleEixo={this.state.vehicleEixo}
                                                    vehicleDesiredPrice={
                                                        this.state.vehicleDesiredPrice
                                                    }
                                                    vehicleCostPrice={this.state.vehicleCostPrice}
                                                    vehicleDescription={
                                                        this.state.vehicleDescription
                                                    }
                                                />
                                            </div>
                                        )}

                                        {((!update && step === 3) || (update && step === 2)) && (
                                            <div className="CadastroRepasse step-3">
                                                <CRGallery
                                                    nextStep={this.nextStep}
                                                    prevStep={this.prevStep}
                                                    handleChange={this.handleChange}
                                                    handleStateChange={this.handleStateChange}
                                                    gallery={this.state.gallery}
                                                    galleryDelete={this.state.galleryDelete}
                                                />
                                            </div>
                                        )}

                                        {((!update && step === 4) || (update && step === 3)) && (
                                            <div className="CadastroRepasse step-4">
                                                <CRConfirmation
                                                    update={update}
                                                    nextStep={this.nextStep}
                                                    prevStep={this.prevStep}
                                                    handleChange={this.handleChange}
                                                    handleStateChange={this.handleStateChange}
                                                    carData={this.state}
                                                    vehicleType={this.state.vehicleType}
                                                    highlightImage={this.state.highlightImage}
                                                    vehicleBrand={this.state.vehicleBrand}
                                                    vehicleModelYear={this.state.vehicleModelYear}
                                                    vehicleModel={this.state.vehicleModel}
                                                    vehicleDesiredPrice={
                                                        this.state.vehicleDesiredPrice
                                                    }
                                                    vehicleFipePrice={this.state.vehicleFipePrice}
                                                    vehicleFipePercent={
                                                        this.state.vehicleFipePercent
                                                    }
                                                    vehicleColor={this.state.vehicleColor}
                                                    vehicleDoors={this.state.vehicleDoors}
                                                    vehicleKilometrage={
                                                        this.state.vehicleKilometrage
                                                    }
                                                    vehicleEixo={this.state.vehicleEixo}
                                                    vehicleOptionals={this.state.vehicleOptionals}
                                                    vehicleDescription={
                                                        this.state.vehicleDescription
                                                    }
                                                    gallery={this.state.gallery}
                                                    seller={this.state.seller}
                                                    goToStep={this.goToStep}
                                                />
                                            </div>
                                        )}

                                        {((!update && step === 5) || (update && step === 4)) && (
                                            <div className="CadastroRepasse step-5">
                                                <CRFinish
                                                    update={update}
                                                    highlightImage={this.state.highlightImage}
                                                    vehicleModelYear={this.state.vehicleModelYear}
                                                    vehicleModel={this.state.vehicleModel}
                                                    vehicleDesiredPrice={
                                                        this.state.vehicleDesiredPrice
                                                    }
                                                    vehicleFipePrice={this.state.vehicleFipePrice}
                                                    vehicleFipePercent={
                                                        this.state.vehicleFipePercent
                                                    }
                                                    vehicleColor={this.state.vehicleColor}
                                                    car_id={this.state.car_id}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </CardConteudo>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PageCadastroRepasse;
