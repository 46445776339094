import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Line, defaults } from 'react-chartjs-2';
import { connect } from 'react-redux';

import { repassesAnunciados, repassesValorTotal } from './data-relatorios';

import TopBar from '../../components/TopBar';
import CardConteudo from '../../components/CardConteudo';
import ReportStatusItem from '../../components/ReportStatusItem';
import SpinnerFull from '../../components/SpinnerFull';
import TitleAside from '../../components/TitleAside';

import './style.sass';

import api from '../../services/api';

import moment from 'moment';

const intlMonetary = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
});

class PageRelatorios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            date_range: null,
            overview: {},
            graphic_one: {},
            graphic_two: {},
            average: {},
        };

        // Setting global chartjs settings
        defaults.global.maintainAspectRatio = false;
        defaults.global.defaultFontColor = '#78909C';
        defaults.global.defaultFontWeight = 'normal';
        defaults.global.defaultFontFamily = 'Avenir Next, sans-serif';
        defaults.global.legend.position = 'bottom';
        defaults.global.legend.fullWidth = false;
        defaults.global.tooltips.mode = 'x';
        defaults.global.tooltips.titleFontSize = 14;
        defaults.global.tooltips.titleMarginBottom = 8;
        defaults.global.tooltips.bodyFontSize = 14;
        defaults.global.tooltips.bodySpacing = 7;
        defaults.global.tooltips.xPadding = 10;
        defaults.global.tooltips.yPadding = 10;
        defaults.global.legend.labels.boxWidth = 20;
        defaults.global.legend.labels.padding = 30;
        defaults.global.legend.labels.fontSize = 14;
        defaults.global.elements.line.tension = 0;

        this.repassesValorTotalOptions = {
            legend: {
                display: false,
            },
        };
    }

    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        const { store_id } = this.props;
        this.getReportData('1', repassesAnunciados, repassesValorTotal, store_id);
    }

    getReportData = async (date_range, graphic_one, graphic_two, store_id) => {
        try {
            this.setState({ loading: true });

            const response = await api.get(
                `/api/v2/report/overview?date_range=${date_range}&store_id=${store_id}`
            );
            const { data } = response.data;

            let graphic_1 = graphic_one;
            let graphic_2 = graphic_two;

            if (date_range >= 4) {
                graphic_1.labels = data.graphic_one.axis_x.map(item =>
                    moment(item, 'YYYY-MM').format('MMM/YY')
                );

                graphic_2.labels = data.graphic_two.axis_x.map(item =>
                    moment(item, 'YYYY-MM').format('MMM/YY')
                );
            } else {
                graphic_1.labels = data.graphic_one.axis_x.map(item =>
                    moment(item, 'YYYY-MM-DD').format('DD/MM/YY')
                );

                graphic_2.labels = data.graphic_two.axis_x.map(item =>
                    moment(item, 'YYYY-MM-DD').format('DD/MM/YY')
                );
            }

            graphic_1.datasets[0].data = data.graphic_one.values[0];
            graphic_1.datasets[1].data = data.graphic_one.values[1];
            graphic_1.datasets[2].data = data.graphic_one.values[2];

            graphic_2.datasets[0].data = data.graphic_two.values[0];

            data.graphic_one = graphic_1;
            data.graphic_two = graphic_2;

            this.setReportData(date_range, data);
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    setReportData = (date_range, data) => {
        this.setState({
            date_range: date_range,
            overview: data.overview,
            graphic_one: data.graphic_one,
            graphic_two: data.graphic_two,
            average: data.average,
        });
    };

    render() {
        const { overview, graphic_one, graphic_two, average, loading, date_range } = this.state;
        const { store_id } = this.props;

        return (
            <React.Fragment>
                <TopBar />

                <Helmet>
                    <title>Relatórios • Repasse</title>
                </Helmet>

                <div className="page-container">
                    <div className="content-container">
                        <TitleAside title="Relatórios" />
                        <SpinnerFull
                            size={40}
                            spinnerColor={'#335180'}
                            spinnerWidth={3}
                            visible={loading}
                        />

                        {!loading && (
                            <div className="PageRelatorios">
                                <div className="section-relatorios margin-bottom">
                                    <CardConteudo>
                                        <div className="TitleWSelect">
                                            <div className="TitleWSelect-title-container">
                                                <div className="global-status-title">
                                                    Visão Geral
                                                </div>
                                            </div>
                                            <div className="TitleWSelect-select-container">
                                                <select
                                                    className="form-control"
                                                    name=""
                                                    id=""
                                                    onChange={e =>
                                                        this.getReportData(
                                                            e.target.value,
                                                            repassesAnunciados,
                                                            repassesValorTotal,
                                                            store_id
                                                        )
                                                    }
                                                    value={date_range}
                                                >
                                                    <option value="1">Últimos 7 dias</option>
                                                    <option value="2">Mês atual</option>
                                                    <option value="3">Mês anterior</option>
                                                    <option value="4">Último trimestre</option>
                                                    <option value="5">Último semestre</option>
                                                    <option value="6">Último ano</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="global-status-listing --valores">
                                            <ReportStatusItem
                                                title="Total anunciado"
                                                content={
                                                    overview.total_announced
                                                        ? intlMonetary.format(
                                                              overview.total_announced
                                                          )
                                                        : intlMonetary.format(0)
                                                }
                                                bgColor="#335180"
                                            />
                                            <ReportStatusItem
                                                title="Total vendido"
                                                content={
                                                    overview.total_sold
                                                        ? intlMonetary.format(overview.total_sold)
                                                        : intlMonetary.format(0)
                                                }
                                                bgColor="#335180"
                                            />
                                            <ReportStatusItem
                                                title="Total disponível para venda"
                                                content={
                                                    overview.total_available
                                                        ? intlMonetary.format(
                                                              overview.total_available
                                                          )
                                                        : intlMonetary.format(0)
                                                }
                                                bgColor="#335180"
                                            />
                                        </div>
                                        <div className="global-status-listing --quantidades">
                                            <ReportStatusItem
                                                title="Anunciado na plataforma"
                                                content={
                                                    overview.qty_announced
                                                        ? overview.qty_announced
                                                        : 0
                                                }
                                                color="#335180"
                                                bgColor="#fff"
                                                border="2px solid #335180"
                                            />
                                            <ReportStatusItem
                                                title="Negociado na plataforma"
                                                content={
                                                    overview.qty_negotiated
                                                        ? overview.qty_negotiated
                                                        : 0
                                                }
                                                color="#335180"
                                                bgColor="#fff"
                                                border="2px solid #335180"
                                            />
                                            <ReportStatusItem
                                                title="Vendidos na plataforma"
                                                content={overview.qty_sold ? overview.qty_sold : 0}
                                                color="#335180"
                                                bgColor="#fff"
                                                border="2px solid #335180"
                                            />
                                            <ReportStatusItem
                                                title="Disponíveis na plataforma"
                                                content={
                                                    overview.qty_available
                                                        ? overview.qty_available
                                                        : 0
                                                }
                                                color="#335180"
                                                bgColor="#fff"
                                                border="2px solid #335180"
                                            />
                                            <ReportStatusItem
                                                title="Turbos utilizados"
                                                content={
                                                    overview.turbos_used ? overview.turbos_used : 0
                                                }
                                                color="#335180"
                                                bgColor="#fff"
                                                border="2px solid #335180"
                                            />
                                            <ReportStatusItem
                                                title="Turbos ativos"
                                                content={
                                                    overview.total_turbos_active
                                                        ? overview.total_turbos_active
                                                        : 0
                                                }
                                                color="#335180"
                                                bgColor="#fff"
                                                border="2px solid #335180"
                                            />
                                        </div>
                                    </CardConteudo>
                                </div>

                                <div className="section-relatorios margin-bottom">
                                    <CardConteudo>
                                        <header className="chart-header">
                                            <div className="chart-title">Repasses anunciados</div>
                                            <div className="chart-subtitle">
                                                Oportunidades de vendas
                                            </div>
                                        </header>
                                        <div className="chart-container">
                                            <Line data={graphic_one} height={400} />
                                        </div>
                                    </CardConteudo>
                                </div>

                                <div className="section-relatorios">
                                    <CardConteudo>
                                        <div className="relatorios-row">
                                            <div className="relatorios-col">
                                                <header className="chart-header">
                                                    <div className="chart-title">
                                                        Valor total em Repasses
                                                    </div>
                                                    <div className="chart-total">
                                                        {average.total_sold
                                                            ? intlMonetary.format(
                                                                  average.total_sold
                                                              )
                                                            : intlMonetary.format(0)}
                                                    </div>
                                                </header>
                                                <div className="chart-container">
                                                    <Line
                                                        data={graphic_two}
                                                        options={this.repassesValorTotalOptions}
                                                        height={300}
                                                    />
                                                </div>
                                            </div>
                                            <div className="relatorios-col">
                                                <div className="status-listing">
                                                    <ReportStatusItem
                                                        title="Valor total de repasses vendidos"
                                                        content={
                                                            average.total_sold
                                                                ? intlMonetary.format(
                                                                      average.total_sold
                                                                  )
                                                                : intlMonetary.format(0)
                                                        }
                                                    />
                                                    <ReportStatusItem
                                                        title="Ticket médio repassados"
                                                        content={
                                                            average.average_sold
                                                                ? intlMonetary.format(
                                                                      average.average_sold
                                                                  )
                                                                : intlMonetary.format(0)
                                                        }
                                                    />
                                                    <ReportStatusItem
                                                        title="Ticket médio cadastrados"
                                                        content={
                                                            average.avarage_availabel
                                                                ? intlMonetary.format(
                                                                      average.avarage_availabel
                                                                  )
                                                                : intlMonetary.format(0)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </CardConteudo>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    store_id: auth.storeId,
});

export default connect(mapStateToProps)(PageRelatorios);
