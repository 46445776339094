import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import CardConteudo from '../../components/CardConteudo';
import TopBar from '../../components/TopBar';

import iconWarning from '../../assets/images/icon-warning-lg.png';

import './style.sass';

class PageNaoAutorizado extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <TopBar />
                <div className="PageNaoAutorizado page-container">
                    <div className="content-container">
                        <CardConteudo>
                            <div className="PageNaoAutorizado-card-content">
                                <img className="PageNaoAutorizado-image" src={iconWarning} alt="" />
                                <h2 className="PageNaoAutorizado-title">Acesso Negado</h2>
                                <div className="PageNaoAutorizado-subtitle">
                                    Você não tem permissão para acessar esta página.
                                </div>
                                <Link className="button blue" to="/">
                                    Voltar para a página inicial
                                </Link>
                            </div>
                        </CardConteudo>
                    </div>
                </div>
            </>
        );
    }
}

export default PageNaoAutorizado;
