import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import './style.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators as RepassesListActions } from '../../store/ducks/repassesList';
import TopBar from '../../components/TopBar';
import TitleAside from '../../components/TitleAside';
import CardConteudo from '../../components/CardConteudo';
import IconText from '../../components/IconText';
import Modal from '../../components/Modal';
import FormGroupSelect from '../../components/FormGroupSelect';
import MessageBox from '../../components/MessageBox';

import iconCar from '../../assets/images/icon-car.png';
import iconTruck from '../../assets/images/icon-truck.png';
import iconMotorcycle from '../../assets/images/icon-motorcycle.png';
import api, { baseURL } from '../../services/api';

import SpinnerFull from '../../components/SpinnerFull';

class PagePerfilCompra extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            submitted: false,
            profiles: [],
            profileCreated: false,
            states: [],
            state_id: '',
            regions: [],
            region_id: '',
            start_year: '',
            end_year: '',
            types: [],
            errorMessage: '',
            profileInfoDelete: {
                id: '',
                state_name: '',
                region_info: '',
                type: '',
                start_year: '',
                end_year: '',
            },
            profileDeleted: false,
            profileInfoUpdate: {
                id: '',
                state_id: '',
                region_id: '',
                types: [],
                start_year: '',
                end_year: '',
            },
            profileUpdated: false,
        };

        this.modalCreate = React.createRef();
        this.modalUpdate = React.createRef();
        this.modalRemove = React.createRef();
    }

    style = {
        messageBoxContainer: {
            textAlign: 'center',
            margin: '3rem auto 0',
        },
    };

    componentWillMount = () => {
        this.getProfileList();
    };

    getProfileList = async () => {
        try {
            this.setState({ loading: true });
            const profiles = await api.get(`${baseURL}/api/v2/profile/list`);
            this.setState({ profiles: profiles.data.data });
        } catch (error) {
            console.log(error);
            console.log('erro na busca de estados');
        } finally {
            this.setState({ loading: false });
        }
    };

    componentDidMount = async () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        try {
            const states = await api.get(`${baseURL}/api/states`);
            this.setState({ states: states.data.data });
        } catch (error) {
            console.log(error);
            console.log('erro na busca de estados');
        }
    };

    getRegions = async state => {
        try {
            const regions = await api.get(`${baseURL}/api/ddd/${state}`);

            this.setState({ regions: regions.data.data });
        } catch (error) {
            console.log(error);
            console.log('erro na busca de regiões');
        }
    };

    openModalCreate = () => {
        this.setState(
            {
                submitted: false,
                profileCreated: false,
                start_year: '',
                end_year: '',
                state_id: '',
                region_id: '',
                types: [],
                regions: [],
            },
            () => this.modalCreate.current.openModal()
        );
    };

    closeModalCreate = () => {
        this.setState(
            {
                submitted: false,
                profileCreated: false,
                start_year: '',
                end_year: '',
                state_id: '',
                region_id: '',
                types: [],
                regions: [],
            },
            () => this.modalCreate.current.closeModal()
        );
    };

    openModalUpdate = () => {
        this.getRegions(this.state.state_id);
        this.setState({ submitted: false, profileUpdated: false }, () =>
            this.modalUpdate.current.openModal()
        );
    };

    closeModalUpdate = () => {
        this.setState(
            {
                submitted: false,
                profileUpdated: false,
                start_year: '',
                end_year: '',
                state_id: '',
                region_id: '',
                types: [],
                regions: [],
            },
            () => this.modalUpdate.current.closeModal()
        );
    };

    openModalRemove = () => {
        this.setState({ profileDeleted: false }, () => this.modalRemove.current.openModal());
    };

    closeModalRemove = () => {
        this.setState({ profileDeleted: false }, () => this.modalRemove.current.closeModal());
    };

    createProfileShowSuccess = () => {
        this.setState({ profileCreated: true });
    };

    createProfileHideSuccess = () => {
        this.setState({ profileCreated: false });
    };

    updateProfileShowSuccess = () => {
        this.setState({ profileUpdated: true });
    };

    updateProfileHideSuccess = () => {
        this.setState({ profileUpdated: false });
    };

    deleteProfileShowSuccess = () => {
        this.setState({ profileDeleted: true });
    };

    deleteProfileHideSuccess = () => {
        this.setState({ profileDeleted: false });
    };

    saveProfile = async () => {
        this.setState({ loading: true, submitted: true });
        let { state_id, region_id, start_year, end_year, types } = this.state;
        let yearToday = new Date().getFullYear();

        let body = {
            state_id,
            array_ddd: [region_id],
            array_types: types,
            start_year: start_year ? start_year : yearToday,
            end_year: end_year ? end_year : yearToday,
        };

        console.log('Body: ', body);

        if (
            !body.state_id ||
            !region_id ||
            !body.array_types.length ||
            !body.start_year ||
            !body.end_year ||
            body.start_year > body.end_year
        ) {
            return this.setState({ loading: false });
        }

        console.log(body);

        try {
            const response = await api.post(`${baseURL}/api/v2/profile/save`, body);
            this.setState({ loading: false }, () => this.createProfileShowSuccess());
            console.log('Response: ', response);
            this.getProfileList();
            this.props.requestRepasses();
        } catch (error) {
            console.log({ error });
            this.setState({ loading: false, errorMessage: error.message }, () =>
                this.createProfileHideSuccess()
            );
        }
    };

    setUpdateProfile = id => {
        let updated = this.state.profiles.find(item => item.id === id);

        console.log('Updated: ', updated);

        this.setState(
            {
                id: updated.id,
                state_id: updated.state_id,
                region_id: updated.ddds.map(item => item.id),
                types: updated.types.map(item => item.id),
                start_year: updated.start_year,
                end_year: updated.end_year,
            },
            () => this.openModalUpdate()
        );
    };

    updateProfile = async id_profile => {
        this.setState({ loading: true });

        let { id, state_id, region_id, types, start_year, end_year } = this.state;

        console.log(id);
        let body = {
            state_id: state_id,
            array_ddd: Array.isArray(region_id) ? region_id : [region_id],
            array_types: types,
            start_year: start_year,
            end_year: end_year,
        };

        if (body.start_year > body.end_year) {
            return this.setState({ loading: false });
        }

        try {
            const response = await api.post(`${baseURL}/api/v2/profile/${id}/update`, body);
            console.log(response);
            this.setState({ loading: false }, () => this.updateProfileShowSuccess());
            this.getProfileList();
            this.props.requestRepasses();
        } catch (error) {
            console.log({ error });
            this.setState({ loading: false, errorMessage: error.message }, () =>
                this.updateProfileHideSuccess()
            );
        }
    };

    setDeleteProfile = id => {
        let deleted = this.state.profiles.find(item => item.id === id);

        console.log(deleted);

        this.setState(
            {
                profileInfoDelete: {
                    ...this.state.profileInfoDelete,
                    id: deleted.id,
                    state_name: deleted.ddds.length ? deleted.ddds[0].state_name : '',
                    region_info: deleted.ddds.length
                        ? `${deleted.ddds[0].ddd} - ${deleted.ddds[0].name}`
                        : '',
                    types: deleted.types.length ? deleted.types : '',
                    start_year: deleted.start_year,
                    end_year: deleted.end_year,
                },
            },
            () => this.openModalRemove()
        );
    };

    deleteProfile = async id => {
        this.setState({ loading: true });
        const body = {
            id: [id],
        };

        try {
            const response = await api.post(`${baseURL}/api/v2/profile/remove`, body);
            console.log(response);
            this.setState({ loading: false }, () => this.deleteProfileShowSuccess());
            this.getProfileList();
            this.props.requestRepasses();
        } catch (error) {
            console.log({ error });
            this.setState({ loading: false, errorMessage: error.message }, () =>
                this.deleteProfileHideSuccess()
            );
        }
    };

    formatTypes = (types, view) => {
        if (types && view === 'list') {
            return types
                .sort((a, b) => {
                    if (a.id > b.id) {
                        return 1;
                    }
                    if (a.id < b.id) {
                        return -1;
                    }
                    return 0;
                })
                .map(item => <span>{item.name}</span>);
        }
        if (types && view === 'delete') {
            return types
                .sort((a, b) => {
                    if (a.id > b.id) {
                        return 1;
                    }
                    if (a.id < b.id) {
                        return -1;
                    }
                    return 0;
                })
                .map(item => item.name)
                .join(', ');
        }
    };

    render() {
        const stateOptions = [
            { value: 'default', content: 'Selecionar estado' },
            ...this.state.states.map(item => {
                return { value: item.id, content: item.name };
            }),
        ];

        const regionOptions = [
            { value: 'default', content: 'Selecionar região' },
            ...this.state.regions.map(item => {
                return { value: item.id, content: item.name };
            }),
        ];

        const yearToday = new Date().getFullYear();

        const startYearOptions = Array(yearToday + 2 - 1984)
            .fill()
            .map((item, index) => {
                return { value: yearToday + 1 - index, content: `${yearToday + 1 - index}` };
            });

        const endYearOptions = Array(yearToday + 2 - 1984)
            .fill()
            .map((item, index) => {
                return { value: yearToday + 1 - index, content: `${yearToday + 1 - index}` };
            });

        if (!this.state.start_year) {
            this.setState({ start_year: new Date().getFullYear() });
        }

        if (!this.state.end_year) {
            this.setState({ end_year: new Date().getFullYear() });
        }

        return (
            <React.Fragment>
                <TopBar />

                <Helmet>
                    <title>Perfil de compra • Repasse</title>
                </Helmet>

                <div className="page-container">
                    <div className="content-container">
                        <TitleAside title="Perfil de compra">
                            <button onClick={this.openModalCreate} className="button primary">
                                Criar novo perfil
                            </button>
                        </TitleAside>

                        <SpinnerFull
                            size={40}
                            spinnerColor={'#335180'}
                            spinnerWidth={3}
                            visible={this.state.loading}
                        />

                        <CardConteudo>
                            <div className="PerfilCompra-header">
                                <div className="PerfilCompra-header-item">Perfil</div>
                                <div className="PerfilCompra-header-item">DDD e Região</div>
                                <div className="PerfilCompra-header-item">Tipo</div>
                                <div className="PerfilCompra-header-item">Ano inicial - final</div>
                            </div>
                            <div className="PerfilCompra-listing">
                                {this.state.profiles.length ? (
                                    this.state.profiles.map((item, index) => {
                                        return (
                                            <div className="PerfilCompra-row" key={index}>
                                                <div className="PerfilCompra-item --title">
                                                    <div className="PerfilCompra-item-caption">
                                                        Perfil
                                                    </div>
                                                    {item.ddds.length
                                                        ? item.ddds[0].state_name
                                                        : ''}
                                                </div>
                                                <div className="PerfilCompra-item --region">
                                                    <div className="PerfilCompra-item-caption">
                                                        DDD e Região
                                                    </div>
                                                    {item.ddds.length
                                                        ? `${item.ddds[0].ddd} - ${
                                                              item.ddds[0].name
                                                          }`
                                                        : ''}
                                                </div>
                                                <div className="PerfilCompra-item --type">
                                                    <div className="PerfilCompra-item-caption">
                                                        Tipo
                                                    </div>
                                                    {item.types.length
                                                        ? this.formatTypes(item.types, 'list')
                                                        : ''}
                                                </div>
                                                <div className="PerfilCompra-item --year">
                                                    <div className="PerfilCompra-item-caption">
                                                        Ano inicial - final
                                                    </div>
                                                    {`${item.start_year} - ${item.end_year}`}
                                                </div>

                                                <div className="PerfilCompra-item --actions">
                                                    <button
                                                        className="--edit button clean"
                                                        onClick={() =>
                                                            this.setUpdateProfile(item.id)
                                                        }
                                                    >
                                                        <IconText
                                                            icon="pencil-alt"
                                                            text="Editar"
                                                            iconColor="#1e88e5"
                                                        />
                                                    </button>
                                                    <button
                                                        className="--delete button clean"
                                                        onClick={() =>
                                                            this.setDeleteProfile(item.id)
                                                        }
                                                    >
                                                        <IconText
                                                            icon="trash"
                                                            text="Excluir"
                                                            iconColor="#c63f43"
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="margin-top">
                                        Você não cadastrou nenhum perfil de compra. Clique em 'Criar
                                        novo perfil' para cadastrar.
                                    </div>
                                )}
                            </div>
                        </CardConteudo>
                    </div>
                </div>

                <Modal
                    title="Criar perfil de venda"
                    customClass="Modal-PerfilCompra --create"
                    ref={this.modalCreate}
                >
                    {!this.state.profileCreated && (
                        <React.Fragment>
                            <SpinnerFull
                                size={40}
                                spinnerColor={'#335180'}
                                spinnerWidth={3}
                                visible={this.state.loading}
                            />
                            <div className="form-row">
                                <div className="form-col--4">
                                    <FormGroupSelect
                                        label="Estado"
                                        size="small"
                                        options={stateOptions}
                                        value={this.state.state_id}
                                        onChange={event =>
                                            this.setState({ state_id: event.target.value }, () =>
                                                this.getRegions(this.state.state_id)
                                            )
                                        }
                                        errorMessage={
                                            this.state.submitted &&
                                            !this.state.state_id &&
                                            'Campo obrigatório.'
                                        }
                                    />
                                </div>
                                <div className="form-col--4">
                                    <FormGroupSelect
                                        label="Região"
                                        size="small"
                                        options={regionOptions}
                                        value={this.state.region_id}
                                        onChange={event =>
                                            this.setState({ region_id: event.target.value })
                                        }
                                        errorMessage={
                                            this.state.submitted &&
                                            !this.state.region_id &&
                                            'Campo obrigatório.'
                                        }
                                    />
                                </div>
                                <div className="form-col">
                                    <FormGroupSelect
                                        label="Ano inicial"
                                        size="small"
                                        value={this.state.start_year}
                                        options={startYearOptions}
                                        onChange={event =>
                                            this.setState({ start_year: event.target.value })
                                        }
                                        errorMessage={
                                            this.state.submitted &&
                                            parseInt(this.state.start_year) >
                                                parseInt(this.state.end_year) &&
                                            'Ano inicial deve ser igual ou menor do que o final.'
                                        }
                                    />
                                </div>
                                <div className="form-col">
                                    <FormGroupSelect
                                        label="Ano final"
                                        size="small"
                                        value={this.state.end_year}
                                        options={endYearOptions}
                                        onChange={event =>
                                            this.setState({ end_year: event.target.value })
                                        }
                                        errorMessage={
                                            this.state.submitted &&
                                            parseInt(this.state.end_year) <
                                                parseInt(this.state.start_year) &&
                                            'Ano final deve ser igual ou maior do que o inicial.'
                                        }
                                    />
                                </div>
                            </div>

                            <label htmlFor="" className="label small">
                                Tipo de veículo
                            </label>

                            <div
                                className={
                                    this.state.submitted && !this.state.types.length
                                        ? 'seletor-veiculo-listing is-invalid'
                                        : 'seletor-veiculo-listing'
                                }
                            >
                                <label
                                    className="seletor-veiculo__item "
                                    htmlFor="seletor-veiculo-carro"
                                >
                                    <input
                                        type="checkbox"
                                        name=""
                                        id="seletor-veiculo-carro"
                                        checked={this.state.types.find(item => item === '0')}
                                        onChange={event =>
                                            this.setState({
                                                types: this.state.types.find(item => item === '0')
                                                    ? this.state.types.filter(item => item !== '0')
                                                    : this.state.types.concat('0'),
                                            })
                                        }
                                    />
                                    <div className="checkbox-box" />
                                    <div className="seletor-veiculo__icon">
                                        <img src={iconCar} alt="" />
                                    </div>
                                    <div className="seletor-veiculo__texto">
                                        Carros e utilitários pequenos
                                    </div>
                                </label>

                                <label
                                    className="seletor-veiculo__item"
                                    htmlFor="seletor-veiculo-caminhao"
                                >
                                    <input
                                        type="checkbox"
                                        name=""
                                        id="seletor-veiculo-caminhao"
                                        checked={this.state.types.find(item => item === '5')}
                                        onChange={event =>
                                            this.setState({
                                                types: this.state.types.find(item => item === '5')
                                                    ? this.state.types.filter(item => item !== '5')
                                                    : this.state.types.concat('5'),
                                            })
                                        }
                                    />
                                    <div className="checkbox-box" />
                                    <div className="seletor-veiculo__icon">
                                        <img src={iconTruck} alt="" />
                                    </div>
                                    <div className="seletor-veiculo__texto">
                                        Caminhões e micro ônibus
                                    </div>
                                </label>

                                <label
                                    className="seletor-veiculo__item"
                                    htmlFor="seletor-veiculo-motorcycle"
                                >
                                    <input
                                        type="checkbox"
                                        name=""
                                        id="seletor-veiculo-motorcycle"
                                        checked={this.state.types.find(item => item === '8')}
                                        onChange={event =>
                                            this.setState({
                                                types: this.state.types.find(item => item === '8')
                                                    ? this.state.types.filter(item => item !== '8')
                                                    : this.state.types.concat('8'),
                                            })
                                        }
                                    />
                                    <div className="checkbox-box" />
                                    <div className="seletor-veiculo__icon">
                                        <img src={iconMotorcycle} alt="" />
                                    </div>
                                    <div className="seletor-veiculo__texto">Motos</div>
                                </label>
                            </div>

                            {this.state.submitted && !this.state.types.length && (
                                <div
                                    className="form-validation-danger is-visible"
                                    style={{
                                        textAlign: 'center',
                                        marginTop: '1rem',
                                    }}
                                >
                                    <FontAwesomeIcon icon="exclamation-circle" /> Selecione um tipo
                                    de veículo.
                                </div>
                            )}

                            {this.state.errorMessage && (
                                <div style={this.style.messageBoxContainer}>
                                    <MessageBox type="danger">{this.state.errorMessage}</MessageBox>
                                </div>
                            )}

                            <div className="margin-top" style={{ textAlign: 'center' }}>
                                <button
                                    className="button primary big"
                                    style={{ maxWidth: '30rem', width: '100%' }}
                                    onClick={() => this.saveProfile()}
                                >
                                    Salvar
                                </button>
                            </div>
                        </React.Fragment>
                    )}

                    {this.state.profileCreated && (
                        <React.Fragment>
                            <div
                                style={{
                                    marginBottom: '2rem',
                                    textAlign: 'center',
                                }}
                            >
                                <FontAwesomeIcon
                                    style={{
                                        color: '#549e00',
                                        fontSize: '3rem',
                                    }}
                                    icon="check"
                                />
                            </div>
                            <div
                                style={{
                                    fontSize: '1.6rem',
                                    textAlign: 'center',
                                }}
                            >
                                Perfil criado com sucesso!
                            </div>
                        </React.Fragment>
                    )}
                </Modal>

                <Modal
                    title="Editar perfil de venda"
                    customClass="Modal-PerfilCompra --create"
                    ref={this.modalUpdate}
                >
                    {!this.state.profileUpdated && (
                        <React.Fragment>
                            <SpinnerFull
                                size={40}
                                spinnerColor={'#335180'}
                                spinnerWidth={3}
                                visible={this.state.loading}
                            />
                            <div className="form-row">
                                <div className="form-col--4">
                                    <FormGroupSelect
                                        label="Estado"
                                        size="small"
                                        options={stateOptions}
                                        value={this.state.state_id}
                                        onChange={event =>
                                            this.setState(
                                                {
                                                    state_id: event.target.value,
                                                },
                                                () => this.getRegions(this.state.state_id)
                                            )
                                        }
                                    />
                                </div>
                                <div className="form-col--4">
                                    <FormGroupSelect
                                        label="Região"
                                        size="small"
                                        options={regionOptions}
                                        value={this.state.region_id}
                                        onChange={event =>
                                            this.setState({
                                                region_id: event.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="form-col">
                                    <FormGroupSelect
                                        label="Ano inicial"
                                        size="small"
                                        value={this.state.start_year}
                                        options={startYearOptions}
                                        onChange={event =>
                                            this.setState({
                                                start_year: event.target.value,
                                            })
                                        }
                                        errorMessage={
                                            parseInt(this.state.end_year) <
                                                parseInt(this.state.start_year) &&
                                            'Ano final deve ser igual ou maior do que o inicial.'
                                        }
                                    />
                                </div>
                                <div className="form-col">
                                    <FormGroupSelect
                                        label="Ano final"
                                        size="small"
                                        value={this.state.end_year}
                                        options={endYearOptions}
                                        onChange={event =>
                                            this.setState({
                                                end_year: event.target.value,
                                            })
                                        }
                                        errorMessage={
                                            parseInt(this.state.end_year) <
                                                parseInt(this.state.start_year) &&
                                            'Ano final deve ser igual ou maior do que o inicial.'
                                        }
                                    />
                                </div>
                            </div>

                            <label htmlFor="" className="label small">
                                Tipo de veículo
                            </label>

                            <div className={'seletor-veiculo-listing'}>
                                <label
                                    className="seletor-veiculo__item "
                                    htmlFor="seletor-veiculo-carro-edit"
                                >
                                    <input
                                        type="checkbox"
                                        name=""
                                        id="seletor-veiculo-carro-edit"
                                        checked={
                                            this.state.types.filter(item => {
                                                return item === 0 || item === '0';
                                            }).length
                                        }
                                        onChange={event =>
                                            this.setState({
                                                types: this.state.types.filter(item => item === 0)
                                                    .length
                                                    ? this.state.types.filter(item => item !== 0)
                                                    : this.state.types.concat(0),
                                            })
                                        }
                                    />
                                    <div className="checkbox-box" />
                                    <div className="seletor-veiculo__icon">
                                        <img src={iconCar} alt="" />
                                    </div>
                                    <div className="seletor-veiculo__texto">
                                        Carros e utilitários pequenos
                                    </div>
                                </label>

                                <label
                                    className="seletor-veiculo__item"
                                    htmlFor="seletor-veiculo-caminhao-edit"
                                >
                                    <input
                                        type="checkbox"
                                        name=""
                                        id="seletor-veiculo-caminhao-edit"
                                        value={5}
                                        checked={
                                            this.state.types.filter(item => {
                                                return item === 5 || item === '5';
                                            }).length
                                        }
                                        onChange={event =>
                                            this.setState({
                                                types: this.state.types.filter(item => item === 5)
                                                    .length
                                                    ? this.state.types.filter(item => item !== 5)
                                                    : this.state.types.concat(5),
                                            })
                                        }
                                    />
                                    <div className="checkbox-box" />
                                    <div className="seletor-veiculo__icon">
                                        <img src={iconTruck} alt="" />
                                    </div>
                                    <div className="seletor-veiculo__texto">
                                        Caminhões e micro ônibus
                                    </div>
                                </label>

                                <label
                                    className="seletor-veiculo__item"
                                    htmlFor="seletor-veiculo-motorcycle-edit"
                                >
                                    <input
                                        type="checkbox"
                                        name=""
                                        id="seletor-veiculo-motorcycle-edit"
                                        value={8}
                                        checked={
                                            this.state.types.filter(item => {
                                                return item === 8 || item === '8';
                                            }).length
                                        }
                                        onChange={event =>
                                            this.setState({
                                                types: this.state.types.filter(item => item === 8)
                                                    .length
                                                    ? this.state.types.filter(item => item !== 8)
                                                    : this.state.types.concat(8),
                                            })
                                        }
                                    />
                                    <div className="checkbox-box" />
                                    <div className="seletor-veiculo__icon">
                                        <img src={iconMotorcycle} alt="" />
                                    </div>
                                    <div className="seletor-veiculo__texto">Motos</div>
                                </label>
                            </div>

                            {this.state.errorMessage && (
                                <div style={this.style.messageBoxContainer}>
                                    <MessageBox type="danger">{this.state.errorMessage}</MessageBox>
                                </div>
                            )}

                            <div className="margin-top" style={{ textAlign: 'center' }}>
                                <button
                                    className="button primary big"
                                    style={{ maxWidth: '30rem', width: '100%' }}
                                    onClick={() => this.updateProfile(this.state.id)}
                                >
                                    Salvar
                                </button>
                            </div>
                        </React.Fragment>
                    )}

                    {this.state.profileUpdated && (
                        <React.Fragment>
                            <div
                                style={{
                                    marginBottom: '2rem',
                                    textAlign: 'center',
                                }}
                            >
                                <FontAwesomeIcon
                                    style={{
                                        color: '#549e00',
                                        fontSize: '3rem',
                                    }}
                                    icon="check"
                                />
                            </div>
                            <div
                                style={{
                                    fontSize: '1.6rem',
                                    textAlign: 'center',
                                }}
                            >
                                Perfil atualizado com sucesso!
                            </div>
                        </React.Fragment>
                    )}
                </Modal>

                <Modal
                    title="Excluir perfil de venda"
                    customClass="Modal-PerfilCompra"
                    ref={this.modalRemove}
                >
                    {!this.state.profileDeleted ? (
                        <React.Fragment>
                            <SpinnerFull
                                size={40}
                                spinnerColor={'#335180'}
                                spinnerWidth={3}
                                visible={this.state.loading}
                            />
                            <div className="remove__title">
                                Tem certeza que deseja excluir esse perfil de venda?
                            </div>
                            <div className="remove__title-confirm">
                                {this.state.profileInfoDelete.state_name} /{' '}
                                {this.state.profileInfoDelete.region_info} /{' '}
                                {this.formatTypes(this.state.profileInfoDelete.types, 'delete')} /{' '}
                                {this.state.profileInfoDelete.start_year} -{' '}
                                {this.state.profileInfoDelete.end_year}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: 'auto',
                                }}
                            >
                                <button
                                    className="button primary outline margin-right"
                                    style={{ maxWidth: '20rem', width: '100%' }}
                                    onClick={this.closeModalRemove}
                                >
                                    Cancelar
                                </button>
                                <button
                                    onClick={() =>
                                        this.deleteProfile(this.state.profileInfoDelete.id)
                                    }
                                    className="button primary"
                                    style={{ maxWidth: '20rem', width: '100%' }}
                                >
                                    Excluir
                                </button>
                            </div>{' '}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div
                                style={{
                                    marginBottom: '2rem',
                                    textAlign: 'center',
                                }}
                            >
                                <FontAwesomeIcon
                                    style={{
                                        color: '#549e00',
                                        fontSize: '3rem',
                                    }}
                                    icon="check"
                                />
                            </div>
                            <div
                                style={{
                                    fontSize: '1.6rem',
                                    textAlign: 'center',
                                }}
                            >
                                Perfil deletado com sucesso!
                            </div>
                        </React.Fragment>
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(RepassesListActions, dispatch);

export default connect(null, mapDispatchToProps)(PagePerfilCompra);
