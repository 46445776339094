import { call, delay, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { Creators } from '../ducks/auth';
import api from '../../services/api';

export function* loginUser({ email, password }) {
    try {
        const {
            data: { data },
        } = yield call(api.post, '/api/v2/login', {
            email,
            password,
        });

        yield put(Creators.loginUserSuccess(data));
        yield localStorage.setItem('@Repasse:token', data.token);

        const {
            auth: { goto },
        } = yield select(state => state);

        if (!data.stores.length) {
            yield put(Creators.setHavePlan(false));

            if (goto) yield put(push(goto));
            else yield put(push('/'));
        } else if (data.stores.length === 1) {
            const storeId = data.stores[0].id;
            const user = data;

            yield call(loadUserData, { storeId, user });
        } else {
            yield put(push('/selecionar-loja'));
        }
    } catch ({ response }) {
        console.log({ response });

        if (response) {
            const { msg } = response.data;

            if (msg === 'Não autorizado.') {
                yield put(
                    Creators.loginUserFail('Verifique seu usuário e senha e tente novamente.')
                );
            } else {
                yield put(Creators.loginUserFail(msg));
            }
        } else yield put(Creators.loginUserFail('Ocorreu um problema com o login'));
    }
}

/** Função que carrega e salva dados adicionais do usuário  */
export function* loadUserData({ storeId, user }) {
    try {
        yield put(Creators.toggleAuthLoading(true));

        const additionalUserData = {
            terms: true,
            storeId: 0,
            roleUser: {},
            havePlan: false,
            hasInvites: false,
            isDirectorWithPlan: false,
            hasPJPermissions: false,
            scoreboard: {},
        };

        /** Busca na api qual o role do usuário na loja selecionada */
        const {
            data: { data },
        } = yield call(api.get, `api/v2/store/${storeId}/staff/${user.id}`);

        if (data.role === 'Diretor') additionalUserData.roleUser = { id: 2, role: data.role };
        if (data.role === 'Gerente') additionalUserData.roleUser = { id: 3, role: data.role };
        if (data.role === 'Vendedor') additionalUserData.roleUser = { id: 4, role: data.role };

        /** Armazena loja selecionada */
        additionalUserData.storeId = storeId;

        /** Verifica se a loja selecionada tem plano */
        const user_store = user.stores.find(item => item.id === Number(storeId));
        additionalUserData.havePlan = user_store.have_plan;

        /** Verifica se o usuário é diretor com plano  */
        if (data.role === 'Diretor' && user_store.have_plan)
            additionalUserData.isDirectorWithPlan = true;

        /** Verifica se o usuário tem permissões de PJ */
        if ((Number(user.user_type) !== 3 && Number(user.user_type) !== 4) || user_store.have_plan)
            additionalUserData.hasPJPermissions = true;

        /** Busca na api se o usuário tem convites pendentes */
        const {
            data: {
                data: { items },
            },
        } = yield call(api.get, `/api/v2/store/staff/invites`);
        additionalUserData.hasInvites = items.length ? true : false;

        /** Busca na api se o usuário aceitou os termos de uso */
        const {
            data: { terms, plan },
        } = yield call(api.get, '/api/v2/user/terms');
        additionalUserData.terms = terms && plan ? true : false;

        /** Carrega placar caso seja diretor com plano */
        if (additionalUserData.isDirectorWithPlan) {
            const {
                data: { data },
            } = yield call(api.get, '/api/v2/report/scoreboard');

            additionalUserData.scoreboard = {
                qty_active: data.qty_active,
                qty_cars: data.qty_cars,
                qty_franchise: data.qty_franchise,
                qty_salesman: data.qty_salesman,
                qty_transactions: data.qty_transactions,
                qty_turbos: data.qty_turbos,
            };
        }

        /** Seta todos os dados no reducer */
        yield put(Creators.setAdditionalUserData(additionalUserData));

        const {
            auth: { goto },
        } = yield select(state => state);

        if (goto) yield put(push(goto));
        else yield put(push('/'));
    } catch (error) {
        console.log(error);

        yield put(Creators.logoutUser());

        yield put(push('/login'));
        yield put(Creators.loginUserFail('Não foi possível carregar as informações desse usuário'));
    } finally {
        yield put(Creators.toggleAuthLoading(false));
    }
}

export function* acceptTermsOfUse() {
    console.log('accepttermsofuse');
    try {
        yield call(api.post, '/api/v2/user/terms/accept');

        yield put(Creators.setTermsOfUse(true));
    } catch (error) {
        console.log('Erro acceptTerms: ', error);
    }
}

export function* logoutUser() {
    yield put({ type: 'RESET_APP' });

    yield localStorage.removeItem('@Repasse:token');

    yield put(push('/login'));

    yield delay(1000);
    yield window.location.reload();
}
