import React from 'react';
import InputMask from 'react-input-mask';

// import { Link } from 'react-router-dom';

import './style.sass';

/**
 * Seller
 *
 * @param {*} props.isLite Add classe .is-lite no component.
 * @param {*} props.sellerImage Url da imagem do vendedor.
 */
const Seller = props => {
    return (
        <div className={props.isLite ? 'Seller is-lite' : 'Seller'}>
            {props.sellerImage && !props.isLite && (
                <div className="Seller-image">
                    <img src={props.sellerImage} alt="Foto do vendedor" />
                </div>
            )}
            <div className="Seller-info">
                <div className="Seller-label">Vendedor: </div>
                <div className="Seller-name">{props.sellerName}</div>
                {props.sellerCity && <div className="Seller-name">{props.sellerCity}</div>}
                <div className="Seller-phones">
                    {props.sellerPhone && (
                        <div className="Seller-phone-item">
                            <InputMask
                                mask="(99) 9999-9999"
                                value={props.sellerPhone}
                                style={{ border: 0, width: 100 }}
                                readOnly
                            />
                        </div>
                    )}
                    {props.sellerCellPhone && (
                        <div className="Seller-phone-item">
                            <InputMask
                                mask="(99) 99999-9999"
                                value={props.sellerCellPhone}
                                style={{ border: 0, width: 110 }}
                                readOnly
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Seller;
