import React, { Component } from 'react';
import { Line } from 'rc-progress';
import './style.sass';

/**
 * @param {string} props.corElemento
 * @param {number} props.valorPorcentagem
 */
export default class BarraPorcentagem extends Component {
    setColorFromPercentage = percentage => {
        if (percentage < 15) {
            return '#D0021B';
        } else if (percentage < 30) {
            return '#F5A623';
        } else {
            return '#2B4F81';
        }
    };

    render() {
        let { corElemento, valorPorcentagem } = this.props;
        corElemento = this.props.corElemento
            ? this.props.corElemento
            : this.setColorFromPercentage(valorPorcentagem);

        return (
            <div className="BarraPorcentagem">
                <div className="BarraPorcentagem-titulo" style={{ color: corElemento }}>
                    {valorPorcentagem}% FIPE
                </div>
                <Line
                    percent={valorPorcentagem}
                    strokeWidth="3"
                    trailWidth="3"
                    strokeColor={corElemento}
                />
            </div>
        );
    }
}
