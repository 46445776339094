import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnimateHeight from 'react-animate-height';

import './style.sass';

export default class BoxChatInfoBar extends Component {
    state = {
        height: 0,
    };

    toggle = () => {
        const { height } = this.state;

        this.setState({
            height: height === 0 ? 'auto' : 0,
        });
    };

    render() {
        const { height } = this.state;

        return (
            <div className="chat-info-bar">
                <button onClick={this.toggle} className="botao-toggle">
                    {height === 0
                        ? 'Mostrar Informações do Veículo'
                        : 'Ocultar Informações do Veículo'}
                </button>
                <AnimateHeight duration={300} height={height}>
                    <div className="container-flex-info-chat">
                        <div className="foto-veiculo">
                            <img src={this.props.fotoVeiculo} alt="" />
                        </div>
                        <div className="desc-veiculo">
                            <div className="nome-veiculo">{this.props.nomeVeiculo}</div>
                            <div className="detalhe-veiculo">
                                {this.props.anoVeiculo}- {this.props.modeloVeiculo}
                            </div>
                            <div className="detalhe-veiculo">{this.props.corVeiculo}</div>
                            <div className="status-veiculo">Status: {this.props.statusVeiculo}</div>
                        </div>
                        <div className="proposta-container">
                            {this.props.valorProposta && (
                                <div className="texto-proposta">
                                    <FontAwesomeIcon icon="dollar-sign" /> Proposta:
                                    {this.props.valorProposta}
                                </div>
                            )}
                            <div className="vendedor-container">
                                <div className="foto-vendedor">
                                    <img src={this.props.fotoVendedor} alt="" />
                                </div>
                                <div className="info-vendedor">
                                    <div className="titulo-info-vendedor">Vendedor:</div>
                                    <div className="nome-info-vendedor">
                                        {this.props.nomeVendedor}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="botao-anunciar">
                            <button className="button blue">
                                <Link
                                    to={`/repasses/${this.props.idVeiculo}/${
                                        this.props.idVendedor
                                    }`}
                                >
                                    Ver anúncio
                                </Link>
                            </button>
                        </div> */}
                    </div>
                </AnimateHeight>
            </div>
        );
    }
}
