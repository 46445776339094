import React, { Component } from 'react';

import './style.sass';

export default class PlanoCadastroMobileItem extends Component {
    render() {
        return (
            <div className="plano-cadastro-mobile-item">
                <div className="titulo-plano">{this.props.nomePlano}</div>
                <div className="valor-plano">
                    R${this.props.valorPlano}
                    <span className="tag-mes">/mês</span>
                </div>
                <div className="desc-item">
                    <div className="titulo-item">Transações mensais</div>
                    <div className="conteudo-item">{this.props.transacoesMensais}</div>
                </div>
                <div className="desc-item">
                    <div className="titulo-item">Adicional por transação</div>
                    <div className="conteudo-item">R$ {this.props.adicionalTransacao}</div>
                </div>
                <div className="desc-item">
                    <div className="titulo-item">Fotos por veículo</div>
                    <div className="conteudo-item">{this.props.fotosVeiculo}</div>
                </div>
                <div className="desc-item">
                    <div className="titulo-item">Turbos por Mês</div>
                    <div className="conteudo-item">{this.props.turbosMes}</div>
                </div>
                <div className="desc-item">
                    <div className="titulo-item">Turbo Avulso</div>
                    <div className="conteudo-item">R$ {this.props.turboAvulso}</div>
                </div>
                <div className="desc-item">
                    <div className="titulo-item">
                        Consultar Veicular
                        <br />
                        <span className="span-consulta">(no primeiro mês)</span>
                    </div>
                    <div className="conteudo-item">{this.props.consultaPrimeiroMes}</div>
                </div>
                <div className="desc-item">
                    <div className="titulo-item">Consulta Veicular</div>
                    <div className="conteudo-item">R$ {this.props.consultaVeicular}</div>
                </div>
                <div className="desc-item">
                    <div className="titulo-item">Diretores</div>
                    <div className="conteudo-item">{this.props.diretores}</div>
                </div>
                <div className="desc-item">
                    <div className="titulo-item">Gerentes</div>
                    <div className="conteudo-item">{this.props.gerentes}</div>
                </div>
                <div className="desc-item">
                    <div className="titulo-item">Vendedores</div>
                    <div className="conteudo-item">{this.props.vendedores}</div>
                </div>

                <button
                    className="button primary"
                    onClick={() => this.props.choosePlan(this.props.idPlano, this.props.planObject)}
                >
                    Escolher esse Plano
                </button>
            </div>
        );
    }
}
