import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

/**
 * @param {string} props.id
 * @param {string} props.label
 * @param {string} props.size
 * @param {string} props.labelSize
 * @param {string} props.inputSize
 * @param {string} props.errorMessage
 * @param {string|number} props.option.value
 * @param {string|number} props.option.content
 * @param {function} props.onChange
 * @param {function} props.onClick
 * @param {function} props.value
 */
const FormGroupSelect = props => {
    const {
        label = 'Label',
        labelSize = '',
        inputSize = '',
        size = '',
        options,
        errorMessage = '',
        placeholder,
    } = props;

    const labelClass = classNames('label', [labelSize], [size]);
    const inputClass = classNames('form-control', [inputSize], [size]);
    const groupClass = classNames('FormGroupInput', 'form-group', {
        'is-invalid': errorMessage,
        'no-margin': props.noMargin,
    });

    return (
        <div className={groupClass}>
            {label && (
                <label
                    className={labelClass}
                    htmlFor={props.id}
                    style={{ color: props.labelColor }}
                >
                    {label}
                </label>
            )}

            <select
                className={inputClass}
                id={props.id}
                onChange={props.onChange}
                onClick={props.onClick}
                value={props.value}
                readOnly={props.readOnly}
                disabled={props.disabled}
            >
                {placeholder && (
                    <option value="" disabled={true}>
                        {placeholder}
                    </option>
                )}
                {options &&
                    options.map((option, index) => {
                        return (
                            <option value={option.value} key={index}>
                                {option.content}
                            </option>
                        );
                    })}
            </select>

            {errorMessage && (
                <div className="form-validation-danger is-visible">
                    <FontAwesomeIcon icon="exclamation-circle" /> {errorMessage}
                </div>
            )}
        </div>
    );
};

export default FormGroupSelect;
