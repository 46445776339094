import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconTurbo from '../../assets/images/turbo.png';
import Modal from '../Modal';
import MessageBox from '../MessageBox';
import SpinnerFull from '../SpinnerFull';
import IconText from '../IconText';

import api from '../../services/api';

import './style.sass';
export default class TurboCallModal extends Component {
    turboRef = React.createRef();

    state = {
        error: '',
        loading: false,
        successMessage: false,
        turbo: this.props.turbo,
    };

    componentDidUpdate = prevProps => {
        if (prevProps.showModalTurbo !== this.props.showModalTurbo) {
            this.openTurboModal();
        }
    };

    openTurboModal = () => this.turboRef.current.openModal();

    consumeTurbo = async car_id => {
        try {
            if (this.props.qtyFranchise >= 10) {
                this.setState({
                    error: 'Você já usou 10 turbos, entre em contato para adquirir mais!',
                });

                return;
            }

            if (this.state.turbo) {
                this.setState({
                    error: 'Este anúncio já está turbinado',
                });

                return;
            }

            if (this.props.qtyFranchise < 10 && !this.state.turbo) {
                this.setState({ loading: true });

                const { data } = await api.get(`/api/v2/products/quote/1`);
                const product_id = data[0].id;

                await api.post(`/api/v2/products/consume/turbo`, {
                    product_id,
                    car_id,
                });

                this.props.getScoreboard();
                this.setState({ turbo: true, successMessage: true });
            }
        } catch (error) {
            console.log({ error });

            const { response } = error;

            if (response !== undefined) {
                const { msg } = response.data;
                this.setState({ error: msg });
            } else this.setState({ error: 'Ocorreu um problema ao adicionar o turbo' });
        } finally {
            this.setState({ loading: false });
        }
    };

    activeTurbo = () => {
        this.consumeTurbo(this.props.carId);
    };

    render() {
        return (
            <div className="TurboTab">
                <div className="mini-title">
                    <img className="mini-title-icon" src={iconTurbo} alt="" />
                    Turbo
                </div>

                <div className="TurboTab-box">
                    <div className="TurboTab-image">
                        <img src={iconTurbo} alt="" />
                    </div>
                    <div className="TurboTab-text">
                        {this.state.turbo
                            ? 'Parabéns! Você já adicionou pelo menos um turbo a esse anúncio.'
                            : 'Este anúncio não possui um pacote Turbo. Acelere suas vendas, obtenha agora!'}
                    </div>
                    <button className="button blue TurboTab-button" onClick={this.openTurboModal}>
                        Ver Pacotes
                    </button>

                    {this.props.turboReach && (
                        <div className="TurboReach-Outside">
                            <div className="TurboReach-container">
                                <div className="Turbo-bold">
                                    <div className="icone">
                                        <IconText icon="bell" />
                                    </div>
                                    <div className="Turbo-value">
                                        {this.props.turboReach.notifications
                                            ? this.props.turboReach.notifications
                                            : 0}
                                    </div>
                                </div>
                                <div className="Turbo-titulo">Notificações</div>
                            </div>
                            <div className="TurboReach-container">
                                <div className="Turbo-bold">
                                    <div className="icone">
                                        <IconText icon="comment" />
                                    </div>
                                    <div className="Turbo-value">
                                        {this.props.turboReach.negotiations
                                            ? this.props.turboReach.negotiations
                                            : 0}
                                    </div>
                                </div>
                                <div className="Turbo-titulo">Negociações</div>
                            </div>
                            <div className="TurboReach-container">
                                <div className="Turbo-bold">
                                    <div className="icone">
                                        <IconText icon="phone" />
                                    </div>
                                    <div className="Turbo-value">
                                        {this.props.turboReach.calls
                                            ? this.props.turboReach.calls
                                            : 0}
                                    </div>
                                </div>
                                <div className="Turbo-titulo">Ligações</div>
                            </div>
                            <div className="TurboReach-container">
                                <div className="Turbo-bold">
                                    <div className="icone">
                                        <IconText icon="star" />
                                    </div>
                                    <div className="Turbo-value">
                                        {this.props.turboReach.favorites
                                            ? this.props.turboReach.favorites
                                            : 0}
                                    </div>
                                </div>
                                <div className="Turbo-titulo">Favoritos</div>
                            </div>
                        </div>
                    )}

                    <Modal
                        ref={this.turboRef}
                        title="Turbine seu Anúncio"
                        onClose={() => this.setState({ successMessage: false })}
                    >
                        {!this.state.successMessage ? (
                            <div className="modal-turbo-content">
                                <SpinnerFull
                                    size={40}
                                    spinnerColor={'#335180'}
                                    spinnerWidth={3}
                                    visible={this.state.loading}
                                />
                                <div className="texto-desc">
                                    Impulsione seu anúncio com o turbo! Aumente a visibilidade,
                                    receba muito mais propostas e venda mais rápido!
                                </div>

                                <div className="image-turbo">
                                    <img src={iconTurbo} alt="" />
                                </div>

                                <div className="flex-desc-container">
                                    <div className="desc-item">
                                        <div className="titulo-desc-item">
                                            {this.props.qtyFranchise}/10
                                        </div>
                                        <div className="conteudo-desc-item">
                                            turbos da franquia utilizados
                                        </div>
                                    </div>
                                    <div className="desc-item">
                                        <div className="titulo-desc-item">R$ 50</div>
                                        <div className="conteudo-desc-item">
                                            por turbo adicional
                                        </div>
                                    </div>
                                </div>

                                {this.state.error && (
                                    <div style={{ textAlign: 'center', marginBottom: 15 }}>
                                        <MessageBox type="danger" size="normal">
                                            {this.state.error}
                                        </MessageBox>
                                    </div>
                                )}

                                <div className="botao-turbo">
                                    <button
                                        className="button primary"
                                        style={{ width: 300 }}
                                        onClick={this.activeTurbo}
                                    >
                                        Utilizar Turbo
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <React.Fragment>
                                <div
                                    style={{
                                        marginBottom: '2rem',
                                        textAlign: 'center',
                                    }}
                                >
                                    <FontAwesomeIcon
                                        style={{
                                            color: '#549e00',
                                            fontSize: '3rem',
                                        }}
                                        icon="check"
                                    />
                                </div>
                                <div
                                    style={{
                                        fontSize: '1.6rem',
                                        textAlign: 'center',
                                    }}
                                >
                                    Turbo adicionado com sucesso!
                                </div>
                            </React.Fragment>
                        )}
                    </Modal>
                </div>
            </div>
        );
    }
}
