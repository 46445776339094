import React from 'react';
import InputMask from 'react-input-mask';
// import iconArrowPositive from '../../assets/images/icon-arrow-positive.png';
// import iconArrowNegative from '../../assets/images/icon-arrow-negative.png';

import './style.sass';

const GestaoEquipeInviteRow = props => {
    /* function returnVariationIcon(variationInput) {
        if (variationInput === 'positive' || variationInput === 'pos') {
            return iconArrowPositive;
        } else if (variationInput === 'negative' || variationInput === 'neg') {
            return iconArrowNegative;
        } else {
            return null;
        }
    } */

    function returnNameInitials(name) {
        const names = name.split(' ');
        const initials = names.map(name => name[0]).join('');

        if (initials.length > 2) {
            return '' + initials[0] + initials[initials.length - 1];
        }

        return initials;
    }

    const nameInitials = returnNameInitials(props.name);
    // const salesVariation = returnVariationIcon(props.salesVariation);
    // const avgTimeVariation = returnVariationIcon(props.avgTimeVariation);

    let isLite = false;
    let avatar = props.imageUrl ? (
        <img className="gestao-equipe-avatar" src={props.imageUrl} alt="Avatar" />
    ) : (
        <div className="gestao-equipe-initials">{nameInitials}</div>
    );

    if (props.isLite === true) {
        isLite = true;
    }

    return (
        <div className={isLite ? 'GestaoEquipeRow is-lite' : 'GestaoEquipeRow'}>
            <div className="GestaoEquipeRow-item --avatar">
                <div className="gestao-equipe-avatar-container">{avatar}</div>
            </div>

            <div className="GestaoEquipeRow-item --name">
                <div className="gestao-equipe-name-container">
                    <div className="gestao-equipe-name">{props.name}</div>
                    <div className="gestao-equipe-job-title">{props.jobTitle}</div>
                    <div className="gestao-equipe-phones">
                        <InputMask
                            mask="(99) 9999-9999"
                            value={props.phone}
                            style={{ border: 0 }}
                            readOnly
                        />
                        <div>{props.email}</div>
                    </div>
                </div>
            </div>

            <div style={{ margin: '0 auto' }} />
            <button className="button blue" style={{ marginRight: 15 }} onClick={props.onClick}>
                Cancelar convite
            </button>
        </div>
    );
};

export default GestaoEquipeInviteRow;
