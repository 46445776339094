import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { baseURL } from '../../services/api';
import MensagemItem from '../MensagemItem';
import Modal from '../Modal';
import './style.sass';

class BoxChatMessageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            urlImage: '',
            messages: [],
        };

        this.messagesEnd = React.createRef();
        this.modalImagem = React.createRef();
    }

    scrollToBottom = () => {
        this.messagesEnd.current && this.messagesEnd.current.scrollIntoView({ behavior: 'auto' });
    };

    componentDidMount = () => {
        this.scrollToBottom();
    };

    componentDidUpdate = () => {
        this.scrollToBottom();
    };

    openModal = () => {
        this.modalImagem.current.openModal();
    };

    zoomImage = url => {
        this.setState({ urlImage: url }, () => this.openModal());
    };

    render() {
        const { messages } = this.props;

        return (
            <div className="container-mensagens">
                <Modal ref={this.modalImagem} customClass="ModalGaleria" title="Imagem">
                    <div>
                        <img src={this.state.urlImage} alt="" />
                    </div>
                </Modal>
                {
                    messages.map((item, key) => (
                        <div key={key}>
                            <div className="tag-dia">{moment(item.date).format('DD/MM/YYYY')}</div>
                            {
                                item.msgs.map((element, key) => {
                                    return (
                                        <MensagemItem
                                            key={key}
                                            data={element}
                                            right={
                                                element.car ?
                                                    element.sender_id === this.props.user.id ||
                                                    element.sender_id === element.car.user_id :
                                                    element.receiver_id !== this.props.user.id
                                            }
                                        >
                                            {
                                                element.message ? (
                                                    element.message
                                                ) : element.image ? (
                                                    <img
                                                        onClick={() =>
                                                            this.zoomImage(`${baseURL}/api/get-chat-image/${element.image}`)
                                                        }
                                                        src={`${baseURL}/api/get-chat-image/${element.image}`}
                                                        alt=""
                                                    />
                                                ) : element.audio ? (
                                                    <audio
                                                        className="audio-item"
                                                        src={`${baseURL}/api/get-chat-audio/${element.audio}`}
                                                        controls
                                                    />
                                                ) : null
                                            }
                                        </MensagemItem>
                                    );
                                })
                            }
                            <div ref={this.messagesEnd} />
                        </div>
                    ))
                }
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
});

export default connect(mapStateToProps)(BoxChatMessageContainer);
