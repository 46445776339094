import { createReducer } from 'reduxsauce';

import { Types } from './actions';

/* Initial State */

const INITIAL_STATE = {
    error: '',
    loading: false,
    fields: {},
};

/* Reducers */

const setError = (state = INITIAL_STATE, action) => ({
    ...state,
    error: action.msg,
});

const setLoading = (state = INITIAL_STATE, action) => ({
    ...state,
    loading: action.value,
});

const setFieldsByType = (state = INITIAL_STATE, action) => ({
    ...state,
    fields: action.data,
});

const setFieldsByBrand = (state = INITIAL_STATE, action) => ({
    ...state,
    fields: {
        ...state.fields,
        years: action.data,
    },
});

const setFieldsByYear = (state = INITIAL_STATE, action) => ({
    ...state,
    fields: {
        ...state.fields,
        models: action.data,
        percent: action.percent,
    },
});

const setFieldsByFipe = (state = INITIAL_STATE, action) => ({
    ...state,
    fields: {
        ...state.fields,
        models: [action]
    },
});

const resetApp = (state = INITIAL_STATE, action) => ({
    ...state,
    ...INITIAL_STATE,
});

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
    [Types.SET_ERROR]: setError,
    [Types.SET_LOADING]: setLoading,
    [Types.SET_FIELDS_BY_TYPE]: setFieldsByType,
    [Types.SET_FIELDS_BY_BRAND]: setFieldsByBrand,
    [Types.SET_FIELDS_BY_YEAR]: setFieldsByYear,
    [Types.SET_FIELDS_BY_FIPE]: setFieldsByFipe,
    [Types.RESET_APP]: resetApp,
});
